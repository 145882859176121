import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import ReactTable from 'react-table'

import {
	startWatchingDispatchLiveRequests,
	stopWatchingDispatchLiveRequests,
} from './../../../actions'
import { getDispatchLiveRequestsArray } from './../../../selectors'

import TEHelmet from './../../../component/Header/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import { tableColumns } from './tableConfigs'
import { customTableFilter } from './../../../helpers'
import styles from './styles'

class Live extends Component {
	state = { activeView: 'Routes' }

	componentDidMount() {
		const { startWatchingDispatchLiveRequests } = this.props

		startWatchingDispatchLiveRequests()
	}
	componentWillUnmount() {
		const { stopWatchingDispatchLiveRequests } = this.props

		stopWatchingDispatchLiveRequests()
	}

	render() {
		const meta = {
			title: 'FirstClass | Dispatch Live',
			description: '',
		}

		const { requestArray, liveRequestsLoaded } = this.props

		return (
			<Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper style={styles.panelWrapper}>
					<Panel title="Live Ride Requests">
						<ReactTable
							data={requestArray}
							columns={tableColumns}
							defaultPageSize={10}
							filterable
							defaultFilterMethod={customTableFilter}
							defaultSorted={[
								{
									id: 'requestTime',
									desc: false,
								},
							]}
							loading={!liveRequestsLoaded}
						/>
					</Panel>
				</PanelWrapper>
			</Fragment>
		)
	}
}

Live.propTypes = {}

Live.defaultProps = {}

const mapStateToProps = (state) => {
	const { liveRequestsLoaded } = state.Dispatch
	const requestArray = getDispatchLiveRequestsArray(state)

	return {
		requestArray,
		liveRequestsLoaded,
	}
}

export default connect(
	mapStateToProps,
	{
		startWatchingDispatchLiveRequests,
		stopWatchingDispatchLiveRequests,
	}
)(Radium(Live))
