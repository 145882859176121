import React from 'react'

import TEButton from './../../../component/Form/TEButton'

import styles from './styles'

export const tableColumns =  (handleRemoveUser) => [{
	id: 'name',
	Header: 'Alias',
	accessor: 'profile.alias'
}, {
	id: 'email',
	Header: 'Email',
	accessor: 'profile.email'
}, {
	id: 'phone',
	Header: 'Phone',
	accessor: 'profile.phone'
}, {
	id: 'actions',
	Header: '',
	width: 120,
	sortable: false,
	filterable: false,
	accessor: 'uid',
	Cell: d => <TEButton onClick={() => handleRemoveUser(d.value)} style={styles.tableButton}>Remove</TEButton>
}]
