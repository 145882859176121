import {
    //SHOW_ALERT,
    SHOW_NETWORK_ACTIVITY,
    HIDE_NETWORK_ACTIVITY,
    LoadingKeys,
    AuthKeys,
    RiderKeys,
} from './types'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

// import { unregister } from './../registerServiceWorker'
// import { compareToLocalVersion } from './../helpers'

export const startWatchingAppVersion = () => {
    return (dispatch) => {
        firebase
            .database()
            .ref(`Versions/web`)
            .on('value', (snapshot) => {
                // if (snapshot.val()) {
                //     const { minimumVersion, currentVersion } = snapshot.val()
                //     if (minimumVersion) {
                //         const { behind } = compareToLocalVersion(minimumVersion)
                //         if (behind) {
                //             // console.log(
                //             //  'Attempting to unregister Service worker due to being behind minimumVersion'
                //             // )
                //             unregister()
                //         }
                //     }
                //     if (currentVersion) {
                //         const { behind } = compareToLocalVersion(currentVersion)
                //         if (behind) {
                //             // console.log(
                //             //  'Attempting to unregister Service worker due to being behind currentVersion'
                //             // )
                //             unregister()
                //         }
                //     }
                // }
                dispatch({
                    type: LoadingKeys.FETCH_APP_VERSIONS,
                    payload: snapshot.val(),
                })
            })
    }
}

///////////
// ADMIN //
///////////

//////////////////
// COORDINATORS //
//////////////////
//
//Profile
//
const startWatchingRider = (dispatch, getState) => {
    const {
        FETCH_RIDER,
        SAVE_RIDER_WATCHER,

        FETCH_RIDER_ORGANIZATION,
        SAVE_RIDER_ORGANIZATION_WATCHER,
    } = RiderKeys

    const { uid } = firebase.auth().currentUser
    const riderWatcherRef = firebase.database().ref(`Riders/${uid}`)
    riderWatcherRef.on('value', (snapshot) => {
        if (snapshot.val() && snapshot.val().organizations) {
            for (const key in snapshot.val().organizations) {
                const organizationWatcherRef = firebase
                    .database()
                    .ref(`Organizations/OrgInfo/${key}`)
                organizationWatcherRef.on('value', (snapshot) => {
                    dispatch({
                        type: FETCH_RIDER_ORGANIZATION,
                        payload: {
                            organizationInfo: snapshot.val(),
                            uid: key,
                        },
                    })
                })
                dispatch({
                    type: SAVE_RIDER_ORGANIZATION_WATCHER,
                    payload: {
                        ref: organizationWatcherRef,
                        uid: key,
                    },
                })
            }
        }

        dispatch({
            type: FETCH_RIDER,
            payload: snapshot.val(),
        })

        //*******************//
        // COMPLETED LOADING //
        //*******************//
        // - UserPermissions Loaded
        // - Loaded Coordinator
        //
        const { userCheckComplete } = getState().Loading
        if (!userCheckComplete) {
            dispatch({ type: HIDE_NETWORK_ACTIVITY })
            dispatch({ type: LoadingKeys.USER_CHECK_COMPLETE })
        }
    })

    dispatch({
        type: SAVE_RIDER_WATCHER,
        payload: riderWatcherRef,
    })
}
const stopWatchingCoordinator = (dispatch, getState) => {
    const { riderWatcherRef, organizationWatchers } = getState().Rider

    if (riderWatcherRef) {
        riderWatcherRef.off()
        dispatch({ type: RiderKeys.REMOVE_RIDER_WATCHER })
    }

    if (organizationWatchers) {
        for (const key in organizationWatchers) {
            organizationWatchers[key].off()
        }
        dispatch({ type: RiderKeys.REMOVE_RIDER_ORANIZATION_WATCHERS })
    }
}

//
//User Permissions
//
const startWatchingUserPermissions = (dispatch, getState) => {
    //Load User Type
    const { uid } = firebase.auth().currentUser
    const userPermissionsWatcherRef = firebase.database().ref(`UserPermissions/${uid}`)
    userPermissionsWatcherRef.on('value', (snapshot) => {
        dispatch({
            type: AuthKeys.FETCH_USER_PERMISSIONS,
            payload: snapshot.val(),
        })
        const userPermissions = snapshot.val()
        if (userPermissions && userPermissions.admin) {
            //*******************//
            // COMPLETED LOADING //
            //*******************//
            // - UserPermissions Loaded
            //
            const { userCheckComplete } = getState().Loading
            if (!userCheckComplete) {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: LoadingKeys.USER_CHECK_COMPLETE })
            }
        } else if (userPermissions && userPermissions.rider) {
            startWatchingRider(dispatch, getState)
        } else {
            //*******************//
            // COMPLETED LOADING //
            //*******************//
            // - UserPermissions Loaded
            // - NEITHER ADMIN OR COORDINATOR!?!?
            //
            const { userCheckComplete } = getState().Loading
            if (!userCheckComplete) {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: LoadingKeys.USER_CHECK_COMPLETE })
            }
        }
    })
    dispatch({
        type: AuthKeys.SAVE_USER_PERMISSIONS_WATCHER,
        payload: userPermissionsWatcherRef,
    })
}
const stopWatchingUserPermissions = (dispatch, getState) => {
    const { userPermissionsWatcherRef } = getState().Auth
    if (userPermissionsWatcherRef) {
        userPermissionsWatcherRef.off()
        dispatch({ type: AuthKeys.REMOVE_USER_PERMISSIONS_WATCHER })
    }
}

//
//Actions
//
//
// THIS STARTS OFF THE LOADING PROCESS.
//  - EVERY PAGE WAITS TO RENDER UNTIL LoadingKeys.USER_CHECK_COMPLETE
//      IS TRIGGERED. THIS HANDLES MANY BUGS OF NOT HAVING ALL THE
//      REQUIRED DATA NEEDED FOR A PAGE
//  - LIKELY NOT THE BEST WAY TO DO THIS BUT WORKS.... SOC
//
export const startFirebaseUserWatcher = () => {
    return (dispatch, getState) => {
        //Questionable if we want this here. Turning it off for now. This should be so quick that it doesn't matter.
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Loading...',
        })

        //
        //USED FOR WORKING ON THE SITE OFFLINE
        //
        //dispatch({ type: HIDE_NETWORK_ACTIVITY })
        //dispatch({ type: LoadingKeys.USER_CHECK_COMPLETE})
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // console.log("USER SIGNED IN. START WATCHERS!")
                dispatch({ type: LoadingKeys.USER_CHECK_STARTED })
                startWatchingUserPermissions(dispatch, getState)
            } else {
                //*******************//
                // COMPLETED LOADING //
                //*******************//
                // - UserPermissions Failed To Load -> Not Signed In
                //
                const { userCheckComplete } = getState().Loading
                if (!userCheckComplete) {
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                    dispatch({ type: LoadingKeys.USER_CHECK_COMPLETE })
                }

                // console.log("USER SIGNED OUT. STOP WATCHERS!")
                stopWatchingUserPermissions(dispatch, getState)
                stopWatchingCoordinator(dispatch, getState)
            }
        })
    }
}
