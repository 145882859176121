import { colors } from './../../../config/styles'
//import images from './../../../config/images'

export default {
	container : {
		alignItems 		: 'center',
		justifyContent	: 'center',
		minHeight 		: 'calc(100vh - 160px)',
	},
	panel : {
		maxWidth 		: 700,
		borderRadius 	: 5,
		paddingTop 		: 60,
		paddingBottom 	: 60,
		paddingRight 	: 60,
		paddingLeft 	: 60,
	},
	form : {
		backgroundColor     : colors.blue,
		paddingTop 			: 40,
		paddingBottom 		: 40,
		paddingRight 		: 40,
		paddingLeft 		: 40,
		maxWidth 			: 600,
		borderRadius 		: 5,
		textAlign 			: 'center',
	},
	title : {
		color               : colors.white,
		fontSize            : 44,
		textAlign           : 'center',
		marginTop           : 0,
		marginBottom        : 10,
	},
	signIn : {
		textDecoration  : 'none',
		color           : colors.white,
		fontSize        : 18,
		textAlign 		: 'center',
		display 		: 'inline-block',
		marginBottom 	: 30,
	},
	label : {
		display : 'none',
	},
	input : {
		border  		: 'none',
		paddingTop 		: 10,
		paddingBottom 	: 10,
		paddingRight 	: 10,
		paddingLeft 	: 10,

		':hover' : {
			border      : 'none',
			boxShadow   : 'none',
		},
		':active' : {
			border      : 'none',
			boxShadow   : 'none',
		}
	},
	buttonContainer : {
		display             : 'flex',
		justifyContent      : 'center',
		alignItems 			: 'center',
		width               : '100%',
	},
	button : {
		width 			: 'auto',
		paddingTop 		: 10,
		paddingBottom 	: 10,
		paddingRight 	: 50,
		paddingLeft 	: 50,
		display 		: 'inline-block',

		borderTop			: `1px solid ${colors.white}`,
		borderRight			: `1px solid ${colors.white}`,
		borderBottom		: `1px solid ${colors.white}`,
		borderLeft			: `1px solid ${colors.white}`,

		':hover' : {
			borderTop			: `1px solid ${colors.white}`,
			borderRight			: `1px solid ${colors.white}`,
			borderBottom		: `1px solid ${colors.white}`,
			borderLeft			: `1px solid ${colors.white}`,
		},
		':active' : {
			borderTop			: `1px solid ${colors.white}`,
			borderRight			: `1px solid ${colors.white}`,
			borderBottom		: `1px solid ${colors.white}`,
			borderLeft			: `1px solid ${colors.white}`,
		}
	},
}
