export const colors = {
    white: '#ffffff',
    lightestGray: '#f6f6f6', //Background
    lighterGray: '#eff3f4', //Borders (Input / Nav)
    lightGray: '#e5e5e5',
    gray: '#a0a0a0',
    darkGray: '#5e5e5e',
    darkerGray: '#555555',
    black: '#333333',

    red: '#ff0000',
    blue: '#5ac9ec',
    green: '#4ADEC2',

    primary: '#5ac9ec',
    secondary: '#4ADEC2',

    shadow: 'rgba(0,0,0,0.1)',
}

export const layout = {
    gutterSize: 30,
}
