import React from 'react'

import TELink from './../../../component/Navigation/TELink'

import { convertToDateShort } from './../../../helpers'
import styles from './styles'

export const tableColumns = [{
	id: 'dateCreated',
	Header: 'Date Created',
	width: 170,
	accessor: d => convertToDateShort(d.dateCreated*1000)
}, {
	id: 'name',
	Header: 'Name',
	accessor: 'name'
}, /*{
	id: 'contact',
	Header: 'Contact',
	accessor: 'primaryContact'
}, {
	id: 'contactEmail',
	Header: 'Contact Email',
	accessor: 'primaryEmail'
}, {
	id: 'contactPhone',
	Header: 'Contact Phone',
	accessor: 'primaryPhone'
}, *//*{
	id: 'type',
	Header: 'Type',
	accessor: 'type'
}, */{
	id: 'actions',
	Header: '',
	width: 120,
	sortable: false,
	filterable: false,
	accessor: 'uid',
	Cell: d => <TELink to={`/admin/organizations/${d.value}`} wrapperStyle={styles.detailLinkWrapper} style={styles.detailLink}>Details</TELink>
}]
