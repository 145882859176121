import { colors } from './../../../config/styles';

export default {
	content : {
		width				: '90%',
		maxWidth			: 600,
		marginTop 			: 100,
		marginBottom 		: 100,
		marginLeft 			: 'auto',
		marginRight 		: 'auto',
	},
	title : {
		position 			: 'relative',
		width 				: '100%',
		textAlign			: 'center',
		fontSize			: 24,
		marginBottom		: 20,
		color 				: colors.black,
		paddingLeft			: 30,
		paddingTop			: 30,
		paddingRight		: 30,
		paddingBottom		: 0,
	},
	message : {
		position			: 'relative',
		width 				: '100%',
		textAlign			: 'center',
		fontSize			: 18,
		lineHeight			: 1.25,
		marginBottom		: 30,
		paddingLeft 		: 40,
		paddingRight 		: 40,
		paddingTop 			: 0,
		paddingBottom 		: 0,
		color 				: colors.black,
	},
	buttonContainer : {
		borderBottomRightRadius 	: 20,
		borderBottomLeftRadius 		: 20,
		overflow 					: 'hidden',
	},
	button : {
		position			: 'relative',
		width 				: '100%',
		textAlign			: 'center',
		fontSize			: 18,
		color 				: colors.blue,
		backgroundColor		: colors.white,
		borderTop			: `1px solid ${colors.blue}`,
		borderRight			: 'none',
		borderBottom		: 'none',
		borderLeft			: 'none',
		lineHeight			: 2,
		borderRadius 		: 0,

		':hover' : {
			color 				: colors.white,
			backgroundColor		: colors.blue,
			borderTop			: `1px solid ${colors.blue}`,
			borderRight			: 'none',
			borderBottom		: 'none',
			borderLeft			: 'none',
		},
		':active' : {
			color 				: colors.white,
			backgroundColor		: colors.blue,
			borderTop			: `1px solid ${colors.blue}`,
			borderRight			: 'none',
			borderBottom		: 'none',
			borderLeft			: 'none',
		}
	}
}
