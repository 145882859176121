import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'

import TELink from './../../../component/Navigation/TELink'

import { inputHasChanged, signInUser } from './../../../actions'
import { AuthKeys } from './../../../actions/types'

import TEHelmet from './../../../component/Header/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import TEForm from './../../../component/Form/TEForm'
import TEInputRow from './../../../component/Form/TEInputRow'
import TESubmit from './../../../component/Form/TESubmit'

import styles from './styles'

class SignIn extends Component {
	handleSignInSubmit = (e) => {
		e.preventDefault()
		const { signInEmailData, signInPasswordData, signInUser, history } = this.props
		const email = signInEmailData.value
		const password = signInPasswordData.value
		signInUser({ email, password, history })
	}

	render() {
		const meta = {
			title: 'FirstClass | Sign In',
			description:
				'Acting as your modern-day concierge service, FirstClass allows you to schedule rides with reputable black car transportation companies directly from your phone.',
		}
		const { signInEmailData, signInPasswordData } = this.props

		return (
			<React.Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper style={styles.container}>
					<Panel containerStyle={styles.panel}>
						<TEForm style={styles.form} onSubmit={this.handleSignInSubmit}>
							<h1 style={styles.title}>Sign In</h1>
							<TELink
								to="/signUp"
								wrapperStyle={styles.signUpWrapper}
								style={styles.signUp}
							>
								Don't have an account?
							</TELink>
							<TEInputRow
								labelForKey="email"
								type="email"
								rowData={signInEmailData}
								onChangeKey={AuthKeys.SIGN_IN_EMAIL_CHANGED}
								validationRules={['required', 'email']}
								inputExtras={{ placeholder: 'Email' }}
								labelStyles={styles.label}
								inputStyles={styles.input}
							/>
							<TEInputRow
								labelForKey="password"
								type="password"
								rowData={signInPasswordData}
								onChangeKey={AuthKeys.SIGN_IN_PASSWORD_CHANGED}
								validationRules={['required', 'password']}
								inputExtras={{ placeholder: 'Password' }}
								labelStyles={styles.label}
								inputStyles={styles.input}
							/>
							<div style={styles.buttonContainer}>
								<TELink
									to="/forgot-password"
									wrapperStyle={styles.forgotWrapper}
									style={styles.forgot}
								>
									Forgot Password?
								</TELink>
								<TESubmit style={styles.button}>Sign In</TESubmit>
							</div>
						</TEForm>
					</Panel>
				</PanelWrapper>
			</React.Fragment>
		)
	}
}

SignIn.propTypes = {}

SignIn.defaultProps = {}

const mapStateToProps = (state) => {
	const { signInEmailData, signInPasswordData } = state.Auth

	return {
		signInEmailData,
		signInPasswordData,
	}
}
export default connect(
	mapStateToProps,
	{
		inputHasChanged,
		signInUser,
	}
)(Radium(SignIn))
