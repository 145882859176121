import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import ReactTable from 'react-table'

import {
	startWatchingAdminOrganization,
	stopWatchingAdminOrganization,
	startWatchingAdminRiders,
	stopWatchingAdminRiders,
	saveAdminOrganization,
	showAddAdminRiderPopup,
} from './../../../actions'
import { AdminKeys } from './../../../actions/types'

import TEHelmet from './../../../component/Header/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'
import SideNavbar from './../../../component/Layout/SideNavbar'

import TEInputRow from './../../../component/Form/TEInputRow'
// import TESegmentedGroup from './../../../component/Form/TESegmentedGroup'
import TEButton from './../../../component/Form/TEButton'

import AddUserPopup from './AddUserPopup'

import { tableColumns } from './tableConfigs'
import { customTableFilter } from './../../../helpers'
import styles from './styles'

class OrganizationDetailed extends Component {
	state = { activeView: 'Details' }
	componentDidMount(){
		const {
			match,
			startWatchingAdminOrganization,
			startWatchingAdminRiders,
		} = this.props
		const { uid } = match.params

		startWatchingAdminOrganization(uid)
		startWatchingAdminRiders()
	}
	componentWillUnmount(){
		const {
			stopWatchingAdminOrganization,
			stopWatchingAdminRiders,
		} = this.props

		stopWatchingAdminOrganization()
		stopWatchingAdminRiders()
	}
	handleSaveOrganization = () => {
		const {
			match,
			saveAdminOrganization,
			organizationNameData,
			// organizationTypeData,
		} = this.props
		const { uid } = match.params

		const data = {
			uid,
			organizationNameData,
			// organizationTypeData,
		}

		saveAdminOrganization(data)
	}
	handleShowAddUserPopup = () => {
		this.props.showAddAdminRiderPopup()
	}

	render(){
		const meta = {
			title: 'FirstClass | Organization Details',
			description: '',
		}
		const { activeView } = this.state
		const {
			match,
			organizationModel,
			organizationModelLoaded,
			organizationNameData,
			// organizationTypeData,
			organizationUserArray,
			organizationUsersLoaded,
			addUserPopupVisible,
		} = this.props
		const { uid } = match.params

		if (!organizationModel){
			if (organizationModelLoaded){
				return <h1 style={styles.errorMessage}>Error Loading Organization...</h1>
			}else{
				return null
			}
		}

		return (
			<Fragment>
				<TEHelmet {...meta}/>
				<SideNavbar
					onChange={ (event) => { this.setState({ activeView: event.target.value }) } }
					checkedValue={activeView}
					buttonArray={[{
			 			label: 'Details'
					}, {
			 			label: 'Users',
					}]}
				/>
				<PanelWrapper style={styles.panelWrapper}>
					{activeView === 'Details' &&
						<Panel title='Organization Details' containerStyle={styles.editPanel}>
							<TEInputRow
								labelForKey='name'
								rowData={organizationNameData}
								onChangeKey={AdminKeys.ORGANIZATION_NAME_CHANGED}
								validationRules={['required']}
								inputExtras={{ placeholder: 'Organization Name' }}
							/>
							{/*<TESegmentedGroup
								labelForKey='organizationType'
								checkedInputData={organizationTypeData}
								onChangeKey={AdminKeys.ORGANIZATION_TYPE_CHANGED}
								buttonArray={['Livery', 'Business']}
								validationRules={['required']}
								inline
							/>*/}
							<TEButton onClick={this.handleSaveOrganization} style={styles.saveButton}>Save</TEButton>
						</Panel>
					}
					{activeView === 'Users' &&
						<Panel
							title='Organization Users'
							rightComponent={
								<div style={styles.actionsWrapper}>
									<TEButton onClick={this.handleShowAddUserPopup} style={styles.actionButton}>Add User</TEButton>
								</div>
							}
						>
							<ReactTable
								data={organizationUserArray}
								columns={tableColumns(uid)}
								defaultPageSize={10}
								filterable
								defaultFilterMethod={customTableFilter}
								defaultSorted={[{
									id: "name",
									desc: false
								}]}
								loading={!organizationUsersLoaded}
							/>
						</Panel>
					}
				</PanelWrapper>
				<AddUserPopup visible={addUserPopupVisible} uid={uid}/>
			</Fragment>
		)
	}
}

OrganizationDetailed.propTypes = {}

OrganizationDetailed.defaultProps = {}

const mapStateToProps = (state, somethingElse) => {
	const {
		organizationModel,
		organizationModelLoaded,
		organizationNameData,
		// organizationTypeData,
		organizationUsers,
		organizationUsersLoaded,
		addUserPopupVisible,
	} = state.Admin

	const organizationUserArray = []
	for (const key in organizationUsers){
		const dataToMap = organizationUsers[key]
		dataToMap.uid = key
		organizationUserArray.push(dataToMap)
	}

	return {
		organizationModel,
		organizationModelLoaded,
		organizationNameData,
		// organizationTypeData,
		organizationUserArray,
		organizationUsersLoaded,
		addUserPopupVisible,
	}
}

export default connect(mapStateToProps, {
	startWatchingAdminOrganization,
	stopWatchingAdminOrganization,
	startWatchingAdminRiders,
	stopWatchingAdminRiders,
	saveAdminOrganization,
	showAddAdminRiderPopup,
})(Radium(OrganizationDetailed))
