// import { HomeKeys } from './../actions/types'

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
	// const {} = HomeKeys

	switch (action.type) {
		default:
			return state;
	}
};

