import React, { Component, Fragment } from 'react'
import {
	Route,
	withRouter,
	Switch,
} from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import firebase from 'firebase/app'
import 'firebase/auth'
import { connect } from 'react-redux'
import ifvisible from 'ifvisible'

import {
	hideAlert,
	hideConfirm,
	setUserActive,
	setUserInactive,
	startWatchingAppVersion,
	startFirebaseUserWatcher,
} from './../actions'

import settings from './../config/settings'

//Utility
import PrivateRoute from './../component/PrivateRoute'
import NotFound from './../pages/NotFound'

//Global Components
import TEAlert from './../component/Popup/TEAlert'
import TEConfirm from './../component/Popup/TEConfirm'
import TENetworkActivity from './../component/Popup/TENetworkActivity'

//
//Front End
//
import FrontContentWrapper from './../layout/Front/FrontContentWrapper'
import Home from './../pages/Front/Home'
import SignIn from './../pages/Front/SignIn'
import SignUp from './../pages/Front/SignUp'
import ForgotPassword from './../pages/Front/ForgotPassword'
import EmailActions from './../pages/Front/EmailActions'

//
//Portal
//
import PortalContentWrapper from './../layout/Portal/PortalContentWrapper'
import RiderCreate from './../pages/Portal/Create'
import RiderLive from './../pages/Portal/Live'
import RiderLiveDetails from './../pages/Portal/LiveDetailed'
import RiderHistoric from './../pages/Portal/Historic'
import RiderHistoricDetails from './../pages/Portal/HistoricDetailed'

//
// Dispatch
//
import DispatchContentWrapper from './../layout/Dispatch/DispatchContentWrapper'
import DispatchLive from './../pages/Dispatch/Live'
import DispatchLiveDetails from './../pages/Dispatch/LiveDetailed'
import DispatchHistoric from './../pages/Dispatch/Historic'
import DispatchHistoricDetails from './../pages/Dispatch/HistoricDetailed'
import DispatchFleet from './../pages/Dispatch/Fleet'
import DispatchDriverDetailed from './../pages/Dispatch/DriverDetailed'

//
//Admin
//
import AdminContentWrapper from './../layout/Admin/AdminContentWrapper'
import AdminOrganizations from './../pages/Admin/Organizations'
import AdminOrganizationDetails from './../pages/Admin/OrganizationsDetailed'
import AdminOrganizationUserDetails from './../pages/Admin/OrganizationUserDetailed'
import AdminDispatchCompanies from './../pages/Admin/DispatchCompanies'
import AdminDispatchCompanyDetails from './../pages/Admin/DispatchCompaniesDetailed'

class App extends Component {
	componentDidMount() {
		const {
			dev,
			DEVELOPMENT,
			PRODUCTION,
		} = settings
		const {
			startFirebaseUserWatcher,
			startWatchingAppVersion,
			setUserActive,
			setUserInactive,
		} = this.props

		firebase.initializeApp({
			apiKey              : dev ? DEVELOPMENT.FIREBASE_APIKEY         : PRODUCTION.FIREBASE_APIKEY,
			authDomain          : dev ? DEVELOPMENT.FIREBASE_AUTHDOMAIN     : PRODUCTION.FIREBASE_AUTHDOMAIN,
			databaseURL         : dev ? DEVELOPMENT.FIREBASE_URL            : PRODUCTION.FIREBASE_URL,
			projectId           : dev ? DEVELOPMENT.FIREBASE_PRODUCT_ID     : PRODUCTION.FIREBASE_PRODUCT_ID,
			storageBucket       : dev ? DEVELOPMENT.FIREBASE_STORAGEBUCKET  : PRODUCTION.FIREBASE_STORAGEBUCKET,
			messagingSenderId   : dev ? DEVELOPMENT.FIREBASE_MESSAGING_ID   : PRODUCTION.FIREBASE_MESSAGING_ID,
		})
		startFirebaseUserWatcher()
		startWatchingAppVersion()

		//Monitor User Activity
		ifvisible.setIdleDuration(600) //10 Minutes Idle Time
		ifvisible.wakeup(() => { setUserActive() })
		ifvisible.idle(() => { setUserInactive() })
	}

	//Alert
	handleConfirmClose = () => { this.props.hideConfirm() }
	handleAlertClose = () => { this.props.hideAlert() }

	render() {
		const {
			confirmTitle, confirmMessage, confirmIsVisible,
			confirmLeftTitle, confirmRightOnClick, confirmRightTitle,
			alertIsVisible, alertTitle, alertMessage,
			networkActivityIsVisible, networkMessage,
			userCheckComplete, appVersions, appVersionsLoaded,
			location
		} = this.props

		if (!userCheckComplete || !appVersionsLoaded){
			return null
		}

		return (
			<Fragment>
				<Switch>
					<Route path="/admin/" render={() =>
						<AdminContentWrapper location={location} appVersions={appVersions}>
							<Switch>
								<PrivateRoute path="/admin/organizations/:orgUID/user/:userUID" component={AdminOrganizationUserDetails} 	requiredUserType="admin" />
								<PrivateRoute path="/admin/organizations/:uid" 					component={AdminOrganizationDetails} 		requiredUserType="admin" />
								<PrivateRoute path="/admin/organizations" 						component={AdminOrganizations} 				requiredUserType="admin" />
								<PrivateRoute path="/admin/dispatch-companies/:uid" 			component={AdminDispatchCompanyDetails} 	requiredUserType="admin" />
								<PrivateRoute path="/admin/dispatch-companies" 					component={AdminDispatchCompanies} 			requiredUserType="admin" />

								<Route component={NotFound} />
							</Switch>
						</AdminContentWrapper>
					}/>
					<Route path="/dispatch/" render={() =>
						<DispatchContentWrapper location={location} appVersions={appVersions}>
							<Switch>
								<PrivateRoute path="/dispatch/live/:uid" 		component={DispatchLiveDetails} 		requiredUserType="dispatch" />
								<PrivateRoute path="/dispatch/live" 			component={DispatchLive} 				requiredUserType="dispatch" />
								<PrivateRoute path="/dispatch/historic/:uid" 	component={DispatchHistoricDetails} 	requiredUserType="dispatch" />
								<PrivateRoute path="/dispatch/historic" 		component={DispatchHistoric} 			requiredUserType="dispatch" />
								<PrivateRoute path="/dispatch/fleet/:uid" 		component={DispatchDriverDetailed} 		requiredUserType="dispatch" />
								<PrivateRoute path="/dispatch/fleet" 			component={DispatchFleet} 				requiredUserType="dispatch" />

								<Route component={NotFound} />
							</Switch>
						</DispatchContentWrapper>
					}/>
					<Route path="/portal/" render={() =>
						<PortalContentWrapper location={location} appVersions={appVersions}>
							<Switch>
								<PrivateRoute path="/portal/request-a-ride" component={RiderCreate} 			requiredUserType="rider" />
								<PrivateRoute path="/portal/live/:uid" 		component={RiderLiveDetails} 		requiredUserType="rider" />
								<PrivateRoute path="/portal/live" 			component={RiderLive} 				requiredUserType="rider" />
								<PrivateRoute path="/portal/historic/:uid" 	component={RiderHistoricDetails} 	requiredUserType="rider" />
								<PrivateRoute path="/portal/historic" 		component={RiderHistoric} 			requiredUserType="rider" />

								<Route component={NotFound} />
							</Switch>
						</PortalContentWrapper>
					}/>
					<Route path="/" render={() =>
						<FrontContentWrapper location={location}>
							<TransitionGroup>
								<CSSTransition key={location.pathname} timeout={{ enter: 500, exit: 0 }} classNames="fade" appear>
									<div className="AnimationWrapper">
										<Switch>
											<Route exact path="/" 			component={Home} />

											<Route path="/signIn" 			component={SignIn} />
											<Route path="/signUp" 			component={SignUp} />
											<Route path="/forgot-password" 	component={ForgotPassword} />
											<Route path="/auth-actions" 	component={EmailActions} />

											<Route component={NotFound} />
										</Switch>
									</div>
								</CSSTransition>
							</TransitionGroup>
						</FrontContentWrapper>
					}/>
				</Switch>

				<TEConfirm
					title={confirmTitle}
					message={confirmMessage}
					visible={confirmIsVisible}
					leftOnClick={this.handleConfirmClose}
					leftButtonTitle={confirmLeftTitle}
					rightOnClick={confirmRightOnClick}
					rightButtonTitle={confirmRightTitle}
				/>
				<TEAlert
					title={alertTitle}
					message={alertMessage}
					visible={alertIsVisible}
					onClick={this.handleAlertClose}
				/>
				<TENetworkActivity
					message={networkMessage}
					visible={networkActivityIsVisible}
				/>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => {
	const {
		confirmTitle, confirmMessage, confirmIsVisible,
		confirmLeftTitle, confirmRightOnClick, confirmRightTitle,
		alertIsVisible, alertTitle, alertMessage,
		networkActivityIsVisible, networkMessage,
	} = state.Global

	//
	//NOTE:
	//
	//  - userCheckComplete is used to allow for firebase to
	//      figure out of there is a user already signed in or
	//      not. Because this is async we need to wait to render
	//      all the routes on the page for a split second before
	//      letting react router figure out if it should redirect
	//      from a protected page.
	//
	//  - This should probably be moved to a nested place where
	//      all portal content is held. This way we aren't doing
	//      this check for the front pages.
	//
	const {
		userCheckComplete,
		appVersions,
		appVersionsLoaded
	} = state.Loading

	return {
		confirmTitle, confirmMessage, confirmIsVisible,
		confirmLeftTitle, confirmRightOnClick, confirmRightTitle,
		alertIsVisible, alertTitle, alertMessage,
		networkActivityIsVisible, networkMessage,
		userCheckComplete, appVersions, appVersionsLoaded,
	}
}

export default withRouter(connect(mapStateToProps, {
	hideAlert,
	hideConfirm,
	setUserActive,
	setUserInactive,
	startWatchingAppVersion,
	startFirebaseUserWatcher
})(App))
