import React, { Component } from 'react'
import Radium from 'radium'

import images from './../../../config/images'

import styles from './styles'

class Footer extends Component {
	render() {
		return (
			<div style={styles.container}>
                <div style={styles.copyrightContainer}>
                    <span style={styles.contact}>Contact us: <a href="mailto:info@38thstreetstudios.com" style={styles.emailLink}>info@38thstreetstudios.com</a></span>
                    <span style={styles.copyright}>© 2018 38th Street Studios, LLC. All rights reserved.</span>
               </div>
				<div style={styles.logoContainer}>
					<img src={images.logo} alt='FirstClass Logo' title='FirstClass Logo' style={styles.logo} />
				</div>
			</div>
		)
	}
}

Footer.propTypes = {}

Footer.defaultProps = {}

export default Radium(Footer)
