//
//TE Version 0.1.0
//

import React, { Component } from 'react';
import Radium from 'radium';

import { layout } from './../../../config/styles'

import PanelTitle from './../PanelTitle'

import styles from './styles';

class Panel extends Component {

	renderPanelSize(size){
		switch (size){
			case 'full':
				return { width: '100%' }
			case 'two-third':
				return { 
					width: `calc(66.666% - ${layout.gutterSize * (2/3)}px)`,
					'@media (max-width: 700px)' : {
						width 			: '100%',
					}
				}
			case 'half':
				return {
					width: `calc(50% - ${layout.gutterSize/2}px)`,

					'@media (max-width: 700px)' : {
						width 			: '100%',
					}
				}
			case 'third':
				return {
					width: `calc(33.333% - ${layout.gutterSize/3}px)`,
					'@media (max-width: 700px)' : {
						width 			: '100%',
					}
				}
			default:
				return { width: '100%' }
		}
	}
	render() {
		const { 
			size, 
			containerStyle,

			title,
			titleStyle,
			leftComponent,
			subtitle,
			rightComponent,

			children,
		} = this.props
		return (
			<div style={[styles.container, this.renderPanelSize(size), containerStyle]}>
				{(title || leftComponent || subtitle || rightComponent) && 
					<PanelTitle 
						style={titleStyle} 
						title={title} 
						leftComponent={leftComponent}
						subtitle={subtitle}
						rightComponent={rightComponent}
					/>
				}
				{children}
			</div>
		);
	}
}

Panel.propTypes = {}

Panel.defaultProps = {
	size : 'full',
};

export default Radium(Panel);
