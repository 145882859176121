import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'

import { hideDispatchCompeteRequestPopup, dispatchCompleteRequest } from './../../../../actions'
import { DispatchKeys } from './../../../../actions/types'

import TEPopup from './../../../../component/Popup/TEPopup'
import TEMultiStepForm from './../../../../component/Form/TEMultiStepForm'
import TEInputRow from './../../../../component/Form/TEInputRow'

import styles from './styles'

class CompleteRequestPopup extends Component {
	handleClosePopup = () => {
		this.props.hideDispatchCompeteRequestPopup()
	}
	handleCreate = () => {
		const { liveRequest, uid, dispatchCompleteRequest, additionalFeeData, history } = this.props

		const data = {
			liveRequest,
			uid,
			additionalFeeData,
			history,
		}

		dispatchCompleteRequest(data)
	}

	render() {
		const {
			visible,

			additionalFeeData,
		} = this.props

		return (
			<TEPopup visible={visible} contentStyles={styles.content}>
				<div style={styles.container}>
					<TEMultiStepForm
						stepData={[
							{
								title: 'Complete Request',
								component: (
									<Fragment>
										<TEInputRow
											type="number"
											rowData={additionalFeeData}
											onChangeKey={DispatchKeys.ADDITIONAL_FEE_CHANGED}
											validationRules={['required']}
										/>
									</Fragment>
								),
								validateData: [additionalFeeData],
							},
						]}
						handleCancelOnClick={this.handleClosePopup}
						handleSubmitOnClick={this.handleCreate}
					/>
				</div>
			</TEPopup>
		)
	}
}

CompleteRequestPopup.propTypes = {}

CompleteRequestPopup.defaultProps = {}

const mapStateToProps = (state, props) => {
	const { additionalFeeData } = state.Dispatch

	return {
		additionalFeeData,
	}
}

export default connect(
	mapStateToProps,
	{
		hideDispatchCompeteRequestPopup,
		dispatchCompleteRequest,
	}
)(Radium(CompleteRequestPopup))
