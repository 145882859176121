import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import TELink from './../../../component/Navigation/TELink'
import TENavLink from './../../../component/Navigation/TENavLink'
import firebase from 'firebase/app'
import 'firebase/auth'

import TESVG from './../../../component/TESVG'

import images from './../../../config/images'
import styles from './styles'

class Navbar extends Component {
	state = { mobileNavOpen : false }

	handleToggleNav = () => {
		this.setState({ mobileNavOpen: !this.state.mobileNavOpen })
	}

	render() {
		const { mobileNavOpen } = this.state
		const { userPermissions } = this.props
		return (
			<div style={styles.container}>
				<TELink
					to="/"
					wrapperStyle={styles.brandContainer}
					style={styles.brandLink}
				>
					<img
						src={images.logo}
						alt='FirstClass Logo'
						title='FirstClass Logo'
						style={styles.logo}
					/>
				</TELink>
				<div style={styles.navContainer}>
					<ul style={styles.ul}>
						<li key="eventCodeLiKey" style={[styles.li, mobileNavOpen && styles.liOpen]}>
							<TENavLink
								key="eventCodeLinkKey"
								wrapperStyle={styles.linkWrapper}
								style={styles.link}
								activeStyle={styles.active}
								to="/"
								exact
							>
								Home
							</TENavLink>
						</li>
						{firebase.auth().currentUser &&
							<Fragment>
								{userPermissions && userPermissions.admin &&
									<li key="adminLiKey" style={[styles.li, mobileNavOpen && styles.liOpen]}>
										<TENavLink
											wrapperStyle={styles.linkWrapper}
											style={styles.link}
											activeStyle={styles.active}
											to="/admin/organizations"
											exact
										>
											Admin
										</TENavLink>
									</li>
								}
								{userPermissions && userPermissions.dispatch &&
									<li key="dispatchLiKey" style={[styles.li, mobileNavOpen && styles.liOpen]}>
										<TENavLink
											wrapperStyle={styles.linkWrapper}
											style={styles.link}
											activeStyle={styles.active}
											to="/dispatch/live"
											exact
										>
											Dispatch
										</TENavLink>
									</li>
								}
								{userPermissions && userPermissions.rider &&
									<li key="portalLiKey" style={[styles.li, mobileNavOpen && styles.liOpen]}>
										<TENavLink
											wrapperStyle={styles.linkWrapper}
											style={styles.link}
											activeStyle={styles.active}
											to="/portal/live"
											exact
										>
											Portal
										</TENavLink>
									</li>
								}
							</Fragment>
						}
						{!firebase.auth().currentUser &&
							<li key="signInLiKey" style={[styles.li, mobileNavOpen && styles.liOpen]}>
								<TENavLink
									key="signInLinkKey"
									wrapperStyle={styles.linkWrapper}
									style={styles.link}
									activeStyle={styles.active}
									to="/signIn"
									exact
								>
									Sign In
								</TENavLink>
							</li>
						}
					</ul>
				</div>
				<button
					key='mobileNavButtonKey'
					onClick={this.handleToggleNav}
					style={[
						styles.mobileNavButton,
						mobileNavOpen && styles.activeMobileNavButton
					]}
				>
					<TESVG
						path={images.navIcon}
						wrapperStyle={styles.navIcon}
					/>
				</button>
			</div>
		)
	}
}

Navbar.propTypes = {}

Navbar.defaultProps = {}

const mapStateToProps = (state) => {
	const { userPermissions } = state.Auth
	return { userPermissions }
}

export default connect(mapStateToProps, { })(Radium(Navbar))
