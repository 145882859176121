import React from 'react'

import TELink from './../../../component/Navigation/TELink'

import styles from './styles'

export const tableColumns = [{
	id: 'chauffeurId',
	Header: 'Id',
	accessor: 'profile.chauffeurId'
}, {
	id: 'name',
	Header: 'Name',
	accessor: d => d.profile && `${d.profile.nameF} ${d.profile.nameL}`
}, {
	id: 'email',
	Header: 'Email',
	accessor: 'email'
}, {
	id: 'phone',
	Header: 'Phone',
	accessor: 'profile.phone'
}, {
	id: 'vehicle',
	Header: 'Vehicle',
	accessor: d => d.activeVehicle && `${d.activeVehicle.carYear} ${d.activeVehicle.carMake}`
}, {
	id: 'vehicleType',
	Header: 'Vehicle Type',
	accessor: d => {
		if (!d.activeVehicle){
			return ' -- '
		}
		if (d.activeVehicle.carCapacity <= 3){
			return 'Sedan'
		}
		if (d.activeVehicle.carCapacity <= 6){
			return 'SUV'
		}

		return `Capacity: ${d.activeVehicle.carCapacity}`
	}
}, {
	id: 'license',
	Header: 'License',
	accessor: 'activeVehicle.carLicense'
}, {
	id: 'cityPlates',
	Header: 'City Plates',
	accessor: d => d.activeVehicle && (d.activeVehicle && d.activeVehicle.carCityLicense ? 'Yes' : 'No')
}, {
	id: 'clockedIn',
	Header: 'Clock In',
	accessor: d => d.statuses && (d.statuses && d.statuses.clockIn ? 'Clocked In' : 'Clocked Out')
}, {
	id: 'status',
	Header: 'Status',
	accessor: d => {
		if (d.statuses.status){
			switch (d.statuses.status){
				case -1:
					return 'Unactivated'
				case 1:
					return 'Available'
				case 2:
					return 'In A Request'
				default:
					return ' -- '
			}
		}
		return ' -- '
	}
}, {
	id: 'actions',
	Header: '',
	width: 120,
	sortable: false,
	filterable: false,
	accessor: 'uid',
	Cell: d => <TELink to={`/dispatch/fleet/${d.value}`} wrapperStyle={styles.detailLinkWrapper} style={styles.detailLink}>Details</TELink>
}]
