export default {
	container:{
		position		: 'relative',
		display			: 'inline-block',
		verticalAlign 	: 'top',

		width			: '100%',
		marginBottom	: 10,

		textAlign		: 'left',
	}
}
