import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import firebase from 'firebase/app'
import 'firebase/auth'
import { withRouter } from 'react-router-dom'

import TELink from './../../../component/Navigation/TELink'
import TENavLink from './../../../component/Navigation/TENavLink'

import TESVG from './../../../component/TESVG'

import { signOutUser } from './../../../actions'
import images from './../../../config/images'
import styles from './styles'

class Navbar extends Component {
	state = {
		mobileNavOpen: false,
	}

	handleToggleNav = () => {
		this.setState({ mobileNavOpen: !this.state.mobileNavOpen })
	}
	handleSignOut = () => {
		const { history, signOutUser } = this.props
		signOutUser(history)
	}

	render() {
		const { mobileNavOpen } = this.state
		return (
			<div style={styles.container}>
				<TELink to="/" wrapperStyle={styles.brandContainer} style={styles.brandLink}>
					<img
						src={images.logo}
						alt="FirstClass Logo"
						title="FirstClass Logo"
						style={styles.logo}
					/>
				</TELink>
				<div style={styles.navContainer}>
					<ul style={styles.ul}>
						<li
							key="organizationsLiKey"
							style={[styles.li, mobileNavOpen && styles.liOpen]}
						>
							<TENavLink
								wrapperStyle={styles.linkWrapper}
								style={styles.link}
								activeStyle={styles.active}
								to="/admin/organizations"
								exact
							>
								Organizations
							</TENavLink>
						</li>
						<li
							key="dispatchCompanyLiKey"
							style={[styles.li, mobileNavOpen && styles.liOpen]}
						>
							<TENavLink
								wrapperStyle={styles.linkWrapper}
								style={styles.link}
								activeStyle={styles.active}
								to="/admin/dispatch-companies"
								exact
							>
								Dispatch Companies
							</TENavLink>
						</li>
						{firebase.auth().currentUser ? (
							<li
								key="signOutLiKey"
								style={[styles.li, mobileNavOpen && styles.liOpen]}
							>
								<button
									key="signOutButtonKey"
									onClick={this.handleSignOut}
									style={styles.navButton}
								>
									Sign Out
								</button>
							</li>
						) : (
							<li
								key="signOutLiKey"
								style={[styles.li, mobileNavOpen && styles.liOpen]}
							>
								<TENavLink
									wrapperStyle={styles.linkWrapper}
									style={styles.link}
									activeStyle={styles.active}
									to="/signIn"
									exact
								>
									Sign In
								</TENavLink>
							</li>
						)}
					</ul>
				</div>
				<button
					key="mobileNavButtonKey"
					onClick={this.handleToggleNav}
					style={[styles.mobileNavButton, mobileNavOpen && styles.activeMobileNavButton]}
				>
					<TESVG path={images.navIcon} wrapperStyle={styles.navIcon} />
				</button>
			</div>
		)
	}
}

Navbar.propTypes = {}

Navbar.defaultProps = {}

const mapStateToProps = (state) => {
	//const { } = state.Auth
	return {}
}

export default connect(
	mapStateToProps,
	{ signOutUser }
)(withRouter(Radium(Navbar)))
