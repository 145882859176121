//
//TE Version 0.1.0
//

import React, { Component } from 'react'
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	TrafficLayer,
} from "react-google-maps"
//import PropTypes from 'prop-types'

import settings from './../../../config/settings'

import TEButton from './../../Form/TEButton'

import styles from './styles'

class Map extends Component {
	state = {
		trafficVisible 		: true,
	}

	render(){
		const { trafficVisible } = this.state
		const {
			defaultZoom = 8,
			defaultCenter = { lat: -34.397, lng: 150.644 },
			setRef,
			children,

			showTrafficButton,
			styleTrafficButton,
			...rest
		} = this.props
		return (
			<GoogleMap
				defaultZoom={defaultZoom}
				defaultCenter={defaultCenter}
				ref={setRef}
				{...rest}
			>
				{/*TRAFFIC*/}
				{showTrafficButton && trafficVisible && <TrafficLayer autoUpdate />}
				{showTrafficButton &&
					<TEButton
						style={{
							...styles.trafficButton,
							...trafficVisible && styles.trafficButtonHighlighted,
							...styleTrafficButton
						}}
						onClick={() => {this.setState({ trafficVisible: !trafficVisible }) }}
					>
						Traffic
					</TEButton>
				}
				{children}
			</GoogleMap>
		)
	}
}

const MapWithFunctions = withScriptjs(withGoogleMap(Map))

class TEGoogleMap extends Component {
	render() {
		const {
			dev,
			DEVELOPMENT,
			PRODUCTION,
		} = settings
		const { GOOGLE_MAP_API } = dev ? DEVELOPMENT : PRODUCTION

		const {
			defaultZoom 	= 8,
			defaultCenter 	= { lat: 41.885958, lng: -87.635568 },
			googleMapURL 	= `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API}&v=3.exp&libraries=geometry,drawing,places`,
			...rest
		} = this.props

		return (
			<MapWithFunctions
				defaultZoom={defaultZoom}
				defaultCenter={defaultCenter}
				googleMapURL={googleMapURL}
				loadingElement={<div style={{ height: `100%` }} />}
				containerElement={<div style={{ height: '100%', width : '100%', position: 'relative', }} />}
				mapElement={<div style={{ height: `100%` }} />}
				setRef={(map) => { this.map = map }}
				ref='mapContainer'
				{...rest}
			/>
		)
	}
}

TEGoogleMap.propTypes = {}

TEGoogleMap.defaultProps = {}

export default TEGoogleMap
