import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import ReactTable from 'react-table'

import {
	startWatchingAdminDispatchCompanies,
	stopWatchingAdminDispatchCompanies,
	showCreateAdminDispatchCompanyPopup,
} from './../../../actions'

import TEHelmet from './../../../component/Header/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import TEButton from './../../../component/Form/TEButton'

import Create from './Create'

import { tableColumns } from './tableConfigs'
import { customTableFilter } from './../../../helpers'
import styles from './styles'

class Organizations extends Component {
	state = { activeView : 'Routes' }

	componentDidMount(){
		const {
			startWatchingAdminDispatchCompanies,
		} = this.props

		startWatchingAdminDispatchCompanies()
	}
	componentWillUnmount(){
		const {
			stopWatchingAdminDispatchCompanies,
		} = this.props

		stopWatchingAdminDispatchCompanies()
	}
	handleShowCreateOrganization = () => {
		this.props.showCreateAdminDispatchCompanyPopup()
	}

	render(){
		const meta = {
			title: 'FirstClass | Admin Dispatch Companies',
			description: '',
		}

		const {
			dispatchCompanyArray,
			dispatchCompaniesLoaded,
			createDispatchCompanyPopupVisible,
		} = this.props

		return (
			<Fragment>
				<TEHelmet {...meta}/>
				<PanelWrapper style={styles.panelWrapper}>
					<Panel
						title='Dispatch Companies'
						rightComponent={
							<div style={styles.actionsWrapper}>
								<TEButton onClick={this.handleShowCreateOrganization} style={styles.actionButton}>Create Dispatch Company</TEButton>
							</div>
						}
					>
						<ReactTable
							data={dispatchCompanyArray}
							columns={tableColumns}
							defaultPageSize={10}
							filterable
							defaultFilterMethod={customTableFilter}
							defaultSorted={[{
								id: "name",
								desc: false
							}]}
							loadin={!dispatchCompaniesLoaded}
						/>
					</Panel>
				</PanelWrapper>
				<Create visible={createDispatchCompanyPopupVisible} />
			</Fragment>
		)
	}
}

Organizations.propTypes = {}

Organizations.defaultProps = {}

const mapStateToProps = (state) => {
	const {
		dispatchCompanies,
		dispatchCompaniesLoaded,
		createDispatchCompanyPopupVisible
	} = state.Admin

	const dispatchCompanyArray = []
	for (const key in dispatchCompanies){
		const dataToMap = dispatchCompanies[key]
		dataToMap.uid = key
		dispatchCompanyArray.push(dataToMap)
	}

	return {
		dispatchCompanyArray,
		dispatchCompaniesLoaded,
		createDispatchCompanyPopupVisible
	}
}

export default connect(mapStateToProps, {
	startWatchingAdminDispatchCompanies,
	stopWatchingAdminDispatchCompanies,
	showCreateAdminDispatchCompanyPopup,
})(Radium(Organizations))
