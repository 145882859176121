import { colors } from './../../../config/styles';

export default {
	container : {
		position 			: 'relative',
		backgroundColor 	: colors.white,
		height 				: 60,
		width 				: '100%',
		zIndex				: 100,
		display 			: 'flex',
		flexWrap 			: 'wrap',
		justifyContent 		: 'space-between',
		alignItems 			: 'center',
		paddingLeft 		: 30,
		paddingRight 		: 30,
		boxShadow           : `0px 0px 3px 0px ${colors.shadow}`,

		'@media (max-width: 450px)' : {
			height 			: 'auto',
			padding 		: 15,
		},
	},
	copyrightContainer : {
		width 		: 'auto',
		fontSize 	: 12,
		fontWeight 	: 300,
		color 		: colors.darkGray,

		'@media (max-width: 450px)' : {
			width 		: '100%',
			textAlign 	: 'center',
		},
	},
	contact : {
		display 		: 'block',
		marginBottom 	: 5,
	},
	emailLink : {
		color 		: colors.blue,

		':hover' : {
			textDecoration 	: 'none',
		},
		':active' : {
			textDecoration 	: 'none',
		}
	},
	copyright : {
		display 	: 'block'
	},
	logoContainer : {
		width 		: 'auto',
		height		: '100%',
		padding		: 10,

		'@media (max-width: 450px)' : {
			display 	: 'none',
		},

	},
	logo : {
		position	: 'relative',
		height		: '100%',
	}
}
