import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Radium from 'radium'
import { Link } from 'react-router-dom';

import {
	inputHasChanged,
	sendForgotPasswordEmail,
} from './../../../actions';
import { AuthKeys } from './../../../actions/types';

import TEHelmet from './../../../component/Header/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import TEForm from './../../../component/Form/TEForm';
import TEInputRow from './../../../component/Form/TEInputRow'
import TESubmit from './../../../component/Form/TESubmit';

import styles from './styles';

class ForgotPassword extends Component {
	handleForgotPasswordSubmit = (e) => {
		e.preventDefault()
		const { forgotEmailData, sendForgotPasswordEmail } = this.props;

		const email     = forgotEmailData.value;
		sendForgotPasswordEmail({ email });
	}
	render(){
		const meta = {
			title: 'FirstClass | Forgot Password',
			description: 'Acting as your modern-day concierge service, FirstClass allows you to schedule rides with reputable black car transportation companies directly from your phone.',
		}
		const { forgotEmailData } = this.props;

		return (
			<React.Fragment>
				<TEHelmet {...meta}/>
				<PanelWrapper style={styles.container}>
					<Panel containerStyle={styles.panel} >
						<TEForm style={styles.form} onSubmit={this.handleForgotPasswordSubmit}>
							<h1 style={styles.title}>Forgot Password</h1>
							<Link to="/signIn" style={styles.signIn}>Sign In?</Link>
							<TEInputRow
								labelForKey='email'
								type='email'
								rowData={forgotEmailData}
								onChangeKey={AuthKeys.FORGOT_EMAIL_CHANGED}
								validationRules={['required', 'email']}
								inputExtras={{placeholder:'Email'}}
								labelStyles={styles.label}
								inputStyles={styles.input}
							/>
							<TESubmit style={styles.button}>Submit</TESubmit>
						</TEForm>
					</Panel>
				</PanelWrapper>
			</React.Fragment>
		)
	}
}

ForgotPassword.propTypes = {};

ForgotPassword.defaultProps = {};

const mapStateToProps = (state) => {
	const { forgotEmailData } = state.Auth;

	return { forgotEmailData };
}
export default connect(mapStateToProps, {
	inputHasChanged,
	sendForgotPasswordEmail,
})(Radium(ForgotPassword));
