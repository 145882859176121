import { colors } from './../../../config/styles';

export default {
	inlineRow : {
		display 		: 'flex',
		justifyContent	: 'space-between',
	},
	title : {
		textAlign 		: 'left',
		display 		: 'block',
		marginBottom	: 3,
		fontWeight		: 300,
		fontSize		: 16,
	},
	inlineTitle : {
		width 			: 'auto',
		alignSelf 		: 'center',
		marginBottom	: 0,
		paddingRight 	: 10,
	},
	segmentedContainer : {

	},
	inlineSegmentedContainer : {
		display 		: 'inline-block',
		width 			: 'auto',
		whiteSpace 		: 'nowrap',
		alignSelf 		: 'center',
	},
	labelWrapper : {
		display 		: 'inline-block',
		width 			: 'auto',
	},
	input: {
		display 		: 'none',
	},
	label : {
		display 			: 'inline-block',
		width 				: 'auto',
		borderTop			: `1px solid ${colors.lightGray}`,
		borderLeft			: `1px solid ${colors.lightGray}`,
		borderRight			: `1px solid ${colors.lightGray}`,
		borderBottom		: `1px solid ${colors.lightGray}`,
		cursor				: 'pointer',
		paddingLeft 		: 15,
		paddingRight 		: 15,
		paddingBottom 		: 10,
		paddingTop 			: 10,
		marginLeft 			: 0,
		marginRight 		: 0,
		marginBottom 		: 0,
		marginTop 			: 0,
		backgroundColor 	: colors.white,
		fontSize 			: 14,
		color 				: colors.darkGray,
		fontWeight 			: 300,

		transition 			: 'color 0.2s ease-in, background-color 0.2s ease-in, border 0.2s ease-in',

		':hover' : {
			color				: colors.white,
			backgroundColor		: colors.blue,
			borderTop			: `1px solid ${colors.blue}`,
			borderLeft			: `1px solid ${colors.blue}`,
			borderRight			: `1px solid ${colors.blue}`,
			borderBottom		: `1px solid ${colors.blue}`,
		},
		':active' : {
			color				: colors.white,
			backgroundColor		: colors.blue,
			borderTop			: `1px solid ${colors.blue}`,
			borderLeft			: `1px solid ${colors.blue}`,
			borderRight			: `1px solid ${colors.blue}`,
			borderBottom		: `1px solid ${colors.blue}`,
		},
	},
	leftSegment : {
		borderLeft 					: `1px solid ${colors.lightGray}`,
		borderTopLeftRadius 		: 5,
		borderBottomLeftRadius 		: 5,

		':hover' : {
			color				: colors.white,
			backgroundColor		: colors.blue,
			borderTop			: `1px solid ${colors.blue}`,
			borderLeft			: `1px solid ${colors.blue}`,
			borderRight			: `1px solid ${colors.blue}`,
			borderBottom		: `1px solid ${colors.blue}`,
		},
		':active' : {
			color				: colors.white,
			backgroundColor		: colors.blue,
			borderTop			: `1px solid ${colors.blue}`,
			borderLeft			: `1px solid ${colors.blue}`,
			borderRight			: `1px solid ${colors.blue}`,
			borderBottom		: `1px solid ${colors.blue}`,
		}
	},
	rightSegment : {
		borderTopRightRadius 		: 5,
		borderBottomRightRadius 	: 5,
	},
	checkedSegmented : {
		color				: colors.white,
		backgroundColor		: colors.blue,
		borderTop			: `1px solid ${colors.blue}`,
		borderLeft			: `1px solid ${colors.blue}`,
		borderRight			: `1px solid ${colors.blue}`,
		borderBottom		: `1px solid ${colors.blue}`,
	},
	disabledLabel : {
		pointerEvents 	: 'none'
	}
}
