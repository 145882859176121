//import { colors } from './../../../config/styles'

export default {
	form: {
		position 		: 'relative',
		width 			: '100%',
		marginTop 		: 0,
		marginBottom 	: 0,
		marginLeft 		: 'auto',
		marginRight 	: 'auto',
	}
}
