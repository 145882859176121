import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import ReactTable from 'react-table'

import {
	startWatchingAdminDispatchCompany,
	stopWatchingAdminDispatchCompany,
	startWatchingAdminRiders,
	stopWatchingAdminRiders,
	saveAdminDispatchCompany,
	showAddAdminDispatcherPopup,
	confirmRemoveAdminDispatchCompanyDispatcher,
} from './../../../actions'
import { AdminKeys } from './../../../actions/types'

import TEHelmet from './../../../component/Header/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'
import SideNavbar from './../../../component/Layout/SideNavbar'

import TEInputRow from './../../../component/Form/TEInputRow'
// import TESegmentedGroup from './../../../component/Form/TESegmentedGroup'
import TEButton from './../../../component/Form/TEButton'

import AddUserPopup from './AddUserPopup'

import { tableColumns } from './tableConfigs'
import { customTableFilter } from './../../../helpers'
import styles from './styles'

class OrganizationDetailed extends Component {
	state = { activeView: 'Details' }
	componentDidMount(){
		const {
			match,
			startWatchingAdminDispatchCompany,
			startWatchingAdminRiders,
		} = this.props
		const { uid } = match.params

		startWatchingAdminDispatchCompany(uid)
		startWatchingAdminRiders()
	}
	componentWillUnmount(){
		const {
			stopWatchingAdminDispatchCompany,
			stopWatchingAdminRiders,
		} = this.props

		stopWatchingAdminDispatchCompany()
		stopWatchingAdminRiders()
	}
	handleSaveOrganization = () => {
		const {
			match,
			saveAdminOrganization,
			organizationNameData,
		} = this.props
		const { uid } = match.params

		const data = {
			uid,
			organizationNameData,
		}

		saveAdminOrganization(data)
	}
	handleShowAddUserPopup = () => {
		this.props.showAddAdminDispatcherPopup()
	}
	handleRemoveUser = (uid) => {
		this.props.confirmRemoveAdminDispatchCompanyDispatcher(uid)
	}

	render(){
		const meta = {
			title: 'FirstClass | Organization Details',
			description: '',
		}
		const { activeView } = this.state
		const {
			match,
			dispatchCompanyModel,
			dispatchCompanyModelLoaded,
			dispatchCompanyNameData,
			dispatcherArray,
			dispatchersLoaded,
			addDispatcherPopupVisible,
		} = this.props
		const { uid } = match.params

		if (!dispatchCompanyModel){
			if (dispatchCompanyModelLoaded){
				return <h1 style={styles.errorMessage}>Error Loading Dispatch Company...</h1>
			}else{
				return null
			}
		}

		return (
			<Fragment>
				<TEHelmet {...meta}/>
				<SideNavbar
					onChange={ (event) => { this.setState({ activeView: event.target.value }) } }
					checkedValue={activeView}
					buttonArray={[{
			 			label: 'Details'
					}, {
			 			label: 'Users',
					}]}
				/>
				<PanelWrapper style={styles.panelWrapper}>
					{activeView === 'Details' &&
						<Panel title='Dispatch Company Details' containerStyle={styles.editPanel}>
							<TEInputRow
								labelForKey='name'
								rowData={dispatchCompanyNameData}
								onChangeKey={AdminKeys.DISPATCH_COMPANY_NAME_CHANGED}
								validationRules={['required']}
								inputExtras={{ placeholder: 'Company Name' }}
							/>
							<TEButton onClick={this.handleSaveOrganization} style={styles.saveButton}>Save</TEButton>
						</Panel>
					}
					{activeView === 'Users' &&
						<Panel
							title='Dispatchers'
							rightComponent={
								<div style={styles.actionsWrapper}>
									<TEButton onClick={this.handleShowAddUserPopup} style={styles.actionButton}>Add User</TEButton>
								</div>
							}
						>
							<ReactTable
								data={dispatcherArray}
								columns={tableColumns(this.handleRemoveUser)}
								defaultPageSize={10}
								filterable
								defaultFilterMethod={customTableFilter}
								defaultSorted={[{
									id: "name",
									desc: false
								}]}
								loading={!dispatchersLoaded}
							/>
						</Panel>
					}
				</PanelWrapper>
				<AddUserPopup visible={addDispatcherPopupVisible} uid={uid}/>
			</Fragment>
		)
	}
}

OrganizationDetailed.propTypes = {}

OrganizationDetailed.defaultProps = {}

const mapStateToProps = (state, somethingElse) => {
	const {
		dispatchCompanyModel,
		dispatchCompanyModelLoaded,
		dispatchCompanyNameData,
		dispatchers,
		dispatchersLoaded,
		addDispatcherPopupVisible,
	} = state.Admin

	const dispatcherArray = []
	for (const key in dispatchers){
		const dataToMap = dispatchers[key]
		dataToMap.uid = key
		dispatcherArray.push(dataToMap)
	}

	return {
		dispatchCompanyModel,
		dispatchCompanyModelLoaded,
		dispatchCompanyNameData,
		dispatcherArray,
		dispatchersLoaded,
		addDispatcherPopupVisible,
	}
}

export default connect(mapStateToProps, {
	startWatchingAdminDispatchCompany,
	stopWatchingAdminDispatchCompany,
	startWatchingAdminRiders,
	stopWatchingAdminRiders,
	saveAdminDispatchCompany,
	showAddAdminDispatcherPopup,
	confirmRemoveAdminDispatchCompanyDispatcher,
})(Radium(OrganizationDetailed))
