//Firebase Helpers

//
// Convert Firebase Error Messages To Custom Ones
//
export const determineAuthErrorMessage = (error) => {
	switch (error.code) {
		case 'auth/invalid-email': 
			return { 
				alertTitle: 'Invalid Email Format', 
				alertMessage: 'Please provide a valid email address and try again.', 
				alertIsVisible: true
		}
		case 'auth/wrong-password':
			return {
				alertTitle: 'Invalid Password', 
				alertMessage: 'Please provide a valid password and try again.', 
				alertIsVisible: true
			}
		case 'auth/network-request-failed':
			return {
				alertTitle: 'Network Request Failed', 
				alertMessage: 'Please check your network connection and try again.', 
				alertIsVisible: true
			}
		case 'auth/user-not-found':
			return {
				alertTitle: 'Error Signing In', 
				alertMessage: 'Please check your email and password and try again.', 
				alertIsVisible: true
			}	
		case 'auth/invalid-action-code':
			return {
				alertTitle: 'Invalid Code', 
				alertMessage: 'The code has either expired or already been used.', 
				alertIsVisible: true
			}	
		default:
			console.log("Uncaught error", error)
			return {
				alertTitle: 'Authentication Failed', 
				alertMessage: error.message, 
				alertIsVisible: true
			}
	}
}
export const determineForgotPasswordErrorMessage = (error) => {
	switch (error.code) {
		case 'auth/invalid-email': 
			return { 
				alertTitle: 'Invalid Email Format', 
				alertMessage: 'Please provide a valid email address and try again.', 
				alertIsVisible: true
		}
		case 'auth/user-not-found':
			return {
				alertTitle: 'No Matching Email', 
				alertMessage: 'Please check your email and try again.', 
				alertIsVisible: true
			}
		case 'auth/network-request-failed':
			return {
				alertTitle: 'Network Request Failed', 
				alertMessage: 'Please check your network connection and try again.', 
				alertIsVisible: true
			}
		default:
			console.log("Uncaught error", error)
			return {
				alertTitle: 'Error Sending Email', 
				alertMessage: 'Failed to send password reset email. Check that you are entereing a valid email address and try again.', 
				alertIsVisible: true
			}
	}
}
export const determinePasswordResetErrorMessage = (error) => {
	switch (error.code) {
		case 'auth/weak-password': 
			return { 
				alertTitle: 'Invalid Password', 
				alertMessage: 'Password must be at least 6 characters long.', 
				alertIsVisible: true
		}
		default:
			console.log("Uncaught error", error)
			return {
				alertTitle: 'Error Updating Password', 
				alertMessage: 'Check that your password is more then 6 characters and matches the confirm password.', 
				alertIsVisible: true
			}
	}
}
