import { colors } from './../../../config/styles'

export default {
	container: {
		position		: 'relative',
		display			: 'block',
		width			: '100%',
		fontSize		: 14,
		backgroundColor	: colors.white,
		color           : colors.gray,
		fontWeight 		: 300,
		borderRadius 	: 5,
		paddingTop 		: 10,
		paddingLeft 	: 10,
		paddingRight 	: 10,
		paddingBottom 	: 10,
		marginBottom 	: 10,

		border			: `1px solid ${colors.lightGray}`,
		transition		: 'border-color 0.2s ease-in, box-shadow 0.2s ease-in',

		':hover' : {
			border 		: `1px solid ${colors.blue}`,
			boxShadow 	: `0 0 0 1px ${colors.blue} inset`,
		},
		':active' : {
			border 		: `1px solid ${colors.blue}`,
			boxShadow 	: `0 0 0 1px ${colors.blue} inset`,
		},

		'@media (max-width: 550px)' : {
			paddingTop 		: 6,
			paddingLeft 	: 6,
			paddingRight 	: 6,
			paddingBottom 	: 6,
		},
	},
	//Not CSS but should be here
	styleConfig: {
		base: {
			fontSize 		: '16px',
			'::placeholder': {
				color 			: '#a4a4a4',
			    fontSize 		: '0.95em',
			    fontWeight 		: '100',
			    letterSpacing 	: '0.5px',
			    fontStyle 		: 'italic'
			},
		},
		invalid: {
			color: colors.blue,
		}
	}
}
