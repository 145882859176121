import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'

import {
	inputHasChanged,
	resetPassword,
	recoverEmail,
	verifyEmail,
} from './../../../actions'
import { AuthKeys } from './../../../actions/types'

import TEForm from './../../../component/Form/TEForm'
import TEInputRow from './../../../component/Form/TEInputRow'
import TEButton from './../../../component/Form/TEButton'

import styles from './styles'

class EmailActions extends Component {
	componentDidMount(){
		const query 		= new URLSearchParams(this.props.location.search)
		const mode 			= query.get('mode')

		switch (mode){
			case 'recoverEmail':
				this.handleRecoverEmail()
				break
			case 'verifyEmail':
				this.handleVerifyEmail()
				break
			default:
				break
		}
	}

	handleResetPassword = () => {
		const query 		= new URLSearchParams(this.props.location.search)
		const oobCode 		= query.get('oobCode')

		const { resetPasswordData } = this.props;

		this.props.resetPassword(oobCode, resetPasswordData)
	}
	handleRecoverEmail = () => {
		const query 		= new URLSearchParams(this.props.location.search)
		const oobCode 		= query.get('oobCode')

		this.props.recoverEmail(oobCode)
	}
	handleVerifyEmail = () => {
		const query 		= new URLSearchParams(this.props.location.search)
		const oobCode 		= query.get('oobCode')

		this.props.verifyEmail(oobCode)
	}

	renderByMode = ({mode}) => {
		switch (mode){
			case 'resetPassword':
				const { resetPasswordData } = this.props
				return (
					<TEForm style={styles.form}>
						<h1 style={styles.title}>Reset Password</h1>
						<TEInputRow
							labelForKey='password'
							type='password'
							rowData={resetPasswordData}
							onChangeKey={AuthKeys.RESET_PASSWORD_CHANGED}
							validationRules={['required', 'password']}
							inputExtras={{placeholder:'new password'}}
							labelStyles={styles.label}
							inputStyles={styles.input}
						/>
						<TEButton onClick={this.handleResetPassword} style={styles.button}>Reset Password</TEButton>
					</TEForm>
				)
			case 'recoverEmail':
				return (
					<TEForm style={styles.form}>
						<h1 style={styles.title}>Recover Email</h1>
					</TEForm>
				)
			case 'verifyEmail':
				return (
					<TEForm style={styles.form}>
						<h1 style={styles.title}>Email Verification</h1>
					</TEForm>
				)
			default:
				return <h1 style={styles.title}>Error Loading Appropriate Content.</h1>
		}
	}

	render(){
		const query 		= new URLSearchParams(this.props.location.search)
		const mode 			= query.get('mode')

		return (
			<div style={styles.background}>
				{this.renderByMode({mode})}
			</div>
		)
	}
}

EmailActions.propTypes = {}

EmailActions.defaultProps = {}

const mapStateToProps = (state) => {
	const { resetPasswordData } = state.Auth

	return { resetPasswordData }
}
export default connect(mapStateToProps, {
	inputHasChanged,
	resetPassword,
	recoverEmail,
	verifyEmail,
})(Radium(EmailActions))
