import { colors } from './../../../config/styles';

export default {
	background : {
		position 			: 'fixed',
		width				: '100%',
		height				: '100%',
		left				: 0,
		right				: 0,
		top					: 0,
		bottom				: 0,
		backgroundColor		: 'rgba(0,0,0,0.5)',
		zIndex				: 1000,
		opacity				: '0',
		visibility			: 'hidden',
		transition 			: 'visibility 0.25s ease-in-out, opacity 0.25s ease-in-out',
		overflowY 			: 'auto',
	},
	visible : {
		opacity 			: '1',
		visibility			: 'visible',
	},
	content : {
		position			: 'relative',
		backgroundColor		: colors.white,
		borderRadius		: 20,
	},
}
