import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import Radium from 'radium'

import TEAlert from './../../../component/Popup/TEAlert'
import Navbar from './../Navbar'
import Footer from './../Footer'

import { compareToLocalVersion } from './../../../helpers'
import styles from './styles'

class AdminContentWrapper extends Component {
	render() {
		const { location, appVersions, children } = this.props

		const { behind } = compareToLocalVersion(appVersions.minimumVersion)

		if (behind){
			return <TEAlert
				title='Major Version Update'
				message={`A newer version of the platform is available (v${appVersions.minimumVersion}) and is required to continue using the platform. Press the okay button below to receive the most recent version. If you continue to see this prompt after many attempts, either clear your cache or contact technical support at info@38thstreetstudios.com.`}
				onClick={() => window.location.reload(true) }
				visible
			/>
		}

		return (
			<div className='front-wrapper' style={styles.frontWrapper}>
				<Navbar location={location} />
				<div className='front-content-wrapper' style={styles.frontContentWrapper}>{children}</div>
				<Footer />
			</div>
		)
	}
}

AdminContentWrapper.propTypes = {}

AdminContentWrapper.defaultProps = {}

export default Radium(AdminContentWrapper)
