//
//TE Version 0.1.0
//

import React, { Component } from 'react';
import Radium from 'radium';

import styles from './styles';

class PanelTitle extends Component {

	render() {
		const {
			barStyle,

			title,
			titleStyle,

			subtitle,
			subtitleStyle,

			rightComponent,
		} = this.props
		
		return (
			<div style={[styles.container, barStyle]}>
				<h1 style={[styles.title, titleStyle]}>{title}</h1>
				{subtitle && <h2 style={[styles.subtitle, subtitleStyle]}>{subtitle}</h2>}
				{rightComponent && 
					<div style={styles.rightComponentContainer}>
						{rightComponent}
					</div>
				}
			</div>
		);
	}
}

PanelTitle.propTypes = {}

PanelTitle.defaultProps = {};

export default Radium(PanelTitle);
