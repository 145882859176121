import { colors } from './../../../config/styles';

const navBarHeight = '100px'

export default {
	container : {
		position 			: 'relative',
		backgroundColor 	: colors.white,
		height 				: navBarHeight,
		width 				: '100%',
		zIndex				: 100,
		boxShadow 			: `0px 1px 3px 0px ${colors.shadow}`,

		'@media (max-width: 450px)' : {
			height 	: 'auto',
		}
	},
	brandContainer : {
		position			: 'absolute',
		left				: 30,
		top					: 0,
		height				: navBarHeight,
		padding				: '30px 10px',
		zIndex				: 10,
		cursor				: 'pointer',

		'@media (max-width: 450px)' : {
			position 		: 'relative',
			display 		: 'block',
			textAlign 		: 'center',
			width 			: '100%',
			left 			: 0,
			borderBottom 	: `1px solid ${colors.lightGray}`,
		}
	},
	brandLink : {
		height				: '100%',
	},
	logo : {
		position 			: 'relative',
		height				: '100%',
	},
	navContainer : {
		position			: 'relative',
		width				: '100%',
		height 				: '100%',
		textAlign			: 'right',
	},
	ul : {
		position			: 'relative',
		width				: '100%',
		height 				: '100%',
		margin				: 0,
		paddingRight		: 30,

		'@media (max-width: 450px)' : {
			padding 		: 0,
		}
	},
	li : {
		position			: 'relative',
		display				: 'inline-block',
		lineHeight			: navBarHeight,
		textAlign			: 'center',
		padding				: '0px 15px',

		'@media (max-width: 450px)' : {
			padding			: '10px 0px',
			fontSize		: 18,
			lineHeight 		: 1,
			display 		: 'none',
			width 			: '100%',
			borderBottom 	: `1px solid ${colors.lightGray}`,
		}
	},
	liOpen : {
		'@media (max-width: 450px)' : {
			display 	: 'block',
		}
	},
	linkWrapper : {
		color 				: colors.darkGray,
		transition			: 'color 0.2s ease-in',

		':hover' : {
			color : colors.blue,
		},
		':active' : {
			color : colors.blue,
		},
	},
	link : {
		textAlign			: 'center',
		textDecoration		: 'none',
		fontWeight 			: 300,
		fontSize 			: 16,
	},
	active : {
		color 				: colors.blue
	},
	navButton : {
		WebkitAppearance	: 'none',
		backgroundColor		: 'transparent',
		border 				: 'none',
		fontSize 			: 16,
		textAlign 			: 'center',
		cursor				: 'pointer',
		fontWeight			: 500,
		color 				: colors.darkGray,

		transition			: 'color 0.2s ease-in',

		':hover' : {
			color : colors.blue,
		},
		':active' : {
			color : colors.blue,
		},
		':focus' : {
			outline : 'none'
		}
	},
	mobileNavButton : {
		position			: 'absolute',
		top 				: 30,
		right 				: 30,
		width 				: 40,
		height 				: 40,
		borderRadius 		: 5,
		cursor 				: 'pointer',
		display 			: 'none',
		zIndex 				: 100,
		padding 			: 5,


		border 				: `1px solid ${colors.blue}`,
		backgroundColor 	: colors.blue,
		fill 				: colors.white,
		transition 			: 'background-color 0.2s ease-in, fill 0.2s ease-in, transform 0.3s ease-in-out',


		':hover' : {
			backgroundColor 	: colors.white,
			fill 				: colors.blue,
		},
		':active' : {
			backgroundColor 	: colors.white,
			fill 				: colors.blue,
		},
		'@media (max-width: 450px)' : {
			display 		: 'block',
		}
	},
	navIcon : {
		width 			: '100%',
	},
	activeMobileNavButton : {
	   	WebkitTransform		: 'rotate(90deg)',
	    msTransform			: 'rotate(90deg)',
		transform 			: 'rotate(90deg)'
	}
}
