import base64 from 'base-64'

export const settings = {
	// dev: false,
	dev: process.env.NODE_ENV === 'development',
	apiDebugging: process.env.NODE_ENV === 'development',

	VERSION: '0.1.2',

	EMAIL_API_ROUTE: 'https://ov7qju62dl.execute-api.us-east-1.amazonaws.com/development/sendMail',
	GOOGLE_ANALYTICS_KEY: '',

	api_auth: base64.encode(
		'sdlkfwjeofijw8823lkjASDFWEFAALKJOI32kjLL:KKKijfo23j28909jsdfAAFEWGGGSksiLLKJJij'
	),

	DEVELOPMENT: {
		DEBUG: true,

		BASE_URL: 'http://localhost:3004',

		FIREBASE_URL: 'https://fc-dev2.firebaseio.com',
		FIREBASE_APIKEY: 'AIzaSyDoWFKGyTjMFLgqvfoYRjpZj3YJrCPkfS8',
		FIREBASE_AUTHDOMAIN: 'fc-dev2.firebaseapp.com',
		FIREBASE_STORAGEBUCKET: 'fc-dev2.appspot.com',
		FIREBASE_PRODUCT_ID: 'fc-dev2',
		MESSAGING_SENDER_ID: '878736380976',

		GOOGLE_MAP_API: 'AIzaSyDHxf5beZsj3le9Voz9DdxylRMvm3SY9Bk',

		STRIPE_API_KEY: 'pk_test_OkxTSVAAtGFhMENjnvrWbkQz',

		API_BASE_URL: 'https://2zg2khxm3l.execute-api.us-east-1.amazonaws.com/dev',
	},
	PRODUCTION: {
		DEBUG: false,

		BASE_URL: 'https://www.firstclasseventz.com/',

		FIREBASE_URL: 'https://fc-prod2.firebaseio.com',
		FIREBASE_APIKEY: 'AIzaSyBze0Q5zgjzXu9R593aBHjdBCpDJdIvazE',
		FIREBASE_AUTHDOMAIN: 'fc-prod2.firebaseapp.com',
		FIREBASE_STORAGEBUCKET: 'fc-prod2.appspot.com',
		FIREBASE_PRODUCT_ID: 'fc-prod2',
		MESSAGING_SENDER_ID: '180345656628',

		GOOGLE_MAP_API: 'AIzaSyDHxf5beZsj3le9Voz9DdxylRMvm3SY9Bk',

		STRIPE_API_KEY: 'pk_live_QVbN2w69zqJbcOn0FBbMYFo1',

		API_BASE_URL: 'https://ttxemxo6m1.execute-api.us-east-1.amazonaws.com/prod',
	},
}

export default settings
