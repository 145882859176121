//
//TE Version 0.1.0
//

import React, { Component } from 'react'
import { withScriptjs } from "react-google-maps"
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox'
import Radium from 'radium'

//import PropTypes from 'prop-types'

import settings from './../../../config/settings'

import styles from './styles'

class SearchBox extends Component {
	render(){
		const {
			setSearchboxRef,
			setInputRef,
			onPlacesChanged,
			placeholder = 'Search',
			style,
		} = this.props
		return (
			<StandaloneSearchBox
				ref={setSearchboxRef}
				// bounds={props.bounds}
				onPlacesChanged={onPlacesChanged}
				// onPlacesChanged={onPlacesChanged}
			>
				<input
					ref={setInputRef}
					type="text"
					placeholder={placeholder}
					style={{ ...styles.input, ...style }}
				/>
			</StandaloneSearchBox>
		)
	}
}

const SearchBoxWithFunctions = withScriptjs(Radium(SearchBox))

class TEGoogleSearchBox extends Component {
	render() {
		const {
			dev,
			DEVELOPMENT,
			PRODUCTION,
		} = settings
		const { GOOGLE_MAP_API } = dev ? DEVELOPMENT : PRODUCTION

		const {
			googleMapURL 	= `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API}&v=3.exp&libraries=geometry,drawing,places`,
			...rest
		} = this.props

		return (
			<SearchBoxWithFunctions
				googleMapURL={googleMapURL}
				loadingElement={<div style={{ width: '100%' }}/>}
				containerElement={<div style={{ width: '100%' }}/>}
				{...rest}
			/>
		)
	}
}

TEGoogleSearchBox.propTypes = {}

TEGoogleSearchBox.defaultProps = {}

export default TEGoogleSearchBox


// const PlacesWithStandaloneSearchBox = compose(
//   withProps({
//     googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places",
//     loadingElement: <div style={{ height: `100%` }} />,
//     containerElement: <div style={{ height: `400px` }} />,
//   }),
//   lifecycle({
//     componentWillMount() {
//       const refs = {}

//       this.setState({
//         places: [],
//         onSearchBoxMounted: ref => {
//           refs.searchBox = ref;
//         },
//         onPlacesChanged: () => {
//           const places = refs.searchBox.getPlaces();

//           this.setState({
//             places,
//           });
//         },
//       })
//     },
//   }),
//   withScriptjs
// )(props =>
//   <div data-standalone-searchbox="">
//     <StandaloneSearchBox
//       ref={props.onSearchBoxMounted}
//       bounds={props.bounds}
//       onPlacesChanged={props.onPlacesChanged}
//     >
//       <input
//         type="text"
//         placeholder="Customized your placeholder"
//         style={{
//           boxSizing: `border-box`,
//           border: `1px solid transparent`,
//           width: `240px`,
//           height: `32px`,
//           padding: `0 12px`,
//           borderRadius: `3px`,
//           boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
//           fontSize: `14px`,
//           outline: `none`,
//           textOverflow: `ellipses`,
//         }}
//       />
//     </StandaloneSearchBox>
//     <ol>
//       {props.places.map(({ place_id, formatted_address, geometry: { location } }) =>
//         <li key={place_id}>
//           {formatted_address}
//           {" at "}
//           ({location.lat()}, {location.lng()})
//         </li>
//       )}
//     </ol>
//   </div>
// );

// <PlacesWithStandaloneSearchBox />

