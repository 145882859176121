import { colors } from './../../../config/styles';

export default {
	input:{
		position		: 'relative',
		display			: 'block',
		width			: '100%',
		padding 		: 5,
		fontSize		: 14,
		backgroundColor	: colors.white,
    	minHeight 		: 100,
		borderRadius 	: 5,
        color           : colors.darkGray,

		border			: `1px solid ${colors.lightGray}`,
		transition		: 'border-color 0.2s ease-in, box-shadow 0.2s ease-in',

		':hover' : {
		    border 		: `1px solid ${colors.blue}`,
		    boxShadow 	: `0 0 0 1px ${colors.blue} inset`,
		},
		':active' : {
		    border 		: `1px solid ${colors.blue}`,
		    boxShadow 	: `0 0 0 1px ${colors.blue} inset`,
		}
	},
	disabled : {
		color 	: colors.gray,
		border 	: `1px solid ${colors.lighterGray}`,
	},
}
