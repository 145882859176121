import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import ReactTable from 'react-table'

import {
	startWatchingDrivers,
	stopWatchingDrivers,
	showDispatchAddDriverPopup,
} from './../../../actions'

import TEHelmet from './../../../component/Header/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import TEButton from './../../../component/Form/TEButton'

import AddDriverPopup from './AddDriverPopup'

import { tableColumns } from './tableConfigs'
import { customTableFilter } from './../../../helpers'
import styles from './styles'

class Fleet extends Component {
	componentDidMount(){
		const {
			riderModel,
			startWatchingDrivers,
		} = this.props

		startWatchingDrivers(riderModel.dispatchUID)
	}
	componentWillUnmount(){
		const {
			stopWatchingDrivers,
		} = this.props

		stopWatchingDrivers()
	}
	handleShowAddDriverPopup = () => {
		this.props.showDispatchAddDriverPopup()
	}
	render(){
		const meta = {
			title: 'FirstClass | Dispatch Fleet',
			description: '',
		}

		const {
			driverArray,
			driversLoaded,
			addDriverPopupVisible
		} = this.props

		return (
			<Fragment>
				<TEHelmet {...meta}/>
				<PanelWrapper style={styles.panelWrapper}>
					<Panel
						title='Fleets'
						rightComponent={
							<div style={styles.actionContainer}>
								<TEButton onClick={this.handleShowAddDriverPopup} style={styles.actionButton}>Add Chauffeur</TEButton>
							</div>
						}
					>
						<ReactTable
							data={driverArray}
							columns={tableColumns}
							defaultPageSize={10}
							filterable
							defaultFilterMethod={customTableFilter}
							defaultSorted={[{
								id: "name",
								desc: false
							}]}
							loading={!driversLoaded}
						/>
					</Panel>
				</PanelWrapper>
				<AddDriverPopup visible={addDriverPopupVisible}/>
			</Fragment>
		)
	}
}

Fleet.propTypes = {}

Fleet.defaultProps = {}

const mapStateToProps = (state) => {
	const {
		drivers,
		driversLoaded,
		addDriverPopupVisible
	} = state.Dispatch
	const { riderModel } = state.Rider

	const driverArray = []
	for (const key in drivers){
		const dataToMap = drivers[key]
		dataToMap.uid = key
		driverArray.push(dataToMap)
	}

	return {
		riderModel,
		driverArray,
		driversLoaded,
		addDriverPopupVisible,
	}
}

export default connect(mapStateToProps, {
	startWatchingDrivers,
	stopWatchingDrivers,
	showDispatchAddDriverPopup,
})(Radium(Fleet))
