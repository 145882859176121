import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import { Elements, injectStripe } from 'react-stripe-elements'
import tzlookup from 'tz-lookup'

import {
	fetchRiderCreditCards,
	createRiderSetPickUp,
	createRiderClearPickUp,
	createRiderSetDestination,
	createRiderClearDestination,
	createRiderVerifyLocations,
	createRiderVerifyVehicle,
	createRiderVerifyAccomodations,
	createRiderVerifyPickUpTime,
	createRiderSubmitPromo,
	createRiderVerifyPayment,
	createRiderRideRequest,
} from './../../../actions'
import { RiderKeys } from './../../../actions/types'

import TEHelmet from './../../../component/Header/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import TEGoogleMap from './../../../component/Map/TEGoogleMap'
import TEGoogleMarker from './../../../component/Map/TEGoogleMarker'
import TEGoogleSearchBox from './../../../component/Map/TEGoogleSearchBox'

import TERow from './../../../component/Form/TERow'
import TEMultiStepForm from './../../../component/Form/TEMultiStepForm'
import TEInputRow from './../../../component/Form/TEInputRow'
import TESegmentedGroup from './../../../component/Form/TESegmentedGroup'
import TESearchSelectRow from './../../../component/Form/TESearchSelectRow'
import TEDatetimeRow from './../../../component/Form/TEDatetimeRow'
import TEButton from './../../../component/Form/TEButton'
import TECardElement from './../../../component/Stripe/TECardElement'

import { convertToDateTimeShort, computeUTCOffsetDifferenceFromLatLng } from './../../../helpers'
import images from './../../../config/images'
import styles from './styles'

class CreateForm extends Component {
	state = { activeSearchedPlace: '' }

	componentDidMount() {
		this.props.fetchRiderCreditCards()
	}
	handleSetPickUp = () => {
		const places = this.searchBox.getPlaces()
		this.props.createRiderSetPickUp(places).then(() => {
			this.setState({ activeSearchedPlace: '' })
			this.searchBoxInput.value = ''
		})
	}
	handleClearPickUp = () => {
		this.props.createRiderClearPickUp()
	}
	handleSetDestination = () => {
		const places = this.searchBox.getPlaces()
		this.props.createRiderSetDestination(places).then(() => {
			this.setState({ activeSearchedPlace: '' })
			this.searchBoxInput.value = ''
		})
	}
	handleClearDestination = () => {
		this.props.createRiderClearDestination()
	}
	handleVerifyLocations = () => {
		const { createRiderVerifyLocations, createPickUpData, createDestinationData } = this.props

		const data = {
			createPickUpData,
			createDestinationData,
		}

		return createRiderVerifyLocations(data)
	}
	handleVehicleVerification = () => {
		const { createRiderVerifyVehicle, createPickUpData, createDestinationData } = this.props

		const data = {
			createPickUpData,
			createDestinationData,
		}

		return createRiderVerifyVehicle(data)
	}
	handleVerifyAccomodations = () => {
		// console.log('handleVerifyAccomodations')
		const {
			createRiderVerifyAccomodations,
			createPickUpData,
			createDestinationData,
			createPetsData,
		} = this.props

		const data = {
			createPickUpData,
			createDestinationData,
			createPetsData,
		}

		return createRiderVerifyAccomodations(data)
	}
	handleVerifyPickUpTime = () => {
		// console.log('handleVerifyPickUpTime')
		const {
			createRiderVerifyPickUpTime,
			createMinPickUpTime,
			createPickUpData,
			createDestinationData,
			createPickUpTimeData,
			createVehicleTypeData,
			createPromoData,
		} = this.props

		const data = {
			createPickUpData,
			createDestinationData,
			createMinPickUpTime,
			createPickUpTimeData,
			createVehicleTypeData,
			createPromoData,
		}

		return createRiderVerifyPickUpTime(data)
	}
	handleSubmitPromo = () => {
		// console.log('handleSubmitPromo')
		const {
			createRiderSubmitPromo,
			createPickUpData,
			createDestinationData,
			createPickUpTimeData,
			createVehicleTypeData,
			createPromoData,
		} = this.props

		const data = {
			createPickUpData,
			createDestinationData,
			createPickUpTimeData,
			createVehicleTypeData,
			createPromoData,
		}

		createRiderSubmitPromo(data)
	}
	handleVerifyPayment = () => {
		// console.log('handleVerifyPayment')
		const {
			createRiderVerifyPayment,
			createLinkToOrgData,
			createOrgPayingData,
			createCreditCardData,
			stripe,
		} = this.props

		if (createLinkToOrgData.value === 'Yes' && createOrgPayingData.value === 'Yes') {
			return new Promise((res, rej) => res())
		}

		const data = {
			createCreditCardData,
			stripe,
		}

		return createRiderVerifyPayment(data)
	}
	handleCreateRideRequest = () => {
		const {
			riderModel,
			createRiderRideRequest,
			createPickUpData,
			createDestinationData,
			createPickUpTimeData,
			createPetsData,
			createAdditionalNotesData,
			createPricingInformation,
			createPromoData,
			createVehicleTypeData,
			createCreditCardData,
			createLinkToOrgData,
			createOrgData,
			createOrgPayingData,
			createOrgResForData,
			createOrgPaxAliasData,
			createOrgPaxPhoneData,
			createOrgPaxEmailData,
			history,
		} = this.props

		const data = {
			riderModel,
			createPickUpData,
			createDestinationData,
			createPickUpTimeData,
			createPetsData,
			createAdditionalNotesData,
			createPricingInformation,
			createPromoData,
			createVehicleTypeData,
			createCreditCardData,
			createLinkToOrgData,
			createOrgData,
			createOrgPayingData,
			createOrgResForData,
			createOrgPaxAliasData,
			createOrgPaxPhoneData,
			createOrgPaxEmailData,
			history,
		}

		createRiderRideRequest(data)
	}

	getTimezone = () => {
		const { createPickUpData } = this.props
		if (!createPickUpData) {
			return null
		}

		if (createPickUpData && createPickUpData.lat && createPickUpData.lng) {
			const { lat, lng } = createPickUpData
			return tzlookup(lat, lng)
		}
	}
	getConvertedPickUpTime = () => {
		const { createPickUpData, createPickUpTimeData } = this.props
		if (!createPickUpData || !createPickUpTimeData.value) {
			return createPickUpTimeData.value
		}

		const timezoneOffset =
			computeUTCOffsetDifferenceFromLatLng(createPickUpData.lat, createPickUpData.lng) *
			60 *
			1000
		const convertedPickUpTime = createPickUpTimeData.value + timezoneOffset
		return convertedPickUpTime
	}

	render() {
		const { activeSearchedPlace } = this.state
		const {
			riderModel,
			organizationArray,
			createLinkToOrgData,
			createOrgData,
			createOrgPayingData,
			createOrgResForData,
			createOrgPaxAliasData,
			createOrgPaxPhoneData,
			createOrgPaxEmailData,
			creditCards,
			creditCardsLoaded,
			createPickUpData,
			createDestinationData,
			createVehicleTypeData,
			createPetsData,
			createAdditionalNotesData,
			createPickUpTimeData,
			createVehicleOptions,
			createMinPickUpTime,
			createPricingInformation,
			createPromoData,
			createCreditCardData,
		} = this.props

		const setupValidation = [createLinkToOrgData]

		const linkedToOrg = createLinkToOrgData.value === 'Yes'
		const linkedOrgPaying =
			linkedToOrg && createOrgData.value && createOrgPayingData.value === 'Yes'
		const linkedOrgNotPaying =
			linkedToOrg && createOrgData.value && createOrgPayingData.value === 'No'

		if (linkedToOrg) {
			//Validate Data
			setupValidation.push(createOrgData)
			setupValidation.push(createOrgPayingData)

			if (linkedOrgPaying) {
				setupValidation.push(createOrgResForData)
			}
			if (
				linkedOrgNotPaying ||
				(linkedOrgPaying && createOrgResForData.value === '3rd Party')
			) {
				setupValidation.push(createOrgPaxAliasData)
				setupValidation.push(createOrgPaxPhoneData)
				setupValidation.push(createOrgPaxEmailData)
			}
		}

		return (
			<TEMultiStepForm
				roundedCorners={true}
				initialCancel={false}
				stepData={[
					...(riderModel.organizations
						? [
								{
									title: 'Setup',
									component: (
										<div>
											<h1 style={styles.stepTitle}>Passenger Information</h1>
											<div style={styles.stepContentWrapper}>
												<TESegmentedGroup
													labelForKey="linkedToOrg"
													checkedInputData={createLinkToOrgData}
													onChangeKey={
														RiderKeys.CREATE_LINK_TO_ORG_CHANGED
													}
													buttonArray={['No', 'Yes']}
													validationRules={['required']}
													inline
												/>
												{linkedToOrg && (
													<TESearchSelectRow
														labelForKey="selectedOrg"
														options={organizationArray}
														rowData={createOrgData}
														optionLabelPath="name"
														onChangeKey={RiderKeys.CREATE_ORG_CHANGED}
														validationRules={['required']}
													/>
												)}
												{linkedToOrg && createOrgData.value && (
													<TESegmentedGroup
														labelForKey="organizationPaying"
														checkedInputData={createOrgPayingData}
														onChangeKey={
															RiderKeys.CREATE_ORG_PAYING_CHANGED
														}
														buttonArray={['No', 'Yes']}
														validationRules={['required']}
														inline
													/>
												)}
												{linkedOrgPaying && (
													<TESearchSelectRow
														labelForKey="ordResFor"
														options={['Myself', '3rd Party']}
														rowData={createOrgResForData}
														onChangeKey={
															RiderKeys.CREATE_ORG_RES_FOR_CHANGED
														}
														validationRules={['required']}
													/>
												)}
												{(linkedOrgNotPaying ||
													(linkedOrgPaying &&
														createOrgResForData.value ===
															'3rd Party')) && (
													<Fragment>
														<TEInputRow
															labelForKey="alias"
															rowData={createOrgPaxAliasData}
															onChangeKey={
																RiderKeys.CREATE_ORG_PAX_ALIAS_CHANGED
															}
															validationRules={['required']}
														/>
														<TEInputRow
															type="tel"
															labelForKey="phone"
															rowData={createOrgPaxPhoneData}
															onChangeKey={
																RiderKeys.CREATE_ORG_PAX_PHONE_CHANGED
															}
															validationRules={['required', 'phone']}
														/>
														<TEInputRow
															type="email"
															labelForKey="email"
															rowData={createOrgPaxEmailData}
															onChangeKey={
																RiderKeys.CREATE_ORG_PAX_EMAIL_CHANGED
															}
															validationRules={['required', 'email']}
														/>
													</Fragment>
												)}
											</div>
										</div>
									),
									validateData: setupValidation,
								},
						  ]
						: []),
					{
						title: 'Endpoints',
						component: (
							<div>
								<h1 style={styles.stepTitle}>Pick Up Location and Destination</h1>
								<div style={styles.stepContentWrapper}>
									<TERow style={styles.searchWrapper}>
										<TEGoogleSearchBox
											setSearchboxRef={(ref) => (this.searchBox = ref)}
											setInputRef={(ref) => (this.searchBoxInput = ref)}
											onPlacesChanged={() => {
												const places = this.searchBox.getPlaces()
												if (places[0]) {
													this.setState({
														activeSearchedPlace: places[0],
													})
													this.map.map.panTo({
														lat: places[0].geometry.location.lat(),
														lng: places[0].geometry.location.lng(),
													})
												}
											}}
											style={styles.searchBox}
										/>
										{!createPickUpData && (
											<TEButton
												style={styles.searchClearButton}
												onClick={this.handleSetPickUp}
											>
												Set Pick Up
											</TEButton>
										)}
										{!createDestinationData && (
											<TEButton
												style={styles.searchClearButton}
												onClick={this.handleSetDestination}
											>
												Set Destination
											</TEButton>
										)}
										<TEButton
											style={styles.searchClearButton}
											onClick={() => {
												this.setState({ activeSearchedPlace: '' })
												this.searchBoxInput.value = ''
											}}
										>
											Clear
										</TEButton>
									</TERow>
									<div style={styles.mapWrapper}>
										<TEGoogleMap
											ref={(ref) => (this.map = ref)}
											showTrafficButton
										>
											{activeSearchedPlace &&
												activeSearchedPlace.geometry.location.lat() &&
												activeSearchedPlace.geometry.location.lng() && (
													<TEGoogleMarker
														position={{
															lat: activeSearchedPlace.geometry.location.lat(),
															lng: activeSearchedPlace.geometry.location.lng(),
														}}
													/>
												)}
											{createPickUpData &&
												createPickUpData.lat &&
												createPickUpData.lng && (
													<TEGoogleMarker
														position={{
															lat: createPickUpData.lat,
															lng: createPickUpData.lng,
														}}
														icon={images.startMapIcon}
													/>
												)}
											{createDestinationData &&
												createDestinationData.lat &&
												createDestinationData.lng && (
													<TEGoogleMarker
														position={{
															lat: createDestinationData.lat,
															lng: createDestinationData.lng,
														}}
														icon={images.endMapIcon}
													/>
												)}
										</TEGoogleMap>
									</div>
									<div style={styles.addressContainer}>
										<div style={styles.addressWrapper}>
											<div style={styles.addressText}>
												<span style={styles.addressTitle}>
													Pick Up Address
												</span>
												<span style={styles.addressValue}>
													{createPickUpData &&
													createPickUpData.fullAddress
														? createPickUpData.fullAddress
														: ' -- '}
												</span>
											</div>
											<TEButton
												style={styles.searchClearButton}
												onClick={this.handleClearPickUp}
											>
												Clear
											</TEButton>
										</div>
										<div style={styles.addressWrapper}>
											<div style={styles.addressText}>
												<span style={styles.addressTitle}>
													Desination Address
												</span>
												<span style={styles.addressValue}>
													{createDestinationData &&
													createDestinationData.fullAddress
														? createDestinationData.fullAddress
														: ' -- '}
												</span>
											</div>
											<TEButton
												style={styles.searchClearButton}
												onClick={this.handleClearDestination}
											>
												Clear
											</TEButton>
										</div>
									</div>
								</div>
							</div>
						),
						validateData: [],
						additionalProcessing: this.handleVerifyLocations,
					},
					{
						title: 'Vehicle',
						component: (
							<div>
								<h1 style={styles.stepTitle}>Select A Vehicle Type</h1>
								<h2 style={styles.stepSubtitle}>
									If you are looking for a vehicle not listed below feel free to
									contact us at info@38thstreetstudios.com.
								</h2>
								<div style={styles.stepContentWrapper}>
									{createVehicleOptions && (
										<TESearchSelectRow
											labelForKey="vehicleType"
											options={createVehicleOptions}
											rowData={createVehicleTypeData}
											optionLabelPath="type"
											onChangeKey={RiderKeys.CREATE_VEHICLE_TYPE_CHANGED}
											validationRules={['required']}
										/>
									)}
									{createVehicleTypeData.value.type === 'Sedan' && (
										<img
											src={images.vehicleSedan}
											alt="Sedan"
											style={styles.vehicleTypeImage}
										/>
									)}
									{createVehicleTypeData.value.type === 'SUV' && (
										<img
											src={images.vehicleSUV}
											alt="SUV"
											style={styles.vehicleTypeImage}
										/>
									)}
									{createVehicleTypeData.value && (
										<div style={styles.vehicleTypeContainer}>
											<div style={styles.vehicleTypeDetailContainer}>
												<span style={styles.vehicleTypeTitle}>
													Capacity
												</span>
												<span style={styles.vehicleTypeValue}>
													{createVehicleTypeData.value.capacity}
												</span>
											</div>
											<div style={styles.vehicleTypeDetailContainer}>
												<span style={styles.vehicleTypeTitle}>Baggage</span>
												<span style={styles.vehicleTypeValue}>
													{createVehicleTypeData.value.baggage}
												</span>
											</div>
										</div>
									)}
								</div>
							</div>
						),
						validateData: [createVehicleTypeData],
						additionalProcessing: this.handleVehicleVerification,
					},
					{
						title: 'Accommodations',
						component: (
							<div>
								<h1 style={styles.stepTitle}>Accommodations</h1>
								<div style={styles.stepContentWrapper}>
									<TESegmentedGroup
										labelForKey="pets"
										checkedInputData={createPetsData}
										onChangeKey={RiderKeys.CREATE_PETS_CHANGED}
										buttonArray={['Yes', 'No']}
										inline
									/>
									<TEInputRow
										type="textarea"
										labelForKey="notes"
										rowData={createAdditionalNotesData}
										onChangeKey={RiderKeys.CREATE_NOTES_CHANGED}
										inputExtras={{
											placeholder:
												'Airline Number, Pick Up Instructions, etc.',
										}}
									/>
								</div>
							</div>
						),
						validateData: [createPetsData, createAdditionalNotesData],
						additionalProcessing: this.handleVerifyAccomodations,
					},
					{
						title: 'Pick Up Time',
						component: (
							<div>
								<h1 style={styles.stepTitle}>Select A Pick Up Time</h1>
								<div style={styles.stepContentWrapper}>
									<span style={styles.pickupTimeMessage}>
										Earliest Reservation:{' '}
										{convertToDateTimeShort(
											createMinPickUpTime,
											this.getTimezone()
										)}
									</span>
									<TEDatetimeRow
										labelForKey="pickUpTime"
										rowData={createPickUpTimeData}
										onChangeKey={RiderKeys.CREATE_PICK_UP_TIME_CHANGED}
										inputExtras={{
											showTimeSelect: true,
											dateFormat: 'MMMM d, yyyy h:mm aa',
											timeIntervals: 15,
										}}
									/>
									<span style={styles.pickupTimeNote}>
										Time is displayed in local pick up location time. (
										{this.getTimezone()})
									</span>
								</div>
							</div>
						),
						validateData: [createPickUpTimeData],
						additionalProcessing: this.handleVerifyPickUpTime,
					},
					{
						title: 'Payment',
						component: (
							<div>
								<h1 style={styles.stepTitle}>Payment Method</h1>
								<div style={styles.stepContentWrapper}>
									{createPricingInformation && (
										<div style={styles.tableWrapper}>
											<table style={styles.table}>
												<tbody>
													<tr style={styles.tr}>
														<th style={styles.th}>Base Fare</th>
														<td style={styles.td}>
															$
															{Math.round(
																createPricingInformation.basePrice *
																	100
															) / 100}
														</td>
													</tr>
													<tr style={styles.tr}>
														<th style={styles.th}>Airport Fee</th>
														<td style={styles.td}>
															$
															{Math.round(
																createPricingInformation.airportCost *
																	100
															) / 100}
														</td>
													</tr>
													<tr style={styles.tr}>
														<th style={styles.th}>Fuel Fee</th>
														<td style={styles.td}>
															$
															{Math.round(
																createPricingInformation.fuelCost *
																	100
															) / 100}
														</td>
													</tr>
													<tr style={styles.tr}>
														<th style={styles.th}>Gratuity</th>
														<td style={styles.td}>
															$
															{Math.round(
																createPricingInformation.gratuityCost *
																	100
															) / 100}
														</td>
													</tr>
													<tr style={styles.tr}>
														<th style={styles.th}>Processing</th>
														<td style={styles.td}>
															$
															{Math.round(
																createPricingInformation.processingCost *
																	100
															) / 100}
														</td>
													</tr>
													<tr style={styles.tr}>
														<th style={styles.th}>Total Fare</th>
														<td style={styles.td}>
															$
															{Math.round(
																createPricingInformation.finalPrice *
																	100
															) / 100}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									)}
									<div style={styles.promoInputWrapper}>
										<TEInputRow
											size="half"
											labelForKey="promoCode"
											rowData={createPromoData}
											onChangeKey={RiderKeys.CREATE_PROMO_CHANGED}
											validationRules={['required']}
										/>
										<TEButton
											onClick={this.handleSubmitPromo}
											style={styles.submitPromoButton}
										>
											Apply Code
										</TEButton>
									</div>
									{linkedOrgPaying && (
										<span style={styles.organizationProvidingMessage}>
											This trip will be charged to {createOrgData.value.name}.
										</span>
									)}
									{creditCardsLoaded && (!linkedToOrg || linkedOrgNotPaying) && (
										<TESegmentedGroup
											labelForKey="creditCard"
											checkedInputData={createCreditCardData}
											onChangeKey={RiderKeys.CREATE_CREDIT_CARD_CHANGED}
											buttonArray={[...(creditCards || []), 'New Card'].map(
												(card) => {
													if (typeof card === 'string') {
														return {
															label: card,
															value: card,
															key: 'New Card',
														}
													}

													const {
														brand,
														exp_month,
														exp_year,
														last4,
														id,
													} = card
													return {
														label: (
															<Fragment>
																<div style={styles.cardWrapper}>
																	<span style={styles.cardType}>
																		{brand}
																	</span>
																	<span
																		style={styles.cardExpration}
																	>
																		{exp_month}/{exp_year}
																	</span>
																	<span style={styles.cardLast4}>
																		{last4}
																	</span>
																</div>
															</Fragment>
														),
														value: id,
														key: id,
													}
												}
											)}
											// groupContainerStyles={styles.selectedImageGroup}
											labelWrapperStyles={styles.selectedCardLabelWrapper}
											labelStyles={styles.selecetedCardLabel}
											checkedLabelStyles={styles.selectedCardCheckedLabel}
											validationRules={['required']}
										/>
									)}
									{createCreditCardData.value === 'New Card' &&
										(!linkedToOrg || linkedOrgNotPaying) && (
											<TECardElement
												onReady={(ref) => (this.cardInputRef = ref)}
											/>
										)}
								</div>
							</div>
						),
						validateData: [],
						additionalProcessing: this.handleVerifyPayment,
					},
					{
						title: 'Review',
						component: (
							<div>
								<h1 style={styles.stepTitle}>Review Ride Request Details</h1>
								<div style={styles.stepContentWrapper}>
									<div style={styles.tableWrapper}>
										<table style={styles.table}>
											<tbody>
												<tr style={styles.tr}>
													<th style={styles.th}>Pick Up Location</th>
													<td style={styles.td}>
														{createPickUpData &&
															createPickUpData.fullAddress}
													</td>
												</tr>
												<tr style={styles.tr}>
													<th style={styles.th}>Destination</th>
													<td style={styles.td}>
														{createDestinationData &&
															createDestinationData.fullAddress}
													</td>
												</tr>
												<tr style={styles.tr}>
													<th style={styles.th}>Pick Up Time</th>
													<td style={styles.td}>
														{createPickUpTimeData &&
															createPickUpTimeData.value &&
															convertToDateTimeShort(
																this.getConvertedPickUpTime(),
																this.getTimezone()
															)}
													</td>
												</tr>
												<tr style={styles.tr}>
													<th style={styles.th}>Pets Present</th>
													<td style={styles.td}>
														{createPetsData.value || 'No'}
													</td>
												</tr>
												<tr style={styles.tr}>
													<th style={styles.th}>Additional Notes</th>
													<td style={styles.td}>
														<pre>
															{createAdditionalNotesData.value ||
																' -- '}
														</pre>
													</td>
												</tr>
												<tr style={styles.tr}>
													<th style={styles.th}>Total Fare</th>
													<td style={styles.td}>
														$
														{createPricingInformation &&
															Math.round(
																createPricingInformation.finalPrice *
																	100
															) / 100}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						),
						validateData: [],
					},
				]}
				handleSubmitOnClick={this.handleCreateRideRequest}
			/>
		)
	}
}

CreateForm.propTypes = {}

CreateForm.defaultProps = {}

const mapStateToProps = (state) => {
	const {
		riderModel,
		organizations,
		createLinkToOrgData,
		createOrgData,
		createOrgPayingData,
		createOrgResForData,
		createOrgPaxAliasData,
		createOrgPaxPhoneData,
		createOrgPaxEmailData,
		creditCards,
		creditCardsLoaded,
		createPickUpData,
		createDestinationData,
		createVehicleTypeData,
		createPetsData,
		createAdditionalNotesData,
		createPickUpTimeData,
		createVehicleOptions,
		createMinPickUpTime,
		createPricingInformation,
		createPromoData,
		createCreditCardData,
	} = state.Rider

	const organizationArray = []
	for (const key in organizations) {
		const dataToMap = organizations[key]
		dataToMap.uid = key
		if (dataToMap.type !== 'Livery') {
			organizationArray.push(dataToMap)
		}
	}
	organizationArray.sort((a, b) => {
		if (a.name < b.name) {
			return -1
		}
		if (a.name > b.name) {
			return 1
		}
		return 0
	})

	return {
		riderModel,
		organizationArray,
		createLinkToOrgData,
		createOrgData,
		createOrgPayingData,
		createOrgResForData,
		createOrgPaxAliasData,
		createOrgPaxPhoneData,
		createOrgPaxEmailData,
		creditCards,
		creditCardsLoaded,
		createPickUpData,
		createDestinationData,
		createVehicleTypeData,
		createPetsData,
		createAdditionalNotesData,
		createPickUpTimeData,
		createVehicleOptions,
		createMinPickUpTime,
		createPricingInformation,
		createPromoData,
		createCreditCardData,
	}
}

const WrappedCreateForm = connect(
	mapStateToProps,
	{
		fetchRiderCreditCards,
		createRiderSetPickUp,
		createRiderClearPickUp,
		createRiderSetDestination,
		createRiderClearDestination,
		createRiderVerifyLocations,
		createRiderVerifyVehicle,
		createRiderVerifyAccomodations,
		createRiderVerifyPickUpTime,
		createRiderSubmitPromo,
		createRiderVerifyPayment,
		createRiderRideRequest,
	}
)(Radium(injectStripe(CreateForm)))

class Create extends Component {
	render() {
		const meta = {
			title: 'FirstClass | Request a Ride',
			description: '',
		}
		return (
			<Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper>
					<Panel containerStyle={styles.panel}>
						<Elements>
							<WrappedCreateForm {...this.props} />
						</Elements>
					</Panel>
				</PanelWrapper>
			</Fragment>
		)
	}
}

export default Radium(Create)
