// import { colors } from './../../../config/styles'

export default {
	errorMessage: {
		marginTop 		: 30,
		marginBottom 	: 30,
		textAlign 		: 'center',

	},
	actionsContainer: {
		display 	: 'flex',
		alignItems 	: 'center',
	},
	actionButton: {
		width 		: 'auto',
		marginLeft 	: 5,
		whiteSpace 	: 'nowrap',
	},
	detailsContainer: {
		display 		: 'flex',
		justifyContent 	: 'center',
		flexWrap 		: 'wrap',
	},
	mapWrapper: {
		width 			: '100%',
		height 			: 450,
		marginBottom 	: 15,
	},
	leftColumn: {
		width			: '50%',
		paddingRight 	: 10,
	},
	rightColumn: {
		width			: '50%',
		paddingLeft 	: 10,
	},
	tableWrapper: {
		overflowX 	: 'auto',
	    margin 		: '10px auto',
	},
	table: {
		borderCollapse 	: 'collapse',
		width			: '100%'
	},
	caption: {
		fontWeight 			: 500,
	    padding 			: '5px 0px',
	    backgroundColor		: '#e0e0e0',
	},
	tr: {},
	th: {
		textAlign 		: 'left',
		padding 		: '3px 5px',
		fontSize 		: 14,
		fontWeight 		: 500,
	},
	td: {
		padding 		: '3px 5px',
		fontSize 		: 14,
		fontWeight 		: 300,
	},
	editTimestampsButton: {
		width 			: 'auto',
		marginLeft 		: 'auto',
		marginRight 	: 'auto',
		marginTop 		: 15,
		paddingLeft 	: 20,
		paddingRight 	: 20,
	},
	completeRequestWrapper: {
		marginTop 		: 50,
		marginBottom 	: 50,
	},
	completeRequestTitle: {
		marginTop 		: 0,
		marginBottom 	: 0,
		fontSize 		: 24,
		fontWeight 		: 500,
		lineHeight 		: 1.2,
		textAlign 		: 'center',
	},
	completeRequestMessage: {
		marginTop 			: 0,
		marginBottom 		: 10,
		fontSize 			: 14,
		lineHeight 		 	: 1.5,
		fontWeight 			: 100,
		maxWidth 	 		: 350,
		textAlign 			: 'center',
		marginLeft 			: 'auto',
		marginRight 		: 'auto',
	},
	completeRequestButton: {
		width 			: 'auto',
		marginLeft 		: 'auto',
		marginRight 	: 'auto',
	},
}
