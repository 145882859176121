import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'

import {
	hideDispatchAddDriverPopup,
	dispatchAddDriver,
} from './../../../../actions'
import { DispatchKeys } from './../../../../actions/types'

import TEPopup from './../../../../component/Popup/TEPopup'
import TEMultiStepForm from './../../../../component/Form/TEMultiStepForm'
import TEInputRow from './../../../../component/Form/TEInputRow'

import styles from './styles'

class AddDriverPopup extends Component {
	handleClosePopup = () => {
		this.props.hideDispatchAddDriverPopup()
	}
	handleCreate = () => {
		const {
			dispatchAddDriver,
			riderModel,
			createDriverFirstNameData,
			createDriverLastNameData,
			createDriverPhoneData,
			createDriverEmailData,
		} = this.props

		const data = {
			riderModel,
			createDriverFirstNameData,
			createDriverLastNameData,
			createDriverPhoneData,
			createDriverEmailData,
		}

		dispatchAddDriver(data)
	}

	render(){
		const {
			visible,

			createDriverFirstNameData,
			createDriverLastNameData,
			createDriverPhoneData,
			createDriverEmailData,
		} = this.props

		return (
			<TEPopup visible={visible} contentStyles={styles.content}>
				<div style={styles.container}>
					<TEMultiStepForm
						stepData={[{
							title 			: 'Account Details',
							component 		: <Fragment>
									<TEInputRow
										labelForKey='firstName'
										rowData={createDriverFirstNameData}
										onChangeKey={DispatchKeys.CREATE_DRIVER_FIRST_NAME_CHANGED}
										inputExtras={{ placeholder: 'First Name' }}
										validationRules={['required']}
										size='half'
									/>
									<TEInputRow
										labelForKey='lastName'
										rowData={createDriverLastNameData}
										onChangeKey={DispatchKeys.CREATE_DRIVER_LAST_NAME_CHANGED}
										inputExtras={{ placeholder: 'Last Name' }}
										validationRules={['required']}
										size='half'
									/>
									<TEInputRow
										type='tel'
										labelForKey='phone'
										rowData={createDriverPhoneData}
										onChangeKey={DispatchKeys.CREATE_DRIVER_PHONE_CHANGED}
										validationRules={['required']}
										inputExtras={{ placeholder: 'Phone' }}
									/>
									<TEInputRow
										type='email'
										labelForKey='email'
										rowData={createDriverEmailData}
										onChangeKey={DispatchKeys.CREATE_DRIVER_EMAIL_CHANGED}
										validationRules={['required']}
										inputExtras={{ placeholder: 'Email' }}
									/>
								</Fragment>,
							validateData 	: [createDriverFirstNameData, createDriverLastNameData, createDriverPhoneData, createDriverEmailData]
						}]}
						handleCancelOnClick={this.handleClosePopup}
						handleSubmitOnClick={this.handleCreate}
					/>
				</div>
			</TEPopup>
		)
	}
}

AddDriverPopup.propTypes = {}

AddDriverPopup.defaultProps = {}

const mapStateToProps = (state, props) => {
	const {
		createDriverFirstNameData,
		createDriverLastNameData,
		createDriverPhoneData,
		createDriverEmailData,
	} = state.Dispatch
	const { riderModel } = state.Rider


	return {
		riderModel,
		createDriverFirstNameData,
		createDriverLastNameData,
		createDriverPhoneData,
		createDriverEmailData,
	}
}

export default connect(mapStateToProps, {
	hideDispatchAddDriverPopup,
	dispatchAddDriver,
})(Radium(AddDriverPopup))
