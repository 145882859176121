import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'

import {
	startWatchingAdminOrganization,
	stopWatchingAdminOrganization,
	startWatchingAdminOrganizationRider,
	stopWatchingAdminOrganizationRider,
	saveAdminOrganizationUser,
	confirmRemoveAdminOrganizationUser,
} from './../../../actions'
import { AdminKeys } from './../../../actions/types'

import TEHelmet from './../../../component/Header/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import TESegmentedGroup from './../../../component/Form/TESegmentedGroup'
import TEButton from './../../../component/Form/TEButton'

import styles from './styles'

class OrganizationUserDetailed extends Component {
	componentDidMount() {
		const {
			match,
			startWatchingAdminOrganization,
			startWatchingAdminOrganizationRider,
		} = this.props
		const { orgUID, userUID } = match.params

		startWatchingAdminOrganization(orgUID)
		startWatchingAdminOrganizationRider(userUID)
	}
	componentWillUnmount() {
		const { stopWatchingAdminOrganization, stopWatchingAdminOrganizationRider } = this.props

		stopWatchingAdminOrganization()
		stopWatchingAdminOrganizationRider()
	}
	handleRemoveUser = () => {
		const { match, confirmRemoveAdminOrganizationUser, history } = this.props
		const { orgUID, userUID } = match.params

		const data = { orgUID, userUID, history }

		confirmRemoveAdminOrganizationUser(data)
	}
	handleSave = () => {
		const { match, saveAdminOrganizationUser, savePermissionData } = this.props
		const { orgUID, userUID } = match.params

		const data = {
			orgUID,
			userUID,
			savePermissionData,
		}

		saveAdminOrganizationUser(data)
	}

	render() {
		const meta = {
			title: 'FirstClass | Organization User Details',
			description: '',
		}
		const {
			organizationModel,
			organizationModelLoaded,
			organizationRiderModel,
			organizationRiderModelLoaded,
			savePermissionData,
		} = this.props

		if (!organizationModel || !organizationRiderModel) {
			if (organizationModelLoaded && organizationRiderModelLoaded) {
				return <h1 style={styles.errorMessage}>Error Loading Organization...</h1>
			} else {
				return null
			}
		}

		return (
			<Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper style={styles.panelWrapper}>
					<Panel
						containerStyle={styles.editPanel}
						title={`${organizationRiderModel.profile &&
							organizationRiderModel.profile.alias} Details`}
						rightComponent={
							<div style={styles.actionsWrapper}>
								<TEButton
									onClick={this.handleRemoveUser}
									style={styles.actionButton}
								>
									Remove User From Organization
								</TEButton>
							</div>
						}
					>
						<TESegmentedGroup
							labelForKey="permission"
							checkedInputData={savePermissionData}
							onChangeKey={AdminKeys.SAVE_PERMISSION_CHANGED}
							buttonArray={['Admin', 'Director', 'Member']}
							validationRules={['required']}
							inline
						/>
						<TEButton onClick={this.handleSave} style={styles.saveButton}>
							Save
						</TEButton>
					</Panel>
				</PanelWrapper>
			</Fragment>
		)
	}
}

OrganizationUserDetailed.propTypes = {}

OrganizationUserDetailed.defaultProps = {}

const mapStateToProps = (state, props) => {
	const { orgUID } = props.match.params
	const {
		organizationModel,
		organizationModelLoaded,
		organizationRiderModel,
		organizationRiderModelLoaded,
		savePermissionData,
	} = state.Admin

	if (!savePermissionData.valid && !savePermissionData.value) {
		if (
			organizationRiderModel &&
			organizationRiderModel.organizations &&
			organizationRiderModel.organizations[orgUID] &&
			organizationRiderModel.organizations[orgUID].permission
		) {
			savePermissionData.value = organizationRiderModel.organizations[orgUID].permission
			savePermissionData.valid = true
			savePermissionData.validLabel = ''
		}
	}

	return {
		organizationModel,
		organizationModelLoaded,
		organizationRiderModel,
		organizationRiderModelLoaded,
		savePermissionData,
	}
}

export default connect(
	mapStateToProps,
	{
		startWatchingAdminOrganization,
		stopWatchingAdminOrganization,
		startWatchingAdminOrganizationRider,
		stopWatchingAdminOrganizationRider,
		saveAdminOrganizationUser,
		confirmRemoveAdminOrganizationUser,
	}
)(Radium(OrganizationUserDetailed))
