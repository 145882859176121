import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import Radium from 'radium'
import { CardElement } from 'react-stripe-elements'

// import TEButton from './../../../../component/Form/TEButton'

import styles from './styles'

class TECardElement extends Component {
	render(){
		return (
			<div style={styles.container}>
				<CardElement style={styles.styleConfig} {...this.props}/>
			</div>
		)
	}
}

TECardElement.propTypes = {}

TECardElement.defaultProps = {}

export default Radium(TECardElement)
