//
//TE Version 0.1.0
//

import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import Radium from 'radium'
import { connect } from 'react-redux'

import { showInvalidDataAlert } from './../../../actions'

import TEForm from './../TEForm'
import TEButton from './../TEButton'

import styles from './styles'

class TEMultiStepForm extends Component {
	constructor(props) {
		super(props)

		this.state = { currentStep : 0 }

		this.renderSteps              	= this.renderSteps.bind(this)
		this.renderStepComponent        = this.renderStepComponent.bind(this)
		this.handlePreviousPressed		= this.handlePreviousPressed.bind(this)
		this.handleNextPressed			= this.handleNextPressed.bind(this)
	}

	componentDidMount(){
		const { onStepChange } = this.props
		const { currentStep } = this.state
		if (onStepChange){
			onStepChange(currentStep)
		}
	}
	componentWillUpdate(nextProps, nextState){
		const { onStepChange } = this.props
		const { currentStep } = this.state
		const nextCurrentStep = nextState.currentStep
		if (currentStep !== nextCurrentStep){
			if (onStepChange){
				onStepChange(nextCurrentStep)
			}
		}
	}

	renderSteps(){
		const { stepData } = this.props
		const { currentStep } = this.state
		if (!stepData){ return null }

		const numberOfSteps = stepData.length

		if (numberOfSteps === 0){ return null }

		return stepData.map((step, index) => {
			const { title } = step
			const width = { width : `${100/numberOfSteps}%` }
			return (
				<div key={index} style={[styles.stepWrapper, width]}>
					<div style={[styles.stepNumber, index === currentStep && styles.activeStepNumber]}>{index + 1}</div>
					<div style={styles.stepTitle}>{title}</div>
				</div>
			)
		})
	}

	renderStepComponent(){
		const { stepData } = this.props
		const { currentStep } = this.state
		if (!stepData){ return null }

		const numberOfSteps = stepData.length

		if (numberOfSteps === 0){ return null }

		return stepData[currentStep].component
	}

	handlePreviousPressed(){
		const { handleCancelOnClick } = this.props
		const { currentStep } = this.state

		if (currentStep === 0){
			handleCancelOnClick()
			return
		}

		this.setState({ currentStep : currentStep - 1 })
	}
	handleNextPressed(){
		const { handleSubmitOnClick, showInvalidDataAlert, stepData } = this.props
		const { currentStep } = this.state

		//Validation Step
		const { validateData, additionalProcessing } = stepData[currentStep]
		if (validateData){
			for (let i=0; i<validateData.length; i++){
				const dataToValidate = validateData[i]
				if (!dataToValidate.valid){
					showInvalidDataAlert(dataToValidate)
					return
				}
			}
		}

		if (additionalProcessing){
			additionalProcessing()
			.then(() => {
				if (currentStep + 1 === stepData.length){
					handleSubmitOnClick()
					this.setState({ currentStep : 0 })
					return
				}
				this.setState({ currentStep : currentStep + 1 })
			})
			.catch((error) => console.log(error))
		}else{
			if (currentStep + 1 === stepData.length){
				handleSubmitOnClick()
				this.setState({ currentStep : 0 })
				return
			}
			this.setState({ currentStep : currentStep + 1 })
		}

	}
	render() {
		const {
			roundedCorners = true,
			initialCancel = true,
			stepData,
		} = this.props

		if (!stepData){ return null }

		const { currentStep } = this.state

		const stepBarDynamicStyles = {
			width 	: `${100 - (100/stepData.length)}%`,
			left 	: `${100/stepData.length/2}%`
		}

		return (
			<div style={styles.container}>
				<div style={styles.stepsContainer}>
					<div style={[styles.stepBar, stepBarDynamicStyles]} />
					{this.renderSteps()}
				</div>
				<TEForm style={styles.form} onSubmit={e => { e.preventDefault() }}>
					{this.renderStepComponent()}
				</TEForm>
				<div style={[styles.buttonContainer, roundedCorners && styles.roundedCorners]}>
					{(initialCancel || currentStep !== 0) &&
						<TEButton
							onClick={this.handlePreviousPressed}
							style={{...styles.stepButtons, ...styles.stepButtonLeft}}
						>
							{ currentStep === 0 ? 'cancel' : 'previous' }
						</TEButton>
					}
					<TEButton
						onClick={this.handleNextPressed}
						style={{...styles.stepButtons, ...styles.stepButtonRight}}
					>
						{ currentStep + 1 === stepData.length ? 'submit' : 'next' }
					</TEButton>
				</div>
			</div>
		)
	}
}

TEMultiStepForm.propTypes = {}

TEMultiStepForm.defaultProps = {}

const mapStateToProps = (state) => {
	return { }
}

export default connect(mapStateToProps, {
	showInvalidDataAlert
})(Radium(TEMultiStepForm))
