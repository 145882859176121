import { combineReducers } from 'redux'

import Global from './GlobalReducer'
import Loading from './LoadingReducer'
import Auth from './AuthReducer'

//Front
import Home from './HomeReducer'

//Admin
import Admin from './AdminReducer'

//Coordinator
import Rider from './RiderReducer'
import Dispatch from './DispatchReducer'

export default combineReducers({
	Global,
	Loading,
	Auth,

	Home,

	Admin,

	Rider,
	Dispatch,
})
