//GLOBAL
export const SHOW_ALERT = 'show_alert'
export const HIDE_ALERT = 'hide_alert'
export const SHOW_CONFIRM = 'show_confirm'
export const HIDE_CONFIRM = 'hide_confirm'
export const SHOW_NETWORK_ACTIVITY = 'show_network_activity'
export const HIDE_NETWORK_ACTIVITY = 'hide_network_activity'
export const SET_USER_ACTIVE = 'set_user_active'
export const SET_USER_INACTIVE = 'set_user_inactive'

//
// Global Stores
//
//Auth
export const AuthKeys = {
    //Inputs
    SIGN_IN_EMAIL_CHANGED: 'auth__sign_in_email_changed',
    SIGN_IN_PASSWORD_CHANGED: 'auth__sign_in_password_changed',

    SIGN_UP_ALIAS_CHANGED: 'auth__sign_up_alias_changed',
    SIGN_UP_PHONE_CHANGED: 'auth__sign_up_phone_changed',
    SIGN_UP_EMAIL_CHANGED: 'auth__sign_up_email_changed',
    SIGN_UP_PASSWORD_CHANGED: 'auth__sign_up_password_changed',

    REGISTER_USER_TYPE_CHANGED: 'auth__register_user_type_changed',
    REGISTER_EMAIL_CHANGED: 'auth__register_email_changed',
    REGISTER_PASSWORD_CHANGED: 'auth__register_password_changed',
    REGISTER_PASSWORD_CONFIRM_CHANGED: 'auth__register_password_confirm_changed',

    FORGOT_EMAIL_CHANGED: 'auth__forgot_email_changed',

    RESET_PASSWORD_CHANGED: 'auth__reset_password_changed',

    //Requests

    SIGN_UP_USER_SUCCESS: 'auth__sign_up_user_success',
    SIGN_UP_USER_FAIL: 'auth__sign_up_user_fail',

    SIGN_IN_USER_SUCCESS: 'auth__sign_in_user_success',
    SIGN_IN_USER_FAIL: 'auth__sign_in_user_fail',

    SIGN_OUT_USER_SUCCESS: 'auth__sign_out_user_success',

    REGISTER_AGENT_SUCCESS: 'auth__register_agent_success',
    REGISTER_AGENT_FAIL: 'auth__register_agent_fail',

    REGISTER_CLIENT_SUCCESS: 'auth__register_client_success',
    REGISTER_CLIENT_FAIL: 'auth__register_client_fail',

    REGISTER_ADMIN_SUCCESS: 'auth__register_admin_success',
    REGISTER_ADMIN_FAIL: 'auth__register_admin_fail',

    REGISTER_USER_SUCCESS: 'auth__register_user_success',
    REGISTER_USER_FAIL: 'auth__register_user_fail',

    RESET_PASSWORD_SUCCESS: 'auth__reset_password_success',
    RESET_PASSWORD_FAIL: 'auth__reset_password_fail',

    SEND_FORGOT_PASSWORD_EMAIL_SUCCESS: 'auth__send_forgot_password_email_success',
    SEND_FORGOT_PASSWORD_EMAIL_FAIL: 'auth__send_forgot_password_email_fail',

    FETCH_USER_PERMISSIONS: 'auth__load_user_permissions',
    SAVE_USER_PERMISSIONS_WATCHER: 'auth__save_user_permissions_watcher',
    REMOVE_USER_PERMISSIONS_WATCHER: 'auth__remove_user_permissions_watcher',
}

//Loading
export const LoadingKeys = {
    USER_CHECK_STARTED: 'loading__user_check_started',
    USER_CHECK_COMPLETE: 'loading__user_check_complete',
    FETCH_APP_VERSIONS: 'loading__fetch_app_versions',
}

//
//Front End
//
//Home
export const HomeKeys = {}

//
//Admin
//
export const AdminKeys = {
    FETCH_RIDERS: 'admin__fetch_riders',
    SAVE_RIDERS_WATCHER: 'admin__save_riders_watcher',
    REMOVE_RIDERS_WATCHER: 'admin__remove_riders_watcher',

    //Organizations
    FETCH_ORGANIZATIONS: 'admin__fetch_organizations',
    SAVE_ORGANIZATIONS_WATCHER: 'admin__save_organizations_watcher',
    REMOVE_ORGANIZATIONS_WATCHER: 'admin__remove_organizations_watcher',

    SHOW_CREATE_ORGANIZATION_POPUP: 'admin__show_create_organization_popup',
    HIDE_CREATE_ORGANIZATION_POPUP: 'admin__hide_create_organization_popup',

    CREATE_ORGANIZATION_NAME_CHANGED: 'admin__create_organization_name_changed',
    CREATE_ORGANIZATION_TYPE_CHANGED: 'admin__create_organization_type_changed',

    CREATE_ORGANIZATION_SUCCESS: 'admin__create_organization_success',
    CREATE_ORGANIZATION_FAIL: 'admin__create_organization_fail',

    FETCH_ORGANIZATION: 'admin__fetch_organization',
    SAVE_ORGANIZATION_WATCHER: 'admin__save_organization_watcher',
    REMOVE_ORGANIZATION_WATCHER: 'admin__remove_organization_watcher',

    ORGANIZATION_NAME_CHANGED: 'admin__organization_name_changed',
    ORGANIZATION_TYPE_CHANGED: 'admin__organization_type_changed',

    SAVE_ORGANIZATION_SUCCESS: 'admin__save_organization_success',
    SAVE_ORGANIZATION_FAIL: 'admin__save_organization_fail',

    FETCH_ORGANIZATION_USERS: 'admin__fetch_organization_users',
    SAVE_ORGANIZATION_USERS_WATCHERS: 'admin__save_organization_users_watchers',
    REMOVE_ORGANIZATION_USER: 'admin__remove_organization_user',
    REMOVE_ORGANIZATION_USERS_WATCHERS: 'admin__remove_organization_users_watchers',

    SHOW_ADD_RIDER_POPUP: 'admin__show_add_rider_popup',
    HIDE_ADD_RIDER_POPUP: 'admin__hide_add_rider_popup',

    ADD_USER_CHANGED: 'admin__add_user_changed',
    ADD_PERMISSION_CHANGED: 'admin__add_permission_changed',

    ADD_USER_TO_ORG_SUCCESS: 'admin__add_user_to_org_success',
    ADD_USER_TO_ORG_FAIL: 'admin__add_user_to_org_fail',

    FETCH_ORG_RIDER: 'admin__fetch_org_rider',
    SAVE_ORG_RIDER_WATCHER: 'admin__save_org_rider_watcher',
    REMOVE_ORG_RIDER_WATCHER: 'admin__remove_org_rider_watcher',

    SAVE_PERMISSION_CHANGED: 'admin__save_permission_changed',

    //Dispatch
    FETCH_DISPATCH_COMPANIES: 'admin__fetch_dispatch_companies',
    SAVE_DISPATCH_COMPANIES_WATCHER: 'admin__save_dispatch_companies_watcher',
    REMOVE_DISPATCH_COMPANIES_WATCHER: 'admin__remove_dispatch_companies_watcher',

    SHOW_CREATE_DISPATCH_COMPANY_POPUP: 'admin__show_create_dispatch_company_popup',
    HIDE_CREATE_DISPATCH_COMPANY_POPUP: 'admin__hide_create_dispatch_company_popup',

    CREATE_DISPATCH_COMPANY_NAME_CHANGED: 'admin__create_dispatch_company_name_changed',

    CREATE_DISPATCH_COMPANY_SUCCESS: 'admin__create_dispatch_company_success',
    CREATE_DISPATCH_COMPANY_FAIL: 'admin__create_dispatch_company_fail',

    FETCH_DISPATCH_COMPANY: 'admin__fetch_dispatch_company',
    SAVE_DISPATCH_COMPANY_WATCHER: 'admin__save_dispatch_company_watcher',
    REMOVE_DISPATCH_COMPANY_WATCHER: 'admin__remove_dispatch_company_watcher',

    FETCH_DISPATCH_COMPANY_USERS: 'admin__fetch_dispatch_company_users',
    SAVE_DISPATCH_COMPANY_USERS_WATCHERS: 'admin__save_dispatch_company_users_watchers',
    REMOVE_DISPATCH_COMPANY_USERS_WATCHERS: 'admin__remove_dispatch_company_users_watchers',

    DISPATCH_COMPANY_NAME_CHANGED: 'admin__dispatch_company_name_changed',

    SAVE_DISPATCH_COMPANY_SUCCESS: 'admin__save_dispatch_company_success',
    SAVE_DISPATCH_COMPANY_FAIL: 'admin__save_dispatch_company_fail',

    SHOW_ADD_DISPATCHER_POPUP: 'admin__show_add_dispatcher_popup',
    HIDE_ADD_DISPATCHER_POPUP: 'admin__hide_add_dispatcher_popup',

    ADD_DISPATCHER_CHANGED: 'admin__add_dispatcher_changed',

    ADD_USER_TO_DISPATCH_COMPANY_SUCCESS: 'admin__add_user_to_dispatch_company_success',
    ADD_USER_TO_DISPATCH_COMPANY_FAIL: 'admin__add_user_to_dispatch_company_fail',
}

//
// Dispatch
//
export const DispatchKeys = {
    FETCH_LIVE_REQUESTS: 'dispatch__fetch_live_requests',
    SAVE_LIVE_REQUESTS_WATCHER: 'dispatch__save_live_requests_watcher',
    REMOVE_LIVE_REQUESTS_WATCHER: 'dispatch__remove_live_requests_watcher',

    FETCH_HISTORIC_REQUESTS: 'dispatch__fetch_historic_requests',
    SAVE_HISTORIC_REQUESTS_WATCHER: 'dispatch__save_historic_requests_watcher',
    REMOVE_HISTORIC_REQUESTS_WATCHER: 'dispatch__remove_historic_requests_watcher',

    FETCH_DRIVERS: 'dispatch__drivers_requests',
    SAVE_DRIVERS_WATCHER: 'dispatch__save_drivers_watcher',
    REMOVE_DRIVERS_WATCHER: 'dispatch__remove_drivers_watcher',

    FETCH_LIVE_REQUEST: 'dispatch__fetch_live_request',
    SAVE_LIVE_REQUEST_WATCHER: 'dispatch__save_live_request_watcher',
    REMOVE_LIVE_REQUEST_WATCHER: 'dispatch__remove_live_request_watcher',

    FETCH_HISTORIC_REQUEST: 'dispatch__fetch_historic_request',
    SAVE_HISTORIC_REQUEST_WATCHER: 'dispatch__save_historic_request_watcher',
    REMOVE_HISTORIC_REQUEST_WATCHER: 'dispatch__remove_historic_request_watcher',

    FETCH_REQUEST_RIDER: 'dispatch__fetch_request_rider',
    SAVE_REQUEST_RIDER_WATCHER: 'dispatch__save_request_rider_watcher',
    REMOVE_REQUEST_RIDER_WATCHER: 'dispatch__remove_request_rider_watcher',

    FETCH_REQUEST_RIDER_LOCATION: 'dispatch__fetch_request_rider_location',
    SAVE_REQUEST_RIDER_LOCATION_WATCHER: 'dispatch__save_request_rider_location_watcher',
    REMOVE_REQUEST_RIDER_LOCATION_WATCHER: 'dispatch__remove_request_rider_location_watcher',

    FETCH_DRIVER: 'dispatch__fetch_driver',
    SAVE_DRIVER_WATCHER: 'dispatch__save_driver_watcher',
    REMOVE_DRIVER_WATCHER: 'dispatch__remove_driver_watcher',

    FETCH_DRIVER_LOCATION: 'dispatch__fetch_driver_location',
    SAVE_DRIVER_LOCATION_WATCHER: 'dispatch__save_driver_location_watcher',
    REMOVE_DRIVER_LOCATION_WATCHER: 'dispatch__remove_driver_location_watcher',

    FETCH_REQUEST_ORGANIZATION: 'dispatch__fetch_request_organization',
    SAVE_REQUEST_ORGANIZATION_WATCHER: 'dispatch__save_request_organization_watcher',
    REMOVE_REQUEST_ORGANIZATION_WATCHER: 'dispatch__remove_request_organization_watcher',

    SELECTED_DRIVER_CHANGED: 'dispatch__selected_driver_changed',

    SHOW_EDIT_REQUESTS_POPUP: 'dispatch__show_edit_requests_popup',
    HIDE_EDIT_REQUESTS_POPUP: 'dispatch__hide_edit_requests_popup',

    SHOW_EDIT_STATUSES_POPUP: 'dispatch__show_edit_statuses_popup',
    HIDE_EDIT_STATUSES_POPUP: 'dispatch__hide_edit_statuses_popup',

    SAVE_UPDATE_STATUSES_SUCCESS: 'dispatch__save_update_statuses_success',
    SAVE_UPDATE_STATUSES_FAIL: 'dispatch__save_update_statuses_fail',

    SHOW_COMPLETE_REQUEST_POPUP: 'dispatch__show_complete_request_popup',
    HIDE_COMPLETE_REQUEST_POPUP: 'dispatch__hide_complete_request_popup',

    ADDITIONAL_FEE_CHANGED: 'dispatch__additional_fee_changed',

    COMPLETE_REQUEST_SUCCESS: 'dispatch__complete_request_success',
    COMPLETE_REQUEST_FAIL: 'dispatch__complete_request_fail',

    ASSIGN_CHAUFFEUR_SUCCESS: 'dispatch__assign_chauffeur_success',
    ASSIGN_CHAUFFEUR_FAIL: 'dispatch__assign_chauffeur_fail',

    SHOW_ADD_DRIVER_POPUP: 'dispatch__show_add_driver_popup',
    HIDE_ADD_DRIVER_POPUP: 'dispatch__hide_add_driver_popup',

    CREATE_DRIVER_FIRST_NAME_CHANGED: 'dispatch__create_driver_first_name_changed',
    CREATE_DRIVER_LAST_NAME_CHANGED: 'dispatch__create_driver_last_name_changed',
    CREATE_DRIVER_PHONE_CHANGED: 'dispatch__create_driver_phone_changed',
    CREATE_DRIVER_EMAIL_CHANGED: 'dispatch__create_driver_email_changed',

    CREATE_DRIVER_SUCCESS: 'dispatch__create_driver_success',
    CREATE_DRIVER_FAIL: 'dispatch__create_driver_fail',

    DRIVER_FIRST_NAME_CHANGED: 'dispatch__driver_first_name_changed',
    DRIVER_LAST_NAME_CHANGED: 'dispatch__driver_last_name_changed',
    DRIVER_PHONE_CHANGED: 'dispatch__driver_phone_changed',
    DRIVER_ID_CHANGED: 'dispatch__driver_id_changed',
    DRIVER_VEHICLE_MAKE_CHANGED: 'dispatch__driver_vehicle_make_changed',
    DRIVER_VEHICLE_YEAR_CHANGED: 'dispatch__driver_vehicle_year_changed',
    DRIVER_VEHICLE_TYPE_CHANGED: 'dispatch__driver_vehicle_type_changed',
    DRIVER_VEHICLE_LICENSE_CHANGED: 'dispatch__driver_vehicle_license_changed',
    DRIVER_VEHICLE_CITY_LICENSE_CHANGED: 'dispatch__driver_vehicle_city_license_changed',
    DRIVER_VEHICLE_ID_CHANGED: 'dispatch__driver_vehicle_id_changed',
    DRIVER_STATUS_CHANGED: 'dispatch__driver_status_changed',
}

//
//Rider
//
export const RiderKeys = {
    FETCH_RIDER_CREDIT_CARDS: 'rider__fetch_rider_credit_cards',

    CREATE_LINK_TO_ORG_CHANGED: 'rider__create_link_to_org_changed',
    CREATE_ORG_CHANGED: 'rider__create_org_changed',
    CREATE_ORG_PAYING_CHANGED: 'rider__create_org_paying_changed',
    CREATE_ORG_RES_FOR_CHANGED: 'rider__create_org_res_for_changed',
    CREATE_ORG_PAX_ALIAS_CHANGED: 'rider__create_org_pax_alias_changed',
    CREATE_ORG_PAX_PHONE_CHANGED: 'rider__create_org_pax_phone_changed',
    CREATE_ORG_PAX_EMAIL_CHANGED: 'rider__create_org_pax_email_changed',
    CREATE_PICK_UP_CHANGED: 'rider__create_pick_up_changed',
    CREATE_DESTINATION_CHANGED: 'rider__create_destination_changed',
    CREATE_VEHICLE_TYPE_CHANGED: 'rider__create_vehicle_type_changed',
    CREATE_PETS_CHANGED: 'rider__create_pets_changed',
    CREATE_NOTES_CHANGED: 'rider__create_notes_changed',
    CREATE_PICK_UP_TIME_CHANGED: 'rider__create_pick_up_time_changed',
    CREATE_CREDIT_CARD_CHANGED: 'rider__create_credit_card_changed',
    CREATE_PROMO_CHANGED: 'rider__create_promo_changed',

    FETCH_CREATE_VEHICLES: 'rider__fetch_create_vehicles',
    FETCH_CREATE_MIN_PICK_UP_TIME: 'rider__fetch_create_min_pick_up_time',
    FETCH_CREATE_PRICING_INFORMATION: 'rider__fetch_create_pricing_information',

    CREATE_REQUEST_SUCCESS: 'rider__create_request_success',
    CREATE_REQUEST_FAIL: 'rider__create_request_fail',

    FETCH_RIDER: 'rider__fetch_rider',
    SAVE_RIDER_WATCHER: 'rider__save_rider_watcher',
    REMOVE_RIDER_WATCHER: 'rider__remove_rider_watcher',

    FETCH_RIDER_ORGANIZATION: 'rider__fetch_rider_organization',
    SAVE_RIDER_ORGANIZATION_WATCHER: 'rider__save_rider_organization_watcher',
    REMOVE_RIDER_ORANIZATION_WATCHERS: 'rider__remove_rider_oranization_watchers',

    FETCH_LIVE_REQUESTS: 'rider__fetch_live_requests',
    SAVE_LIVE_REQUESTS_WATCHER: 'rider__save_live_requests_watcher',
    REMOVE_LIVE_REQUESTS_WATCHER: 'rider__remove_live_requests_watcher',

    FETCH_LIVE_ORGANIZATION_REQUESTS: 'rider__fetch_live_organization_requests',
    SAVE_LIVE_ORGANIZATION_REQUESTS_WATCHER: 'rider__save_live_organization_requests_watcher',
    REMOVE_LIVE_ORGANIZATION_REQUESTS_WATCHER: 'rider__remove_live_organization_requests_watcher',

    LIVE_ORGANIZATION_SELECTED_CHANGED: 'rider__live_organization_selected_changed',

    FETCH_HISTORIC_REQUESTS: 'rider__fetch_historic_requests',
    SAVE_HISTORIC_REQUESTS_WATCHER: 'rider__save_historic_requests_watcher',
    REMOVE_HISTORIC_REQUESTS_WATCHER: 'rider__remove_historic_requests_watcher',

    FETCH_HISTORIC_ORGANIZATION_REQUESTS: 'rider__fetch_historic_organization_requests',
    SAVE_HISTORIC_ORGANIZATION_REQUESTS_WATCHER:
        'rider__save_historic_organization_requests_watcher',
    REMOVE_HISTORIC_ORGANIZATION_REQUESTS_WATCHER:
        'rider__remove_historic_organization_requests_watcher',

    HISTORIC_ORGANIZATION_SELECTED_CHANGED: 'rider__historic_organization_selected_changed',

    FETCH_LIVE_REQUEST: 'rider__fetch_live_request',
    SAVE_LIVE_REQUEST_WATCHER: 'rider__save_live_request_watcher',
    REMOVE_LIVE_REQUEST_WATCHER: 'rider__remove_live_request_watcher',

    FETCH_HISTORIC_REQUEST: 'rider__fetch_historic_request',
    SAVE_HISTORIC_REQUEST_WATCHER: 'rider__save_historic_request_watcher',
    REMOVE_HISTORIC_REQUEST_WATCHER: 'rider__remove_historic_request_watcher',

    FETCH_REQUEST_RIDER: 'rider__fetch_request_rider',
    SAVE_REQUEST_RIDER_WATCHER: 'rider__save_request_rider_watcher',
    REMOVE_REQUEST_RIDER_WATCHER: 'rider__remove_request_rider_watcher',

    FETCH_REQUEST_RIDER_LOCATION: 'rider__fetch_request_rider_location',
    SAVE_REQUEST_RIDER_LOCATION_WATCHER: 'rider__save_request_rider_location_watcher',
    REMOVE_REQUEST_RIDER_LOCATION_WATCHER: 'rider__remove_request_rider_location_watcher',

    FETCH_DRIVER: 'rider__fetch_driver',
    SAVE_DRIVER_WATCHER: 'rider__save_driver_watcher',
    REMOVE_DRIVER_WATCHER: 'rider__remove_driver_watcher',

    FETCH_DRIVER_LOCATION: 'rider__fetch_driver_location',
    SAVE_DRIVER_LOCATION_WATCHER: 'rider__save_driver_location_watcher',
    REMOVE_DRIVER_LOCATION_WATCHER: 'rider__remove_driver_location_watcher',

    FETCH_REQUEST_ORGANIZATION: 'rider__fetch_request_organization',
    SAVE_REQUEST_ORGANIZATION_WATCHER: 'rider__save_request_organization_watcher',
    REMOVE_REQUEST_ORGANIZATION_WATCHER: 'rider__remove_request_organization_watcher',
}
