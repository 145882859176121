import {
    SHOW_ALERT,
    SHOW_CONFIRM,
    HIDE_CONFIRM,
    SHOW_NETWORK_ACTIVITY,
    HIDE_NETWORK_ACTIVITY,
    RiderKeys,
} from './types'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import {
    validateInputDataArray,
    validateInputData,
    apiRequest,
    computeUTCOffsetDifferenceFromLatLng,
} from './../helpers'
import settings from './../config/settings'

//
// Create
//
export const fetchRiderCreditCards = () => {
    return (dispatch) => {
        const { uid } = firebase.auth().currentUser
        apiRequest('POST', `customer/${uid}`, {})
            .then((response) => {
                // console.log(response)
                dispatch({
                    type: RiderKeys.FETCH_RIDER_CREDIT_CARDS,
                    payload: response.data,
                })
            })
            .catch((error) => console.log(error))
    }
}
const getAddressParts = (place) => {
    const addressData = {
        fullAddress: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
    }

    for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0]
        switch (addressType) {
            case 'street_number':
                addressData.streetNumber = place.address_components[i].long_name
                break
            case 'route':
                addressData.street = place.address_components[i].long_name
                break
            case 'locality':
                addressData.city = place.address_components[i].long_name
                break
            case 'administrative_area_level_1':
                addressData.state = place.address_components[i].long_name
                break
            case 'postal_code':
                addressData.zip = place.address_components[i].long_name
                break
            case 'country':
                addressData.country = place.address_components[i].long_name
                break
            default:
                break
        }
    }
    return addressData
}

export const createRiderSetPickUp = (places) => {
    if (!places || (places && places.length === 0) || (places && !places[0])) {
        return (dispatch) => {
            return new Promise((res, rej) => {
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'No place was selected in the search field.',
                    },
                })
                return rej()
            })
        }
    }

    const addressData = getAddressParts(places[0])
    const { fullAddress, lat, lng, streetNumber, street, city, state, zip, country } = addressData
    if (
        !fullAddress ||
        !lat ||
        !lng ||
        !streetNumber ||
        !street ||
        !city ||
        !state ||
        !zip ||
        !country
    ) {
        return (dispatch) => {
            return new Promise((res, rej) => {
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage:
                            'The place you have selected does not contain all of the necessary address parts. Try searching again.',
                    },
                })
                return rej()
            })
        }
    }

    return (dispatch) => {
        return new Promise((res, rej) => {
            dispatch({
                type: RiderKeys.CREATE_PICK_UP_CHANGED,
                payload: {
                    ...addressData,
                    streetAddress: streetNumber + ' ' + street,
                },
            })
            return res()
        })
    }
}
export const createRiderClearPickUp = () => {
    return {
        type: RiderKeys.CREATE_PICK_UP_CHANGED,
        payload: undefined,
    }
}
export const createRiderSetDestination = (places) => {
    if (!places || (places && places.length === 0) || (places && !places[0])) {
        return (dispatch) => {
            return new Promise((res, rej) => {
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'No place was selected in the search field.',
                    },
                })
                return rej()
            })
        }
    }

    const addressData = getAddressParts(places[0])
    const { fullAddress, lat, lng, streetNumber, street, city, state, zip, country } = addressData
    if (
        !fullAddress ||
        !lat ||
        !lng ||
        !streetNumber ||
        !street ||
        !city ||
        !state ||
        !zip ||
        !country
    ) {
        return (dispatch) => {
            return new Promise((res, rej) => {
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage:
                            'The place you have selected does not contain all of the necessary address parts. Try searching again.',
                    },
                })
                return rej()
            })
        }
    }

    return (dispatch) => {
        return new Promise((res, rej) => {
            dispatch({
                type: RiderKeys.CREATE_DESTINATION_CHANGED,
                payload: {
                    ...addressData,
                    streetAddress: streetNumber + ' ' + street,
                },
            })
            return res()
        })
    }
}
export const createRiderClearDestination = () => {
    return {
        type: RiderKeys.CREATE_DESTINATION_CHANGED,
        payload: undefined,
    }
}
export const createRiderVerifyLocations = (data) => {
    const { createPickUpData, createDestinationData } = data

    if (!createPickUpData || !createDestinationData) {
        return (dispatch) => {
            return new Promise((res, rej) => {
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Both Pick Up Address and Destination Address must be set.',
                    },
                })
                return rej()
            })
        }
    }

    return (dispatch) => {
        return new Promise((res, rej) => {
            dispatch({
                type: SHOW_NETWORK_ACTIVITY,
                payload: 'Check Vehicle Availability...',
            })

            //Check Vehicle Availability
            var data = {
                startLat: createPickUpData.lat,
                startLon: createPickUpData.lng,
                endLat: createDestinationData.lat,
                endLon: createDestinationData.lng,
            }
            apiRequest('POST', 'api/v1/vehicleAvailability', data)
                .then((response) => {
                    // console.log(response)
                    if (response && response.data && response.data.status) {
                        if (response.data.status === 'Success') {
                            dispatch({ type: HIDE_NETWORK_ACTIVITY })
                            dispatch({
                                type: RiderKeys.FETCH_CREATE_VEHICLES,
                                payload: response.data.data,
                            })
                            return res()
                        }
                        if (response.data.status === 'Error') {
                            dispatch({ type: HIDE_NETWORK_ACTIVITY })
                            dispatch({
                                type: SHOW_ALERT,
                                payload: {
                                    alertTitle: 'Error',
                                    alertMessage: response.data.message,
                                },
                            })
                            return rej()
                        }
                    }
                    return rej()
                })
                .catch((error) => {
                    console.log(error)
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                    dispatch({
                        type: SHOW_ALERT,
                        payload: {
                            alertTitle: 'Error',
                            alertMessage: 'Error checking vehicle availability.',
                        },
                    })
                    return rej()
                })
        })
    }
}
export const createRiderVerifyVehicle = (data) => {
    const { createPickUpData, createDestinationData } = data

    if (!createPickUpData || !createDestinationData) {
        return (dispatch) => {
            return new Promise((res, rej) => {
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Both Pick Up Address and Destination Address must be set.',
                    },
                })
                return rej()
            })
        }
    }

    return (dispatch) => {
        return new Promise((res, rej) => {
            dispatch({
                type: SHOW_NETWORK_ACTIVITY,
                payload: 'Check Accommodations Availability...',
            })

            //Check Accommodations Availability
            var data = {
                startLat: createPickUpData.lat,
                startLon: createPickUpData.lng,
                endLat: createDestinationData.lat,
                endLon: createDestinationData.lng,
            }
            apiRequest('POST', 'api/v1/vehicleAccommodations', data)
                .then((response) => {
                    // console.log(response)
                    if (response && response.data && response.data.status) {
                        if (response.data.status === 'Success') {
                            dispatch({ type: HIDE_NETWORK_ACTIVITY })
                            return res()
                        }
                        if (response.data.status === 'Error') {
                            dispatch({ type: HIDE_NETWORK_ACTIVITY })
                            dispatch({
                                type: SHOW_ALERT,
                                payload: {
                                    alertTitle: 'Error',
                                    alertMessage: response.data.message,
                                },
                            })
                            return rej()
                        }
                    }
                    return rej()
                })
                .catch((error) => {
                    console.log(error)
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                    dispatch({
                        type: SHOW_ALERT,
                        payload: {
                            alertTitle: 'Error',
                            alertMessage: 'Error checking vehicle availability.',
                        },
                    })
                    return rej()
                })
        })
    }
}

export const createRiderVerifyAccomodations = (data) => {
    const { createPickUpData, createDestinationData, createPetsData } = data

    if (!createPickUpData || !createDestinationData) {
        return (dispatch) => {
            return new Promise((res, rej) => {
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Both Pick Up Address and Destination Address must be set.',
                    },
                })
                return rej()
            })
        }
    }

    return (dispatch) => {
        return new Promise((res, rej) => {
            dispatch({
                type: SHOW_NETWORK_ACTIVITY,
                payload: 'Check Accommodations Availability...',
            })

            //Check Accommodations Availability
            const data = {
                startLat: createPickUpData.lat,
                startLon: createPickUpData.lng,
                endLat: createDestinationData.lat,
                endLon: createDestinationData.lng,
                pets: createPetsData.value === 'Yes',
                carSeat: false, //NOT CURRENTLY USED
            }
            apiRequest('POST', 'api/v1/minPickupTime', data)
                .then((response) => {
                    // console.log(response)
                    if (response && response.data && response.data.status) {
                        if (response.data.status === 'Success' && response.data.data) {
                            dispatch({ type: HIDE_NETWORK_ACTIVITY })
                            dispatch({
                                type: RiderKeys.FETCH_CREATE_MIN_PICK_UP_TIME,
                                payload:
                                    response.data.data.minimumTime * 1000 + new Date().getTime(),
                            })
                            return res()
                        }
                        if (response.data.status === 'Error') {
                            dispatch({ type: HIDE_NETWORK_ACTIVITY })
                            dispatch({
                                type: SHOW_ALERT,
                                payload: {
                                    alertTitle: 'Error',
                                    alertMessage: response.data.message,
                                },
                            })
                            return rej()
                        }
                    }
                    return rej()
                })
                .catch((error) => {
                    console.log(error)
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                    dispatch({
                        type: SHOW_ALERT,
                        payload: {
                            alertTitle: 'Error',
                            alertMessage: 'Error checking vehicle availability.',
                        },
                    })
                    return rej()
                })
        })
    }
}
export const createRiderVerifyPickUpTime = (data) => {
    const {
        createPickUpData,
        createDestinationData,
        createPickUpTimeData,
        createMinPickUpTime,
        createVehicleTypeData,
        createPromoData,
    } = data

    if (!createPickUpData || !createDestinationData) {
        return (dispatch) => {
            return new Promise((res, rej) => {
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Both Pick Up Address and Destination Address must be set.',
                    },
                })
                return rej()
            })
        }
    }
    //Validate Data
    const validationArray = [createPickUpTimeData, createVehicleTypeData]
    const { validation, validationData } = validateInputDataArray(validationArray)
    if (!validation) {
        return (dispatch) => {
            return new Promise((res, rej) => {
                dispatch({
                    type: SHOW_ALERT,
                    payload: validateInputData(validationData),
                })
                return rej()
            })
        }
    }

    const timezoneOffset =
        computeUTCOffsetDifferenceFromLatLng(createPickUpData.lat, createPickUpData.lng) * 60 * 1000
    const convertedPickUpTime = createPickUpTimeData.value + timezoneOffset

    if (createMinPickUpTime > convertedPickUpTime) {
        return (dispatch) => {
            return new Promise((res, rej) => {
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Pick up time must be after earliest pick up time.',
                    },
                })
                return rej()
            })
        }
    }

    const service = new window.google.maps.DistanceMatrixService()
    const distanceConfig = {
        origins: [{ lat: createPickUpData.lat, lng: createPickUpData.lng }],
        destinations: [{ lat: createDestinationData.lat, lng: createDestinationData.lng }],
        travelMode: 'DRIVING',
    }
    return (dispatch) => {
        return new Promise((res, rej) => {
            dispatch({
                type: SHOW_NETWORK_ACTIVITY,
                payload: 'Calculating Fare...',
            })
            service.getDistanceMatrix(distanceConfig, (response, status) => {
                // console.log(response, status)
                if (
                    status === 'OK' &&
                    response &&
                    response.rows &&
                    response.rows[0] &&
                    response.rows[0].elements &&
                    response.rows[0].elements[0] &&
                    response.rows[0].elements[0].distance &&
                    response.rows[0].elements[0].distance.value &&
                    response.rows[0].elements[0].duration &&
                    response.rows[0].elements[0].duration.value
                ) {
                    const distance = response.rows[0].elements[0].distance.value
                    const tripEta = response.rows[0].elements[0].duration.value
                    const data = {
                        startLat: createPickUpData.lat,
                        startLon: createPickUpData.lng,
                        endLat: createDestinationData.lat,
                        endLon: createDestinationData.lng,
                        currentDatetime: Math.round(new Date().getTime() / 1000),
                        pickupDatetime: convertedPickUpTime / 1000, //createPickUpTimeData.value / 1000,
                        startCity: createPickUpData.city,
                        endCity: createDestinationData.city,
                        carType: createVehicleTypeData.value.type,
                        distance,
                        tripEta,
                    }
                    if (createPromoData.value) {
                        data.promoCode = createPromoData.value
                    }
                    // console.log(data)

                    apiRequest('POST', 'api/v1/pricingInformation', data)
                        .then((response) => {
                            // console.log(response)
                            if (response && response.data) {
                                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                                dispatch({
                                    type: RiderKeys.FETCH_CREATE_PRICING_INFORMATION,
                                    payload: response.data,
                                })
                                return res()
                            }
                            dispatch({ type: HIDE_NETWORK_ACTIVITY })
                            dispatch({
                                type: SHOW_ALERT,
                                payload: {
                                    alertTitle: 'Error',
                                    alertMessage: 'Error calculating fare.',
                                },
                            })
                            return rej()
                        })
                        .catch((error) => {
                            console.log(error)
                            dispatch({ type: HIDE_NETWORK_ACTIVITY })
                            dispatch({
                                type: SHOW_ALERT,
                                payload: {
                                    alertTitle: 'Error',
                                    alertMessage: 'Error calculating fare.',
                                },
                            })
                            return rej()
                        })
                } else {
                    console.log(response, status)
                    dispatch({
                        type: SHOW_ALERT,
                        payload: {
                            alertTitle: 'Error',
                            alertMessage:
                                'Error calculating distance between pick up location and destination.',
                        },
                    })
                    return rej()
                }
            })
        })
    }
}
export const createRiderSubmitPromo = (data) => {
    const {
        createPickUpData,
        createDestinationData,
        createPickUpTimeData,
        createVehicleTypeData,
        createPromoData,
    } = data

    if (!createPickUpData || !createDestinationData) {
        return (dispatch) => {
            return new Promise((res, rej) => {
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Both Pick Up Address and Destination Address must be set.',
                    },
                })
                return rej()
            })
        }
    }
    //Validate Data
    const validationArray = [createPickUpTimeData, createVehicleTypeData]
    const { validation, validationData } = validateInputDataArray(validationArray)
    if (!validation) {
        return (dispatch) => {
            dispatch({
                type: SHOW_ALERT,
                payload: validateInputData(validationData),
            })
        }
    }
    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Calculating Fare...',
        })

        const service = new window.google.maps.DistanceMatrixService()
        const distanceConfig = {
            origins: [{ lat: createPickUpData.lat, lng: createPickUpData.lng }],
            destinations: [{ lat: createDestinationData.lat, lng: createDestinationData.lng }],
            travelMode: 'DRIVING',
        }
        service.getDistanceMatrix(distanceConfig, (response, status) => {
            // console.log(response, status)
            if (
                status === 'OK' &&
                response &&
                response.rows &&
                response.rows[0] &&
                response.rows[0].elements &&
                response.rows[0].elements[0] &&
                response.rows[0].elements[0].distance &&
                response.rows[0].elements[0].distance.value &&
                response.rows[0].elements[0].duration &&
                response.rows[0].elements[0].duration.value
            ) {
                const distance = response.rows[0].elements[0].distance.value
                const tripEta = response.rows[0].elements[0].duration.value

                const timezoneOffset =
                    computeUTCOffsetDifferenceFromLatLng(
                        createPickUpData.lat,
                        createPickUpData.lng
                    ) *
                    60 *
                    1000
                const convertedPickUpTime = createPickUpTimeData.value + timezoneOffset

                const data = {
                    startLat: createPickUpData.lat,
                    startLon: createPickUpData.lng,
                    endLat: createDestinationData.lat,
                    endLon: createDestinationData.lng,
                    currentDatetime: Math.round(new Date().getTime() / 1000),
                    pickupDatetime: convertedPickUpTime / 1000, //createPickUpTimeData.value / 1000,
                    startCity: createPickUpData.city,
                    endCity: createDestinationData.city,
                    carType: createVehicleTypeData.value.type,
                    distance,
                    tripEta,
                }
                if (createPromoData.value) {
                    data.promoCode = createPromoData.value
                }
                // console.log(data)

                apiRequest('POST', 'api/v1/pricingInformation', data)
                    .then((response) => {
                        // console.log(response)
                        if (response && response.data) {
                            dispatch({ type: HIDE_NETWORK_ACTIVITY })
                            dispatch({
                                type: RiderKeys.FETCH_CREATE_PRICING_INFORMATION,
                                payload: response.data,
                            })
                        }
                        dispatch({ type: HIDE_NETWORK_ACTIVITY })
                        dispatch({
                            type: SHOW_ALERT,
                            payload: {
                                alertTitle: 'Success',
                                alertMessage: 'Promo Code Submitted.',
                            },
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                        dispatch({ type: HIDE_NETWORK_ACTIVITY })
                        dispatch({
                            type: SHOW_ALERT,
                            payload: {
                                alertTitle: 'Error',
                                alertMessage: 'Error Submitting Promo. Promo code invalid.',
                            },
                        })
                    })
            } else {
                console.log(response, status)
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage:
                            'Error calculating distance between pick up location and destination.',
                    },
                })
            }
        })
    }
}
export const createRiderVerifyPayment = (data) => {
    const { createCreditCardData, stripe } = data

    // console.log(createCreditCardData)

    //Validate Data
    const validationArray = [createCreditCardData]
    const { validation, validationData } = validateInputDataArray(validationArray)
    if (!validation) {
        return (dispatch) => {
            return new Promise((res, rej) => {
                dispatch({
                    type: SHOW_ALERT,
                    payload: validateInputData(validationData),
                })
                return rej()
            })
        }
    }

    //New Card
    if (createCreditCardData.value === 'New Card') {
        return (dispatch) => {
            return new Promise((res, rej) => {
                dispatch({
                    type: SHOW_NETWORK_ACTIVITY,
                    payload: 'Submitting Payment Information...',
                })

                stripe.createToken().then((response) => {
                    if (response.error) {
                        // console.log(response.error)
                        dispatch({ type: HIDE_NETWORK_ACTIVITY })
                        dispatch({
                            type: SHOW_ALERT,
                            payload: {
                                alertTitle: 'Error',
                                alertMessage: response.error.message,
                            },
                        })
                        return rej()
                    }
                    const { id: tokenId } = response.token
                    const { id: cardId } = response.token.card
                    const { uid } = firebase.auth().currentUser

                    apiRequest('POST', `customer/sources/${uid}/${tokenId}`, {})
                        .then((response) => {
                            // console.log(response)
                            if (response.data.message === 'Success') {
                                apiRequest('POST', `customer/default_source/${uid}/${cardId}`, {})
                                    .then((response) => {
                                        apiRequest('POST', `customer/${uid}`, {})
                                            .then((response) => {
                                                // console.log(response)
                                                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                                                dispatch({
                                                    type: RiderKeys.FETCH_RIDER_CREDIT_CARDS,
                                                    payload: response.data,
                                                })
                                                return res()
                                            })
                                            .catch((error) => {
                                                console.log(error)
                                                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                                                dispatch({
                                                    type: SHOW_ALERT,
                                                    payload: {
                                                        alertTitle: 'Error',
                                                        alertMessage:
                                                            'Error Submitting Payment Method.',
                                                    },
                                                })
                                                return rej()
                                            })
                                    })
                                    .catch((error) => {
                                        dispatch({ type: HIDE_NETWORK_ACTIVITY })
                                        dispatch({
                                            type: SHOW_ALERT,
                                            payload: {
                                                alertTitle: 'Error',
                                                alertMessage: 'Error Submitting Payment Method.',
                                            },
                                        })
                                        return rej()
                                    })
                            } else if (response.data.message === 'FAILURE') {
                                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                                dispatch({
                                    type: SHOW_ALERT,
                                    payload: {
                                        alertTitle: 'Error',
                                        alertMessage: 'Error Submitting Payment Method.',
                                    },
                                })
                                return rej()
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            dispatch({ type: HIDE_NETWORK_ACTIVITY })
                            dispatch({
                                type: SHOW_ALERT,
                                payload: {
                                    alertTitle: 'Error',
                                    alertMessage: 'Error Submitting Payment Method.',
                                },
                            })
                            return rej()
                        })
                })
            })
        }
    }

    //Card Selected
    return (dispatch) => {
        return new Promise((res, rej) => {
            dispatch({
                type: SHOW_NETWORK_ACTIVITY,
                payload: 'Submitting Payment Information...',
            })

            const { uid } = firebase.auth().currentUser
            apiRequest('POST', `customer/default_source/${uid}/${createCreditCardData.value}`, {})
                .then((response) => {
                    // console.log(response)
                    if (response.data.message === 'Success') {
                        dispatch({ type: HIDE_NETWORK_ACTIVITY })
                        return res()
                    } else if (response.data.message === 'FAILURE') {
                        dispatch({ type: HIDE_NETWORK_ACTIVITY })
                        dispatch({
                            type: SHOW_ALERT,
                            payload: {
                                alertTitle: 'Error',
                                alertMessage: 'Error Submitting Payment Method.',
                            },
                        })
                        return rej()
                    }
                })
                .catch((error) => {
                    console.log(error)
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                    dispatch({
                        type: SHOW_ALERT,
                        payload: {
                            alertTitle: 'Error',
                            alertMessage: 'Error Submitting Payment Method.',
                        },
                    })
                    return rej()
                })
        })
    }
}
export const createRiderRideRequest = (data) => {
    const { CREATE_REQUEST_SUCCESS, CREATE_REQUEST_FAIL } = RiderKeys
    const {
        riderModel,
        createPickUpData,
        createDestinationData,
        createPickUpTimeData,
        createPetsData,
        createAdditionalNotesData,
        createPricingInformation,
        createPromoData,
        createVehicleTypeData,
        createCreditCardData,
        createLinkToOrgData,
        createOrgData,
        createOrgPayingData,
        createOrgResForData,
        createOrgPaxAliasData,
        createOrgPaxPhoneData,
        createOrgPaxEmailData,
        history,
    } = data

    //Validate Data
    const validationArray = [createPickUpTimeData, createVehicleTypeData]
    const { validation, validationData } = validateInputDataArray(validationArray)
    if (!validation) {
        return (dispatch) => {
            dispatch({
                type: SHOW_ALERT,
                payload: validateInputData(validationData),
            })
        }
    }

    if (!createPickUpData || !createDestinationData) {
        return (dispatch) => {
            dispatch({
                type: SHOW_ALERT,
                payload: {
                    alertTitle: 'Error',
                    alertMessage: 'Both Pick Up Address and Destination Address must be set.',
                },
            })
        }
    }

    if (
        !createOrgPayingData.value ||
        (createOrgPayingData.value && createOrgPayingData.value === 'No')
    ) {
        if (!createCreditCardData.valid) {
            return (dispatch) => {
                dispatch({
                    type: SHOW_ALERT,
                    payload: validateInputData(createCreditCardData),
                })
            }
        }
    }

    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Creating Ride Request...',
        })

        //TODO: Save distance and ETA instead of re calculating it.
        const service = new window.google.maps.DistanceMatrixService()
        const distanceConfig = {
            origins: [{ lat: createPickUpData.lat, lng: createPickUpData.lng }],
            destinations: [{ lat: createDestinationData.lat, lng: createDestinationData.lng }],
            travelMode: 'DRIVING',
        }
        service.getDistanceMatrix(distanceConfig, (response, status) => {
            // console.log(response, status)
            if (
                status === 'OK' &&
                response &&
                response.rows &&
                response.rows[0] &&
                response.rows[0].elements &&
                response.rows[0].elements[0] &&
                response.rows[0].elements[0].distance &&
                response.rows[0].elements[0].distance.value &&
                response.rows[0].elements[0].duration &&
                response.rows[0].elements[0].duration.value
            ) {
                const distance = response.rows[0].elements[0].distance.value
                const tripEta = response.rows[0].elements[0].duration.value

                const timezoneOffset =
                    computeUTCOffsetDifferenceFromLatLng(
                        createPickUpData.lat,
                        createPickUpData.lng
                    ) *
                    60 *
                    1000
                const convertedPickUpTime = createPickUpTimeData.value + timezoneOffset

                const data = {
                    dev: settings.dev,
                    createdAt: Math.round(new Date().getTime() / 1000),
                    carType: createVehicleTypeData.value.type,
                    tripOption: 'Normal',
                    startLat: createPickUpData.lat,
                    startLon: createPickUpData.lng,
                    endLat: createDestinationData.lat,
                    endLon: createDestinationData.lng,
                    requestTime: Math.round(convertedPickUpTime / 1000), //Math.round(createPickUpTimeData.value / 1000),
                    pets: createPetsData.value === 'Yes',
                    riderNote: createAdditionalNotesData.value,
                    riderId: firebase.auth().currentUser.uid,
                    distance,
                    tripEta,
                    startAddress: {
                        startAddrFull: createPickUpData.fullAddress || ' - ',
                        startAddr: createPickUpData.streetAddress || ' - ',
                        startCity: createPickUpData.city || ' - ',
                        startState: createPickUpData.state || ' - ',
                        startZip: createPickUpData.zip || '00000',
                    },
                    endAddress: {
                        endAddrFull: createDestinationData.fullAddress || ' - ',
                        endAddr: createDestinationData.streetAddress || ' - ',
                        endCity: createDestinationData.city || ' - ',
                        endState: createDestinationData.state || ' - ',
                        endZip: createDestinationData.zip || '00000',
                    },
                    pricing: {
                        airportCost: createPricingInformation.airportCost,
                        applyAirportTax: createPricingInformation.applyAirportTax,
                        area: createPricingInformation.area,
                        basePrice: createPricingInformation.basePrice,
                        cardId: createCreditCardData.value,
                        carSeatCost: createPricingInformation.carSeatCost,
                        cityToCity: createPricingInformation.cityToCity,
                        codeUsed: createPricingInformation.codeUsed,
                        endCity: createPricingInformation.endCity,
                        executeLateCharge: createPricingInformation.executeLateCharge,
                        finalPrice: createPricingInformation.finalPrice,
                        fuel: createPricingInformation.fuel,
                        fuelCost: createPricingInformation.fuelCost,
                        gratuity: createPricingInformation.gratuity,
                        gratuityCost: createPricingInformation.gratuityCost,
                        lateCost: createPricingInformation.lateCost,
                        modelVersion: createPricingInformation.modelVersion,
                        numCarSeats: createPricingInformation.numCarSeats,
                        processingCost: createPricingInformation.processingCost,
                        startCity: createPricingInformation.startCity,
                        stripePayment: createPricingInformation.stripePayment,
                        customerId: riderModel.customer_id,
                    },
                }

                if (createPromoData.valid && createPromoData.value) {
                    data.promoCode = createPromoData.value
                }

                //Organization Billing
                if (createLinkToOrgData.value === 'Yes' && createOrgPayingData.value === 'Yes') {
                    data.pricing.cardId = createOrgData.value.card_id || 'invoice'
                    data.pricing.customerId = createOrgData.value.customer_id || 'invoice'
                }

                //Organization Linked
                if (createLinkToOrgData.value === 'Yes') {
                    data.organizationId = createOrgData.value.uid
                    data.organizationInformation = {
                        type: 'Business', //createOrgData.value.type, //Types will probably be phased out
                        createdBy: firebase.auth().currentUser.uid,
                        providingTrip: createOrgPayingData.value === 'Yes',
                    }
                }
                //Organization Entered PAX Information
                if (
                    createLinkToOrgData.value === 'Yes' &&
                    (createOrgPayingData.value === 'No' ||
                        createOrgResForData.value === '3rd Party')
                ) {
                    data.organizationInformation.alias = createOrgPaxAliasData.value
                    data.organizationInformation.phone = createOrgPaxPhoneData.value
                    data.organizationInformation.email = createOrgPaxEmailData.value
                }

                apiRequest('POST', 'api/v1/createRequest', data)
                    .then((response) => {
                        dispatch({ type: HIDE_NETWORK_ACTIVITY })
                        dispatch({ type: CREATE_REQUEST_SUCCESS })
                        history.push('/portal/live')
                        dispatch({
                            type: SHOW_ALERT,
                            payload: {
                                alertTitle: 'Success',
                                alertMessage:
                                    'Ride request has been created. You should receive an email confirming this.',
                            },
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                        dispatch({ type: HIDE_NETWORK_ACTIVITY })
                        dispatch({ type: CREATE_REQUEST_FAIL })
                        dispatch({
                            type: SHOW_ALERT,
                            payload: {
                                alertTitle: 'Error',
                                alertMessage:
                                    'Error creating request. Please contact info@38thstreetstudios.com if this continues to occur.',
                            },
                        })
                    })
            } else {
                console.log(response, status)
                dispatch({ type: CREATE_REQUEST_FAIL })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Error creating ride request.',
                    },
                })
            }
        })
    }
}

//Live Ride Requests
export const startWatchingRiderLiveRequests = () => {
    const { FETCH_LIVE_REQUESTS, SAVE_LIVE_REQUESTS_WATCHER } = RiderKeys

    return (dispatch) => {
        const { uid } = firebase.auth().currentUser
        const liveRequestsWatcherRef = firebase
            .database()
            .ref('RideRequests')
            .orderByChild('riderId')
            .equalTo(uid)
        liveRequestsWatcherRef.on('value', (snapshot) => {
            dispatch({
                type: FETCH_LIVE_REQUESTS,
                payload: snapshot.val(),
            })
        })

        dispatch({
            type: SAVE_LIVE_REQUESTS_WATCHER,
            payload: liveRequestsWatcherRef,
        })
    }
}
export const stopWatchingRiderLiveRequests = () => {
    return (dispatch, getState) => {
        const { liveRequestsWatcherRef } = getState().Rider
        if (liveRequestsWatcherRef) {
            liveRequestsWatcherRef.off()
            dispatch({ type: RiderKeys.REMOVE_LIVE_REQUESTS_WATCHER })
        }
    }
}

export const startWatchingRiderLiveOrgRequests = (organizations) => {
    const { FETCH_LIVE_ORGANIZATION_REQUESTS, SAVE_LIVE_ORGANIZATION_REQUESTS_WATCHER } = RiderKeys

    return (dispatch) => {
        for (const key in organizations) {
            const liveOrganizationRequestsWatcherRef = firebase
                .database()
                .ref('RideRequests')
                .orderByChild('organizationId')
                .equalTo(key)
            liveOrganizationRequestsWatcherRef.on('value', (snapshot) => {
                dispatch({
                    type: FETCH_LIVE_ORGANIZATION_REQUESTS,
                    payload: {
                        uid: key,
                        requests: snapshot.val(),
                    },
                })
            })

            dispatch({
                type: SAVE_LIVE_ORGANIZATION_REQUESTS_WATCHER,
                payload: {
                    uid: key,
                    ref: liveOrganizationRequestsWatcherRef,
                },
            })
        }
    }
}
export const stopWatchingRiderLiveOrgRequests = () => {
    return (dispatch, getState) => {
        const { liveOrganizationRequestsWatchers } = getState().Rider
        if (liveOrganizationRequestsWatchers) {
            for (const key in liveOrganizationRequestsWatchers) {
                liveOrganizationRequestsWatchers[key].off()
            }
            dispatch({ type: RiderKeys.REMOVE_LIVE_ORGANIZATION_REQUESTS_WATCHER })
        }
    }
}

//Historic Ride Requests
export const startWatchingRiderHistoricRequests = () => {
    const { FETCH_HISTORIC_REQUESTS, SAVE_HISTORIC_REQUESTS_WATCHER } = RiderKeys

    return (dispatch) => {
        const { uid } = firebase.auth().currentUser
        const historicRequestsWatcherRef = firebase.database().ref(`History/Riders/${uid}`)
        historicRequestsWatcherRef.on('value', (snapshot) => {
            dispatch({
                type: FETCH_HISTORIC_REQUESTS,
                payload: snapshot.val(),
            })
        })

        dispatch({
            type: SAVE_HISTORIC_REQUESTS_WATCHER,
            payload: historicRequestsWatcherRef,
        })
    }
}
export const stopWatchingRiderHistoricRequests = () => {
    return (dispatch, getState) => {
        const { historicRequestsWatcherRef } = getState().Rider
        if (historicRequestsWatcherRef) {
            historicRequestsWatcherRef.off()
            dispatch({ type: RiderKeys.REMOVE_HISTORIC_REQUESTS_WATCHER })
        }
    }
}
export const startWatchingRiderHistoricOrgRequests = (organizations) => {
    const {
        FETCH_HISTORIC_ORGANIZATION_REQUESTS,
        SAVE_HISTORIC_ORGANIZATION_REQUESTS_WATCHER,
    } = RiderKeys

    return (dispatch) => {
        for (const key in organizations) {
            const historicOrganizationRequestsWatcherRef = firebase
                .database()
                .ref('History/All')
                .orderByChild('organizationId')
                .equalTo(key)
            historicOrganizationRequestsWatcherRef.on('value', (snapshot) => {
                dispatch({
                    type: FETCH_HISTORIC_ORGANIZATION_REQUESTS,
                    payload: {
                        uid: key,
                        requests: snapshot.val(),
                    },
                })
            })

            dispatch({
                type: SAVE_HISTORIC_ORGANIZATION_REQUESTS_WATCHER,
                payload: {
                    uid: key,
                    ref: historicOrganizationRequestsWatcherRef,
                },
            })
        }
    }
}
export const stopWatchingRiderHistoricOrgRequests = () => {
    return (dispatch, getState) => {
        const { historicOrganizationRequestsWatchers } = getState().Rider
        if (historicOrganizationRequestsWatchers) {
            for (const key in historicOrganizationRequestsWatchers) {
                historicOrganizationRequestsWatchers[key].off()
            }
            dispatch({ type: RiderKeys.REMOVE_HISTORIC_ORGANIZATION_REQUESTS_WATCHER })
        }
    }
}

//Live Ride Request
export const startWatchingRiderLiveRequest = (uid) => {
    const {
        FETCH_LIVE_REQUEST,
        SAVE_LIVE_REQUEST_WATCHER,

        FETCH_REQUEST_RIDER,
        SAVE_REQUEST_RIDER_WATCHER,
        REMOVE_REQUEST_RIDER_WATCHER,

        FETCH_REQUEST_RIDER_LOCATION,
        SAVE_REQUEST_RIDER_LOCATION_WATCHER,
        REMOVE_REQUEST_RIDER_LOCATION_WATCHER,

        FETCH_DRIVER,
        SAVE_DRIVER_WATCHER,
        REMOVE_DRIVER_WATCHER,

        FETCH_DRIVER_LOCATION,
        SAVE_DRIVER_LOCATION_WATCHER,
        REMOVE_DRIVER_LOCATION_WATCHER,

        FETCH_REQUEST_ORGANIZATION,
        SAVE_REQUEST_ORGANIZATION_WATCHER,
        REMOVE_REQUEST_ORGANIZATION_WATCHER,
    } = RiderKeys

    return (dispatch, getState) => {
        const liveRequestWatcherRef = firebase.database().ref(`RideRequests/${uid}`)
        liveRequestWatcherRef.on('value', (snapshot) => {
            if (snapshot.val()) {
                const { riderId, driverId, organizationId } = snapshot.val()
                const {
                    liveRequest,
                    requestRiderWatcherRef,
                    requestRiderLocationWatcherRef,
                    driverWatcherRef,
                    driverLocationWatcherRef,
                    organizationWatcherRef,
                } = getState().Rider

                //
                // Rider
                //
                if (liveRequest && riderId !== liveRequest.riderId) {
                    //Stop Rider Watcher
                    if (requestRiderWatcherRef) {
                        requestRiderWatcherRef.off()
                        dispatch({ type: REMOVE_REQUEST_RIDER_WATCHER })
                    }
                    //Stop Rider Location Watcher
                    if (requestRiderLocationWatcherRef) {
                        requestRiderLocationWatcherRef.off()
                        dispatch({ type: REMOVE_REQUEST_RIDER_LOCATION_WATCHER })
                    }
                }
                if (
                    riderId &&
                    (!requestRiderWatcherRef || (liveRequest && riderId !== liveRequest.riderId))
                ) {
                    //Watch Rider
                    const requestRiderWatcherRef = firebase.database().ref(`Riders/${riderId}`)
                    requestRiderWatcherRef.on('value', (snapshot) => {
                        dispatch({
                            type: FETCH_REQUEST_RIDER,
                            payload: snapshot.val(),
                        })
                    })
                    dispatch({
                        type: SAVE_REQUEST_RIDER_WATCHER,
                        payload: requestRiderWatcherRef,
                    })

                    //Watch Rider Location
                    const requestRiderLocationWatcherRef = firebase
                        .database()
                        .ref(`Locations/Riders/${riderId}`)
                    requestRiderLocationWatcherRef.on('value', (snapshot) => {
                        dispatch({
                            type: FETCH_REQUEST_RIDER_LOCATION,
                            payload: snapshot.val(),
                        })
                    })
                    dispatch({
                        type: SAVE_REQUEST_RIDER_LOCATION_WATCHER,
                        payload: requestRiderLocationWatcherRef,
                    })
                }

                //
                // Driver
                //
                if (liveRequest && driverId !== liveRequest.driverId) {
                    //Stop Driver Watcher
                    if (driverWatcherRef) {
                        driverWatcherRef.off()
                        dispatch({ type: REMOVE_DRIVER_WATCHER })
                    }
                    //Stop Driver Location Watcher
                    if (driverLocationWatcherRef) {
                        driverLocationWatcherRef.off()
                        dispatch({ type: REMOVE_DRIVER_LOCATION_WATCHER })
                    }
                }
                if (
                    driverId &&
                    (!driverWatcherRef || (liveRequest && driverId !== liveRequest.driverId))
                ) {
                    //Watch Driver
                    const driverWatcherRef = firebase.database().ref(`Drivers/${driverId}`)
                    driverWatcherRef.on('value', (snapshot) => {
                        dispatch({
                            type: FETCH_DRIVER,
                            payload: snapshot.val(),
                        })
                    })
                    dispatch({
                        type: SAVE_DRIVER_WATCHER,
                        payload: driverWatcherRef,
                    })

                    //Watch Driver Location
                    const driverLocationWatcherRef = firebase
                        .database()
                        .ref(`Locations/Drivers/${driverId}`)
                    driverLocationWatcherRef.on('value', (snapshot) => {
                        dispatch({
                            type: FETCH_DRIVER_LOCATION,
                            payload: snapshot.val(),
                        })
                    })
                    dispatch({
                        type: SAVE_DRIVER_LOCATION_WATCHER,
                        payload: driverLocationWatcherRef,
                    })
                }

                //
                // Organization
                //
                if (liveRequest && liveRequest !== liveRequest.organizationId) {
                    //Stop Driver Watcher
                    if (organizationWatcherRef) {
                        organizationWatcherRef.off()
                        dispatch({ type: REMOVE_REQUEST_ORGANIZATION_WATCHER })
                    }
                }
                if (
                    organizationId &&
                    (!organizationWatcherRef ||
                        (liveRequest && organizationId !== liveRequest.organizationId))
                ) {
                    //Watch Driver
                    const organizationWatcherRef = firebase
                        .database()
                        .ref(`Organizations/OrgInfo/${organizationId}`)
                    organizationWatcherRef.on('value', (snapshot) => {
                        dispatch({
                            type: FETCH_REQUEST_ORGANIZATION,
                            payload: snapshot.val(),
                        })
                    })
                    dispatch({
                        type: SAVE_REQUEST_ORGANIZATION_WATCHER,
                        payload: organizationWatcherRef,
                    })
                }
            }

            dispatch({
                type: FETCH_LIVE_REQUEST,
                payload: snapshot.val(),
            })
        })
        dispatch({
            type: SAVE_LIVE_REQUEST_WATCHER,
            payload: liveRequestWatcherRef,
        })
    }
}
export const stopWatchingRiderLiveRequest = () => {
    const {
        REMOVE_LIVE_REQUEST_WATCHER,
        REMOVE_REQUEST_RIDER_WATCHER,
        REMOVE_DRIVER_WATCHER,
        REMOVE_DRIVER_LOCATION_WATCHER,
        REMOVE_REQUEST_ORGANIZATION_WATCHER,
    } = RiderKeys

    return (dispatch, getState) => {
        const {
            liveRequestWatcherRef,
            requestRiderWatcherRef,
            driverWatcherRef,
            driverLocationWatcherRef,
            organizationWatcherRef,
        } = getState().Rider

        if (liveRequestWatcherRef) {
            liveRequestWatcherRef.off()
            dispatch({ type: REMOVE_LIVE_REQUEST_WATCHER })
        }
        if (requestRiderWatcherRef) {
            requestRiderWatcherRef.off()
            dispatch({ type: REMOVE_REQUEST_RIDER_WATCHER })
        }
        if (driverWatcherRef) {
            driverWatcherRef.off()
            dispatch({ type: REMOVE_DRIVER_WATCHER })
        }
        if (driverLocationWatcherRef) {
            driverLocationWatcherRef.off()
            dispatch({ type: REMOVE_DRIVER_LOCATION_WATCHER })
        }
        if (organizationWatcherRef) {
            organizationWatcherRef.off()
            dispatch({ type: REMOVE_REQUEST_ORGANIZATION_WATCHER })
        }
    }
}

export const confirmCancelRequest = (data) => {
    const { liveRequest, uid, history } = data

    let canCancelNoFee = true
    // 2 hours Check
    if (
        liveRequest &&
        liveRequest.requestTime &&
        liveRequest.requestTime * 1000 < new Date().getTime + 1000 * 60 * 60 * 2
    ) {
        canCancelNoFee = false
    }

    return (dispatch) => {
        const cancelRequest = () => {
            dispatch({
                type: SHOW_NETWORK_ACTIVITY,
                payload: 'Canceling request',
            })

            const data = {
                uid,
                finishOption: canCancelNoFee ? 'FreeCancel' : 'NoShow',
            }
            apiRequest('POST', 'api/v1/finishRequest', data)
                .then((response) => {
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                    dispatch({ type: HIDE_CONFIRM })
                    history.push('/portal/live')
                    dispatch({
                        type: SHOW_ALERT,
                        payload: {
                            alertTitle: 'Success',
                            alertMessage:
                                'Ride request has been canceled. You should receive an email confirming this.',
                        },
                    })
                })
                .catch((error) => {
                    console.log(error)
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                    dispatch({
                        type: SHOW_ALERT,
                        payload: {
                            alertTitle: 'Error',
                            alertMessage:
                                'Error canceling request. Please contact info@38thstreetstudios.com if this continues to occur.',
                        },
                    })
                })
        }

        dispatch({
            type: SHOW_CONFIRM,
            payload: {
                confirmTitle: 'Cancel Ride Request',
                confirmMessage: canCancelNoFee
                    ? 'Are you sure you want to cancel this request? Because you are canceling more than 2 hours before the pick up time you will not be charged for canceling.'
                    : 'Are you sure you want to cancel this request? Because you are canceling less than 2 hours before the pick up time you will be charged full price for this request. In some cases this can be avoided, please contact info@38thstreetstudios.com.',
                confirmLeftTitle: 'Back',
                confirmRightOnClick: cancelRequest,
                confirmRightTitle: 'Cancel Request',
            },
        })
    }
}

//Historic Ride Request
export const startWatchingRiderHistoricRequest = (uid) => {
    const {
        FETCH_HISTORIC_REQUEST,
        SAVE_HISTORIC_REQUEST_WATCHER,

        FETCH_REQUEST_RIDER,
        SAVE_REQUEST_RIDER_WATCHER,
        REMOVE_REQUEST_RIDER_WATCHER,

        FETCH_REQUEST_RIDER_LOCATION,
        SAVE_REQUEST_RIDER_LOCATION_WATCHER,
        REMOVE_REQUEST_RIDER_LOCATION_WATCHER,

        FETCH_DRIVER,
        SAVE_DRIVER_WATCHER,
        REMOVE_DRIVER_WATCHER,

        FETCH_REQUEST_ORGANIZATION,
        SAVE_REQUEST_ORGANIZATION_WATCHER,
        REMOVE_REQUEST_ORGANIZATION_WATCHER,
    } = RiderKeys

    return (dispatch, getState) => {
        const historicRequestWatcherRef = firebase.database().ref(`History/All/${uid}`)
        historicRequestWatcherRef.on('value', (snapshot) => {
            if (snapshot.val()) {
                const { riderId, driverId, organizationId } = snapshot.val()
                const {
                    historicRequest,
                    requestRiderWatcherRef,
                    requestRiderLocationWatcherRef,
                    driverWatcherRef,
                    organizationWatcherRef,
                } = getState().Rider

                //
                // Rider
                //
                if (historicRequest && riderId !== historicRequest.riderId) {
                    //Stop Rider Watcher
                    if (requestRiderWatcherRef) {
                        requestRiderWatcherRef.off()
                        dispatch({ type: REMOVE_REQUEST_RIDER_WATCHER })
                    }
                    //Stop Rider Location Watcher
                    if (requestRiderLocationWatcherRef) {
                        requestRiderLocationWatcherRef.off()
                        dispatch({ type: REMOVE_REQUEST_RIDER_LOCATION_WATCHER })
                    }
                }
                if (
                    riderId &&
                    (!requestRiderWatcherRef ||
                        (historicRequest && riderId !== historicRequest.riderId))
                ) {
                    //Watch Rider
                    const requestRiderWatcherRef = firebase.database().ref(`Riders/${riderId}`)
                    requestRiderWatcherRef.on('value', (snapshot) => {
                        dispatch({
                            type: FETCH_REQUEST_RIDER,
                            payload: snapshot.val(),
                        })
                    })
                    dispatch({
                        type: SAVE_REQUEST_RIDER_WATCHER,
                        payload: requestRiderWatcherRef,
                    })

                    //Watch Rider Location
                    const requestRiderLocationWatcherRef = firebase
                        .database()
                        .ref(`Locations/Riders/${riderId}`)
                    requestRiderLocationWatcherRef.on('value', (snapshot) => {
                        dispatch({
                            type: FETCH_REQUEST_RIDER_LOCATION,
                            payload: snapshot.val(),
                        })
                    })
                    dispatch({
                        type: SAVE_REQUEST_RIDER_LOCATION_WATCHER,
                        payload: requestRiderLocationWatcherRef,
                    })
                }

                //
                // Driver
                //
                if (historicRequest && driverId !== historicRequest.driverId) {
                    //Stop Driver Watcher
                    if (driverWatcherRef) {
                        driverWatcherRef.off()
                        dispatch({ type: REMOVE_DRIVER_WATCHER })
                    }
                }
                if (
                    driverId &&
                    (!driverWatcherRef ||
                        (historicRequest && driverId !== historicRequest.driverId))
                ) {
                    //Watch Driver
                    const driverWatcherRef = firebase.database().ref(`Drivers/${driverId}`)
                    driverWatcherRef.on('value', (snapshot) => {
                        dispatch({
                            type: FETCH_DRIVER,
                            payload: snapshot.val(),
                        })
                    })
                    dispatch({
                        type: SAVE_DRIVER_WATCHER,
                        payload: driverWatcherRef,
                    })
                }

                //
                // Organization
                //
                if (historicRequest && historicRequest !== historicRequest.organizationId) {
                    //Stop Driver Watcher
                    if (organizationWatcherRef) {
                        organizationWatcherRef.off()
                        dispatch({ type: REMOVE_REQUEST_ORGANIZATION_WATCHER })
                    }
                }
                if (
                    organizationId &&
                    (!organizationWatcherRef ||
                        (historicRequest && organizationId !== historicRequest.organizationId))
                ) {
                    //Watch Driver
                    const organizationWatcherRef = firebase
                        .database()
                        .ref(`Organizations/OrgInfo/${organizationId}`)
                    organizationWatcherRef.on('value', (snapshot) => {
                        dispatch({
                            type: FETCH_REQUEST_ORGANIZATION,
                            payload: snapshot.val(),
                        })
                    })
                    dispatch({
                        type: SAVE_REQUEST_ORGANIZATION_WATCHER,
                        payload: organizationWatcherRef,
                    })
                }
            }

            dispatch({
                type: FETCH_HISTORIC_REQUEST,
                payload: snapshot.val(),
            })
        })
        dispatch({
            type: SAVE_HISTORIC_REQUEST_WATCHER,
            payload: historicRequestWatcherRef,
        })
    }
}
export const stopWatchingRiderHistoricRequest = () => {
    const {
        REMOVE_HISTORIC_REQUEST_WATCHER,
        REMOVE_REQUEST_RIDER_WATCHER,
        REMOVE_DRIVER_WATCHER,
        REMOVE_REQUEST_ORGANIZATION_WATCHER,
    } = RiderKeys

    return (dispatch, getState) => {
        const {
            historicRequestWatcherRef,
            requestRiderWatcherRef,
            driverWatcherRef,
            organizationWatcherRef,
        } = getState().Rider

        if (historicRequestWatcherRef) {
            historicRequestWatcherRef.off()
            dispatch({ type: REMOVE_HISTORIC_REQUEST_WATCHER })
        }
        if (requestRiderWatcherRef) {
            requestRiderWatcherRef.off()
            dispatch({ type: REMOVE_REQUEST_RIDER_WATCHER })
        }
        if (driverWatcherRef) {
            driverWatcherRef.off()
            dispatch({ type: REMOVE_DRIVER_WATCHER })
        }
        if (organizationWatcherRef) {
            organizationWatcherRef.off()
            dispatch({ type: REMOVE_REQUEST_ORGANIZATION_WATCHER })
        }
    }
}
