import { DispatchKeys } from './../actions/types'

const INITIAL_STATE = {
	liveRequests: undefined,
	liveRequestsLoaded: false,
	liveRequestsWatcherRef: undefined,

	historicRequests: undefined,
	historicRequestsLoaded: false,
	historicRequestsWatcherRef: undefined,

	drivers: undefined,
	driversLoaded: false,
	driversWatcherRef: undefined,

	liveRequest: undefined,
	liveRequestLoaded: false,
	liveRequestWatcherRef: undefined,

	historicRequest: undefined,
	historicRequestLoaded: false,
	historicRequestWatcherRef: undefined,

	driverModel: undefined,
	driverModelLoaded: false,
	driverWatcherRef: undefined,

	driverLocation: undefined,
	driverLocationLoaded: false,
	driverLocationWatcherRef: undefined,

	organization: undefined,
	organizationLoaded: false,
	organizationWatcherRef: undefined,

	selectedDriverData: {
		title: 'Assign A Chauffeur',
		value: '',
		valid: false,
		validLabel: 'Required',
	},

	editRequestsPopupVisible: false,

	editStatusesPopupVisible: false,

	completeReqeustPopupVisible: false,
	additionalFeeData: {
		title: 'Additional Fee',
		value: '',
		valid: false,
		validLabel: 'Required',
	},

	addDriverPopupVisible: false,
	createDriverFirstNameData: {
		title: 'First Name',
		value: '',
		valid: false,
		validLabel: 'Required',
	},
	createDriverLastNameData: {
		title: 'Last Name',
		value: '',
		valid: false,
		validLabel: 'Required',
	},
	createDriverPhoneData: {
		title: 'Phone Number',
		value: '',
		valid: false,
		validLabel: 'Required',
	},
	createDriverIdData: {
		title: 'Chauffeur Id',
		value: '',
		valid: false,
		validLabel: 'Required',
	},
	createDriverEmailData: {
		title: 'Email Address',
		value: '',
		valid: false,
		validLabel: 'Required',
	},

	driverFirstNameData: {
		title: 'First Name',
		value: '',
		valid: false,
		validLabel: 'Required',
	},
	driverLastNameData: {
		title: 'Last Name',
		value: '',
		valid: false,
		validLabel: 'Required',
	},
	driverPhoneData: {
		title: 'Phone Number',
		value: '',
		valid: false,
		validLabel: 'Required',
	},
	driverIdData: {
		title: 'Chauffeur Id',
		value: '',
		valid: false,
		validLabel: 'Required',
	},
	driverVehicleMakeData: {
		title: 'Vehicle Make Model',
		value: '',
		valid: false,
		validLabel: 'Required',
	},
	driverVehicleYearData: {
		title: 'Vehicle Year',
		value: '',
		valid: false,
		validLabel: 'Required',
	},
	driverVehicleTypeData: {
		title: 'Vehicle Type',
		value: '',
		valid: false,
		validLabel: 'Required',
	},
	driverVehicleLicenseData: {
		title: 'License Plate',
		value: '',
		valid: false,
		validLabel: 'Required',
	},
	driverVehicleCityLicenseData: {
		title: 'City Plates',
		value: '',
		valid: false,
		validLabel: 'Required',
	},
	driverVehicleIdData: {
		title: 'Vehicle Id',
		value: '',
		valid: false,
		validLabel: 'Required',
	},
	driverStatusData: {
		title: 'Account Status',
		value: '',
		valid: false,
		validLabel: 'Required',
	},
}

export default (state = INITIAL_STATE, action) => {
	const {
		FETCH_LIVE_REQUESTS,
		SAVE_LIVE_REQUESTS_WATCHER,
		REMOVE_LIVE_REQUESTS_WATCHER,

		FETCH_HISTORIC_REQUESTS,
		SAVE_HISTORIC_REQUESTS_WATCHER,
		REMOVE_HISTORIC_REQUESTS_WATCHER,

		FETCH_DRIVERS,
		SAVE_DRIVERS_WATCHER,
		REMOVE_DRIVERS_WATCHER,

		FETCH_LIVE_REQUEST,
		SAVE_LIVE_REQUEST_WATCHER,
		REMOVE_LIVE_REQUEST_WATCHER,

		FETCH_HISTORIC_REQUEST,
		SAVE_HISTORIC_REQUEST_WATCHER,
		REMOVE_HISTORIC_REQUEST_WATCHER,

		FETCH_REQUEST_RIDER,
		SAVE_REQUEST_RIDER_WATCHER,
		REMOVE_REQUEST_RIDER_WATCHER,

		FETCH_REQUEST_RIDER_LOCATION,
		SAVE_REQUEST_RIDER_LOCATION_WATCHER,
		REMOVE_REQUEST_RIDER_LOCATION_WATCHER,

		FETCH_DRIVER,
		SAVE_DRIVER_WATCHER,
		REMOVE_DRIVER_WATCHER,

		FETCH_DRIVER_LOCATION,
		SAVE_DRIVER_LOCATION_WATCHER,
		REMOVE_DRIVER_LOCATION_WATCHER,

		FETCH_REQUEST_ORGANIZATION,
		SAVE_REQUEST_ORGANIZATION_WATCHER,
		REMOVE_REQUEST_ORGANIZATION_WATCHER,

		SELECTED_DRIVER_CHANGED,

		SHOW_EDIT_REQUESTS_POPUP,
		HIDE_EDIT_REQUESTS_POPUP,

		SHOW_EDIT_STATUSES_POPUP,
		HIDE_EDIT_STATUSES_POPUP,

		SAVE_UPDATE_STATUSES_SUCCESS,
		SAVE_UPDATE_STATUSES_FAIL,

		SHOW_COMPLETE_REQUEST_POPUP,
		HIDE_COMPLETE_REQUEST_POPUP,

		ADDITIONAL_FEE_CHANGED,

		COMPLETE_REQUEST_SUCCESS,
		COMPLETE_REQUEST_FAIL,

		ASSIGN_CHAUFFEUR_SUCCESS,
		ASSIGN_CHAUFFEUR_FAIL,

		SHOW_ADD_DRIVER_POPUP,
		HIDE_ADD_DRIVER_POPUP,

		CREATE_DRIVER_FIRST_NAME_CHANGED,
		CREATE_DRIVER_LAST_NAME_CHANGED,
		CREATE_DRIVER_PHONE_CHANGED,
		CREATE_DRIVER_EMAIL_CHANGED,

		CREATE_DRIVER_SUCCESS,
		CREATE_DRIVER_FAIL,

		DRIVER_FIRST_NAME_CHANGED,
		DRIVER_LAST_NAME_CHANGED,
		DRIVER_PHONE_CHANGED,
		DRIVER_ID_CHANGED,
		DRIVER_VEHICLE_MAKE_CHANGED,
		DRIVER_VEHICLE_YEAR_CHANGED,
		DRIVER_VEHICLE_TYPE_CHANGED,
		DRIVER_VEHICLE_LICENSE_CHANGED,
		DRIVER_VEHICLE_CITY_LICENSE_CHANGED,
		DRIVER_VEHICLE_ID_CHANGED,
		DRIVER_STATUS_CHANGED,
	} = DispatchKeys

	switch (action.type) {
		case FETCH_LIVE_REQUESTS:
			return { ...state, liveRequests: action.payload, liveRequestsLoaded: true }
		case SAVE_LIVE_REQUESTS_WATCHER:
			return { ...state, liveRequestsWatcherRef: action.payload }
		case REMOVE_LIVE_REQUESTS_WATCHER:
			return {
				...state,
				liveRequests: INITIAL_STATE.liveRequests,
				liveRequestsLoaded: INITIAL_STATE.liveRequestsLoaded,
				liveRequestsWatcherRef: INITIAL_STATE.liveRequestsWatcherRef,
			}

		case FETCH_HISTORIC_REQUESTS:
			return { ...state, historicRequests: action.payload, historicRequestsLoaded: true }
		case SAVE_HISTORIC_REQUESTS_WATCHER:
			return { ...state, historicRequestsWatcherRef: action.payload }
		case REMOVE_HISTORIC_REQUESTS_WATCHER:
			return {
				...state,
				historicRequests: INITIAL_STATE.historicRequests,
				historicRequestsLoaded: INITIAL_STATE.historicRequestsLoaded,
				historicRequestsWatcherRef: INITIAL_STATE.historicRequestsWatcherRef,
			}

		case FETCH_DRIVERS:
			return { ...state, drivers: action.payload, driversLoaded: true }
		case SAVE_DRIVERS_WATCHER:
			return { ...state, driversWatcherRef: action.payload }
		case REMOVE_DRIVERS_WATCHER:
			return {
				...state,
				drivers: INITIAL_STATE.drivers,
				driversLoaded: INITIAL_STATE.driversLoaded,
				driversWatcherRef: INITIAL_STATE.driversWatcherRef,
			}

		case FETCH_LIVE_REQUEST:
			return { ...state, liveRequest: action.payload, liveRequestLoaded: true }

		case SAVE_LIVE_REQUEST_WATCHER:
			return { ...state, liveRequestWatcherRef: action.payload }
		case REMOVE_LIVE_REQUEST_WATCHER:
			return {
				...state,
				liveRequest: INITIAL_STATE.liveRequest,
				liveRequestLoaded: INITIAL_STATE.liveRequestLoaded,
				liveRequestWatcherRef: INITIAL_STATE.liveRequestWatcherRef,
			}

		case FETCH_HISTORIC_REQUEST:
			return { ...state, historicRequest: action.payload, historicRequestLoaded: true }
		case SAVE_HISTORIC_REQUEST_WATCHER:
			return { ...state, historicRequestWatcherRef: action.payload }
		case REMOVE_HISTORIC_REQUEST_WATCHER:
			return {
				...state,
				historicRequest: INITIAL_STATE.historicRequest,
				historicRequestLoaded: INITIAL_STATE.historicRequestLoaded,
				historicRequestWatcherRef: INITIAL_STATE.historicRequestWatcherRef,
			}

		case FETCH_REQUEST_RIDER:
			return { ...state, requestRiderModel: action.payload, requestRiderModelLoaded: true }
		case SAVE_REQUEST_RIDER_WATCHER:
			return { ...state, requestRiderWatcherRef: action.payload }
		case REMOVE_REQUEST_RIDER_WATCHER:
			return {
				...state,
				requestRiderModel: INITIAL_STATE.requestRiderModel,
				requestRiderModelLoaded: INITIAL_STATE.requestRiderModelLoaded,
				requestRiderWatcherRef: INITIAL_STATE.requestRiderWatcherRef,
			}

		case FETCH_REQUEST_RIDER_LOCATION:
			return {
				...state,
				requestRiderLocation: action.payload,
				requestRiderLocationLoaded: true,
			}
		case SAVE_REQUEST_RIDER_LOCATION_WATCHER:
			return { ...state, requestRiderLocationWatcherRef: action.payload }
		case REMOVE_REQUEST_RIDER_LOCATION_WATCHER:
			return {
				...state,
				requestRiderLocation: INITIAL_STATE.requestRiderLocation,
				requestRiderLocationLoaded: INITIAL_STATE.requestRiderLocationLoaded,
				requestRiderLocationWatcherRef: INITIAL_STATE.requestRiderLocationWatcherRef,
			}

		case FETCH_DRIVER: {
			if (!action.payload) {
				return { ...state, driverModel: action.payload, driverModelLoaded: true }
			}

			const { profile, activeVehicle, statuses } = action.payload

			const dataToRereturn = {}

			if (profile) {
				const { nameF, nameL, phone, chauffeurId } = profile
				dataToRereturn.driverFirstNameData = {
					...state.driverFirstNameData,
					value: nameF || INITIAL_STATE.driverFirstNameData.value,
					valid: !!nameF,
				}
				dataToRereturn.driverLastNameData = {
					...state.driverLastNameData,
					value: nameL || INITIAL_STATE.driverLastNameData.value,
					valid: !!nameL,
				}
				dataToRereturn.driverPhoneData = {
					...state.driverPhoneData,
					value: phone || INITIAL_STATE.driverPhoneData.value,
					valid: !!phone,
				}
				dataToRereturn.driverIdData = {
					...state.driverIdData,
					value: chauffeurId || INITIAL_STATE.driverIdData.value,
					valid: !!chauffeurId,
				}
			}
			if (activeVehicle) {
				const {
					carCapacity,
					carCityLicense,
					carLicense,
					carMake,
					carYear,
					vehicleId,
				} = activeVehicle

				const cityPlates = carCityLicense ? 'Yes' : 'No'

				let vehicleType = ''
				if (carCapacity <= 3) {
					vehicleType = 'Sedan'
				} else if (carCapacity <= 6) {
					vehicleType = 'SUV'
				}

				dataToRereturn.driverVehicleMakeData = {
					...state.driverVehicleMakeData,
					value: carMake || INITIAL_STATE.driverVehicleMakeData.value,
					valid: !!carMake,
				}
				dataToRereturn.driverVehicleYearData = {
					...state.driverVehicleYearData,
					value: carYear || INITIAL_STATE.driverVehicleYearData.value,
					valid: !!carYear,
				}
				dataToRereturn.driverVehicleTypeData = {
					...state.driverVehicleTypeData,
					value: vehicleType || INITIAL_STATE.driverVehicleTypeData.value,
					valid: !!vehicleType,
				}
				dataToRereturn.driverVehicleLicenseData = {
					...state.driverVehicleLicenseData,
					value: carLicense || INITIAL_STATE.driverVehicleLicenseData.value,
					valid: !!carLicense,
				}
				dataToRereturn.driverVehicleCityLicenseData = {
					...state.driverVehicleCityLicenseData,
					value: cityPlates || INITIAL_STATE.driverVehicleCityLicenseData.value,
					valid: !!cityPlates,
				}
				dataToRereturn.driverVehicleIdData = {
					...state.driverVehicleIdData,
					value: vehicleId || INITIAL_STATE.driverVehicleIdData.value,
					valid: !!vehicleId,
				}
			}
			if (statuses) {
				const { status } = statuses

				let statusFlag = ''
				if (status === -1) {
					statusFlag = 'Unactivated'
				} else if (status === 1) {
					statusFlag = 'Available'
				} else if (status === 2) {
					statusFlag = 'In A Request'
				}

				dataToRereturn.driverStatusData = {
					...state.driverStatusData,
					value: statusFlag || INITIAL_STATE.driverStatusData.value,
					valid: !!statusFlag,
				}
			}

			return {
				...state,
				driverModel: action.payload,
				driverModelLoaded: true,
				...dataToRereturn,
			}
		}
		case SAVE_DRIVER_WATCHER:
			return { ...state, driverWatcherRef: action.payload }
		case REMOVE_DRIVER_WATCHER:
			return {
				...state,
				driverModel: INITIAL_STATE.driverModel,
				driverModelLoaded: INITIAL_STATE.driverModelLoaded,
				driverWatcherRef: INITIAL_STATE.driverWatcherRef,
			}

		case FETCH_DRIVER_LOCATION:
			return { ...state, driverLocation: action.payload, driverLocationLoaded: true }
		case SAVE_DRIVER_LOCATION_WATCHER:
			return { ...state, driverLocationWatcherRef: action.payload }
		case REMOVE_DRIVER_LOCATION_WATCHER:
			return {
				...state,
				driverLocation: INITIAL_STATE.driverLocation,
				driverLocationLoaded: INITIAL_STATE.driverLocationLoaded,
				driverLocationWatcherRef: INITIAL_STATE.driverLocationWatcherRef,
			}

		case FETCH_REQUEST_ORGANIZATION:
			return { ...state, organization: action.payload, organizationLoaded: true }
		case SAVE_REQUEST_ORGANIZATION_WATCHER:
			return { ...state, organizationWatcherRef: action.payload }
		case REMOVE_REQUEST_ORGANIZATION_WATCHER:
			return {
				...state,
				organization: INITIAL_STATE.organization,
				organizationLoaded: INITIAL_STATE.organizationLoaded,
				organizationWatcherRef: INITIAL_STATE.organizationWatcherRef,
			}

		case SHOW_EDIT_REQUESTS_POPUP:
			return { ...state, editRequestsPopupVisible: true }
		case HIDE_EDIT_REQUESTS_POPUP:
			return { ...state, editRequestsPopupVisible: false }

		case SHOW_EDIT_STATUSES_POPUP:
			return { ...state, editStatusesPopupVisible: true }
		case HIDE_EDIT_STATUSES_POPUP:
			return { ...state, editStatusesPopupVisible: false }

		case SAVE_UPDATE_STATUSES_SUCCESS:
			return { ...state, editStatusesPopupVisible: false }
		case SAVE_UPDATE_STATUSES_FAIL:
			return state

		case SHOW_COMPLETE_REQUEST_POPUP:
			return { ...state, completeReqeustPopupVisible: true }
		case HIDE_COMPLETE_REQUEST_POPUP:
			return { ...state, completeReqeustPopupVisible: false }

		case ADDITIONAL_FEE_CHANGED:
			return {
				...state,
				additionalFeeData: { ...state.additionalFeeData, ...action.payload },
			}

		case COMPLETE_REQUEST_SUCCESS:
			return {
				...state,
				completeReqeustPopupVisible: INITIAL_STATE.completeReqeustPopupVisible,
				additionalFeeData: INITIAL_STATE.additionalFeeData,
			}

		case COMPLETE_REQUEST_FAIL:
			return state

		case SELECTED_DRIVER_CHANGED:
			return {
				...state,
				selectedDriverData: { ...state.selectedDriverData, ...action.payload },
			}

		case ASSIGN_CHAUFFEUR_SUCCESS:
			return { ...state, selectedDriverData: INITIAL_STATE.selectedDriverData }
		case ASSIGN_CHAUFFEUR_FAIL:
			return state

		case SHOW_ADD_DRIVER_POPUP:
			return { ...state, addDriverPopupVisible: true }
		case HIDE_ADD_DRIVER_POPUP:
			return { ...state, addDriverPopupVisible: false }

		case CREATE_DRIVER_FIRST_NAME_CHANGED:
			return {
				...state,
				createDriverFirstNameData: {
					...state.createDriverFirstNameData,
					...action.payload,
				},
			}
		case CREATE_DRIVER_LAST_NAME_CHANGED:
			return {
				...state,
				createDriverLastNameData: { ...state.createDriverLastNameData, ...action.payload },
			}
		case CREATE_DRIVER_PHONE_CHANGED:
			return {
				...state,
				createDriverPhoneData: { ...state.createDriverPhoneData, ...action.payload },
			}
		case CREATE_DRIVER_EMAIL_CHANGED:
			return {
				...state,
				createDriverEmailData: { ...state.createDriverEmailData, ...action.payload },
			}

		case CREATE_DRIVER_SUCCESS:
			return {
				...state,
				createDriverFirstNameData: INITIAL_STATE.createDriverFirstNameData,
				createDriverLastNameData: INITIAL_STATE.createDriverLastNameData,
				createDriverPhoneData: INITIAL_STATE.createDriverPhoneData,
				createDriverEmailData: INITIAL_STATE.createDriverEmailData,
				addDriverPopupVisible: INITIAL_STATE.addDriverPopupVisible,
			}
		case CREATE_DRIVER_FAIL:
			return state

		case DRIVER_FIRST_NAME_CHANGED:
			return {
				...state,
				driverFirstNameData: { ...state.driverFirstNameData, ...action.payload },
			}
		case DRIVER_LAST_NAME_CHANGED:
			return {
				...state,
				driverLastNameData: { ...state.driverLastNameData, ...action.payload },
			}
		case DRIVER_PHONE_CHANGED:
			return { ...state, driverPhoneData: { ...state.driverPhoneData, ...action.payload } }
		case DRIVER_ID_CHANGED:
			return { ...state, driverIdData: { ...state.driverIdData, ...action.payload } }
		case DRIVER_VEHICLE_MAKE_CHANGED:
			return {
				...state,
				driverVehicleMakeData: { ...state.driverVehicleMakeData, ...action.payload },
			}
		case DRIVER_VEHICLE_YEAR_CHANGED:
			return {
				...state,
				driverVehicleYearData: { ...state.driverVehicleYearData, ...action.payload },
			}
		case DRIVER_VEHICLE_TYPE_CHANGED:
			return {
				...state,
				driverVehicleTypeData: { ...state.driverVehicleTypeData, ...action.payload },
			}
		case DRIVER_VEHICLE_LICENSE_CHANGED:
			return {
				...state,
				driverVehicleLicenseData: { ...state.driverVehicleLicenseData, ...action.payload },
			}
		case DRIVER_VEHICLE_CITY_LICENSE_CHANGED:
			return {
				...state,
				driverVehicleCityLicenseData: {
					...state.driverVehicleCityLicenseData,
					...action.payload,
				},
			}
		case DRIVER_VEHICLE_ID_CHANGED:
			return {
				...state,
				driverVehicleIdData: { ...state.driverVehicleIdData, ...action.payload },
			}
		case DRIVER_STATUS_CHANGED:
			return { ...state, driverStatusData: { ...state.driverStatusData, ...action.payload } }

		default:
			return state
	}
}
