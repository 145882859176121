import {
	LoadingKeys
} from './../actions/types'

const INITIAL_STATE = {
	userCheckComplete 	: false,

	appVersions 		: undefined,
	appVersionsLoaded 	: false,
}

export default (state = INITIAL_STATE, action) => {
	const {
		USER_CHECK_COMPLETE,
		USER_CHECK_STARTED,

		FETCH_APP_VERSIONS,
	} = LoadingKeys

	switch (action.type) {
        case USER_CHECK_STARTED:
           	return { ...state, userCheckComplete: false }
		case USER_CHECK_COMPLETE:
			return { ...state, userCheckComplete: true }

		case FETCH_APP_VERSIONS:
			return { ...state, appVersions: action.payload, appVersionsLoaded: true }

		default:
			return state;
	}
}

