import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
// import firebase from 'firebase/app'
// import 'firebase/database'

// import { } from './../../../actions'
// import { HomeKeys } from './../../../actions/types'

import TEHelmet from './../../../component/Header/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

//import images from './../../../config/images'
import styles from './styles'

class Home extends Component {
    //Set Every Rider with Livery Privileges to Dispatch True in UserPermissions
    //Set Livery UID In Rider Model
    //Remove Organization from User Org List
    //Copy Livery Information Over
    //Delete Livery Org Data

    //TODO: Add Active Flag To All Drivers

    componentDidMount() {
        // firebase.database().ref('DispatchCompanies').once('value')
        // .then((snapshot) => {
        // 	for (const key in snapshot.val()){
        // 		firebase.database().ref(`Organizations/OrgInfo/${key}`).set(snapshot.val()[key])
        // 	}
        // })

        // firebase.database().ref('Riders').once('value')
        // .then((snapshot) => {
        // 	for (const key in snapshot.val()){
        // 		if (snapshot.val()[key].dispatchUID){
        // 			firebase.database().ref(`Riders/${key}/organizations/${snapshot.val()[key].dispatchUID}/permissions`).set('Admin')
        // 			firebase.database().ref(`Organizations/OrgMembers/${snapshot.val()[key].dispatchUID}/key`).set(true)
        // 		}
        // 	}
        // })
        // firebase.database().ref('Organizations').once('value')
        // .then((snapshot) => {
        // 	const {
        // 		OrgInfo,
        // 		OrgMembers,
        // 	} = snapshot.val()

        // 	const liveryOrgs = []
        // 	for (const key in OrgInfo){
        // 		if (OrgInfo[key].type === 'Livery'){
        // 			liveryOrgs.push(key)

        // 			firebase.database().ref(`DispatchCompanies/${key}`).set(OrgInfo[key])
        // 			firebase.database().ref(`Organizations/OrgInfo/${key}`).remove()
        // 			firebase.database().ref(`Organizations/OrgLocationMembers/${key}`).remove()
        // 			firebase.database().ref(`Organizations/OrgLocations/${key}`).remove()
        // 			firebase.database().ref(`Organizations/OrgMembers/${key}`).remove()
        // 		}
        // 	}

        // 	for (let i=0; i<liveryOrgs.length; i++){
        // 		const liveryUID = liveryOrgs[i]
        // 		const members = OrgMembers[liveryUID]
        // 		for (const riderUID in members){
        // 			firebase.database().ref(`Riders/${riderUID}/organizations/${liveryUID}`).remove()
        // 			firebase.database().ref(`Riders/${riderUID}/dispatchUID`).set(liveryUID)
        // 			firebase.database().ref(`UserPermissions/${riderUID}/dispatch`).set(true)
        // 		}
        // 	}
        // })
    }
    render() {
        const meta = {
            title: 'FirstClass | Home',
            description: 'Acting as your modern-day concierge service, FirstClass allows you to schedule rides with reputable black car transportation companies directly from your phone.',
        }

        return (
            <Fragment>
				<TEHelmet {...meta}/>
				<PanelWrapper style={styles.container}>
					<Panel containerStyle={styles.panel} >
						<h1 style={styles.title}>Welcome to FirstClass Portal</h1>
					</Panel>
				</PanelWrapper>
			</Fragment>
        )
    }
}

Home.propTypes = {}

Home.defaultProps = {}

const mapStateToProps = (state) => {
    return {}
}

export default connect(mapStateToProps, {})(Radium(Home))