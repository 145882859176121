import {
    SHOW_ALERT,
    SHOW_CONFIRM,
    HIDE_CONFIRM,
    SHOW_NETWORK_ACTIVITY,
    HIDE_NETWORK_ACTIVITY,
    AdminKeys,
} from './types'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { validateInputDataArray, validateInputData } from './../helpers'

//Users
export const startWatchingAdminRiders = () => {
    const { FETCH_RIDERS, SAVE_RIDERS_WATCHER } = AdminKeys

    return (dispatch) => {
        const ridersWatcherRef = firebase.database().ref('Riders')
        ridersWatcherRef.on('value', (snapshot) => {
            dispatch({
                type: FETCH_RIDERS,
                payload: snapshot.val(),
            })
        })

        dispatch({
            type: SAVE_RIDERS_WATCHER,
            payload: ridersWatcherRef,
        })
    }
}
export const stopWatchingAdminRiders = () => {
    return (dispatch, getState) => {
        const { ridersWatcherRef } = getState().Admin
        if (ridersWatcherRef) {
            ridersWatcherRef.off()
            dispatch({ type: AdminKeys.REMOVE_RIDERS_WATCHER })
        }
    }
}

//
//Organizations
//
export const startWatchingAdminOrganizations = () => {
    const { FETCH_ORGANIZATIONS, SAVE_ORGANIZATIONS_WATCHER } = AdminKeys

    return (dispatch) => {
        const organizationsWatcherRef = firebase.database().ref('Organizations/OrgInfo')
        organizationsWatcherRef.on('value', (snapshot) => {
            dispatch({
                type: FETCH_ORGANIZATIONS,
                payload: snapshot.val(),
            })
        })

        dispatch({
            type: SAVE_ORGANIZATIONS_WATCHER,
            payload: organizationsWatcherRef,
        })
    }
}
export const stopWatchingAdminOrganizations = () => {
    return (dispatch, getState) => {
        const { organizationsWatcherRef } = getState().Admin
        if (organizationsWatcherRef) {
            organizationsWatcherRef.off()
            dispatch({ type: AdminKeys.REMOVE_ORGANIZATIONS_WATCHER })
        }
    }
}

//Organization
export const showCreateAdminOrganizationPopup = () => ({
    type: AdminKeys.SHOW_CREATE_ORGANIZATION_POPUP,
})
export const hideCreateAdminOrganizationPopup = () => ({
    type: AdminKeys.HIDE_CREATE_ORGANIZATION_POPUP,
})

export const createOrganization = (data) => {
    const { CREATE_ORGANIZATION_SUCCESS, CREATE_ORGANIZATION_FAIL } = AdminKeys

    const {
        createOrganizationNameData,
        // createOrganizationTypeData,
    } = data

    //Validate Data
    const validationArray = [
        createOrganizationNameData,
        // createOrganizationTypeData,
    ]
    const { validation, validationData } = validateInputDataArray(validationArray)
    if (!validation) {
        return (dispatch) => {
            dispatch({
                type: SHOW_ALERT,
                payload: validateInputData(validationData),
            })
        }
    }
    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Creating Organization...',
        })
        const data = {
            dateCreated: new Date().getTime(),
            customer_id: 'invoice',
            card_id: 'invoice',
            name: createOrganizationNameData.value,
            type: 'Business', //createOrganizationTypeData.value, //Somewhat legacy really isn't needed
        }
        firebase
            .database()
            .ref(`Organizations/OrgInfo`)
            .push(data)
            .then((response) => {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: CREATE_ORGANIZATION_SUCCESS })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Success',
                        alertMessage: 'Organization has been created.',
                    },
                })
            })
            .catch((error) => {
                console.log(error)
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: CREATE_ORGANIZATION_FAIL })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Error creating organization.',
                    },
                })
            })
    }
}

export const startWatchingAdminOrganization = (uid) => {
    const {
        FETCH_ORGANIZATION,
        SAVE_ORGANIZATION_WATCHER,

        FETCH_ORGANIZATION_USERS,
        SAVE_ORGANIZATION_USERS_WATCHERS,
        REMOVE_ORGANIZATION_USER,
    } = AdminKeys

    return (dispatch, getState) => {
        const organizationWatcherRef = firebase.database().ref(`Organizations/OrgInfo/${uid}`)
        organizationWatcherRef.on('value', (snapshot) => {
            dispatch({
                type: FETCH_ORGANIZATION,
                payload: snapshot.val(),
            })
        })

        dispatch({
            type: SAVE_ORGANIZATION_WATCHER,
            payload: organizationWatcherRef,
        })

        //This might be able to be done with a orderByChild equalTo....
        firebase
            .database()
            .ref(`Organizations/OrgMembers/${uid}`)
            .on('value', (snapshot) => {
                if (snapshot.val()) {
                    //Check for users who were being watched and are now not
                    const { organizationUsers, organizationUsersWatcherRefs } = getState().Admin

                    for (const key in organizationUsers) {
                        if (!snapshot.val()[key]) {
                            if (organizationUsersWatcherRefs[key]) {
                                organizationUsersWatcherRefs[key].off()
                            }
                            dispatch({
                                type: REMOVE_ORGANIZATION_USER,
                                payload: key,
                            })
                        }
                    }

                    for (const uid in snapshot.val()) {
                        if (
                            !organizationUsersWatcherRefs ||
                            (organizationUsersWatcherRefs && !organizationUsersWatcherRefs[uid])
                        ) {
                            const organizationUserWatcherRef = firebase
                                .database()
                                .ref(`Riders/${uid}`)
                            organizationUserWatcherRef.on('value', (snapshot) => {
                                dispatch({
                                    type: FETCH_ORGANIZATION_USERS,
                                    payload: {
                                        uid,
                                        riderModel: snapshot.val(),
                                    },
                                })
                            })

                            dispatch({
                                type: SAVE_ORGANIZATION_USERS_WATCHERS,
                                payload: {
                                    uid,
                                    ref: organizationUserWatcherRef,
                                },
                            })
                        }
                    }
                }
            })
    }
}
export const stopWatchingAdminOrganization = () => {
    return (dispatch, getState) => {
        const { organizationWatcherRef, organizationUserWatcherRefs } = getState().Admin

        if (organizationWatcherRef) {
            organizationWatcherRef.off()
            dispatch({ type: AdminKeys.REMOVE_ORGANIZATION_WATCHER })
        }

        if (organizationUserWatcherRefs) {
            for (const key in organizationUserWatcherRefs) {
                if (organizationUserWatcherRefs[key]) {
                    organizationUserWatcherRefs[key].off()
                }
            }
            dispatch({ type: AdminKeys.REMOVE_ORGANIZATION_USERS_WATCHERS })
        }
    }
}

export const saveAdminOrganization = (data) => {
    const { SAVE_ORGANIZATION_SUCCESS, SAVE_ORGANIZATION_FAIL } = AdminKeys

    const {
        organizationNameData,
        // organizationTypeData,
        uid,
    } = data

    //Validate Data
    const validationArray = [
        organizationNameData,
        // organizationTypeData,
    ]
    const { validation, validationData } = validateInputDataArray(validationArray)
    if (!validation) {
        return (dispatch) => {
            dispatch({
                type: SHOW_ALERT,
                payload: validateInputData(validationData),
            })
        }
    }
    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Saving Organization...',
        })
        const data = {
            name: organizationNameData.value,
            // type             : organizationTypeData.value,
        }
        firebase
            .database()
            .ref(`Organizations/OrgInfo/${uid}`)
            .update(data)
            .then((response) => {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: SAVE_ORGANIZATION_SUCCESS })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Success',
                        alertMessage: 'Organization details saved.',
                    },
                })
            })
            .catch((error) => {
                console.log(error)
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: SAVE_ORGANIZATION_FAIL })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Error saving organization details.',
                    },
                })
            })
    }
}

export const showAddAdminRiderPopup = () => ({ type: AdminKeys.SHOW_ADD_RIDER_POPUP })
export const hideAddAdminRiderPopup = () => ({ type: AdminKeys.HIDE_ADD_RIDER_POPUP })

export const addUserToOrganization = (data) => {
    const { ADD_USER_TO_ORG_SUCCESS, ADD_USER_TO_ORG_FAIL } = AdminKeys

    const { uid, addUserData, addPermissionData } = data

    //Validate Data
    const validationArray = [addUserData, addPermissionData]
    const { validation, validationData } = validateInputDataArray(validationArray)
    if (!validation) {
        return (dispatch) => {
            dispatch({
                type: SHOW_ALERT,
                payload: validateInputData(validationData),
            })
        }
    }
    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Adding User To Organization...',
        })

        firebase
            .database()
            .ref(`Organizations/OrgMembers/${uid}`)
            .update({
                [addUserData.value.uid]: true,
            })
            .then((response) => {
                return firebase
                    .database()
                    .ref(`Riders/${addUserData.value.uid}/organizations/${uid}`)
                    .update({ permission: addPermissionData.value })
            })
            .then((response) => {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: ADD_USER_TO_ORG_SUCCESS })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Success',
                        alertMessage: 'User added to organization.',
                    },
                })
            })
            .catch((error) => {
                console.log(error)
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: ADD_USER_TO_ORG_FAIL })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Error adding user to organization.',
                    },
                })
            })
    }
}

export const startWatchingAdminOrganizationRider = (uid) => {
    const { FETCH_ORG_RIDER, SAVE_ORG_RIDER_WATCHER } = AdminKeys

    return (dispatch) => {
        const orgRiderWatcherRef = firebase.database().ref(`Riders/${uid}`)
        orgRiderWatcherRef.on('value', (snapshot) => {
            dispatch({
                type: FETCH_ORG_RIDER,
                payload: snapshot.val(),
            })
        })

        dispatch({
            type: SAVE_ORG_RIDER_WATCHER,
            payload: orgRiderWatcherRef,
        })
    }
}
export const stopWatchingAdminOrganizationRider = () => {
    return (dispatch, getState) => {
        const { orgRiderWatcherRef } = getState().Admin
        if (orgRiderWatcherRef) {
            orgRiderWatcherRef.off()
            dispatch({ type: AdminKeys.REMOVE_ORG_RIDER_WATCHER })
        }
    }
}

export const confirmRemoveAdminOrganizationUser = (data) => {
    const { orgUID, userUID, histroy } = data

    return (dispatch) => {
        const removeUser = () => {
            dispatch({
                type: SHOW_NETWORK_ACTIVITY,
                payload: 'Removing User From Organization...',
            })

            firebase
                .database()
                .ref(`Riders/${userUID}/organizations/${orgUID}`)
                .remove()
                .then((response) => {
                    return firebase
                        .database()
                        .ref(`Organizations/OrgMembers/${orgUID}/${userUID}`)
                        .remove()
                })
                .then((response) => {
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                    dispatch({ type: HIDE_CONFIRM })
                    histroy.push(`/admin/organizations/${orgUID}`)
                    dispatch({
                        type: SHOW_ALERT,
                        payload: {
                            alertTitle: 'Success',
                            alertMessage: 'User removed from organization.',
                        },
                    })
                })
                .catch((error) => {
                    console.log(error)
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                    dispatch({
                        type: SHOW_ALERT,
                        payload: {
                            alertTitle: 'Error',
                            alertMessage: 'Error removing user from organization.',
                        },
                    })
                })
        }
        dispatch({
            type: SHOW_CONFIRM,
            payload: {
                confirmTitle: 'Remove User From Organization',
                confirmMessage: 'Are you sure you want to remove user from this organization?',
                confirmLeftTitle: 'Cancel',
                confirmRightOnClick: removeUser,
                confirmRightTitle: 'Remove User',
            },
        })
    }
}
export const saveAdminOrganizationUser = (data) => {
    const { orgUID, userUID, savePermissionData } = data

    if (!savePermissionData.valid) {
        return (dispatch) => {
            dispatch({
                type: SHOW_ALERT,
                payload: validateInputData(savePermissionData),
            })
        }
    }

    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Saving User Permissions...',
        })

        firebase
            .database()
            .ref(`Riders/${userUID}/organizations/${orgUID}`)
            .update({ permission: savePermissionData.value })
            .then((response) => {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Success',
                        alertMessage: 'User permissions saved.',
                    },
                })
            })
            .catch((error) => {
                console.log(error)
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Error saving user permissions.',
                    },
                })
            })
    }
}

//
//Dispatch
//

//Dispatch Companies
export const startWatchingAdminDispatchCompanies = () => {
    const { FETCH_DISPATCH_COMPANIES, SAVE_DISPATCH_COMPANIES_WATCHER } = AdminKeys

    return (dispatch) => {
        const dispatchCompaniesWatcherRef = firebase.database().ref('DispatchCompanies')
        dispatchCompaniesWatcherRef.on('value', (snapshot) => {
            dispatch({
                type: FETCH_DISPATCH_COMPANIES,
                payload: snapshot.val(),
            })
        })

        dispatch({
            type: SAVE_DISPATCH_COMPANIES_WATCHER,
            payload: dispatchCompaniesWatcherRef,
        })
    }
}
export const stopWatchingAdminDispatchCompanies = () => {
    return (dispatch, getState) => {
        const { dispatchCompaniesWatcherRef } = getState().Admin
        if (dispatchCompaniesWatcherRef) {
            dispatchCompaniesWatcherRef.off()
            dispatch({ type: AdminKeys.REMOVE_DISPATCH_COMPANIES_WATCHER })
        }
    }
}

//Dispatch Company
export const showCreateAdminDispatchCompanyPopup = () => ({
    type: AdminKeys.SHOW_CREATE_DISPATCH_COMPANY_POPUP,
})
export const hideCreateAdminDispatchCompanyPopup = () => ({
    type: AdminKeys.HIDE_CREATE_DISPATCH_COMPANY_POPUP,
})

export const createDispatchCompany = (data) => {
    const { CREATE_DISPATCH_COMPANY_SUCCESS, CREATE_DISPATCH_COMPANY_FAIL } = AdminKeys

    const { createDispatchCompanyNameData } = data

    if (!createDispatchCompanyNameData.valid) {
        return (dispatch) => {
            dispatch({
                type: SHOW_ALERT,
                payload: validateInputData(createDispatchCompanyNameData),
            })
        }
    }
    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Creating Dispatch Company...',
        })
        const data = {
            dateCreated: new Date().getTime(),
            name: createDispatchCompanyNameData.value,
        }
        firebase
            .database()
            .ref(`DispatchCompanies`)
            .push(data)
            .then((response) => {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: CREATE_DISPATCH_COMPANY_SUCCESS })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Success',
                        alertMessage: 'Dispatch Company has been created.',
                    },
                })
            })
            .catch((error) => {
                console.log(error)
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: CREATE_DISPATCH_COMPANY_FAIL })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Error creating dispatch company.',
                    },
                })
            })
    }
}

export const startWatchingAdminDispatchCompany = (uid) => {
    const {
        FETCH_DISPATCH_COMPANY,
        SAVE_DISPATCH_COMPANY_WATCHER,

        FETCH_DISPATCH_COMPANY_USERS,
        SAVE_DISPATCH_COMPANY_USERS_WATCHERS,
    } = AdminKeys

    return (dispatch, getState) => {
        const dispatchCompanyWatcherRef = firebase.database().ref(`DispatchCompanies/${uid}`)
        dispatchCompanyWatcherRef.on('value', (snapshot) => {
            dispatch({
                type: FETCH_DISPATCH_COMPANY,
                payload: snapshot.val(),
            })
        })

        dispatch({
            type: SAVE_DISPATCH_COMPANY_WATCHER,
            payload: dispatchCompanyWatcherRef,
        })

        const dispatchCompanyUsersWatcherRef = firebase
            .database()
            .ref(`Riders`)
            .orderByChild('dispatchUID')
            .equalTo(uid)
        dispatchCompanyUsersWatcherRef.on('value', (snapshot) => {
            console.log(snapshot.val())
            dispatch({
                type: FETCH_DISPATCH_COMPANY_USERS,
                payload: snapshot.val(),
            })
        })
        dispatch({
            type: SAVE_DISPATCH_COMPANY_USERS_WATCHERS,
            payload: dispatchCompanyUsersWatcherRef,
        })
    }
}
export const stopWatchingAdminDispatchCompany = () => {
    return (dispatch, getState) => {
        const { organizationWatcherRef, organizationUserWatcherRefs } = getState().Admin

        if (organizationWatcherRef) {
            organizationWatcherRef.off()
            dispatch({ type: AdminKeys.REMOVE_DISPATCH_COMPANY_WATCHER })
        }

        if (organizationUserWatcherRefs) {
            organizationUserWatcherRefs.off()
            dispatch({ type: AdminKeys.REMOVE_DISPATCH_COMPANY_USERS_WATCHERS })
        }
    }
}

export const saveAdminDispatchCompany = (data) => {
    const { SAVE_DISPATCH_COMPANY_SUCCESS, SAVE_DISPATCH_COMPANY_FAIL } = AdminKeys

    const { dispatchCompanyNameData, uid } = data

    if (!dispatchCompanyNameData.valid) {
        return (dispatch) => {
            dispatch({
                type: SHOW_ALERT,
                payload: validateInputData(dispatchCompanyNameData),
            })
        }
    }
    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Saving Dispatch Company...',
        })
        const data = {
            name: dispatchCompanyNameData.value,
        }
        firebase
            .database()
            .ref(`DispatchCompanies/${uid}`)
            .update(data)
            .then((response) => {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: SAVE_DISPATCH_COMPANY_SUCCESS })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Success',
                        alertMessage: 'Dispatch Company details saved.',
                    },
                })
            })
            .catch((error) => {
                console.log(error)
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: SAVE_DISPATCH_COMPANY_FAIL })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Error saving dispatch company details.',
                    },
                })
            })
    }
}

export const showAddAdminDispatcherPopup = () => ({ type: AdminKeys.SHOW_ADD_DISPATCHER_POPUP })
export const hideAddAdminDispatcherPopup = () => ({ type: AdminKeys.HIDE_ADD_DISPATCHER_POPUP })

export const addUserToDispatchCompany = (data) => {
    const { ADD_USER_TO_DISPATCH_COMPANY_SUCCESS, ADD_USER_TO_DISPATCH_COMPANY_FAIL } = AdminKeys

    const { uid, addDispatcherData } = data

    if (!addDispatcherData.valid) {
        return (dispatch) => {
            dispatch({
                type: SHOW_ALERT,
                payload: validateInputData(addDispatcherData),
            })
        }
    }
    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Adding User To Dispatch Organization...',
        })

        firebase
            .database()
            .ref(`Riders/${addDispatcherData.value.uid}/dispatchUID`)
            .set(uid)
            .then((response) => {
                return firebase
                    .database()
                    .ref(`UserPermissions/${addDispatcherData.value.uid}/dispatch`)
                    .set(true)
            })
            .then((response) => {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: ADD_USER_TO_DISPATCH_COMPANY_SUCCESS })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Success',
                        alertMessage: 'User added to dispatch company.',
                    },
                })
            })
            .catch((error) => {
                console.log(error)
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: ADD_USER_TO_DISPATCH_COMPANY_FAIL })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Error adding user to dispatch company.',
                    },
                })
            })
    }
}

export const confirmRemoveAdminDispatchCompanyDispatcher = (uid) => {
    return (dispatch) => {
        const removeUser = () => {
            dispatch({
                type: SHOW_NETWORK_ACTIVITY,
                payload: 'Removing User From Dispatch Company...',
            })

            firebase
                .database()
                .ref(`Riders/${uid}/dispatchUID`)
                .remove()
                .then((response) => {
                    return firebase
                        .database()
                        .ref(`UserPermissions/${uid}/dispatch`)
                        .remove()
                })
                .then((response) => {
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                    dispatch({ type: HIDE_CONFIRM })
                    dispatch({
                        type: SHOW_ALERT,
                        payload: {
                            alertTitle: 'Success',
                            alertMessage: 'User removed from dispatch company.',
                        },
                    })
                })
                .catch((error) => {
                    console.log(error)
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                    dispatch({
                        type: SHOW_ALERT,
                        payload: {
                            alertTitle: 'Error',
                            alertMessage: 'Error removing user from dispatch company.',
                        },
                    })
                })
        }
        dispatch({
            type: SHOW_CONFIRM,
            payload: {
                confirmTitle: 'Remove User From Dispatch Company',
                confirmMessage: 'Are you sure you want to remove user from this dispatch company?',
                confirmLeftTitle: 'Cancel',
                confirmRightOnClick: removeUser,
                confirmRightTitle: 'Remove User',
            },
        })
    }
}
