//
//TE Version 0.1.0
//

import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import Radium from 'radium'

import TERadioButtonInput from './../../Form/TERadioButtonInput'

import styles from './styles'

class SideNavbar extends Component {
	render() {

		const {
			containerStyle,
			onChange,
			checkedValue,
			buttonArray,
			labelForKey 	= 'side-nav-',
		} = this.props

		return (
			<div style={[styles.container, containerStyle]}>
				<ul style={styles.navContainer}>
					{buttonArray.map((button) => {
						const {
				 			label,
				 			value = label,
				 			//icon,
				 		} = button
						const key = labelForKey + label

						return (
							<div key={key} style={styles.labelWrapper}>
								<TERadioButtonInput
									value={value}
									onChange={onChange}
									style={styles.input}
									id={key}
									checked={checkedValue === value}
								/>
								<label
									htmlFor={key}
									key={key}
									style={[
										styles.label,
										checkedValue === value && styles.checkedSegmented,
									]}
								>
									{label}
								</label>
							</div>
						)
					})}
				</ul>
			</div>
		)
	}
}

SideNavbar.propTypes = {}

SideNavbar.defaultProps = {}

export default Radium(SideNavbar)
