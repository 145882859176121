import { colors } from './../../../../config/styles'

export default {
	container: {},
	title: {
		textAlign: 'center',
		color: colors.primary,
		marginTop: 0,
		marginBottom: 20,
	},
	dataRow: {
		marginBottom: 15,
	},
	dataTitle: {
		display: 'block',
		fontSize: 18,
		fontWeight: 500,
		marginBottom: 5,
	},
	dataValue: {
		display: 'block',
		fontSize: 16,
		fontWeight: 100,
		letterSpacing: 0.5,
	},
}
