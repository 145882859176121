//Used for Polyfills.
//TODO: Only import what is needed instead of whole things.
import 'core-js'

import React from 'react'
import ReactDOM from 'react-dom'
import { unregister } from './registerServiceWorker'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import ReduxThunk from 'redux-thunk'
import { StyleRoot } from 'radium'
import { StripeProvider } from 'react-stripe-elements'

import 'react-table/react-table.css'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'
import './reactPowerSelect.css'
import './react-datepicker.css'

import ScrollToTop from './component/ScrollToTop'

import GoogleAnalyticsTracking from './component/GoogleAnalyticsTracking'
import App from './App'
import reducers from './reducers'
import settings from './config/settings'

const store = createStore(reducers, applyMiddleware(ReduxThunk))

const element = (
	<StyleRoot>
		<Provider store={store}>
			<Router>
				<StripeProvider
					apiKey={
						settings.dev || settings.staging
							? settings.DEVELOPMENT.STRIPE_API_KEY
							: settings.PRODUCTION.STRIPE_API_KEY
					}
				>
					<ScrollToTop>
						<Route component={GoogleAnalyticsTracking(App)} />
					</ScrollToTop>
				</StripeProvider>
			</Router>
		</Provider>
	</StyleRoot>
)

ReactDOM.render(element, document.getElementById('root'))
unregister()
