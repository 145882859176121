// import { colors } from './../../../config/styles'

export default {
	panelWrapper : {
		width 		: 'calc(100% - 160px)',
		marginLeft 	: 160
	},
	editPanel: {
		maxWidth 	: 600,
	},
	saveButton: {
		// width 		: 'auto'
	},
	tableButton : {
		fontSize 	: 14,
	},
}
