//import { colors } from './../../config/styles';

export default {
	svgWrapperStyle: {
		fontSize 	: 0,
		pointerEvents 		: 'none',
	},
	svgStyle: {
		width 	 			: '100%',
		pointerEvents 		: 'none',
	},
}
