import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'

import {
	hideAddAdminDispatcherPopup,
	addUserToDispatchCompany,
} from './../../../../actions'
import { AdminKeys } from './../../../../actions/types'

import TEPopup from './../../../../component/Popup/TEPopup'
import TEMultiStepForm from './../../../../component/Form/TEMultiStepForm'
import TESearchSelectRow from './../../../../component/Form/TESearchSelectRow'

import styles from './styles'

class AddUserPopup extends Component {
	handleClosePopup = () => {
		this.props.hideAddAdminDispatcherPopup()
	}
	handleCreate = () => {
		const {
			uid,
			addUserToDispatchCompany,
			addDispatcherData,
		} = this.props

		const data = {
			uid,
			addDispatcherData,
		}

		addUserToDispatchCompany(data)
	}

	render(){
		const {
			visible,

			riderArray,
			addDispatcherData,
		} = this.props

		return (
			<TEPopup visible={visible} contentStyles={styles.content}>
				<div style={styles.container}>
					<TEMultiStepForm
						stepData={[{
							title 			: 'Details',
							component 		: <Fragment>
									<TESearchSelectRow
										labelForKey='rider'
										options={riderArray}
										rowData={addDispatcherData}
										optionLabelPath='alias'
										onChangeKey={AdminKeys.ADD_DISPATCHER_CHANGED}
										validationRules={['required']}
									/>
								</Fragment>,
							validateData 	: [addDispatcherData]
						}]}
						handleCancelOnClick={this.handleClosePopup}
						handleSubmitOnClick={this.handleCreate}
						popupStyles
					/>
				</div>
			</TEPopup>
		)
	}
}

AddUserPopup.propTypes = {}

AddUserPopup.defaultProps = {}

const mapStateToProps = (state) => {
	const {
		riders,
		addDispatcherData,
	} = state.Admin

	const riderArray = []
	for (const key in riders){
		const dataToMap = riders[key]
		dataToMap.uid = key
		dataToMap.alias = dataToMap.profile && dataToMap.profile.alias

		if (!dataToMap.dispatchUID && dataToMap.alias){
			riderArray.push(dataToMap)
		}
 	}
	riderArray.sort((a, b) => {
		if (a.alias < b.alias) { return -1 }
		if (a.alias > b.alias) { return 1 }
		return 0
	})

	return {
		riderArray,
		addDispatcherData,
	}
}

export default connect(mapStateToProps, {
	hideAddAdminDispatcherPopup,
	addUserToDispatchCompany,
})(Radium(AddUserPopup))
