import { colors, layout } from './../../../config/styles';

export default {
	container : {
        position            : 'relative',
		backgroundColor 	: colors.white,
        paddingLeft         : layout.gutterSize,
        paddingRight        : layout.gutterSize,
        paddingBottom       : layout.gutterSize,
        paddingTop          : layout.gutterSize,
		borderRadius 		: 5,
        overflow            : 'hidden',
        marginBottom        : layout.gutterSize,
        boxShadow           : `0px 1px 3px 0px ${colors.shadow}`,

        '@media (max-width: 500px)' : {
            padding             : layout.gutterSize/2,
        }
	},
}
