import { colors } from './../../../config/styles'
//import images from './../../../config/images'

export default {
	background : {
		position            : 'absolute',
		//backgroundImage     : `url(${images.FrontEnd.Auth.authBackground})`,
		backgroundPosition  : 'center center',
		backgroundSize      : 'cover',
		width               : '100%',
		height              : '100%',
		top                 : 0,
		left                : 0,
		paddingTop          : 150
	},
	form : {
		backgroundColor     : colors.blue,
		paddingTop 			: 40,
		paddingBottom 		: 40,
		paddingRight 		: 40,
		paddingLeft 		: 40,
	},
	title : {
		color               : colors.white,
		fontSize            : 44,
		textAlign           : 'center',
		marginTop           : 0,
		marginBottom        : 40,
	},
	label : {
		display : 'none',
	},
	input : {
		border  		: 'none',
		paddingTop 		: 10,
		paddingBottom 	: 10,
		paddingRight 	: 10,
		paddingLeft 	: 10,

		':hover' : {
			border      : 'none',
			boxShadow   : 'none',
		},
		':active' : {
			border      : 'none',
			boxShadow   : 'none',
		}
	},
	buttonContainer : {
		display 			: 'flex',
		justifyContent 		: 'space-between',
		width 				: '100%',
	},
	forgot : {
		textDecoration  : 'none',
		color           : colors.white,
		fontSize        : 18,
	},
	button : {
		borderTop		: 'none',
		borderRight		: 'none',
		borderBottom	: 'none',
		borderLeft		: 'none',
		width 			: 'auto',
		paddingTop 		: 10,
		paddingBottom 	: 10,
		paddingRight 	: 50,
		paddingLeft 	: 50,
		color 			: colors.gray,

		':hover' : {
			boxShadow   	: 'none',
			backgroundColor : colors.white,
			color 			: colors.blue,
		},
		':active' : {
			boxShadow   	: 'none',
			backgroundColor : colors.white,
			color 			: colors.blue,
		}
	}
}
