//
//TE Version 0.1.0
//

import React, { Component } from 'react'
import Radium from 'radium'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { PowerSelect, PowerSelectMultiple } from 'react-power-select'

import { inputHasChanged } from './../../../actions'

import TERow from './../TERow'
import TELabel from './../TELabel'

//import styles from './styles'

class TESearchSelectRow extends Component {
	state = { firstDataValidation: false }
	componentDidMount() {
		//Validate Data On Load
		const {
			onChange,
			inputHasChanged,
			rowData,
			onChangeKey,
			validationRules,
			confirmValue,
			dataForReducer
		} = this.props
		const { value } = rowData

		if (onChange){
			onChange({option: value})
		}else{
			const data = {
				onChangeKey,
				validationRules,
				value,
				confirmValue,
				dataForReducer,
			}

			inputHasChanged(data)
		}
	}
	componentWillReceiveProps(nextProps){
		if (!this.state.firstDataValidation){
			if (nextProps && this.props){
				if (nextProps.rowData && this.props.rowData){
					if (nextProps.rowData.value !== this.props.rowData.value){
						this.setState({ firstDataValidation: true })
						const {
							onChange,
							inputHasChanged,
							rowData,
							onChangeKey,
							validationRules,
							confirmValue,
							dataForReducer,
						} = nextProps
						const { value } = rowData

						if (onChange){
							onChange({option: value})
						}else{
							const data = {
								onChangeKey,
								validationRules,
								value,
								confirmValue,
								dataForReducer,
							}

							inputHasChanged(data)
						}
					}
				}
			}
		}
	}
	handleInputChange = (event) => {
		const {
			type,
			inputHasChanged,
			onChangeKey,
			validationRules,
			confirmValue,
			dataForReducer,
		} = this.props

		if (type === 'default'){
			const { option } = event

			const data = {
				onChangeKey,
				validationRules,
				value: option,
				confirmValue,
				dataForReducer,
			}

			inputHasChanged(data)
		}else if (type === 'multi'){
			const { options } = event

			const data = {
				onChangeKey,
				validationRules,
				value: options,
				confirmValue,
				dataForReducer,
			}

			inputHasChanged(data)
		}
	}

	render() {
		const {
			//Row
			rowStyles,
			size,
			last,

			//Label
			labelStyles,
			disabled,

			//Input
			type,

			options,
			onChange,
			validationRules,
			placeholder,
			optionComponent,
			selectedOptionComponent,
			optionLabelPath,
			searchEnabled,

			//Both
			rowData,
			labelForKey,

		} = this.props

		const {
			title,
			value,
			//validLabel,
			//valid,
		} = rowData

		return (
			<TERow size={size} last={last} style={rowStyles}>
				<TELabel
					htmlFor={labelForKey}
					labelText={title}
					style={labelStyles}
					required={validationRules}
					disabled={disabled}
				/>
				{type === 'default' &&
					<PowerSelect
						options={options}
						selected={value}
						onChange={onChange || this.handleInputChange}
						placeholder={placeholder}
	          			optionComponent={optionComponent}
	          			selectedOptionComponent={selectedOptionComponent}
	          			optionLabelPath={optionLabelPath}
	          			searchEnabled={searchEnabled}
						id={labelForKey}
						disabled={disabled}
					/>
				}
				{type === 'multi' &&
					<PowerSelectMultiple
						options={options}
						selected={value}
						onChange={onChange || this.handleInputChange}
						placeholder={placeholder}
	          			optionComponent={optionComponent}
	          			selectedOptionComponent={selectedOptionComponent}
	          			optionLabelPath={optionLabelPath}
	          			searchEnabled={searchEnabled}
						id={labelForKey}
						disabled={disabled}
					/>
				}
			</TERow>
		)
	}
}

TESearchSelectRow.propTypes = {
	//Row
	rowStyles		: PropTypes.object,
	size 			: PropTypes.string,
	last 			: PropTypes.bool,

	//Input
	type 			: PropTypes.string,
	options			: PropTypes.array,
	onChange 		: PropTypes.func,
	onChangeKey		: PropTypes.string,

	//Both
	rowData : PropTypes.shape({
		title		: PropTypes.string,
		value		: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.bool,
			PropTypes.object,
			PropTypes.array,
		]),
		validLabel	: PropTypes.string.isRequired,
		valid		: PropTypes.bool.isRequired,
	}),
	labelForKey		: PropTypes.string,
}

TESearchSelectRow.defaultProps = {
	type 		 	: 'default',
	searchEnabled 	: true
}

const mapStateToProps = (state) => {
	return { }
}

export default connect(mapStateToProps, { inputHasChanged })(Radium(TESearchSelectRow))
