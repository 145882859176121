import { layout } from './../../../config/styles';

export default {
	container : {
		padding 	    : layout.gutterSize,
		display 	    : 'flex',
		flexFlow 	    : 'row wrap',
		justifyContent	: 'space-between',
		alignItems      : 'flex-start',

        '@media (max-width: 500px)' : {
            padding             : layout.gutterSize/2,
        }
	},
}
