import React from 'react'
import tzlookup from 'tz-lookup'

import TELink from './../../../component/Navigation/TELink'

import { convertToDateTimeShort24 } from './../../../helpers'
import styles from './styles'

export const tableColumns = [
	{
		id: 'requestTime',
		Header: 'Pickup Time',
		filterable: false,
		Cell: (d) => {
			const { requestTime, startLat, startLon } = d.original

			if (!requestTime || !startLat || !startLon) {
				return convertToDateTimeShort24(requestTime * 1000)
			}

			return convertToDateTimeShort24(requestTime * 1000, tzlookup(startLat, startLon))
		},
	},
	{
		id: 'startAddress',
		Header: 'Pick Up',
		accessor: 'startAddress.startAddrFull',
	},
	{
		id: 'endAddress',
		Header: 'Destination',
		accessor: 'endAddress.endAddrFull',
	},
	{
		id: 'vehicle',
		Header: 'Vehicle',
		accessor: 'carType',
	},
	{
		id: 'status',
		Header: 'Status',
		accessor: (d) => {
			switch (d.status) {
				case 1:
					return 'Unaccepted'
				case 2:
					return 'Accepted'
				case 3:
					return 'En Route'
				case 4:
					return 'On Location'
				case 5:
					return 'Picked Up'
				case 6:
					return 'Dropped Off'
				default:
					return ' -- '
			}
		},
	},
	{
		id: 'actions',
		Header: '',
		width: 120,
		sortable: false,
		filterable: false,
		accessor: 'uid',
		Cell: (d) => (
			<TELink
				to={`/dispatch/live/${d.value}`}
				wrapperStyle={styles.detailLinkWrapper}
				style={styles.detailLink}
			>
				Details
			</TELink>
		),
	},
]
