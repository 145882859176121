//
//TE Version 0.1.0
//

import React, { Component } from 'react'
import Radium from 'radium'
import PropTypes from 'prop-types'
import styles from './styles'

class TERadioButtonInput extends Component {

	render() {
		const {
			style,
			...rest
		} = this.props

		return (
			<input 
				type='radio'
				style={[
					styles.radio,
					rest.disabled && styles.disabled,
					style,
				]}
				{...rest}
			/>
		)
	}
}

TERadioButtonInput.propTypes = {
	style	: PropTypes.object,
	value 			: PropTypes.string.isRequired,
	onChange 		: PropTypes.func.isRequired,
	disabled		: PropTypes.bool,
	id 				: PropTypes.string,
	checked			: PropTypes.bool,
}

TERadioButtonInput.defaultProps = {}

export default Radium(TERadioButtonInput)
