import { SHOW_ALERT } from './types';

import {
    isEmail,
    isAtLeastLength,
    requiredCheck,
    isPhoneNumber,
    isYear,
    isPercentage,
    isImageFile,
    isInteger,

    validateInputData
} from './../helpers';

export const inputHasChanged = (data) => {
    //console.log('entered', data)
    const {
        onChangeKey,
        validationRules,
        value,
        confirmValue = null,
        ...rest
    } = data

    const returnInputState = (dispatch, value, valid, validLabel) => {
        //console.log('leaving', value)
        dispatch({
            type: onChangeKey,
            payload: {
                value,
                valid,
                validLabel,
                ...rest
            }
        })
    }

    return (dispatch) => {
        if (validationRules !== undefined && validationRules !== null) {
            if (validationRules.length > 0) {
                let valid = true;
                let validLabel = '';
                for (let i = 0; i < validationRules.length; i++) {
                    const rule = validationRules[i]
                    //Leave if we have found an invalid rule
                    if (!valid) { break; }

                    //Check Rule
                    switch (rule) {
                        case 'required':
                            if (!requiredCheck(value)) {
                                valid = false;
                                validLabel = "Required";
                            }
                            break;
                        case 'email':
                            if (!isEmail(value)) {
                                valid = false;
                                validLabel = "Invalid Email Format";
                            }
                            break;
                        case 'password':
                            if (!isAtLeastLength(value, 6)) {
                                valid = false;
                                validLabel = "At Least 6 Characters";
                            }
                            break;
                        case 'phone':
                            if (!isPhoneNumber(value)) {
                                valid = false;
                                validLabel = "Invalid Phone Number";
                            }
                            break;
                        case 'year':
                            if (!isYear(value)) {
                                valid = false;
                                validLabel = "Invalid Year";
                            }
                            break;
                        case 'positive':
                            if (value < 0) {
                                valid = false;
                                validLabel = "Must Be Positive (>0)";
                            }
                            break;
                        case 'integer':
                            if (!isInteger(value)) {
                                valid = false;
                                validLabel = "Must Be An Integer";
                            }
                            break;
                        case 'percentage':
                            if (!isPercentage(value)) {
                                valid = false;
                                validLabel = "Invalid Percentage (0 - 100)";
                            }
                            break;
                        case 'confirm':
                            if (value !== confirmValue) {
                                valid = false;
                                validLabel = "Doesn't Match";
                            }
                            break;
                        default:
                            console.log("VALIDATION RULE WITHOUT HANDLER", rule)
                    }
                }
                returnInputState(dispatch, value, valid, validLabel);
            } else { returnInputState(dispatch, value, true, ''); }
        } else { returnInputState(dispatch, value, true, ''); }
    };
};

export const fileInputHasChanged = (actionType, validationRules, value) => {
    const returnInputState = (dispatch, value, valid, validLabel) => {
        dispatch({
            type: actionType,
            payload: {
                value,
                valid,
                validLabel,
            }
        });
    }

    return (dispatch) => {
        if (validationRules !== undefined && validationRules !== null) {
            if (validationRules.length > 0) {
                let valid = true;
                let validLabel = '';
                for (let i = 0; i < validationRules.length; i++) {
                    const rule = validationRules[i]
                    //Leave if we have found an invalid rule
                    if (!valid) { break; }

                    //Check Rule
                    switch (rule) {
                        case 'required':
                            if (!requiredCheck(value)) {
                                valid = false;
                                validLabel = "Required";
                            }
                            break;
                        case 'image':
                            if (!isImageFile(value)) {
                                valid = false;
                                validLabel = "Upload an image file. (png or jpeg)";
                            }
                            break;
                        default:
                            console.log("VALIDATION RULE WITHOUT HANDLER", rule)
                    }
                }
                returnInputState(dispatch, value, valid, validLabel);
            } else { returnInputState(dispatch, value, true, ''); }
        } else { returnInputState(dispatch, value, true, ''); }
    };
}

export const checkboxHasChanged = (actionType, validationRules, value) => {
    const returnInputState = (dispatch, value, valid, validLabel) => {
        dispatch({
            type: actionType,
            payload: {
                value,
                valid,
                validLabel,
            }
        });
    }
    return (dispatch) => {
        if (validationRules !== undefined && validationRules !== null) {
            if (validationRules.length > 0) {
                let valid = true;
                let validLabel = '';
                for (let i = 0; i < validationRules.length; i++) {
                    const rule = validationRules[i]
                    //Leave if we have found an invalid rule
                    if (!valid) { break; }

                    //Check Rule
                    switch (rule) {
                        case 'required':
                            if (!requiredCheck(value)) {
                                valid = false;
                                validLabel = "Required";
                            }
                            break;
                        default:
                            console.log("VALIDATION RULE WITHOUT HANDLER", rule)
                    }
                }
                returnInputState(dispatch, value, valid, validLabel);
            } else { returnInputState(dispatch, value, true, ''); }
        } else { returnInputState(dispatch, value, true, ''); }
    };
}

export const showInvalidDataAlert = (data) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_ALERT,
            payload: validateInputData(data)
        })
    }
}
export const googlePlacesSearchBoxChanged = (actionType, data) => {
    return {
        type: actionType,
        payload: data
    }
}