import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import ReactTable from 'react-table'
import Papa from 'papaparse'

import {
	startWatchingDispatchHistoricRequests,
	stopWatchingDispatchHistoricRequests,
	startWatchingDrivers,
	stopWatchingDrivers,
} from './../../../actions'
import { getDispatchHistoricRequestsArray } from './../../../selectors'

import TEHelmet from './../../../component/Header/TEHelmet'
import TEButton from './../../../component/Form/TEButton'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import { tableColumns } from './tableConfigs'
import { customTableFilter, convertToDateTimeShort, downloadCSV } from './../../../helpers'
import styles from './styles'

class LiveCompany extends Component {
	componentDidMount() {
		const {
			riderModel,
			startWatchingDrivers,
			startWatchingDispatchHistoricRequests,
		} = this.props

		startWatchingDrivers(riderModel.dispatchUID)
		startWatchingDispatchHistoricRequests()
	}
	componentWillUnmount() {
		const { stopWatchingDispatchHistoricRequests, stopWatchingDrivers } = this.props

		stopWatchingDispatchHistoricRequests()
		stopWatchingDrivers()
	}

	handleDownload = () => {
		const { requestArray, drivers } = this.props

		const data = requestArray
			.sort((a, b) => b.requestTime - a.requestTime)
			.map((requestData) => {
				const {
					carType,
					completed,
					startAddress,
					endAddress,
					requestTime,
					driverId,
				} = requestData
				const data = {
					Completed: completed === 1 ? 'Completed' : 'Canceled',
					'Pick Up Address': startAddress.startAddrFull,
					'Destination Address': endAddress.endAddrFull,
					'Pick Up Time': convertToDateTimeShort(requestTime * 1000),
					'Vehicle Type': carType,
				}

				if (driverId && drivers && drivers[driverId] && drivers[driverId].profile) {
					const { chauffeurId, nameF, nameL } = drivers[driverId].profile
					data['Chauffeur Id'] = chauffeurId
					data['Chauffeur Name'] = `${nameF || ''} ${nameL || ''}`.trim()
				}

				return data
			})
		downloadCSV(Papa.unparse(data), 'historicRideRequests')
	}

	render() {
		const meta = {
			title: 'FirstClass | Dispatch Historic',
			description: '',
		}

		const { requestArray, historicRequestsLoaded, drivers } = this.props

		return (
			<Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper style={styles.panelWrapper}>
					<Panel
						title="Historic Ride Requests"
						rightComponent={<TEButton onClick={this.handleDownload}>Download</TEButton>}
					>
						<ReactTable
							data={requestArray}
							columns={tableColumns(drivers)}
							defaultPageSize={10}
							filterable
							defaultFilterMethod={customTableFilter}
							defaultSorted={[
								{
									id: 'requestTime',
									desc: true,
								},
							]}
							loading={!historicRequestsLoaded}
						/>
					</Panel>
				</PanelWrapper>
			</Fragment>
		)
	}
}

LiveCompany.propTypes = {}

LiveCompany.defaultProps = {}

const mapStateToProps = (state) => {
	const { riderModel } = state.Rider
	const { drivers, historicRequestsLoaded } = state.Dispatch
	const requestArray = getDispatchHistoricRequestsArray(state)

	return {
		riderModel,
		drivers,
		requestArray,
		historicRequestsLoaded,
	}
}

export default connect(
	mapStateToProps,
	{
		startWatchingDispatchHistoricRequests,
		stopWatchingDispatchHistoricRequests,
		startWatchingDrivers,
		stopWatchingDrivers,
	}
)(Radium(LiveCompany))
