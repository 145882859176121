import { colors } from './../../../config/styles';

export default {
	disabled : {
		color 	: colors.gray,
		border 	: `1px solid ${colors.gray}`,
	},
	radio:{

	}
}
