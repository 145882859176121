import { colors } from './../../config/styles';

export default {
	container : {
		position 		: 'relative',
		width 			: '90%',
		maxWidth		: 400,
		marginLeft 		: 'auto',
		marginRight 	: 'auto',
		marginTop 		: 100,
		marginBottom 	: 100,
	},
	h1 : {
		position	: 'relative',
		textAlign	: 'center',
		color 		: colors.black,
	}
}
