import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'

import settings from './../config/settings'

export const apiRequest = (method, path, data, withToken = true) => {
	const { dev, DEVELOPMENT, PRODUCTION, apiDebugging } = settings

	const { API_BASE_URL } = dev ? DEVELOPMENT : PRODUCTION
	const url = API_BASE_URL + '/' + path

	if (withToken){
		const { uid } = firebase.auth().currentUser
		return firebase.auth().currentUser.getIdToken().then((token) => {
			const headers = {
				'Content-Type'	: 'application/json',
				Authorization 	: "Basic " + settings.api_auth,
				uid,
				token
			}

			if (apiDebugging){ console.log("API REQUEST:", { method, url, headers, data }) }
			return axios({ method, url, headers, data }).then((response) => {
				if (apiDebugging){ console.log(response) }
				return response
			})
		})
	}

	if (apiDebugging){ console.log("API REQUEST:", { method, url, data }) }
	return axios({ method, url, data }).then((response) => {
		if (apiDebugging){ console.log(response) }
		return response
	})
}
