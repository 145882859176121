import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import ReactTable from 'react-table'

import {
	startWatchingRiderHistoricRequests,
	startWatchingRiderHistoricOrgRequests,
	stopWatchingRiderHistoricRequests,
	stopWatchingRiderHistoricOrgRequests,
} from './../../../actions'
import { getRiderHistoricRequestsArray } from './../../../selectors'
import { RiderKeys } from './../../../actions/types'

import TEHelmet from './../../../component/Header/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import TESearchSelectRow from './../../../component/Form/TESearchSelectRow'

import { tableColumns } from './tableConfigs'
import { customTableFilter } from './../../../helpers'
import styles from './styles'

class LiveCompany extends Component {
	componentDidMount() {
		const {
			riderModel,
			startWatchingRiderHistoricRequests,
			startWatchingRiderHistoricOrgRequests,
		} = this.props

		startWatchingRiderHistoricRequests()
		if (riderModel && riderModel.organizations) {
			startWatchingRiderHistoricOrgRequests(riderModel.organizations)
		}
	}
	componentWillUnmount() {
		const {
			stopWatchingRiderHistoricRequests,
			stopWatchingRiderHistoricOrgRequests,
		} = this.props

		stopWatchingRiderHistoricRequests()
		stopWatchingRiderHistoricOrgRequests()
	}

	render() {
		const meta = {
			title: 'FirstClass | Historic',
			description: '',
		}

		const {
			riderModel,
			requestArray,
			organizationArray,
			historicOrganizationSelectedData,
		} = this.props

		return (
			<Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper style={styles.panelWrapper}>
					<Panel
						title="Historic Ride Requests"
						rightComponent={
							<div style={styles.orgSelectorWrapper}>
								{riderModel && riderModel.organizations && (
									<TESearchSelectRow
										labelForKey="activeRequetss"
										options={organizationArray}
										rowData={historicOrganizationSelectedData}
										optionLabelPath="name"
										onChangeKey={
											RiderKeys.HISTORIC_ORGANIZATION_SELECTED_CHANGED
										}
										validationRules={['required']}
										labelStyles={{ display: 'none' }}
									/>
								)}
							</div>
						}
					>
						<ReactTable
							data={requestArray}
							columns={tableColumns}
							defaultPageSize={10}
							filterable
							defaultFilterMethod={customTableFilter}
							defaultSorted={[
								{
									id: 'requestTime',
									desc: true,
								},
							]}
						/>
					</Panel>
				</PanelWrapper>
			</Fragment>
		)
	}
}

LiveCompany.propTypes = {}

LiveCompany.defaultProps = {}

const mapStateToProps = (state) => {
	const {
		riderModel,
		organizations,
		historicOrganizationRequests,
		historicOrganizationSelectedData,
	} = state.Rider

	let requestArray
	if (historicOrganizationSelectedData.value.uid === 'Myself') {
		requestArray = getRiderHistoricRequestsArray(state)
	} else {
		requestArray = []
		for (const key in historicOrganizationRequests[
			historicOrganizationSelectedData.value.uid
		]) {
			const dataToMap =
				historicOrganizationRequests[historicOrganizationSelectedData.value.uid][key]
			dataToMap.uid = key
			requestArray.push(dataToMap)
		}
	}

	const organizationArray = []
	for (const key in organizations) {
		const dataToMap = organizations[key]
		dataToMap.uid = key
		if (dataToMap.type !== 'Livery') {
			organizationArray.push(dataToMap)
		}
	}
	organizationArray.sort((a, b) => {
		if (a.name < b.name) {
			return -1
		}
		if (a.name > b.name) {
			return 1
		}
		return 0
	})
	organizationArray.unshift({ name: 'Myself', uid: 'Myself' })

	return {
		riderModel,
		requestArray,
		organizationArray,
		historicOrganizationRequests,
		historicOrganizationSelectedData,
	}
}

export default connect(
	mapStateToProps,
	{
		startWatchingRiderHistoricRequests,
		startWatchingRiderHistoricOrgRequests,
		stopWatchingRiderHistoricRequests,
		stopWatchingRiderHistoricOrgRequests,
	}
)(Radium(LiveCompany))
