import { colors } from './../../../config/styles'

export default {
	panelWrapper : {

	},
	editPanel: {
		maxWidth 		: 900,
		marginLeft 		: 'auto',
		marginRight 	: 'auto',
	},
	saveButton: {
		// width 		: 'auto'
	},
	detailLinkWrapper : {
		border          : `1px solid ${colors.lightGray}`,
		borderRadius 	: 5,
		color           : colors.darkGray,
		backgroundColor : colors.white,
		transition 	 	: 'color 0.2s ease-in, background-color 0.2s ease-in, border 0.2s ease-in',

		':hover' : {
			color 				: colors.white,
			backgroundColor 	: colors.blue,
			border         	 	: `1px solid ${colors.blue}`,
		},
		':active' : {
			color 				: colors.white,
			backgroundColor 	: colors.blue,
			border         	 	: `1px solid ${colors.blue}`,
		},
	},
	detailLink : {
		position        : 'relative',
		width           : '100%',
		textDecoration  : 'none',
		display         : 'block',
		textAlign       : 'center',
		paddingTop 		: 5,
		paddingBottom 	: 5,
		paddingRight 	: 5,
		paddingLeft 	: 5,
		fontSize		: 14,
	},
}
