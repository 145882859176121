import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'

import TELink from './../../../component/Navigation/TELink'

import { signUpUser } from './../../../actions'
import { AuthKeys } from './../../../actions/types'

import TEHelmet from './../../../component/Header/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import TEForm from './../../../component/Form/TEForm'
import TEInputRow from './../../../component/Form/TEInputRow'
import TESubmit from './../../../component/Form/TESubmit'

import styles from './styles'

class SignUp extends Component {
	handleSignUp = (e) => {
		e.preventDefault()

		const {
			signUpUser,
			signUpAliasData,
			signUpPhoneData,
			signUpEmailData,
			signUpPasswordData,
			history,
		} = this.props

		const data = {
			signUpAliasData,
			signUpPhoneData,
			signUpEmailData,
			signUpPasswordData,
			history,
		}

		signUpUser(data)
	}

	render() {
		const meta = {
			title: 'FirstClass | Sign Up',
			description:
				'Acting as your modern-day concierge service, First Class allows you to schedule rides with reputable black car transportation companies directly from your phone, giving you First Class low rates for booking rides in advance',
		}
		const { signUpAliasData, signUpPhoneData, signUpEmailData, signUpPasswordData } = this.props

		return (
			<Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper style={styles.container}>
					<Panel containerStyle={styles.panel}>
						<TEForm style={styles.form} onSubmit={this.handleSignUp}>
							<h1 style={styles.title}>Sign Up</h1>
							<TELink
								to="/signIn"
								wrapperStyle={styles.signUpWrapper}
								style={styles.signUp}
							>
								Sign In?
							</TELink>
							<TEInputRow
								labelForKey="alias"
								rowData={signUpAliasData}
								onChangeKey={AuthKeys.SIGN_UP_ALIAS_CHANGED}
								validationRules={['required']}
								inputExtras={{ placeholder: 'Alias' }}
								labelStyles={styles.label}
								inputStyles={styles.input}
							/>
							<TEInputRow
								labelForKey="phone"
								type="tel"
								rowData={signUpPhoneData}
								onChangeKey={AuthKeys.SIGN_UP_PHONE_CHANGED}
								validationRules={['required', 'phone']}
								inputExtras={{ placeholder: 'Phone' }}
								labelStyles={styles.label}
								inputStyles={styles.input}
							/>
							<TEInputRow
								labelForKey="email"
								type="email"
								rowData={signUpEmailData}
								onChangeKey={AuthKeys.SIGN_UP_EMAIL_CHANGED}
								validationRules={['required', 'email']}
								inputExtras={{ placeholder: 'Email' }}
								labelStyles={styles.label}
								inputStyles={styles.input}
							/>
							<TEInputRow
								labelForKey="password"
								type="password"
								rowData={signUpPasswordData}
								onChangeKey={AuthKeys.SIGN_UP_PASSWORD_CHANGED}
								validationRules={['required', 'password']}
								inputExtras={{ placeholder: 'Password' }}
								labelStyles={styles.label}
								inputStyles={styles.input}
							/>
							<div style={styles.buttonContainer}>
								<TESubmit style={styles.button}>Sign Up</TESubmit>
							</div>
						</TEForm>
					</Panel>
				</PanelWrapper>
			</Fragment>
		)
	}
}

SignUp.propTypes = {}

SignUp.defaultProps = {}

const mapStateToProps = (state) => {
	const { signUpAliasData, signUpPhoneData, signUpEmailData, signUpPasswordData } = state.Auth

	return {
		signUpAliasData,
		signUpPhoneData,
		signUpEmailData,
		signUpPasswordData,
	}
}
export default connect(
	mapStateToProps,
	{ signUpUser }
)(Radium(SignUp))
