//import { colors } from './../../../config/styles';

export default {
	form:{
		position  	: 'relative',
		width     	: '100%',
		marginLeft 	: 'auto',
		marginRight : 'auto',
	}
}
