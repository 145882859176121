import { createSelector } from 'reselect'

export const getDispatchLiveRequests  				= state => state.Dispatch.liveRequests
export const getDispatchHistoricRequests  			= state => state.Dispatch.historicRequests

export const getDispatchLiveRequestsArray = createSelector(
	getDispatchLiveRequests,
	(requests) => {
		const requestArray = []
		for (const key in requests){
			const dataToMap = requests[key]
			dataToMap.uid = key
			requestArray.push(dataToMap)
		}
		return requestArray
	}
)
export const getDispatchHistoricRequestsArray = createSelector(
	getDispatchHistoricRequests,
	(requests) => {
		const requestArray = []
		for (const key in requests){
			const dataToMap = requests[key]
			dataToMap.uid = key
			requestArray.push(dataToMap)
		}
		return requestArray
	}
)
