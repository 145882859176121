import { colors } from './../../../config/styles'
//import images from './../../../config/images'

export default {
	container : {
		alignItems 		: 'center',
		justifyContent	: 'center',
		minHeight 		: 'calc(100vh - 160px)',
	},
	panel : {
		maxWidth 		: 700,
		borderRadius 	: 5,
		paddingTop 		: 60,
		paddingBottom 	: 60,
		paddingRight 	: 60,
		paddingLeft 	: 60,

		'@media (max-width: 600px)' : {
			paddingTop 		: 30,
			paddingBottom 	: 30,
			paddingRight 	: 30,
			paddingLeft 	: 30,
		},
	},
	form : {
		backgroundColor     : colors.blue,
		paddingTop 			: 40,
		paddingBottom 		: 40,
		paddingRight 		: 40,
		paddingLeft 		: 40,
		maxWidth 			: 600,
		borderRadius 		: 5,

		'@media (max-width: 600px)' : {
			paddingTop 			: 30,
			paddingBottom 		: 30,
			paddingRight 		: 30,
			paddingLeft 		: 30,
		},
		'@media (max-width: 500px)' : {
			paddingTop 			: 20,
			paddingBottom 		: 20,
			paddingRight 		: 20,
			paddingLeft 		: 20,
		},
	},
	title : {
		color               : colors.white,
		fontSize            : 44,
		textAlign           : 'center',
		marginTop           : 0,
		marginBottom        : 20,
	},
	signUpWrapper: {
		width 			: '100%',
		textAlign 		: 'center',
		marginBottom 	: 20,
	},
	signUp: {
		textDecoration  : 'underline',
		color           : colors.white,
		fontSize        : 16,
	},
	label : {
		display : 'none',
	},
	input : {
		border  			: 'none',
		paddingTop 			: 10,
		paddingBottom 		: 10,
		paddingRight 		: 10,
		paddingLeft 		: 10,

		':hover' : {
			border      : 'none',
			boxShadow   : 'none',
		},
		':active' : {
			border      : 'none',
			boxShadow   : 'none',
		}
	},
	buttonContainer : {
		display 			: 'flex',
		justifyContent 		: 'space-between',
		width 				: '100%',

		'@media (max-width: 450px)' : {
			justifyContent 		: 'center',
			flexDirection 		: 'column',
		},
	},
	forgotWrapper : {

		'@media (max-width: 450px)' : {
			marginBottom 	: 10,
			textAlign 		: 'center',
			display 		: 'block',
		},
	},
	forgot : {
		textDecoration  : 'underline',
		color           : colors.white,
		fontSize        : 18,
	},
	button : {
		width 				: 'auto',
		paddingTop 			: 10,
		paddingBottom 		: 10,
		paddingRight 		: 50,
		paddingLeft 		: 50,

		borderTop			: `1px solid ${colors.white}`,
		borderRight			: `1px solid ${colors.white}`,
		borderBottom		: `1px solid ${colors.white}`,
		borderLeft			: `1px solid ${colors.white}`,

		':hover' : {
			borderTop			: `1px solid ${colors.white}`,
			borderRight			: `1px solid ${colors.white}`,
			borderBottom		: `1px solid ${colors.white}`,
			borderLeft			: `1px solid ${colors.white}`,
		},
		':active' : {
			borderTop			: `1px solid ${colors.white}`,
			borderRight			: `1px solid ${colors.white}`,
			borderBottom		: `1px solid ${colors.white}`,
			borderLeft			: `1px solid ${colors.white}`,
		}
	},
}
