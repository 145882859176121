import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import tzlookup from 'tz-lookup'

import {
	startWatchingDispatchLiveRequest,
	stopWatchingDispatchLiveRequest,
	startWatchingDrivers,
	stopWatchingDrivers,
	showDispatchEditRequestPopup,
	showDispatchUpdateStatusesPopup,
	showDispatchCompeteRequestPopup,
	dispatchCancelRequestNoFee,
	dispatchCancelRequestNoShow,
	dispatchAssignChauffeur,
	dispatchRemoveChauffeur,
} from './../../../actions'
import { DispatchKeys } from './../../../actions/types'

import TEHelmet from './../../../component/Header/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import TEGoogleMap from './../../../component/Map/TEGoogleMap'
import TEGoogleMarker from './../../../component/Map/TEGoogleMarker'

import TESearchSelectRow from './../../../component/Form/TESearchSelectRow'
import TEButton from './../../../component/Form/TEButton'

import CompleteRequestPopup from './CompleteRequestPopup'
import EditRequestPopup from './EditRequestPopup'
import EditStatusesPopup from './EditStatusesPopup'

import { convertToDateTimeShort24 } from './../../../helpers'
import images from './../../../config/images'
import styles from './styles'

class LivDetailed extends Component {
	componentDidMount() {
		const {
			match,
			riderModel,
			startWatchingDispatchLiveRequest,
			startWatchingDrivers,
		} = this.props
		const { uid } = match.params

		startWatchingDispatchLiveRequest(uid)
		startWatchingDrivers(riderModel.dispatchUID)
	}
	componentWillUnmount() {
		const { stopWatchingDispatchLiveRequest, stopWatchingDrivers } = this.props

		stopWatchingDispatchLiveRequest()
		stopWatchingDrivers()
	}

	handleShowEditRequest = () => {
		this.props.showDispatchEditRequestPopup()
	}
	handleShowUpdateStatuses = () => {
		this.props.showDispatchUpdateStatusesPopup()
	}
	handleCancelRequest = () => {
		const { match, dispatchCancelRequestNoFee, history } = this.props
		const { uid } = match.params

		dispatchCancelRequestNoFee(uid, history)
	}
	handleMarkAsNoShow = () => {
		const { match, dispatchCancelRequestNoShow, history } = this.props
		const { uid } = match.params

		dispatchCancelRequestNoShow(uid, history)
	}
	handleCompleteRequest = () => {
		const { showDispatchCompeteRequestPopup } = this.props
		showDispatchCompeteRequestPopup()
	}
	handleAssignChauffuer = () => {
		const { match, dispatchAssignChauffeur, riderModel, selectedDriverData } = this.props
		const { uid } = match.params

		const data = {
			uid,
			selectedDriverData,
			riderModel,
		}

		dispatchAssignChauffeur(data)
	}
	handleRemoveChauffuer = () => {
		const { match, dispatchRemoveChauffeur, liveRequest } = this.props
		const { uid } = match.params

		const data = {
			uid,
			liveRequest,
		}

		dispatchRemoveChauffeur(data)
	}
	render() {
		const meta = {
			title: 'FirstClass | Live Details',
			description: '',
		}

		const {
			match,
			driverArray,
			selectedDriverData,
			liveRequest,
			liveRequestLoaded,
			requestRiderModel,
			// requestRiderLocation,
			driverModel,
			driverLocation,
			organization,
			editRequestsPopupVisible,
			editStatusesPopupVisible,
			completeReqeustPopupVisible,
			history,
		} = this.props
		const { uid } = match.params

		if (!liveRequest) {
			if (liveRequestLoaded) {
				return <h1 style={styles.errorMessage}>Error Loading Request...</h1>
			} else {
				return null
			}
		}

		const {
			startLat,
			startLon,
			endLat,
			endLon,
			organizationId,
			organizationInformation,
			startAddress,
			endAddress,
			createdAt,
			requestTime,
			status,
			carType,
			distance,
			tripEta,
			driverEta,
			pets,
			riderNote,
			pricing,
			statusTimes,
		} = liveRequest

		return (
			<Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper>
					<Panel
						title="Live Ride Requests Details"
						rightComponent={
							<div style={styles.actionsContainer}>
								{/*<TEButton onClick={this.handleShowEditRequest} style={styles.actionButton}>Edit Request</TEButton>*/}
								<TEButton
									onClick={this.handleShowUpdateStatuses}
									style={styles.actionButton}
								>
									Edit Timestamps
								</TEButton>
							</div>
						}
					>
						<div style={styles.detailsContainer}>
							<div style={styles.mapWrapper}>
								<TEGoogleMap
									ref="map"
									defaultZoom={10}
									defaultCenter={{
										lat: (startLat + endLat) / 2,
										lng: (startLon + endLon) / 2,
									}}
									showTrafficButton
								>
									{startLat && endLon && (
										<TEGoogleMarker
											position={{
												lat: startLat,
												lng: startLon,
											}}
											icon={images.startMapIcon}
										/>
									)}
									{endLat && endLon && (
										<TEGoogleMarker
											position={{
												lat: endLat,
												lng: endLon,
											}}
											icon={images.endMapIcon}
										/>
									)}
									{driverLocation && driverLocation.lat && driverLocation.lng && (
										<TEGoogleMarker
											position={{
												lat: driverLocation.lat,
												lng: driverLocation.lng,
											}}
											icon={images.driverMapIcon}
										/>
									)}
								</TEGoogleMap>
							</div>
							<div style={styles.leftColumn}>
								<div style={styles.tableWrapper}>
									<table style={styles.table}>
										<caption style={styles.caption}>Client Information</caption>
										<tbody>
											<tr style={styles.tr}>
												<th style={styles.th}>Alias</th>
												{organizationId &&
												organizationInformation &&
												organizationInformation.alias ? (
													<td style={styles.td}>
														{organizationInformation.alias}
													</td>
												) : (
													<td style={styles.td}>
														{requestRiderModel &&
															requestRiderModel.profile &&
															requestRiderModel.profile.alias}
													</td>
												)}
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Phone</th>
												{organizationId &&
												organizationInformation &&
												organizationInformation.phone ? (
													<td style={styles.td}>
														{organizationInformation.phone}
													</td>
												) : (
													<td style={styles.td}>
														{requestRiderModel &&
															requestRiderModel.profile &&
															requestRiderModel.profile.phone}
													</td>
												)}
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Email</th>
												{organizationId &&
												organizationInformation &&
												organizationInformation.email ? (
													<td style={styles.td}>
														{organizationInformation.email}
													</td>
												) : (
													<td style={styles.td}>
														{requestRiderModel &&
															requestRiderModel.profile &&
															requestRiderModel.profile.email}
													</td>
												)}
											</tr>
										</tbody>
									</table>
								</div>
								<div style={styles.tableWrapper}>
									<table style={styles.table}>
										<caption style={styles.caption}>
											Chauffeur Information
										</caption>
										<tbody>
											<tr style={styles.tr}>
												<th style={styles.th}>Name</th>
												<td style={styles.td}>
													{driverModel &&
														driverModel.profile &&
														driverModel.profile.nameF}{' '}
													{driverModel &&
														driverModel.profile &&
														driverModel.profile.nameL}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Active Vehicle</th>
												<td style={styles.td}>
													{driverModel &&
														driverModel.activeVehicle &&
														driverModel.activeVehicle.carYear}{' '}
													{driverModel &&
														driverModel.activeVehicle &&
														driverModel.activeVehicle.carMake}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Vehicle Type</th>
												{driverModel &&
													driverModel.activeVehicle &&
													driverModel.activeVehicle.carCapacity <= 3 && (
														<td style={styles.td}>Sedan</td>
													)}
												{driverModel &&
													driverModel.activeVehicle &&
													driverModel.activeVehicle.carCapacity > 3 &&
													driverModel.activeVehicle.carCapacity <= 6 && (
														<td style={styles.td}>SUV</td>
													)}
												{driverModel &&
													driverModel.activeVehicle &&
													driverModel.activeVehicle.carCapacity > 6 && (
														<td style={styles.td}>
															Capacity:{' '}
															{driverModel.activeVehicle.carCapacity}
														</td>
													)}
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>License Plate</th>
												<td style={styles.td}>
													{driverModel &&
														driverModel.activeVehicle &&
														driverModel.activeVehicle.carLicense}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Phone Number</th>
												<td style={styles.td}>
													{driverModel &&
														driverModel.profile &&
														driverModel.profile.phone}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Chauffeur Id</th>
												<td style={styles.td}>
													{driverModel &&
														driverModel.profile &&
														driverModel.profile.chauffeurId}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Vehicle Id</th>
												<td style={styles.td}>
													{driverModel &&
														driverModel.profile &&
														driverModel.profile.vehicleId}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div style={styles.tableWrapper}>
									<table style={styles.table}>
										<caption style={styles.caption}>Pickup Location</caption>
										<tbody>
											<tr style={styles.tr}>
												<th style={styles.th}>Start Full</th>
												<td style={styles.td}>
													{startAddress && startAddress.startAddrFull}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Start Addr</th>
												<td style={styles.td}>
													{startAddress && startAddress.startAddr}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Start City</th>
												<td style={styles.td}>
													{startAddress && startAddress.startCity}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Start State</th>
												<td style={styles.td}>
													{startAddress && startAddress.startState}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Start Zip</th>
												<td style={styles.td}>
													{startAddress && startAddress.startZip}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div style={styles.tableWrapper}>
									<table style={styles.table}>
										<caption style={styles.caption}>Dropoff Location</caption>
										<tbody>
											<tr style={styles.tr}>
												<th style={styles.th}>End Full</th>
												<td style={styles.td}>
													{endAddress && endAddress.endAddrFull}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>End Addr</th>
												<td style={styles.td}>
													{endAddress && endAddress.endAddr}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>End City</th>
												<td style={styles.td}>
													{endAddress && endAddress.endCity}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>End State</th>
												<td style={styles.td}>
													{endAddress && endAddress.endState}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>End Zip</th>
												<td style={styles.td}>
													{endAddress && endAddress.endZip}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div style={styles.rightColumn}>
								<div style={styles.tableWrapper}>
									<table style={styles.table}>
										<caption style={styles.caption}>Trip Details</caption>
										<tbody>
											<tr style={styles.tr}>
												<th style={styles.th}>Date Created</th>
												<td style={styles.td}>
													{convertToDateTimeShort24(
														createdAt * 1000,
														tzlookup(startLat, startLon)
													)}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Requested Pick Up</th>
												<td style={styles.td}>
													{convertToDateTimeShort24(
														requestTime * 1000,
														tzlookup(startLat, startLon)
													)}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Current Status</th>
												{status === 1 && (
													<td style={styles.td}>Unaccepted</td>
												)}
												{status === 2 && (
													<td style={styles.td}>Accepted</td>
												)}
												{status === 3 && (
													<td style={styles.td}>En Route</td>
												)}
												{status === 4 && (
													<td style={styles.td}>On Location</td>
												)}
												{status === 5 && (
													<td style={styles.td}>Picked Up</td>
												)}
												{status === 6 && (
													<td style={styles.td}>Dropped Off</td>
												)}
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Vehicle Type</th>
												<td style={styles.td}>{carType}</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Distance</th>
												<td style={styles.td}>
													{Math.round(distance * 0.000621371 * 10) / 10}{' '}
													miles
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Trip Duration</th>
												<td style={styles.td}>
													{Math.round(tripEta / 60)} minutes
												</td>
											</tr>
											<tr style={styles.tr} ng-if="request.driverEta != null">
												<th style={styles.th}>Driver ETA</th>
												<td style={styles.td}>
													{convertToDateTimeShort24(
														driverEta * 1000,
														tzlookup(startLat, startLon)
													)}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Pets</th>
												<td style={styles.td}>{pets ? 'Yes' : 'No'}</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Rider Note</th>
												<td style={styles.td}>{riderNote}</td>
											</tr>
											{organizationId && organization && (
												<tr>
													<th style={styles.th}>Providing By</th>
													<td style={styles.td}>{organization.name}</td>
												</tr>
											)}
											<tr style={styles.tr}>
												<th style={styles.th}>Total Fare</th>
												<td style={styles.td}>
													${pricing && pricing.finalPrice}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div style={styles.tableWrapper}>
									<table style={styles.table}>
										<caption style={styles.caption}>Trip Timestamps</caption>
										<thead>
											<tr style={styles.tr}>
												<th style={styles.th}>Status</th>
												<th style={styles.th}>Timestamp</th>
											</tr>
										</thead>
										<tbody>
											<tr style={styles.tr}>
												<td style={styles.td}>Date Created</td>
												<td style={styles.td}>
													{convertToDateTimeShort24(
														createdAt * 1000,
														tzlookup(startLat, startLon)
													)}
												</td>
											</tr>
											<tr style={styles.tr}>
												<td style={styles.td}>Requested Pick Up</td>
												<td style={styles.td}>
													{convertToDateTimeShort24(
														requestTime * 1000,
														tzlookup(startLat, startLon)
													)}
												</td>
											</tr>
											<tr style={styles.tr}>
												<td style={styles.td}>On the way to location</td>
												<td style={styles.td}>
													{statusTimes &&
														convertToDateTimeShort24(
															statusTimes.startingPickup * 1000,
															tzlookup(startLat, startLon)
														)}
												</td>
											</tr>
											<tr style={styles.tr}>
												<td style={styles.td}>Chauffeur On Location</td>
												<td style={styles.td}>
													{statusTimes &&
														convertToDateTimeShort24(
															statusTimes.onLocation * 1000,
															tzlookup(startLat, startLon)
														)}
												</td>
											</tr>
											<tr style={styles.tr}>
												<td style={styles.td}>Passenger In Car</td>
												<td style={styles.td}>
													{statusTimes &&
														convertToDateTimeShort24(
															statusTimes.pickedUp * 1000,
															tzlookup(startLat, startLon)
														)}
												</td>
											</tr>
											<tr style={styles.tr}>
												<td style={styles.td}>Dropped Off Passenger</td>
												<td style={styles.td}>
													{statusTimes &&
														convertToDateTimeShort24(
															statusTimes.droppedOff * 1000,
															tzlookup(startLat, startLon)
														)}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</Panel>
					<Panel title="Chauffeur Assignment" size="half">
						{liveRequest.driverId && (
							<Fragment>
								<div style={styles.tableWrapper}>
									<table style={styles.table}>
										<tbody>
											<tr style={styles.tr}>
												<th style={styles.th}>Name</th>
												<td style={styles.td}>
													{driverModel &&
														driverModel.profile &&
														driverModel.profile.nameF}{' '}
													{driverModel &&
														driverModel.profile &&
														driverModel.profile.nameL}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Active Vehicle</th>
												<td style={styles.td}>
													{driverModel &&
														driverModel.activeVehicle &&
														driverModel.activeVehicle.carYear}{' '}
													{driverModel &&
														driverModel.activeVehicle &&
														driverModel.activeVehicle.carMake}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Vehicle Type</th>
												<td style={styles.td}>
													{driverModel &&
														driverModel.activeVehicle &&
														driverModel.activeVehicle.carCapacity <=
															3 &&
														'Sedan'}
													{driverModel &&
														driverModel.activeVehicle &&
														driverModel.activeVehicle.carCapacity > 3 &&
														driverModel.activeVehicle.carCapacity <=
															6 &&
														'SUV'}
													{driverModel &&
														driverModel.activeVehicle &&
														driverModel.activeVehicle.carCapacity > 6 &&
														`Capacity: {driverModel.activeVehicle.carCapacity}`}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>License Plate</th>
												<td style={styles.td}>
													{driverModel &&
														driverModel.activeVehicle &&
														driverModel.activeVehicle.carLicense}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Phone Number</th>
												<td style={styles.td}>
													{driverModel &&
														driverModel.profile &&
														driverModel.profile.phone}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Chauffeur Id</th>
												<td style={styles.td}>
													{driverModel &&
														driverModel.profile &&
														driverModel.profile.chauffeurId}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Vehicle Id</th>
												<td style={styles.td}>
													{driverModel &&
														driverModel.profile &&
														driverModel.profile.vehicleId}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<TEButton onClick={this.handleRemoveChauffuer}>
									Remove Chauffuer
								</TEButton>
							</Fragment>
						)}
						{!liveRequest.driverId && (
							<Fragment>
								<TESearchSelectRow
									labelForKey="selectedDriver"
									options={driverArray}
									rowData={selectedDriverData}
									optionLabelPath="name"
									onChangeKey={DispatchKeys.SELECTED_DRIVER_CHANGED}
									validationRules={['required']}
								/>
								<div style={styles.tableWrapper}>
									<table style={styles.table}>
										<tbody>
											<tr style={styles.tr}>
												<th style={styles.th}>Name</th>
												<td style={styles.td}>
													{selectedDriverData.value &&
														selectedDriverData.value.profile &&
														selectedDriverData.value.profile.nameF}{' '}
													{selectedDriverData.value &&
														selectedDriverData.value.profile &&
														selectedDriverData.value.profile.nameL}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Active Vehicle</th>
												<td style={styles.td}>
													{selectedDriverData.value &&
														selectedDriverData.value.activeVehicle &&
														selectedDriverData.value.activeVehicle
															.carYear}{' '}
													{selectedDriverData.value &&
														selectedDriverData.value.activeVehicle &&
														selectedDriverData.value.activeVehicle
															.carMake}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Vehicle Type</th>
												<td style={styles.td}>
													{selectedDriverData.value &&
														selectedDriverData.value.activeVehicle &&
														selectedDriverData.value.activeVehicle
															.carCapacity <= 3 &&
														'Sedan'}
													{selectedDriverData.value &&
														selectedDriverData.value.activeVehicle &&
														selectedDriverData.value.activeVehicle
															.carCapacity > 3 &&
														selectedDriverData.value.activeVehicle
															.carCapacity <= 6 &&
														'SUV'}
													{selectedDriverData.value &&
														selectedDriverData.value.activeVehicle &&
														selectedDriverData.value.activeVehicle
															.carCapacity > 6 &&
														`Capacity: ${
															selectedDriverData.value.activeVehicle
																.carCapacity
														}`}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>License Plate</th>
												<td style={styles.td}>
													{selectedDriverData.value &&
														selectedDriverData.value.activeVehicle &&
														selectedDriverData.value.activeVehicle
															.carLicense}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Phone Number</th>
												<td style={styles.td}>
													{selectedDriverData.value &&
														selectedDriverData.value.profile &&
														selectedDriverData.value.profile.phone}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Account Status</th>
												<td style={styles.td}>
													{selectedDriverData.value &&
														(selectedDriverData.value &&
														selectedDriverData.value.statuses &&
														selectedDriverData.value.statuses.status ===
															-1
															? 'Not Activated'
															: 'Activated')}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Chauffeur Id</th>
												<td style={styles.td}>
													{selectedDriverData.value &&
														selectedDriverData.value.profile &&
														selectedDriverData.value.profile
															.chauffeurId}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Vehicle Id</th>
												<td style={styles.td}>
													{selectedDriverData.value &&
														selectedDriverData.value.profile &&
														selectedDriverData.value.profile.vehicleId}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<TEButton onClick={this.handleAssignChauffuer}>
									Assign Chauffuer
								</TEButton>
							</Fragment>
						)}
					</Panel>
					<Panel title="Complete Request" size="half">
						<div style={styles.completeRequestWrapper}>
							<h3 style={styles.completeRequestTitle}>Cancel Request - No Fee</h3>
							<p style={styles.completeRequestMessage}>
								Complete request and make it as cancelled. The rider will not be
								charged.
							</p>
							<TEButton
								onClick={this.handleCancelRequest}
								style={styles.completeRequestButton}
							>
								Cancel Request
							</TEButton>
						</div>
						<div style={styles.completeRequestWrapper}>
							<h3 style={styles.completeRequestTitle}>No Show - Full Fare</h3>
							<p style={styles.completeRequestMessage}>
								Complete request and make it as a no show. The rider will be charged
								full price for the request.
							</p>
							<TEButton
								onClick={this.handleMarkAsNoShow}
								style={styles.completeRequestButton}
							>
								Mark As No Show
							</TEButton>
						</div>
						<div style={styles.completeRequestWrapper}>
							<h3 style={styles.completeRequestTitle}>Complete Request</h3>
							<p style={styles.completeRequestMessage}>
								Complete request as normal. An additional fee can be applied if
								necessary.
							</p>
							<TEButton
								onClick={this.handleCompleteRequest}
								style={styles.completeRequestButton}
							>
								Complete Request
							</TEButton>
						</div>
					</Panel>
				</PanelWrapper>
				<CompleteRequestPopup
					visible={completeReqeustPopupVisible}
					uid={uid}
					liveRequest={liveRequest}
					history={history}
				/>
				<EditRequestPopup visible={editRequestsPopupVisible} uid={uid} />
				<EditStatusesPopup
					visible={editStatusesPopupVisible}
					uid={uid}
					liveRequest={liveRequest}
				/>
			</Fragment>
		)
	}
}

LivDetailed.propTypes = {}

LivDetailed.defaultProps = {}

const mapStateToProps = (state) => {
	const {
		drivers,
		selectedDriverData,
		liveRequest,
		liveRequestLoaded,
		requestRiderModel,
		requestRiderLocation,
		driverModel,
		driverLocation,
		organization,
		editRequestsPopupVisible,
		editStatusesPopupVisible,
		completeReqeustPopupVisible,
	} = state.Dispatch
	const { riderModel } = state.Rider

	const driverArray = []
	for (const key in drivers) {
		const dataToMap = drivers[key]
		dataToMap.uid = key
		dataToMap.name = `${dataToMap.profile && dataToMap.profile.nameF} ${dataToMap.profile &&
			dataToMap.profile.nameL}`.trim()
		driverArray.push(dataToMap)
	}
	driverArray.sort((a, b) => {
		if (a.name < b.name) {
			return -1
		}
		if (a.name > b.name) {
			return 1
		}
		return 0
	})

	return {
		riderModel,
		driverArray,
		selectedDriverData,
		liveRequest,
		liveRequestLoaded,
		requestRiderModel,
		requestRiderLocation,
		driverModel,
		driverLocation,
		organization,
		editRequestsPopupVisible,
		editStatusesPopupVisible,
		completeReqeustPopupVisible,
	}
}

export default connect(
	mapStateToProps,
	{
		startWatchingDispatchLiveRequest,
		stopWatchingDispatchLiveRequest,
		startWatchingDrivers,
		stopWatchingDrivers,
		showDispatchEditRequestPopup,
		showDispatchUpdateStatusesPopup,
		showDispatchCompeteRequestPopup,
		dispatchCancelRequestNoFee,
		dispatchCancelRequestNoShow,
		dispatchAssignChauffeur,
		dispatchRemoveChauffeur,
	}
)(Radium(LivDetailed))
