import React, { Component } from 'react';
import Radium from 'radium';
import styles from './styles';

class NotFound extends Component {
	render() {
		return (
			<div style={styles.container} className="not-found">
				<h1 style={styles.h1}>Page Not Found</h1>
			</div>
		)
	}
}

NotFound.propTypes = {}

NotFound.defaultProps = {};

export default Radium(NotFound);
