import { colors } from './../../../config/styles';

export default {
	container : {
		width 				: '100%',
		backgroundColor 	: colors.white,
		display				: 'flex',
		alignItems 			: 'center',
		justifyContent		: 'space-between',
		paddingBottom 		: 10,
		marginBottom		: 20,
		borderBottom 		: `1px solid ${colors.lightGray}`,

		'@media (max-width: 500px)' : {
			flexDirection 		: 'column',
			justifyContent	 	: 'center',
		}
	},
	title : {
		textAlign		: 'left',
		fontSize        : 30,
		marginTop		: 0,
		marginBottom 	: 0,
		color  			: colors.blue,
		fontWeight 		: 300,
		lineHeight 		: 1,

		'@media (max-width: 900px)' : {
			fontSize        : 24,
		},
		'@media (max-width: 700px)' : {
			fontSize        : 20,
		},
		'@media (max-width: 500px)' : {
			fontSize    	: 18,
			width 			: '100%',
			textAlign		: 'center',
		}
	},
	subtitle : {
		fontSize 			: 18,
		fontWeight 			: 300,
		marginBottom 		: 0,
		marginTop 			: 0,

		'@media (max-width: 500px)' : {
			width 		: '100%',
			textAlign	: 'center',
			marginTop 	: 15,
		}
	},
	rightComponentContainer : {
		width 				: 'auto',

		'@media (max-width: 500px)' : {
			width 		: '100%',
			marginTop 	: 15,
		}
	}
}
