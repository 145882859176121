import { colors } from './../../../config/styles'

export default {
	panel: {
		overflow: 'visible',
		borderRadius: 20,
	},
	stepTitle: {
		marginTop: 40,
		marginBottom: 0,
		fontSize: 26,
		textAlign: 'center',
		fontWeight: 500,
		lightHeight: 1.2,
	},
	stepSubtitle: {
		marginTop: 10,
		marginBottom: 0,
		fontSize: 16,
		lineHeight: 1.5,
		fontWeight: 300,
		textAlign: 'center',
	},
	stepContentWrapper: {
		maxWidth: 900,
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingTop: 30,
		paddingBottom: 30,
	},
	mapWrapper: {
		height: 350,
		width: '100%',
		// maxWidth 		: 800,
		// marginLeft 		: 'auto',
		// marginRight 	: 'auto'
		marginBottom: 30,
	},
	searchWrapper: {
		display: 'flex',
	},
	searchBox: {
		width: '100%',
	},
	searchClearButton: {
		width: 'auto',
		marginLeft: 10,
		whiteSpace: 'nowrap',
	},
	addressContainer: {
		paddingTop: 15,
		paddingLeft: 15,
		paddingRight: 15,
		paddingBottom: 15,
		maxWidth: 600,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	addressWrapper: {
		marginBottom: 30,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	addressText: {},
	addressTitle: {
		fontSize: 20,
		fontWeight: 500,
		width: '100%',
		display: 'block',
	},
	addressValue: {
		fontSize: 16,
		fontWeight: 300,
		lineHeight: 1.5,
	},

	//Vehicle Type
	vehicleTypeContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	vehicleTypeImage: {
		display: 'block',
		width: '100%',
		maxWidth: 600,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginBottom: 30,
		marginTop: 30,
	},
	vehicleTypeDetailContainer: {
		maxWidth: 300,
		paddingLeft: 50,
		paddingRight: 50,
	},
	vehicleTypeTitle: {
		display: 'block',
		textAlign: 'center',
		fontSize: 24,
		fontWeight: 300,
		marginBottom: 10,
	},
	vehicleTypeValue: {
		display: 'block',
		textAlign: 'center',
		fontSize: 18,
		fontWeight: 300,
	},

	//Pick Up Time
	pickupTimeMessage: {
		fontSize: 16,
		marginBottom: 15,
		display: 'block',
	},
	pickupTimeNote: {
		fontSize: 14,
		display: 'block',
	},

	//Payment
	tableWrapper: {
		width: '100%',
		overflowX: 'auto',
		marginBottom: 30,
		maxWidth: 600,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	table: {
		width: '100%',
		borderCollapse: 'collapse',
	},
	tr: {
		borderBottom: `1px solid ${colors.lightGray}`,
	},
	th: {
		textAlign: 'left',
		fontWeight: 500,
		fontSize: 16,
		paddingLeft: 5,
		paddingTop: 5,
		paddingBottom: 5,
	},
	td: {
		textAlign: 'right',
		fontWeight: 300,
		fontSize: 16,
		paddingRight: 5,
		paddingTop: 5,
		paddingBottom: 5,
	},
	promoInputWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
	},
	submitPromoButton: {
		width: 'auto',
	},
	organizationProvidingMessage: {
		display: 'block',
		textAlign: 'center',
		fontSize: 18,
		fontWeight: 300,
		maxWidth: 500,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	selectedCardLabelWrapper: {
		width: '100%',
	},
	selecetedCardLabel: {
		width: '100%',
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
	},
	selectedCardCheckedLabel: {},
	cardWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	cardType: {
		display: 'block',
		fontSize: 16,
	},
	cardExpration: {
		display: 'block',
		fontSize: 16,
		textAlign: 'center',
	},
	cardLast4: {
		display: 'block',
		fontSize: 16,
		textAlign: 'right',
	},
}
