import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import tzlookup from 'tz-lookup'

import {
	startWatchingRiderHistoricRequest,
	stopWatchingRiderHistoricRequest,
} from './../../../actions'

import TEHelmet from './../../../component/Header/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import TEGoogleMap from './../../../component/Map/TEGoogleMap'
import TEGoogleMarker from './../../../component/Map/TEGoogleMarker'

import { convertToDateTimeShort } from './../../../helpers'
import images from './../../../config/images'
import styles from './styles'

class HistoricDetailed extends Component {
	componentDidMount() {
		const { match, startWatchingRiderHistoricRequest } = this.props
		const { uid } = match.params

		startWatchingRiderHistoricRequest(uid)
	}
	componentWillUnmount() {
		const { stopWatchingRiderHistoricRequest } = this.props

		stopWatchingRiderHistoricRequest()
	}

	render() {
		const meta = {
			title: 'FirstClass | Historic Details',
			description: '',
		}

		const {
			historicRequest,
			historicRequestLoaded,
			requestRiderModel,
			// requestRiderLocation,
			driverModel,
			organization,
		} = this.props

		if (!historicRequest) {
			if (historicRequestLoaded) {
				return <h1 style={styles.errorMessage}>Error Loading Request...</h1>
			} else {
				return null
			}
		}
		const {
			startLat,
			startLon,
			endLat,
			endLon,
			organizationId,
			organizationInformation,
			startAddress,
			endAddress,
			createdAt,
			requestTime,
			status,
			carType,
			distance,
			tripEta,
			driverEta,
			pets,
			riderNote,
			pricing,
			statusTimes,
		} = historicRequest

		return (
			<Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper>
					<Panel title="Historic Ride Requests Details">
						<div style={styles.detailsContainer}>
							<div style={styles.mapWrapper}>
								<TEGoogleMap
									ref="map"
									defaultZoom={10}
									defaultCenter={{
										lat: (startLat + endLat) / 2,
										lng: (startLon + endLon) / 2,
									}}
									showTrafficButton
								>
									{startLat && endLon && (
										<TEGoogleMarker
											position={{
												lat: startLat,
												lng: startLon,
											}}
											icon={images.startMapIcon}
										/>
									)}
									{endLat && endLon && (
										<TEGoogleMarker
											position={{
												lat: endLat,
												lng: endLon,
											}}
											icon={images.endMapIcon}
										/>
									)}
								</TEGoogleMap>
							</div>
							<div style={styles.leftColumn}>
								<div style={styles.tableWrapper}>
									<table style={styles.table}>
										<caption style={styles.caption}>Client Information</caption>
										<tbody>
											<tr style={styles.tr}>
												<th style={styles.th}>Alias</th>
												{organizationId &&
												organizationInformation &&
												organizationInformation.alias ? (
													<td style={styles.td}>
														{organizationInformation.alias}
													</td>
												) : (
													<td style={styles.td}>
														{requestRiderModel &&
															requestRiderModel.profile &&
															requestRiderModel.profile.alias}
													</td>
												)}
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Phone</th>
												{organizationId &&
												organizationInformation &&
												organizationInformation.phone ? (
													<td style={styles.td}>
														{organizationInformation.phone}
													</td>
												) : (
													<td style={styles.td}>
														{requestRiderModel &&
															requestRiderModel.profile &&
															requestRiderModel.profile.phone}
													</td>
												)}
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Email</th>
												{organizationId &&
												organizationInformation &&
												organizationInformation.email ? (
													<td style={styles.td}>
														{organizationInformation.email}
													</td>
												) : (
													<td style={styles.td}>
														{requestRiderModel &&
															requestRiderModel.profile &&
															requestRiderModel.profile.email}
													</td>
												)}
											</tr>
										</tbody>
									</table>
								</div>
								<div style={styles.tableWrapper}>
									<table style={styles.table}>
										<caption style={styles.caption}>
											Chauffeur Information
										</caption>
										<tbody>
											<tr style={styles.tr}>
												<th style={styles.th}>Name</th>
												<td style={styles.td}>
													{driverModel &&
														driverModel.profile &&
														driverModel.profile.nameF}{' '}
													{driverModel &&
														driverModel.profile &&
														driverModel.profile.nameL}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Active Vehicle</th>
												<td style={styles.td}>
													{driverModel &&
														driverModel.activeVehicle &&
														driverModel.activeVehicle.carYear}{' '}
													{driverModel &&
														driverModel.activeVehicle &&
														driverModel.activeVehicle.carMake}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Vehicle Type</th>
												{driverModel &&
													driverModel.activeVehicle &&
													driverModel.activeVehicle.carCapacity <= 3 && (
														<td style={styles.td}>Sedan</td>
													)}
												{driverModel &&
													driverModel.activeVehicle &&
													driverModel.activeVehicle.carCapacity > 3 &&
													driverModel.activeVehicle.carCapacity <= 6 && (
														<td style={styles.td}>SUV</td>
													)}
												{driverModel &&
													driverModel.activeVehicle &&
													driverModel.activeVehicle.carCapacity > 6 && (
														<td style={styles.td}>
															Capacity:{' '}
															{driverModel.activeVehicle.carCapacity}
														</td>
													)}
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>License Plate</th>
												<td style={styles.td}>
													{driverModel &&
														driverModel.activeVehicle &&
														driverModel.activeVehicle.carLicense}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Phone Number</th>
												<td style={styles.td}>
													{driverModel &&
														driverModel.profile &&
														driverModel.profile.phone}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div style={styles.tableWrapper}>
									<table style={styles.table}>
										<caption style={styles.caption}>Pickup Location</caption>
										<tbody>
											<tr style={styles.tr}>
												<th style={styles.th}>Start Full</th>
												<td style={styles.td}>
													{startAddress && startAddress.startAddrFull}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Start Addr</th>
												<td style={styles.td}>
													{startAddress && startAddress.startAddr}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Start City</th>
												<td style={styles.td}>
													{startAddress && startAddress.startCity}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Start State</th>
												<td style={styles.td}>
													{startAddress && startAddress.startState}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Start Zip</th>
												<td style={styles.td}>
													{startAddress && startAddress.startZip}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div style={styles.tableWrapper}>
									<table style={styles.table}>
										<caption style={styles.caption}>Dropoff Location</caption>
										<tbody>
											<tr style={styles.tr}>
												<th style={styles.th}>End Full</th>
												<td style={styles.td}>
													{endAddress && endAddress.endAddrFull}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>End Addr</th>
												<td style={styles.td}>
													{endAddress && endAddress.endAddr}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>End City</th>
												<td style={styles.td}>
													{endAddress && endAddress.endCity}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>End State</th>
												<td style={styles.td}>
													{endAddress && endAddress.endState}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>End Zip</th>
												<td style={styles.td}>
													{endAddress && endAddress.endZip}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div style={styles.rightColumn}>
								<div style={styles.tableWrapper}>
									<table style={styles.table}>
										<caption style={styles.caption}>Trip Details</caption>
										<tbody>
											<tr style={styles.tr}>
												<th style={styles.th}>Date Created</th>
												<td style={styles.td}>
													{convertToDateTimeShort(
														createdAt * 1000,
														tzlookup(startLat, startLon)
													)}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Requested Pick Up</th>
												<td style={styles.td}>
													{convertToDateTimeShort(
														requestTime * 1000,
														tzlookup(startLat, startLon)
													)}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Current Status</th>
												{status === 1 && (
													<td style={styles.td}>Unaccepted</td>
												)}
												{status === 2 && (
													<td style={styles.td}>Accepted</td>
												)}
												{status === 3 && (
													<td style={styles.td}>En Route</td>
												)}
												{status === 4 && (
													<td style={styles.td}>On Location</td>
												)}
												{status === 5 && (
													<td style={styles.td}>Picked Up</td>
												)}
												{status === 6 && (
													<td style={styles.td}>Dropped Off</td>
												)}
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Vehicle Type</th>
												<td style={styles.td}>{carType}</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Distance</th>
												<td style={styles.td}>
													{Math.round(distance * 0.000621371 * 10) / 10}{' '}
													miles
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Trip Duration</th>
												<td style={styles.td}>
													{Math.round(tripEta / 60)} minutes
												</td>
											</tr>
											<tr style={styles.tr} ng-if="request.driverEta != null">
												<th style={styles.th}>Driver ETA</th>
												<td style={styles.td}>
													{convertToDateTimeShort(
														driverEta * 1000,
														tzlookup(startLat, startLon)
													)}
												</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Pets</th>
												<td style={styles.td}>{pets ? 'Yes' : 'No'}</td>
											</tr>
											<tr style={styles.tr}>
												<th style={styles.th}>Rider Note</th>
												<td style={styles.td}>{riderNote}</td>
											</tr>
											{organizationId && organization && (
												<tr>
													<th style={styles.th}>Providing By</th>
													<td style={styles.td}>{organization.name}</td>
												</tr>
											)}
											<tr style={styles.tr}>
												<th style={styles.th}>Total Fare</th>
												<td style={styles.td}>
													${pricing && pricing.finalPrice}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div style={styles.tableWrapper}>
									<table style={styles.table}>
										<caption style={styles.caption}>Trip Timestamps</caption>
										<thead>
											<tr style={styles.tr}>
												<th style={styles.th}>Status</th>
												<th style={styles.th}>Timestamp</th>
											</tr>
										</thead>
										<tbody>
											<tr style={styles.tr}>
												<td style={styles.td}>Date Created</td>
												<td style={styles.td}>
													{convertToDateTimeShort(
														createdAt * 1000,
														tzlookup(startLat, startLon)
													)}
												</td>
											</tr>
											<tr style={styles.tr}>
												<td style={styles.td}>Requested Pick Up</td>
												<td style={styles.td}>
													{convertToDateTimeShort(
														requestTime * 1000,
														tzlookup(startLat, startLon)
													)}
												</td>
											</tr>
											<tr style={styles.tr}>
												<td style={styles.td}>On the way to location</td>
												<td style={styles.td}>
													{statusTimes &&
														convertToDateTimeShort(
															statusTimes.startingPickup * 1000,
															tzlookup(startLat, startLon)
														)}
												</td>
											</tr>
											<tr style={styles.tr}>
												<td style={styles.td}>Chauffeur On Location</td>
												<td style={styles.td}>
													{statusTimes &&
														convertToDateTimeShort(
															statusTimes.onLocation * 1000,
															tzlookup(startLat, startLon)
														)}
												</td>
											</tr>
											<tr style={styles.tr}>
												<td style={styles.td}>Passenger In Car</td>
												<td style={styles.td}>
													{statusTimes &&
														convertToDateTimeShort(
															statusTimes.pickedUp * 1000,
															tzlookup(startLat, startLon)
														)}
												</td>
											</tr>
											<tr style={styles.tr}>
												<td style={styles.td}>Dropped Off Passenger</td>
												<td style={styles.td}>
													{statusTimes &&
														convertToDateTimeShort(
															statusTimes.droppedOff * 1000,
															tzlookup(startLat, startLon)
														)}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</Panel>
				</PanelWrapper>
			</Fragment>
		)
	}
}

HistoricDetailed.propTypes = {}

HistoricDetailed.defaultProps = {}

const mapStateToProps = (state) => {
	const {
		historicRequest,
		historicRequestLoaded,
		requestRiderModel,
		requestRiderLocation,
		driverModel,
		organization,
	} = state.Rider

	return {
		historicRequest,
		historicRequestLoaded,
		requestRiderModel,
		requestRiderLocation,
		driverModel,
		organization,
	}
}

export default connect(
	mapStateToProps,
	{
		startWatchingRiderHistoricRequest,
		stopWatchingRiderHistoricRequest,
	}
)(Radium(HistoricDetailed))
