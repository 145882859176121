//
//TE Version 0.2.0
//

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'
import styles from './styles'

class TESubmit extends Component {

    render() {
        const {
            style,
            children,
            ...rest
        } = this.props

        return (
            <button
				type='submit'
                style={{ ...styles.button(rest.disabled), ...style }}
				{...rest}
			>
				{children}
			</button>
        )
    }
}

TESubmit.propTypes = {
    style: PropTypes.object,
}

TESubmit.defaultProps = {}

export default Radium(TESubmit)