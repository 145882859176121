//
//TE Version 0.1.0
//

//
//The goal here is to provide a simple default component to use.
//
import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

//import images from './../../../config/images'

class TEHelmet extends Component {
	render() {
		const {
			// charSet = 'utf-8',
			title = 'FirstClass Eventz',
			description = 'Make Event Tracking Easy',

			// children,
		} = this.props

		return (
			<Helmet
				title={title}
				meta={[
					{
						name: 'description',
						content: description,
					},
				]}
			/>
		)
	}
}

TEHelmet.propTypes = {}

TEHelmet.defaultProps = {}

export default TEHelmet
