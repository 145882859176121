//
//TE Version 0.2.0
//

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'

import styles from './styles'

class TERow extends Component {
    render() {
        const {
            size,
            last,
            style,
            children
        } = this.props

        return (
            <div style={{ ...styles.container(size, last), ...style }}>
				{children}
			</div>
        )
    }
}

TERow.propTypes = {
    size: PropTypes.string,
    last: PropTypes.bool,
    style: PropTypes.object,
    children: PropTypes.node,
}

TERow.defaultProps = {
    size: 'full',
    last: false,
}

export default Radium(TERow)