import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'

import {
	hideCreateAdminOrganizationPopup,
	createOrganization,
} from './../../../../actions'
import { AdminKeys } from './../../../../actions/types'

import TEPopup from './../../../../component/Popup/TEPopup'
import TEMultiStepForm from './../../../../component/Form/TEMultiStepForm'
import TEInputRow from './../../../../component/Form/TEInputRow'
// import TESegmentedGroup from './../../../../component/Form/TESegmentedGroup'

import styles from './styles'

class Create extends Component {
	handleClosePopup = () => {
		this.props.hideCreateAdminOrganizationPopup()
	}
	handleCreate = () => {
		const {
			createOrganization,
			createOrganizationNameData,
			// createOrganizationTypeData,
		} = this.props

		const data = {
			createOrganizationNameData,
			// createOrganizationTypeData,
		}

		createOrganization(data)
	}

	render(){
		const {
			visible,

			createOrganizationNameData,
			// createOrganizationTypeData,
		} = this.props

		return (
			<TEPopup visible={visible} contentStyles={styles.content}>
				<div style={styles.container}>
					<TEMultiStepForm
						stepData={[{
							title 			: 'Details',
							component 		: <Fragment>
									<TEInputRow
										labelForKey='name'
										rowData={createOrganizationNameData}
										onChangeKey={AdminKeys.CREATE_ORGANIZATION_NAME_CHANGED}
										validationRules={['required']}
										inputExtras={{ placeholder: 'Organization Name' }}
									/>
									{/*<TESegmentedGroup
										labelForKey='organizationType'
										checkedInputData={createOrganizationTypeData}
										onChangeKey={AdminKeys.CREATE_ORGANIZATION_TYPE_CHANGED}
										buttonArray={['Livery', 'Business']}
										validationRules={['required']}
										inline
									/>*/}
								</Fragment>,
							validateData 	: [createOrganizationNameData, /*createOrganizationTypeData*/]
						}]}
						handleCancelOnClick={this.handleClosePopup}
						handleSubmitOnClick={this.handleCreate}
						popupStyles
					/>
				</div>
			</TEPopup>
		)
	}
}

Create.propTypes = {}

Create.defaultProps = {}

const mapStateToProps = (state) => {
	const {
		createOrganizationNameData,
		// createOrganizationTypeData,
	} = state.Admin

	return {
		createOrganizationNameData,
		// createOrganizationTypeData,
	}
}

export default connect(mapStateToProps, {
	hideCreateAdminOrganizationPopup,
	createOrganization,
})(Radium(Create))
