import { colors } from './../../../config/styles';
//import images from './../../../config/images';

export default {
	container : {
		alignItems 		: 'center',
		justifyContent	: 'center',
		minHeight 		: 'calc(100vh - 160px)',
	},
	panel : {
		maxWidth 		: 700,
		borderRadius 	: 5,
		paddingTop 		: 60,
		paddingBottom 	: 60,
		paddingRight 	: 60,
		paddingLeft 	: 60,
	},
	title : {
		textAlign       : 'center',
		marginTop       : 0,
		marginBottom    : 15,
		fontSize        : 32,
		color           : colors.blue
	},
}
