import {
    SHOW_ALERT,
    SHOW_NETWORK_ACTIVITY,
    HIDE_NETWORK_ACTIVITY,
    AuthKeys,
    LoadingKeys,
} from './types'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

import { validateInputDataArray, validateInputData, apiRequest } from './../helpers'

import {
    determineAuthErrorMessage,
    //determineForgotPasswordErrorMessage,
} from './../helpers'

export const signUpUser = (data) => {
    const { SIGN_UP_USER_SUCCESS, SIGN_UP_USER_FAIL } = AuthKeys

    const { signUpAliasData, signUpPhoneData, signUpEmailData, signUpPasswordData, history } = data

    //Validate Data
    const validationArray = [signUpAliasData, signUpPhoneData, signUpEmailData, signUpPasswordData]
    const { validation, validationData } = validateInputDataArray(validationArray)
    if (!validation) {
        return (dispatch) => {
            dispatch({
                type: SHOW_ALERT,
                payload: validateInputData(validationData),
            })
        }
    }

    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Creating Account...',
        })
        firebase
            .auth()
            .createUserWithEmailAndPassword(signUpEmailData.value, signUpPasswordData.value)
            .then((response) => {
                const { uid } = firebase.auth().currentUser
                return firebase
                    .database()
                    .ref(`UserPermissions/${uid}`)
                    .set({
                        rider: true,
                    })
            })
            .then((response) => {
                const { uid } = firebase.auth().currentUser
                return firebase
                    .database()
                    .ref(`Riders/${uid}`)
                    .set({
                        profile: {
                            email: signUpEmailData.value.toLowerCase(),
                            alias: signUpAliasData.value,
                            phone: signUpPhoneData.value,
                        },
                        statuses: {
                            status: 1,
                        },
                        balance: 0,
                    })
            })
            .then((response) => {
                const { uid } = firebase.auth().currentUser
                return apiRequest('POST', `createCustomer/${signUpEmailData.value}/${uid}`, data)
            })
            .then((response) => {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: SIGN_UP_USER_SUCCESS })
                history.push('/portal/live')
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Success',
                        alertMessage: 'Your account has been successfully created.',
                    },
                })
            })
            .catch((error) => {
                console.log(error)
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: SIGN_UP_USER_FAIL })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Error Creating Account.',
                    },
                })
            })
    }
}

export const signInUser = ({ email, password, history }) => {
    const { SIGN_IN_USER_SUCCESS, SIGN_IN_USER_FAIL } = AuthKeys

    return (dispatch, getState) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Signing In...',
        })

        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((user) => {
                dispatch({ type: LoadingKeys.USER_CHECK_STARTED })

                const { uid } = firebase.auth().currentUser

                //Check User Permissions
                firebase
                    .database()
                    .ref(`UserPermissions/${uid}`)
                    .once('value', (snapshot) => {
                        dispatch({
                            type: AuthKeys.FETCH_USER_PERMISSIONS,
                            payload: snapshot.val(),
                        })

                        dispatch({ type: HIDE_NETWORK_ACTIVITY })
                        if (snapshot.val()) {
                            if (snapshot.val().admin) {
                                history.push('/admin/organizations')
                            } else if (snapshot.val().dispatch) {
                                history.push('/dispatch/live')
                            } else if (snapshot.val().rider) {
                                history.push('/portal/live')
                            } else {
                                firebase.auth().signOut()
                                dispatch({ type: SIGN_IN_USER_FAIL })
                                dispatch({
                                    type: SHOW_ALERT,
                                    payload: {
                                        alertTitle: 'Error Signing In',
                                        alertMessage:
                                            'Your user permissions do not allow you to sign into this website.',
                                    },
                                })
                            }
                            dispatch({ type: SIGN_IN_USER_SUCCESS })
                        } else {
                            console.log('User does not have any user permissions')
                            dispatch({ type: SIGN_IN_USER_FAIL })
                            dispatch({
                                type: SHOW_ALERT,
                                payload: {
                                    alertTitle: 'Error Signing In',
                                    alertMessage: 'An error occurred signing in.',
                                },
                            })
                        }
                    })
            })
            .catch((error) => {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })

                console.log('Firebase Sign In Catch', error)
                dispatch({
                    type: SHOW_ALERT,
                    payload: determineAuthErrorMessage(error),
                })
                dispatch({ type: SIGN_IN_USER_FAIL })
            })
    }
}

export const signOutUser = (history) => {
    const { SIGN_OUT_USER_SUCCESS } = AuthKeys
    return (dispatch, getState) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Signing Out...',
        })

        firebase
            .auth()
            .signOut()
            .then(() => {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })

                dispatch({ type: SIGN_OUT_USER_SUCCESS })
                history.push('/')
            })
            .catch((error) => {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })

                console.log('Firebase Sign Out Catch', error)
                dispatch({
                    type: SHOW_ALERT,
                    payload: determineAuthErrorMessage(error),
                })
            })
    }
}

export const sendForgotPasswordEmail = ({ email }) => {
    const { SEND_FORGOT_PASSWORD_EMAIL_SUCCESS, SEND_FORGOT_PASSWORD_EMAIL_FAIL } = AuthKeys

    if (!email) {
        return (dispatch) => {
            dispatch({
                type: SHOW_ALERT,
                payload: {
                    alertTitle: 'Error',
                    alertMessage: 'Please provide a valid email address.',
                },
            })
        }
    }

    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Sending Forgot Password Email...',
        })

        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: SEND_FORGOT_PASSWORD_EMAIL_SUCCESS })

                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Success',
                        alertMessage:
                            'An email has been sent to the provided address. Follow the instructions in the email to reset your password.',
                    },
                })
            })
            .catch((error) => {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: SEND_FORGOT_PASSWORD_EMAIL_FAIL })

                console.log('Firebase Forgot Password Catch', error)
                dispatch({
                    type: SHOW_ALERT,
                    payload: determineAuthErrorMessage(error),
                })
            })
    }
}

export const resetPassword = (actionCode, newPasswordData) => {
    const { RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL } = AuthKeys

    //Validate
    if (!newPasswordData.valid) {
        return (dispatch) => {
            dispatch({
                type: SHOW_ALERT,
                payload: validateInputData(newPasswordData),
            })
        }
    }

    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Resetting Password...',
        })

        firebase
            .auth()
            .verifyPasswordResetCode(actionCode)
            .then((email) => {
                firebase
                    .auth()
                    .confirmPasswordReset(actionCode, newPasswordData.value)
                    .then((resp) => {
                        dispatch({ type: HIDE_NETWORK_ACTIVITY })
                        dispatch({ type: RESET_PASSWORD_SUCCESS })
                        dispatch({
                            type: SHOW_ALERT,
                            payload: {
                                alertTitle: 'Success',
                                alertMessage: 'Your password has been reset.',
                            },
                        })
                    })
                    .catch((error) => {
                        console.log('Firebase Error Reseting Password Catch', error)
                        dispatch({ type: HIDE_NETWORK_ACTIVITY })
                        dispatch({ type: RESET_PASSWORD_FAIL })
                        dispatch({
                            type: SHOW_ALERT,
                            payload: determineAuthErrorMessage(error),
                        })
                    })
            })
            .catch((error) => {
                console.log('Firebase Error Reseting Password Catch', error)
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: RESET_PASSWORD_FAIL })
                dispatch({
                    type: SHOW_ALERT,
                    payload: determineAuthErrorMessage(error),
                })
            })
    }
}
export const recoverEmail = (actionCode) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Recovering Email...',
        })

        firebase
            .auth()
            .checkActionCode(actionCode)
            .then((info) => {
                const { email } = info.data

                firebase
                    .auth()
                    .applyActionCode(actionCode)
                    .then(() => {
                        firebase
                            .auth()
                            .sendPasswordResetEmail(email)
                            .then(() => {
                                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                                dispatch({
                                    type: SHOW_ALERT,
                                    payload: {
                                        alertTitle: 'Success',
                                        alertMessage:
                                            'Your email address has been recovered. For security we have also sent a password reset email.',
                                    },
                                })
                            })
                            .catch((error) => {
                                console.log('Firebase Error Recover Email Catch', error)
                                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                                dispatch({
                                    type: SHOW_ALERT,
                                    payload: determineAuthErrorMessage(error),
                                })
                            })
                    })
            })
            .catch((error) => {
                console.log('Firebase Error Recover Email Catch', error)
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({
                    type: SHOW_ALERT,
                    payload: determineAuthErrorMessage(error),
                })
            })
    }
}
export const verifyEmail = (actionCode) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Verifying Email...',
        })

        firebase
            .auth()
            .applyActionCode(actionCode)
            .then((resp) => {
                console.log('successfully verifyEmail', resp)
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                //TODO: Update Agent Accounts emailVerified Value
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Success',
                        alertMessage: 'Your email has been verified.',
                    },
                })
            })
            .catch((error) => {
                console.log('Firebase Error Email Verification Catch', error)
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({
                    type: SHOW_ALERT,
                    payload: determineAuthErrorMessage(error),
                })
            })
    }
}
