// import { colors } from './../../../config/styles'

export default {
	errorMessage: {
		marginTop 		: 30,
		marginBottom 	: 30,
		textAlign 		: 'center',

	},
	detailsContainer: {
		display 		: 'flex',
		justifyContent 	: 'center',
		flexWrap 		: 'wrap',
	},
	mapWrapper: {
		width 			: '100%',
		height 			: 450,
		marginBottom 	: 15,
	},
	leftColumn: {
		width			: '50%',
		paddingRight 	: 10,
	},
	rightColumn: {
		width			: '50%',
		paddingLeft 	: 10,
	},
	tableWrapper: {
		overflowX 	: 'auto',
	    margin 		: '10px auto',
	},
	table: {
		borderCollapse 	: 'collapse',
		width			: '100%'
	},
	caption: {
		fontWeight 			: 500,
	    padding 			: '5px 0px',
	    backgroundColor		: '#e0e0e0',
	},
	tr: {},
	th: {
		textAlign 		: 'left',
		padding 		: '3px 5px',
		fontSize 		: 14,
		fontWeight 		: 500,
	},
	td: {
		padding 		: '3px 5px',
		fontSize 		: 14,
		fontWeight 		: 300,
	},
}
