import React from 'react'
import tzlookup from 'tz-lookup'

import TELink from './../../../component/Navigation/TELink'

import { convertToDateTimeShort24 } from './../../../helpers'
import styles from './styles'

export const tableColumns = (drivers) => [
	{
		id: 'completedStatus',
		Header: 'Completion',
		width: 120,
		accessor: (d) => {
			switch (d.completed) {
				case -1:
					return 'Canceled'
				case 1:
					return 'Completed'
				default:
					return ' -- '
			}
		},
	},
	{
		id: 'requestTime',
		Header: 'Pickup Time',
		filterable: false,

		Cell: (d) => {
			const { requestTime, startLat, startLon } = d.original

			if (!requestTime || !startLat || !startLon) {
				return convertToDateTimeShort24(requestTime * 1000)
			}

			return convertToDateTimeShort24(requestTime * 1000, tzlookup(startLat, startLon))
		},
	},
	{
		id: 'startAddress',
		Header: 'Pick Up',
		accessor: 'startAddress.startAddrFull',
	},
	{
		id: 'endAddress',
		Header: 'Destination',
		accessor: 'endAddress.endAddrFull',
	},
	{
		id: 'carType',
		Header: 'Vehicle Type',
		accessor: 'carType',
	},
	{
		id: 'chauffeur',
		Header: 'Chauffeur',
		accessor: (d) =>
			d.driverId && drivers && drivers[d.driverId] && drivers[d.driverId].profile
				? `${drivers[d.driverId].profile.chauffeurId || ' -- '} - ${drivers[d.driverId]
						.profile.nameF || ''} ${drivers[d.driverId].profile.nameL || ''}`.trim()
				: ' -- ',
	},
	{
		id: 'actions',
		Header: '',
		width: 120,
		sortable: false,
		filterable: false,
		accessor: 'uid',
		Cell: (d) => (
			<TELink
				to={`/dispatch/historic/${d.value}`}
				wrapperStyle={styles.detailLinkWrapper}
				style={styles.detailLink}
			>
				Details
			</TELink>
		),
	},
]
