import { colors } from './../../../config/styles';

export default {
	button : {
		position			: 'relative',
		display				: 'block',
		width				: '100%',
		fontSize 			: 18,
		paddingLeft 		: 20,
		paddingRight 		: 20,
		paddingBottom 		: 5,
		paddingTop 			: 5,
		textAlign			: 'center',
		borderTop			: `1px solid ${colors.lightGray}`,
		borderRight			: `1px solid ${colors.lightGray}`,
		borderBottom		: `1px solid ${colors.lightGray}`,
		borderLeft			: `1px solid ${colors.lightGray}`,
		cursor				: 'pointer',
		borderRadius 		: 5,

		color 				: colors.darkGray,
		backgroundColor		: colors.white,
		transition 			: 'color 0.2s ease-in, background-color 0.2s ease-in, border 0.2s ease-in',

		':hover' : {
			color				: colors.white,
			backgroundColor		: colors.blue,
			borderTop			: `1px solid ${colors.blue}`,
			borderRight			: `1px solid ${colors.blue}`,
			borderBottom		: `1px solid ${colors.blue}`,
			borderLeft			: `1px solid ${colors.blue}`,
		},
		':active' : {
			color				: colors.white,
			backgroundColor		: colors.blue,
			borderTop			: `1px solid ${colors.blue}`,
			borderRight			: `1px solid ${colors.blue}`,
			borderBottom		: `1px solid ${colors.blue}`,
			borderLeft			: `1px solid ${colors.blue}`,
		}
	},
	disabled : {
		color 				: colors.gray,
		borderTop			: `1px solid ${colors.lighterGray}`,
		borderRight			: `1px solid ${colors.lighterGray}`,
		borderBottom		: `1px solid ${colors.lighterGray}`,
		borderLeft			: `1px solid ${colors.lighterGray}`,
	},
}
