import React from 'react'

import TELink from './../../../component/Navigation/TELink'

import styles from './styles'

export const tableColumns =  (orgUID) => [{
	id: 'name',
	Header: 'Alias',
	accessor: 'profile.alias'
}, {
	id: 'email',
	Header: 'Email',
	accessor: 'profile.email'
}, {
	id: 'phone',
	Header: 'Phone',
	accessor: 'profile.phone'
}, {
	id: 'permission',
	Header: 'Permission',
	accessor: `organizations.${orgUID}.permission`
}, {
	id: 'actions',
	Header: '',
	width: 120,
	sortable: false,
	filterable: false,
	accessor: 'uid',
	Cell: d => <TELink to={`/admin/organizations/${orgUID}/user/${d.value}`} wrapperStyle={styles.detailLinkWrapper} style={styles.detailLink}>Details</TELink>
}]
