import { colors } from './../../../config/styles';

export default {
	trafficButton : {
		position 	 	: 'absolute',
		top 			: 13,
		right 			: 60,
		width 			: 'auto',
	},
	trafficButtonHighlighted : {
		color 			: colors.white,
		backgroundColor : colors.blue,
		borderTop 		: `1px solid ${colors.blue}`,
		borderRight 	: `1px solid ${colors.blue}`,
		borderBottom 	: `1px solid ${colors.blue}`,
		borderLeft 		: `1px solid ${colors.blue}`,
	},
	infoWindowContent: {
		padding 		: 0,
		maxWidth 		: 300,
	},
	infoWindowTitle: {
		marginTop 		: 0,
		marginBottom 	: 5,
		fontSize 		: 24,
		fontWeight 		: 500,
		maxWidth 		: 250,
		lineHeight 		: 1.1,
	},
	infoWindowSubtitle: {
		marginTop 		: 0,
		marginBottom 	: 10,
		fontSize 		: 14,
		fontWeight 		: 300,
		maxWidth 		: 250,
		lineHeight 		: 1.2,
	},
	infoWindowLabel: {
		marginTop 		: 0,
		marginBottom 	: 5,
		fontSize 		: 16,
		maxWidth 		: 250,
		fontWeight 		: 500,
		lineHeight 		: 1,
	},
	infoWindowValue: {
		marginTop 		: 0,
		marginBottom 	: 5,
		fontSize 		: 12,
		maxWidth 		: 250,
		lineHeight 		: 1.25,
	}
}
