import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import ReactTable from 'react-table'

import {
	startWatchingAdminOrganizations,
	stopWatchingAdminOrganizations,
	showCreateAdminOrganizationPopup,
} from './../../../actions'

import TEHelmet from './../../../component/Header/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import TEButton from './../../../component/Form/TEButton'

import Create from './Create'

import { tableColumns } from './tableConfigs'
import { customTableFilter } from './../../../helpers'
import styles from './styles'

class Organizations extends Component {
	state = { activeView : 'Routes' }

	componentDidMount(){
		const {
			startWatchingAdminOrganizations,
		} = this.props

		startWatchingAdminOrganizations()
	}
	componentWillUnmount(){
		const {
			stopWatchingAdminOrganizations,
		} = this.props

		stopWatchingAdminOrganizations()
	}
	handleShowCreateOrganization = () => {
		this.props.showCreateAdminOrganizationPopup()
	}

	render(){
		const meta = {
			title: 'FirstClass | Admin Organizations',
			description: '',
		}

		const {
			organizationArray,
			organizationsLoaded,
			createOrganizationPopupVisible,
		} = this.props

		return (
			<Fragment>
				<TEHelmet {...meta}/>
				<PanelWrapper style={styles.panelWrapper}>
					<Panel
						title='Organizations'
						rightComponent={
							<div style={styles.actionsWrapper}>
								<TEButton onClick={this.handleShowCreateOrganization} style={styles.actionButton}>Create Organization</TEButton>
							</div>
						}
					>
						<ReactTable
							data={organizationArray}
							columns={tableColumns}
							defaultPageSize={10}
							filterable
							defaultFilterMethod={customTableFilter}
							defaultSorted={[{
								id: "name",
								desc: false
							}]}
							loading={!organizationsLoaded}
						/>
					</Panel>
				</PanelWrapper>
				<Create visible={createOrganizationPopupVisible} />
			</Fragment>
		)
	}
}

Organizations.propTypes = {}

Organizations.defaultProps = {}

const mapStateToProps = (state) => {
	const {
		organizations,
		organizationsLoaded,
		createOrganizationPopupVisible
	} = state.Admin

	const organizationArray = []
	for (const key in organizations){
		const dataToMap = organizations[key]
		dataToMap.uid = key
		organizationArray.push(dataToMap)
	}

	return {
		organizationArray,
		organizationsLoaded,
		createOrganizationPopupVisible
	}
}

export default connect(mapStateToProps, {
	startWatchingAdminOrganizations,
	stopWatchingAdminOrganizations,
	showCreateAdminOrganizationPopup,
})(Radium(Organizations))
