//
//TE Version 0.1.0
//

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles';

import Radium from 'radium';

import TEPopup from './../TEPopup'

import { colorBetweenColors } from './../../../helpers';
import { colors } from './../../../config/styles';

class TENetworkActivity extends Component {
	calculateRingStyles(totalRings, ringNumber){
		const maxTime 	= 3;
		const minTime	= 1;

		const stepPercent 	= 1 / totalRings / 2;
		const colorSteps 	= 1 / (totalRings - 1);

		const spacing 	= ringNumber * stepPercent;
		const timeStep	= (maxTime - minTime) / totalRings;
		const thisTime	= maxTime / (maxTime - (timeStep * ringNumber));

		return {
			left 			: `${spacing * 100}%`,
			top 			: `${spacing * 100}%`,
			width			: `${(1 - spacing * 2) * 100}%`,
			height			: `${(1 - spacing * 2) * 100}%`,
			borderTopColor 	: colorBetweenColors(colors.blue, colors.green, colorSteps * ringNumber),
			animation		: `loading ${thisTime}s linear infinite`,
		}
		
	}
	renderRings(){
		const totalRings = 6;

		let rings = [];
		for (var i=0; i<totalRings; i++){
			rings.push(
				<div 
					key={`ring${i}`}
					style={[
						styles.ring, 
						this.calculateRingStyles(totalRings, i)
					]} 
				/>
			)
		}
		return rings;
	}

	render() {
		const {
			message,
			visible
		} = this.props;

		return (
			<TEPopup visible={visible} contentStyles={styles.content}>
				<div style={styles.spinner}>
					{this.renderRings()}
				</div>
				<div style={styles.message}>{message}</div>
			</TEPopup>
		);
	}
}

TENetworkActivity.propTypes = {
	numberOfRings	: PropTypes.number,
}

TENetworkActivity.defaultProps = {};

export default Radium(TENetworkActivity);
