import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'

import {
	startWatchingDispatchDriver,
	stopWatchingDispatchDriver,
	saveDispatchDriver,
} from './../../../actions'
import { DispatchKeys } from './../../../actions/types'

import TEHelmet from './../../../component/Header/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import TEInputRow from './../../../component/Form/TEInputRow'
import TESegmentedGroup from './../../../component/Form/TESegmentedGroup'
import TEButton from './../../../component/Form/TEButton'

import styles from './styles'

class DriverDetailed extends Component {
	componentDidMount(){
		const {
			match,
			startWatchingDispatchDriver,
		} = this.props
		const { uid } = match.params

		startWatchingDispatchDriver(uid)
	}
	componentWillUnmount(){
		const {
			stopWatchingDispatchDriver,
		} = this.props

		stopWatchingDispatchDriver()
	}
	handleSave = () => {
		const {
			match,
			saveDispatchDriver,
			driverFirstNameData,
			driverLastNameData,
			driverPhoneData,
			driverIdData,
			driverVehicleMakeData,
			driverVehicleYearData,
			driverVehicleTypeData,
			driverVehicleLicenseData,
			driverVehicleCityLicenseData,
			driverVehicleIdData,
			driverStatusData,
		} = this.props
		const { uid } = match.params

		const data = {
			uid,
			driverFirstNameData,
			driverLastNameData,
			driverPhoneData,
			driverIdData,
			driverVehicleMakeData,
			driverVehicleYearData,
			driverVehicleTypeData,
			driverVehicleLicenseData,
			driverVehicleCityLicenseData,
			driverVehicleIdData,
			driverStatusData,
		}

		saveDispatchDriver(data)
	}

	render(){
		const meta = {
			title: 'FirstClass | Chauffeur Details',
			description: '',
		}
		const {
			driverModel,
			driverModelLoaded,
			// driverLocation,
			// driverLocationLoaded,
			driverFirstNameData,
			driverLastNameData,
			driverPhoneData,
			driverIdData,
			driverVehicleMakeData,
			driverVehicleYearData,
			driverVehicleTypeData,
			driverVehicleLicenseData,
			driverVehicleCityLicenseData,
			driverVehicleIdData,
			driverStatusData,
		} = this.props

		if (!driverModel){
			if (driverModelLoaded){
				return <h1 style={styles.errorMessage}>Error Loading Driver...</h1>
			}else{
				return null
			}
		}

		return (
			<Fragment>
				<TEHelmet {...meta}/>
				<PanelWrapper style={styles.panelWrapper}>
					<Panel
						containerStyle={styles.editPanel}
						title={`${driverModel.profile && driverModel.profile.nameF} ${driverModel.profile && driverModel.profile.nameL} Details`}
					>
						<TEInputRow
							labelForKey='firstName'
							rowData={driverFirstNameData}
							onChangeKey={DispatchKeys.DRIVER_FIRST_NAME_CHANGED}
							validationRules={['required']}
							size='half'
						/>
						<TEInputRow
							labelForKey='lastName'
							rowData={driverLastNameData}
							onChangeKey={DispatchKeys.DRIVER_LAST_NAME_CHANGED}
							validationRules={['required']}
							size='half'
							last
						/>
						<TEInputRow
							type='tel'
							labelForKey='phone'
							rowData={driverPhoneData}
							onChangeKey={DispatchKeys.DRIVER_PHONE_CHANGED}
							validationRules={['required', 'phone']}
							size='half'
						/>
						<TEInputRow
							labelForKey='driverId'
							rowData={driverIdData}
							onChangeKey={DispatchKeys.DRIVER_ID_CHANGED}
							validationRules={['required']}
							size='half'
							last
						/>
						<TEInputRow
							labelForKey='vehicleMake'
							rowData={driverVehicleMakeData}
							onChangeKey={DispatchKeys.DRIVER_VEHICLE_MAKE_CHANGED}
							validationRules={['required']}
							size='half'
						/>
						<TEInputRow
							labelForKey='vehicleYear'
							rowData={driverVehicleYearData}
							onChangeKey={DispatchKeys.DRIVER_VEHICLE_YEAR_CHANGED}
							validationRules={['required', 'year']}
							size='half'
							last
						/>
						<TEInputRow
							labelForKey='licensePlate'
							rowData={driverVehicleLicenseData}
							onChangeKey={DispatchKeys.DRIVER_VEHICLE_LICENSE_CHANGED}
							validationRules={['required']}
							size='half'
						/>
						<TEInputRow
							labelForKey='licensePlate'
							rowData={driverVehicleIdData}
							onChangeKey={DispatchKeys.DRIVER_VEHICLE_ID_CHANGED}
							validationRules={['required']}
							size='half'
							last
						/>
						<TESegmentedGroup
							labelForKey='vehicleType'
							checkedInputData={driverVehicleTypeData}
							onChangeKey={DispatchKeys.DRIVER_VEHICLE_TYPE_CHANGED}
							buttonArray={['Sedan', 'SUV']}
							validationRules={['required']}
							inline
						/>
						<TESegmentedGroup
							labelForKey='cityPlates'
							checkedInputData={driverVehicleCityLicenseData}
							onChangeKey={DispatchKeys.DRIVER_VEHICLE_CITY_LICENSE_CHANGED}
							buttonArray={['Yes', 'No']}
							validationRules={['required']}
							inline
						/>
						<TESegmentedGroup
							labelForKey='status'
							checkedInputData={driverStatusData}
							onChangeKey={DispatchKeys.DRIVER_STATUS_CHANGED}
							buttonArray={['Unactivated', 'Available', 'In A Request']}
							validationRules={['required']}
							inline
						/>

						<TEButton onClick={this.handleSave} style={styles.saveButton}>Save</TEButton>
					</Panel>
				</PanelWrapper>
			</Fragment>
		)
	}
}

DriverDetailed.propTypes = {}

DriverDetailed.defaultProps = {}

const mapStateToProps = (state) => {
	const {
		driverModel,
		driverModelLoaded,
		driverLocation,
		driverLocationLoaded,
		driverFirstNameData,
		driverLastNameData,
		driverPhoneData,
		driverIdData,
		driverVehicleMakeData,
		driverVehicleYearData,
		driverVehicleTypeData,
		driverVehicleLicenseData,
		driverVehicleCityLicenseData,
		driverVehicleIdData,
		driverStatusData,
	} = state.Dispatch

	return {
		driverModel,
		driverModelLoaded,
		driverLocation,
		driverLocationLoaded,
		driverFirstNameData,
		driverLastNameData,
		driverPhoneData,
		driverIdData,
		driverVehicleMakeData,
		driverVehicleYearData,
		driverVehicleTypeData,
		driverVehicleLicenseData,
		driverVehicleCityLicenseData,
		driverVehicleIdData,
		driverStatusData,
	}
}

export default connect(mapStateToProps, {
	startWatchingDispatchDriver,
	stopWatchingDispatchDriver,
	saveDispatchDriver,
})(Radium(DriverDetailed))
