import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'

import {
	hideAddAdminRiderPopup,
	addUserToOrganization,
} from './../../../../actions'
import { AdminKeys } from './../../../../actions/types'

import TEPopup from './../../../../component/Popup/TEPopup'
import TEMultiStepForm from './../../../../component/Form/TEMultiStepForm'
import TESearchSelectRow from './../../../../component/Form/TESearchSelectRow'
import TESegmentedGroup from './../../../../component/Form/TESegmentedGroup'

import styles from './styles'

class AddUserPopup extends Component {
	handleClosePopup = () => {
		this.props.hideAddAdminRiderPopup()
	}
	handleCreate = () => {
		const {
			uid,
			addUserToOrganization,
			addUserData,
			addPermissionData,
		} = this.props

		const data = {
			uid,
			addUserData,
			addPermissionData,
		}

		addUserToOrganization(data)
	}

	render(){
		const {
			visible,

			riderArray,
			addUserData,
			addPermissionData,
		} = this.props

		return (
			<TEPopup visible={visible} contentStyles={styles.content}>
				<div style={styles.container}>
					<TEMultiStepForm
						stepData={[{
							title 			: 'Details',
							component 		: <Fragment>
									<TESearchSelectRow
										labelForKey='rider'
										options={riderArray}
										rowData={addUserData}
										optionLabelPath='alias'
										onChangeKey={AdminKeys.ADD_USER_CHANGED}
										validationRules={['required']}
									/>
									<TESegmentedGroup
										labelForKey='permission'
										checkedInputData={addPermissionData}
										onChangeKey={AdminKeys.ADD_PERMISSION_CHANGED}
										buttonArray={['Admin', 'Director', 'Member']}
										validationRules={['required']}
										inline
									/>
								</Fragment>,
							validateData 	: [addUserData, addPermissionData]
						}]}
						handleCancelOnClick={this.handleClosePopup}
						handleSubmitOnClick={this.handleCreate}
						popupStyles
					/>
				</div>
			</TEPopup>
		)
	}
}

AddUserPopup.propTypes = {}

AddUserPopup.defaultProps = {}

const mapStateToProps = (state, props) => {
	const { uid } = props
	const {
		riders,
		addUserData,
		addPermissionData,
	} = state.Admin

	const riderArray = []
	for (const key in riders){
		const dataToMap = riders[key]
		dataToMap.uid = key
		dataToMap.alias = dataToMap.profile && dataToMap.profile.alias

		//Filter out users already in org and users that don't have an alias
		if ((!dataToMap.organizations || (dataToMap.organizations && !dataToMap.organizations[uid])) && dataToMap.alias ){
			riderArray.push(dataToMap)
		}
 	}
 	riderArray.sort((a, b) => {
		if (a.alias < b.alias) { return -1 }
		if (a.alias > b.alias) { return 1 }
		return 0
	})

	return {
		riderArray,
		addUserData,
		addPermissionData,
	}
}

export default connect(mapStateToProps, {
	hideAddAdminRiderPopup,
	addUserToOrganization,
})(Radium(AddUserPopup))
