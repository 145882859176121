import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import firebase from 'firebase/app'
import 'firebase/auth'
import { withRouter } from 'react-router-dom'

import TELink from './../../../component/Navigation/TELink'
import TENavLink from './../../../component/Navigation/TENavLink'

import { signOutUser } from './../../../actions'
import images from './../../../config/images'
import styles from './styles'

class Navbar extends Component {
	handleSignOut = () => {
		const { history, signOutUser } = this.props
		signOutUser(history)
	}

	render() {
		return (
			<div style={styles.container}>
				<TELink to="/" wrapperStyle={styles.brandContainer} style={styles.brandLink}>
					<img
						src={images.logo}
						alt="FirstClass Logo"
						title="FirstClass Logo"
						style={styles.logo}
					/>
				</TELink>
				<div style={styles.navContainer}>
					<ul style={styles.ul}>
						<li style={styles.li}>
							<TENavLink
								wrapperStyle={styles.linkWrapper}
								style={styles.link}
								activeStyle={styles.active}
								to="/dispatch/live"
								exact
							>
								Live
							</TENavLink>
						</li>
						<li style={styles.li}>
							<TENavLink
								wrapperStyle={styles.linkWrapper}
								style={styles.link}
								activeStyle={styles.active}
								to="/dispatch/historic"
								exact
							>
								Historic
							</TENavLink>
						</li>
						<li style={styles.li}>
							<TENavLink
								wrapperStyle={styles.linkWrapper}
								style={styles.link}
								activeStyle={styles.active}
								to="/dispatch/fleet"
								exact
							>
								Fleet
							</TENavLink>
						</li>
						{firebase.auth().currentUser ? (
							<li style={styles.li}>
								<button onClick={this.handleSignOut} style={styles.navButton}>
									Sign Out
								</button>
							</li>
						) : (
							<li style={styles.li}>
								<TENavLink
									wrapperStyle={styles.linkWrapper}
									style={styles.link}
									activeStyle={styles.active}
									to="/signIn"
									exact
								>
									Sign In
								</TENavLink>
							</li>
						)}
					</ul>
				</div>
			</div>
		)
	}
}

Navbar.propTypes = {}

Navbar.defaultProps = {}

const mapStateToProps = (state) => {
	return {}
}

export default connect(
	mapStateToProps,
	{ signOutUser }
)(withRouter(Radium(Navbar)))
