import React, { Component } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'

import images from './../../../config/images'
import settings from './../../../config/settings'

import { compareToLocalVersion } from './../../../helpers'
import styles from './styles'

class Footer extends Component {
	render() {
		const { appVersions } = this.props

		const { behind } = compareToLocalVersion(appVersions.currentVersion)

		return (
			<div style={styles.container}>
				<div style={styles.copyrightContainer}>
					<span style={styles.contact}>Contact us: <a href="mailto:info@38thstreetstudios.com" style={styles.emailLink}>info@38thstreetstudios.com</a></span>
					<span style={styles.copyright}>© 2018 38th Street Studios, LLC. All rights reserved.</span>
					<p style={styles.version}>
						v{settings.VERSION}
						{behind &&
							<button
								style={styles.updateButton}
								onClick={() => window.location.reload(true)}
								key='UpdateButton'
							>
								Update Avaiable (v{appVersions.currentVersion})
							</button>
						}
					</p>
				</div>
				<div style={styles.logoContainer}>
					<img src={images.logo} alt='FirstClass Logo' title='FirstClass Logo' style={styles.logo} />
				</div>
			</div>
		);
	}
}

Footer.propTypes = {}

Footer.defaultProps = {};

const mapStateToProps = (state) => {
	const { appVersions } = state.Loading
	return { appVersions }
}

export default connect(mapStateToProps, { })(Radium(Footer))
