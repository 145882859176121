import { colors } from './../../../config/styles';

export default {
	stepsContainer : {
		width           : '100%',
		display         : 'flex',
		position        : 'relative',
		marginBottom 	: 30,
	},
	stepBar : {
		position        : 'absolute',
		top             : 14,
		backgroundColor : colors.blue,
		height          : 2,
	},
	stepWrapper : {
		display         : 'flex',
		flexDirection   : 'column',
		alignItems      : 'center',
	},
	stepNumber : {
		textAlign           : 'center',
		backgroundColor     : colors.white,
		width               : 30,
		height              : 30,
		color               : colors.blue,
		marginBottom        : 10,
		lineHeight          : '30px',
		border              : `1px solid ${colors.blue}`,
		zIndex              : 1,
	},
	activeStepNumber : {
		backgroundColor     : colors.blue,
		color               : colors.white,
	},
	stepTitle : {
		textAlign           : 'center',
		fontSize            : 18,
		paddingLeft 		: 5,
		paddingRight 		: 5,

		'@media (max-width: 1000px)' : {
			fontSize            : 16,
		},
		'@media (max-width: 800px)' : {
			fontSize            : 14,
		},
		'@media (max-width: 650px)' : {
			fontSize            : 12,
		},
	},
	form : {
		marginBottom 	 	: 30
	},
	buttonContainer : {
		position            : 'absolute',
		bottom              : 0,
		left 				: 0,
		width 				: '100%',
		display 			: 'flex',
	},
	roundedCorners : {
		borderBottomRightRadius 	: 20,
		borderBottomLeftRadius 		: 20,
		overflow 					: 'hidden',
	},
	stepButtons : {
		paddingTop 		: 10,
		paddingBottom 	: 10,
		paddingLeft 	: 20,
		paddingRight 	: 20,
		color 			: colors.blue,
		backgroundColor	: colors.white,
		borderTop 		: `1px solid ${colors.blue}`,
		borderBottom 	: 'none',
		borderRadius 	: 0,
	},
	stepButtonLeft : {
		borderLeft 		: 'none',
		borderRight 	: `1px solid ${colors.blue}`,

		':hover' : {
			color 			: colors.white,
			backgroundColor	: colors.blue,
			borderTop 		: `1px solid ${colors.blue}`,
			borderLeft 		: 'none',
			borderRight 	: `1px solid ${colors.blue}`,
			borderBottom 	: 'none',
		},
		':active' : {
			color 			: colors.white,
			backgroundColor	: colors.blue,
			borderTop 		: `1px solid ${colors.blue}`,
			borderLeft 		: 'none',
			borderRight 	: `1px solid ${colors.blue}`,
			borderBottom 	: 'none',
		}
	},
	stepButtonRight : {
		borderLeft 		: 'none',
		borderRight 	: 'none',

		':hover' : {
			color 			: colors.white,
			backgroundColor	: colors.blue,
			borderTop 		: `1px solid ${colors.blue}`,
			borderLeft 		: 'none',
			borderRight 	: 'none',
			borderBottom 	: 'none',
		},
		':active' : {
			color 			: colors.white,
			backgroundColor	: colors.blue,
			borderTop 		: `1px solid ${colors.blue}`,
			borderLeft 		: 'none',
			borderRight 	: 'none',
			borderBottom 	: 'none',
		}
	}
}
