import { colors } from './../../../config/styles'

export default {
	container : {
		position 			: 'absolute',
		top 				: 0,
		bottom				: 0,
		left				: 0,
	    maxHeight 			: '100%',
	    height 				: '100%',
		width				: 160,
		display				: 'block',
		zIndex				: 1,
		backgroundColor 	: colors.white,
		overflowX 			: 'hidden',
		overflowY 			: 'auto',
		boxShadow 			: `0px 1px 3px 0px ${colors.shadow}`,
	},
	navContainer : {
		position 		: 'relative',
		width			: '100%',
		padding 		: 0,
		margin 			: 0,

	},
	li : {
		position 		: 'relative',
		width			: '100%',
		padding 		: 0,
		margin 			: 0,
		listStyle 		: 'none',
	},
	input: {
		display 		: 'none',
	},
	label : {
		position 			: 'relative',
		width				: '100%',
		lineHeight 			: 1.25,
		fontSize 			: 18,
		padding 			: '20px 10px',
		textAlign			: 'left',
		color 				: colors.blue,
		fill 				: colors.blue,
		textDecoration		: 'none',
		display 			: 'flex',
		alignItems			: 'center',
		backgroundColor 	: colors.white,
		borderBottom		: `1px solid ${colors.lighterGray}`,
		fontWeight 			: 500,
		cursor 				: 'pointer',

		transition 			: 'background-color 0.2s ease-in, color 0.2s ease-in',

		':hover' : {
			backgroundColor  	: colors.blue,
			fill 				: colors.white,
			color 				: colors.white,
		},
		':active' : {
			backgroundColor  	: colors.blue,
			fill 				: colors.white,
			color 				: colors.white,
		},
	},
	checkedSegmented : {
		backgroundColor  	: colors.blue,
		fill 				: colors.white,
		color 				: colors.white,
	},
	linkIcon : {
		width 			: 20,
		height 			: 20,
		marginRight 	: 15,

		transition 			: 'fill 0.2s ease-in'
	},
	linkText : {
		fontSize 		: 16,
		margin 			: 0,
		lineHeight 		: '60px',
	}
}
