//
//TE Version 0.1.0
//

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'
import styles from './styles'

class TERow extends Component {

	renderSizeStyling(size){
		switch (size){
			case 'full':
				return { width: '100%' }
			case 'half':
				return { width: '50%' }
			case 'third':
				return { width: '33.33%' }
			case 'forth':
				return { width: '25%' }
			case 'condensed':
				return { width: 'auto' }
			default:
				return { width: '100%' }
		}
	}

	renderPositionStyling(size, last){
		if (size === 'full'){
			return {}
		}

		if (last){
			return { paddingLeft: 5 }
		}
		return { paddingRight: 5 }
	}

	render() {
		const {
			size,
			last,
			style,
			children
		} = this.props

		return (
			<div
				className="form-row"
				style={{
					...styles.container,
					...this.renderSizeStyling(size),
					...this.renderPositionStyling(size, last),
					...style
				}}
			>
				{children}
			</div>
		)
	}
}

TERow.propTypes = {
	size 			: PropTypes.string,
	last 			: PropTypes.bool,
	style			: PropTypes.object,
	children		: PropTypes.node,
}

TERow.defaultProps = {
	size 	: 'full',
	last 	: false,
}

export default Radium(TERow)
