import { colors } from './../../../config/styles';

const navBarHeight = '100px'

export default {
	container : {
		position 			: 'relative',
		backgroundColor 	: colors.white,
		height 				: navBarHeight,
		width 				: '100%',
		zIndex				: 100,
		boxShadow 			: `0px 1px 3px 0px ${colors.shadow}`
	},
	brandContainer : {
		position			: 'absolute',
		left				: 30,
		top					: 0,
		height				: navBarHeight,
		padding				: '30px 10px',
		zIndex				: 10,
		cursor				: 'pointer',
	},
	brandLink : {
		height				: '100%',
	},
	logo : {
		position 			: 'relative',
		height				: '100%',
	},
	navContainer : {
		position			: 'relative',
		width				: '100%',
		height 				: '100%',
		textAlign			: 'right',
	},
	ul : {
		position			: 'relative',
		width				: '100%',
		height 				: '100%',
		margin				: 0,
		paddingRight		: 30
	},
	li : {
		position			: 'relative',
		display				: 'inline-block',
		lineHeight			: navBarHeight,
		textAlign			: 'center',
		padding				: '0px 15px',
	},
	linkWrapper : {
		color 				: colors.darkGray,
		transition			: 'color 0.2s ease-in',

		':hover' : {
			color : colors.blue,
		},
		':active' : {
			color : colors.blue,
		},
	},
	link : {
		textAlign			: 'center',
		textDecoration		: 'none',
		fontWeight 			: 300,
		fontSize 			: 16,
	},
	active : {
		color 				: colors.blue
	},
	navButton : {
		WebkitAppearance	: 'none',
		backgroundColor		: 'transparent',
		border 				: 'none',
		fontSize 			: 16,
		textAlign 			: 'center',
		cursor				: 'pointer',
		fontWeight			: 500,
		color 				: colors.darkGray,

		transition			: 'color 0.2s ease-in',

		':hover' : {
			color : colors.blue,
		},
		':active' : {
			color : colors.blue,
		},
		':focus' : {
			outline : 'none'
		}
	}
}
