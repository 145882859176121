import {
    SHOW_ALERT,
    SHOW_CONFIRM,
    HIDE_CONFIRM,
    SHOW_NETWORK_ACTIVITY,
    HIDE_NETWORK_ACTIVITY,
    DispatchKeys,
} from './types'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import {
    validateInputDataArray,
    validateInputData,
    apiRequest,
    generateRandomCode,
    computeUTCOffsetDifferenceFromLatLng,
} from './../helpers'
import settings from './../config/settings'

//Drivers
export const startWatchingDrivers = (uid) => {
    const { FETCH_DRIVERS, SAVE_DRIVERS_WATCHER } = DispatchKeys

    return (dispatch) => {
        const driversWatcherRef = firebase
            .database()
            .ref('Drivers')
            .orderByChild('organization')
            .equalTo(uid)
        driversWatcherRef.on('value', (snapshot) => {
            dispatch({
                type: FETCH_DRIVERS,
                payload: snapshot.val(),
            })
        })

        dispatch({
            type: SAVE_DRIVERS_WATCHER,
            payload: driversWatcherRef,
        })
    }
}
export const stopWatchingDrivers = () => {
    return (dispatch, getState) => {
        const { driversWatcherRef } = getState().Dispatch
        if (driversWatcherRef) {
            driversWatcherRef.off()
            dispatch({ type: DispatchKeys.REMOVE_DRIVERS_WATCHER })
        }
    }
}

//Driver
export const startWatchingDispatchDriver = (uid) => {
    const {
        FETCH_DRIVER,
        SAVE_DRIVER_WATCHER,

        FETCH_DRIVER_LOCATION,
        SAVE_DRIVER_LOCATION_WATCHER,
    } = DispatchKeys

    return (dispatch) => {
        const driverWatcherRef = firebase.database().ref(`Drivers/${uid}`)
        driverWatcherRef.on('value', (snapshot) => {
            dispatch({
                type: FETCH_DRIVER,
                payload: snapshot.val(),
            })
        })

        dispatch({
            type: SAVE_DRIVER_WATCHER,
            payload: driverWatcherRef,
        })

        const driverLocationWatcherRef = firebase.database().ref(`Locations/Drivers/${uid}`)
        driverLocationWatcherRef.on('value', (snapshot) => {
            dispatch({
                type: FETCH_DRIVER_LOCATION,
                payload: snapshot.val(),
            })
        })

        dispatch({
            type: SAVE_DRIVER_LOCATION_WATCHER,
            payload: driverLocationWatcherRef,
        })
    }
}

export const stopWatchingDispatchDriver = () => {
    return (dispatch, getState) => {
        const { driverWatcherRef, driverLocationWatcherRef } = getState().Dispatch
        if (driverWatcherRef) {
            driverWatcherRef.off()
            dispatch({ type: DispatchKeys.REMOVE_DRIVER_WATCHER })
        }
        if (driverLocationWatcherRef) {
            driverLocationWatcherRef.off()
            dispatch({ type: DispatchKeys.REMOVE_DRIVER_LOCATION_WATCHER })
        }
    }
}

export const saveDispatchDriver = (data) => {
    const {
        uid,
        driverFirstNameData,
        driverLastNameData,
        driverPhoneData,
        driverIdData,
        driverVehicleMakeData,
        driverVehicleYearData,
        driverVehicleTypeData,
        driverVehicleLicenseData,
        driverVehicleCityLicenseData,
        driverVehicleIdData,
        driverStatusData,
    } = data

    //Validate Data
    const validationArray = [
        driverFirstNameData,
        driverLastNameData,
        driverPhoneData,
        driverIdData,
        driverVehicleMakeData,
        driverVehicleYearData,
        driverVehicleTypeData,
        driverVehicleLicenseData,
        driverVehicleCityLicenseData,
        driverVehicleIdData,
        driverStatusData,
    ]
    const { validation, validationData } = validateInputDataArray(validationArray)
    if (!validation) {
        return (dispatch) => {
            dispatch({
                type: SHOW_ALERT,
                payload: validateInputData(validationData),
            })
        }
    }

    let carCapacity
    if (driverVehicleTypeData.value === 'Sedan') {
        carCapacity = 3
    } else if (driverVehicleTypeData.value === 'SUV') {
        carCapacity = 6
    }

    let status = -1
    if (driverStatusData.value === '') {
        status = 'Unactivated'
    } else if (driverStatusData.value === '') {
        status = 'Available'
    } else if (driverStatusData.value === '') {
        status = 'In A Request'
    }

    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Saving Chauffeur Details...',
        })

        firebase
            .database()
            .ref(`Drivers/${uid}`)
            .update({
                'profile/nameF': driverFirstNameData.value,
                'profile/nameL': driverLastNameData.value,
                'profile/phone': driverPhoneData.value,
                'profile/chauffeurId': driverIdData.value,
                'activeVehicle/carMake': driverVehicleMakeData.value,
                'activeVehicle/carYear': driverVehicleYearData.value,
                'activeVehicle/carCapacity': carCapacity,
                'activeVehicle/carLicense': driverVehicleLicenseData.value,
                'activeVehicle/carCityLicense': driverVehicleCityLicenseData.value === 'Yes',
                'activeVehicle/vehicleId': driverVehicleIdData.value,
                'statuses/status': status,
            })
            .then(() => {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Success',
                        alertMessage: 'Chauffeur details saved.',
                    },
                })
            })
            .catch((error) => {
                console.log(error)
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Error saving Chauffeur details.',
                    },
                })
            })
    }
}

//Live Dispatch Requests
export const startWatchingDispatchLiveRequests = () => {
    const { FETCH_LIVE_REQUESTS, SAVE_LIVE_REQUESTS_WATCHER } = DispatchKeys

    return (dispatch) => {
        const liveRequestsWatcherRef = firebase.database().ref('RideRequests')
        liveRequestsWatcherRef.on('value', (snapshot) => {
            dispatch({
                type: FETCH_LIVE_REQUESTS,
                payload: snapshot.val(),
            })
        })

        dispatch({
            type: SAVE_LIVE_REQUESTS_WATCHER,
            payload: liveRequestsWatcherRef,
        })
    }
}
export const stopWatchingDispatchLiveRequests = () => {
    return (dispatch, getState) => {
        const { liveRequestsWatcherRef } = getState().Dispatch
        if (liveRequestsWatcherRef) {
            liveRequestsWatcherRef.off()
            dispatch({ type: DispatchKeys.REMOVE_LIVE_REQUESTS_WATCHER })
        }
    }
}

//Historic Dispatch Requests
export const startWatchingDispatchHistoricRequests = () => {
    const { FETCH_HISTORIC_REQUESTS, SAVE_HISTORIC_REQUESTS_WATCHER } = DispatchKeys

    return (dispatch) => {
        const historicRequestsWatcherRef = firebase.database().ref(`History/All`)
        historicRequestsWatcherRef.on('value', (snapshot) => {
            dispatch({
                type: FETCH_HISTORIC_REQUESTS,
                payload: snapshot.val(),
            })
        })

        dispatch({
            type: SAVE_HISTORIC_REQUESTS_WATCHER,
            payload: historicRequestsWatcherRef,
        })
    }
}
export const stopWatchingDispatchHistoricRequests = () => {
    return (dispatch, getState) => {
        const { historicRequestsWatcherRef } = getState().Dispatch
        if (historicRequestsWatcherRef) {
            historicRequestsWatcherRef.off()
            dispatch({ type: DispatchKeys.REMOVE_HISTORIC_REQUESTS_WATCHER })
        }
    }
}
//Live Ride Request
export const startWatchingDispatchLiveRequest = (uid) => {
    const {
        FETCH_LIVE_REQUEST,
        SAVE_LIVE_REQUEST_WATCHER,

        FETCH_REQUEST_RIDER,
        SAVE_REQUEST_RIDER_WATCHER,
        REMOVE_REQUEST_RIDER_WATCHER,

        FETCH_REQUEST_RIDER_LOCATION,
        SAVE_REQUEST_RIDER_LOCATION_WATCHER,
        REMOVE_REQUEST_RIDER_LOCATION_WATCHER,

        FETCH_DRIVER,
        SAVE_DRIVER_WATCHER,
        REMOVE_DRIVER_WATCHER,

        FETCH_DRIVER_LOCATION,
        SAVE_DRIVER_LOCATION_WATCHER,
        REMOVE_DRIVER_LOCATION_WATCHER,

        FETCH_REQUEST_ORGANIZATION,
        SAVE_REQUEST_ORGANIZATION_WATCHER,
        REMOVE_REQUEST_ORGANIZATION_WATCHER,
    } = DispatchKeys

    return (dispatch, getState) => {
        const liveRequestWatcherRef = firebase.database().ref(`RideRequests/${uid}`)
        liveRequestWatcherRef.on('value', (snapshot) => {
            if (snapshot.val()) {
                const { riderId, driverId, organizationId } = snapshot.val()
                const {
                    liveRequest,
                    requestRiderWatcherRef,
                    requestRiderLocationWatcherRef,
                    driverWatcherRef,
                    driverLocationWatcherRef,
                    organizationWatcherRef,
                } = getState().Dispatch

                //
                // Rider
                //
                if (liveRequest && riderId !== liveRequest.riderId) {
                    //Stop Rider Watcher
                    if (requestRiderWatcherRef) {
                        requestRiderWatcherRef.off()
                        dispatch({ type: REMOVE_REQUEST_RIDER_WATCHER })
                    }
                    //Stop Rider Location Watcher
                    if (requestRiderLocationWatcherRef) {
                        requestRiderLocationWatcherRef.off()
                        dispatch({ type: REMOVE_REQUEST_RIDER_LOCATION_WATCHER })
                    }
                }
                if (
                    riderId &&
                    (!requestRiderWatcherRef || (liveRequest && riderId !== liveRequest.riderId))
                ) {
                    //Watch Rider
                    const requestRiderWatcherRef = firebase.database().ref(`Riders/${riderId}`)
                    requestRiderWatcherRef.on('value', (snapshot) => {
                        dispatch({
                            type: FETCH_REQUEST_RIDER,
                            payload: snapshot.val(),
                        })
                    })
                    dispatch({
                        type: SAVE_REQUEST_RIDER_WATCHER,
                        payload: requestRiderWatcherRef,
                    })

                    //Watch Rider Location
                    const requestRiderLocationWatcherRef = firebase
                        .database()
                        .ref(`Locations/Riders/${riderId}`)
                    requestRiderLocationWatcherRef.on('value', (snapshot) => {
                        dispatch({
                            type: FETCH_REQUEST_RIDER_LOCATION,
                            payload: snapshot.val(),
                        })
                    })
                    dispatch({
                        type: SAVE_REQUEST_RIDER_LOCATION_WATCHER,
                        payload: requestRiderLocationWatcherRef,
                    })
                }

                //
                // Driver
                //
                if (liveRequest && driverId !== liveRequest.driverId) {
                    //Stop Driver Watcher
                    if (driverWatcherRef) {
                        driverWatcherRef.off()
                        dispatch({ type: REMOVE_DRIVER_WATCHER })
                    }
                    //Stop Driver Location Watcher
                    if (driverLocationWatcherRef) {
                        driverLocationWatcherRef.off()
                        dispatch({ type: REMOVE_DRIVER_LOCATION_WATCHER })
                    }
                }
                if (
                    driverId &&
                    (!driverWatcherRef || (liveRequest && driverId !== liveRequest.driverId))
                ) {
                    //Watch Driver
                    const driverWatcherRef = firebase.database().ref(`Drivers/${driverId}`)
                    driverWatcherRef.on('value', (snapshot) => {
                        dispatch({
                            type: FETCH_DRIVER,
                            payload: snapshot.val(),
                        })
                    })
                    dispatch({
                        type: SAVE_DRIVER_WATCHER,
                        payload: driverWatcherRef,
                    })

                    //Watch Driver Location
                    const driverLocationWatcherRef = firebase
                        .database()
                        .ref(`Locations/Drivers/${driverId}`)
                    driverLocationWatcherRef.on('value', (snapshot) => {
                        dispatch({
                            type: FETCH_DRIVER_LOCATION,
                            payload: snapshot.val(),
                        })
                    })
                    dispatch({
                        type: SAVE_DRIVER_LOCATION_WATCHER,
                        payload: driverLocationWatcherRef,
                    })
                }

                //
                // Organization
                //
                if (liveRequest && liveRequest !== liveRequest.organizationId) {
                    //Stop Driver Watcher
                    if (organizationWatcherRef) {
                        organizationWatcherRef.off()
                        dispatch({ type: REMOVE_REQUEST_ORGANIZATION_WATCHER })
                    }
                }
                if (
                    organizationId &&
                    (!organizationWatcherRef ||
                        (liveRequest && organizationId !== liveRequest.organizationId))
                ) {
                    //Watch Driver
                    const organizationWatcherRef = firebase
                        .database()
                        .ref(`Organizations/OrgInfo/${organizationId}`)
                    organizationWatcherRef.on('value', (snapshot) => {
                        dispatch({
                            type: FETCH_REQUEST_ORGANIZATION,
                            payload: snapshot.val(),
                        })
                    })
                    dispatch({
                        type: SAVE_REQUEST_ORGANIZATION_WATCHER,
                        payload: organizationWatcherRef,
                    })
                }
            }

            dispatch({
                type: FETCH_LIVE_REQUEST,
                payload: snapshot.val(),
            })
        })
        dispatch({
            type: SAVE_LIVE_REQUEST_WATCHER,
            payload: liveRequestWatcherRef,
        })
    }
}
export const stopWatchingDispatchLiveRequest = () => {
    const {
        REMOVE_LIVE_REQUEST_WATCHER,
        REMOVE_REQUEST_RIDER_WATCHER,
        REMOVE_DRIVER_WATCHER,
        REMOVE_DRIVER_LOCATION_WATCHER,
        REMOVE_REQUEST_ORGANIZATION_WATCHER,
    } = DispatchKeys

    return (dispatch, getState) => {
        const {
            liveRequestWatcherRef,
            requestRiderWatcherRef,
            driverWatcherRef,
            driverLocationWatcherRef,
            organizationWatcherRef,
        } = getState().Dispatch

        if (liveRequestWatcherRef) {
            liveRequestWatcherRef.off()
            dispatch({ type: REMOVE_LIVE_REQUEST_WATCHER })
        }
        if (requestRiderWatcherRef) {
            requestRiderWatcherRef.off()
            dispatch({ type: REMOVE_REQUEST_RIDER_WATCHER })
        }
        if (driverWatcherRef) {
            driverWatcherRef.off()
            dispatch({ type: REMOVE_DRIVER_WATCHER })
        }
        if (driverLocationWatcherRef) {
            driverLocationWatcherRef.off()
            dispatch({ type: REMOVE_DRIVER_LOCATION_WATCHER })
        }
        if (organizationWatcherRef) {
            organizationWatcherRef.off()
            dispatch({ type: REMOVE_REQUEST_ORGANIZATION_WATCHER })
        }
    }
}

export const showDispatchEditRequestPopup = () => ({ type: DispatchKeys.SHOW_EDIT_REQUESTS_POPUP })
export const hideDispatchEditRequestPopup = () => ({ type: DispatchKeys.HIDE_EDIT_REQUESTS_POPUP })

export const showDispatchUpdateStatusesPopup = () => ({
    type: DispatchKeys.SHOW_EDIT_STATUSES_POPUP,
})
export const hideDispatchUpdateStatusesPopup = () => ({
    type: DispatchKeys.HIDE_EDIT_STATUSES_POPUP,
})
export const saveDispatchUpdateStatuses = (data) => {
    const { SAVE_UPDATE_STATUSES_SUCCESS, SAVE_UPDATE_STATUSES_FAIL } = DispatchKeys

    const { uid, liveRequest, onTheWay, onLocation, passengerInCar, droppedOff } = data
    const { startLat, startLon } = liveRequest
    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Saving Timestamps...',
        })

        const timezoneOffset = computeUTCOffsetDifferenceFromLatLng(startLat, startLon) * 60 * 1000

        firebase
            .database()
            .ref(`RideRequests/${uid}/statusTimes`)
            .update({
                startingPickup: onTheWay ? (onTheWay + timezoneOffset) / 1000 : null,
                onLocation: onLocation ? (onLocation + timezoneOffset) / 1000 : null,
                pickedUp: passengerInCar ? (passengerInCar + timezoneOffset) / 1000 : null,
                droppedOff: droppedOff ? (droppedOff + timezoneOffset) / 1000 : null,
            })
            .then((response) => {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: SAVE_UPDATE_STATUSES_SUCCESS })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Success',
                        alertMessage: 'Request timestamps have been saved.',
                    },
                })
            })
            .catch((error) => {
                console.log(error)
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: SAVE_UPDATE_STATUSES_FAIL })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Error saving request timestamps.',
                    },
                })
            })
    }
}

export const dispatchCancelRequestNoFee = (uid, history) => {
    return (dispatch) => {
        const cancelRequestNoFee = () => {
            dispatch({
                type: SHOW_NETWORK_ACTIVITY,
                payload: 'Canceling Request...',
            })

            const data = {
                uid,
                finishOption: 'FreeCancel',
            }
            apiRequest('POST', 'api/v1/finishRequest', data)
                .then((response) => {
                    dispatch({ type: HIDE_CONFIRM })
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                    history.push('/dispatch/live')
                    dispatch({
                        type: SHOW_ALERT,
                        payload: {
                            alertTitle: 'Success',
                            alertMessage: 'Request has been canceled.',
                        },
                    })
                })
                .catch((error) => {
                    console.log(error)
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                    dispatch({
                        type: SHOW_ALERT,
                        payload: {
                            alertTitle: 'Error',
                            alertMessage: 'Error canceling request.',
                        },
                    })
                })
        }

        dispatch({
            type: SHOW_CONFIRM,
            payload: {
                confirmTitle: 'Cancel Ride Request',
                confirmMessage:
                    'Are you sure you want to cancel this request? By canceling in this way, the client will not be charged any fee.',
                confirmLeftTitle: 'Back',
                confirmRightOnClick: cancelRequestNoFee,
                confirmRightTitle: 'Cancel Request',
            },
        })
    }
}
export const dispatchCancelRequestNoShow = (uid, history) => {
    return (dispatch) => {
        const cancelRequestNoFee = () => {
            dispatch({
                type: SHOW_NETWORK_ACTIVITY,
                payload: 'Canceling Request...',
            })

            const data = {
                uid,
                finishOption: 'NoShow',
            }
            apiRequest('POST', 'api/v1/finishRequest', data)
                .then((response) => {
                    dispatch({ type: HIDE_CONFIRM })
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                    history.push('/dispatch/live')
                    dispatch({
                        type: SHOW_ALERT,
                        payload: {
                            alertTitle: 'Success',
                            alertMessage: 'Request has been marked as a no show.',
                        },
                    })
                })
                .catch((error) => {
                    console.log(error)
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                    dispatch({
                        type: SHOW_ALERT,
                        payload: {
                            alertTitle: 'Error',
                            alertMessage: 'Error marking request as a no show.',
                        },
                    })
                })
        }

        dispatch({
            type: SHOW_CONFIRM,
            payload: {
                confirmTitle: 'Mark Request as a No Show',
                confirmMessage:
                    'Are you sure you want to mark this request as a no show? By canceling in this way, the client will be charged the full fee.',
                confirmLeftTitle: 'Back',
                confirmRightOnClick: cancelRequestNoFee,
                confirmRightTitle: 'No Show',
            },
        })
    }
}
export const showDispatchCompeteRequestPopup = () => ({
    type: DispatchKeys.SHOW_COMPLETE_REQUEST_POPUP,
})
export const hideDispatchCompeteRequestPopup = () => ({
    type: DispatchKeys.HIDE_COMPLETE_REQUEST_POPUP,
})

export const dispatchCompleteRequest = (data) => {
    const { COMPLETE_REQUEST_SUCCESS, COMPLETE_REQUEST_FAIL } = DispatchKeys

    const { liveRequest, uid, additionalFeeData, history } = data

    //Validate Data
    if (!additionalFeeData.valid) {
        return (dispatch) => {
            dispatch({
                type: SHOW_ALERT,
                payload: validateInputData(additionalFeeData),
            })
        }
    }

    if (!liveRequest || (liveRequest && !liveRequest.driverId)) {
        return (dispatch) => {
            dispatch({
                type: SHOW_ALERT,
                payload: {
                    alertTitle: 'Error',
                    alertMessage:
                        'Error completing request. Request must have a driver assigned to it to complete.',
                },
            })
        }
    }

    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Completing Request...',
        })
        const data = {
            uid,
            finishOption: 'Complete',
            manualLateCharge: Number(additionalFeeData.value),
        }
        apiRequest('POST', 'api/v1/finishRequest', data)
            .then((response) => {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: COMPLETE_REQUEST_SUCCESS })
                history.push('/dispatch/live')
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Success',
                        alertMessage: 'Request has been completed.',
                    },
                })
            })
            .catch((error) => {
                console.log(error)
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: COMPLETE_REQUEST_FAIL })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Error completing request.',
                    },
                })
            })
    }
}

export const dispatchAssignChauffeur = (data) => {
    const { ASSIGN_CHAUFFEUR_SUCCESS, ASSIGN_CHAUFFEUR_FAIL } = DispatchKeys

    const { selectedDriverData, uid, riderModel } = data

    // console.log(data)
    //Validate Data
    if (!selectedDriverData.valid) {
        return (dispatch) => {
            dispatch({
                type: SHOW_ALERT,
                payload: validateInputData(selectedDriverData),
            })
        }
    }

    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Assigning Chauffeur...',
        })
        const data = {
            driverId: selectedDriverData.value.uid,
            requestId: uid,
            providerId: riderModel.dispatchUID,
        }
        apiRequest('POST', 'api/v1/acceptRequest', data)
            .then((response) => {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: ASSIGN_CHAUFFEUR_SUCCESS })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Success',
                        alertMessage: 'Chauffeur Assigned.',
                    },
                })
            })
            .catch((error) => {
                console.log(error)
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: ASSIGN_CHAUFFEUR_FAIL })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Error assigning chauffeur.',
                    },
                })
            })
    }
}
export const dispatchRemoveChauffeur = (data) => {
    const { liveRequest, uid } = data

    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Removing Chauffeur...',
        })
        firebase
            .database()
            .ref(`Drivers/${liveRequest.driverId}/statuses`)
            .update({ status: 1 })
            .then((response) => {
                return firebase
                    .database()
                    .ref(`RideRequests/${uid}`)
                    .update({
                        driverId: null,
                        status: 1,
                        providerId: null,
                        statusTimes: null,
                        'pricing/lateFee': false,
                    })
            })
            .then((response) => {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Success',
                        alertMessage: 'Chauffeur removed.',
                    },
                })
            })
            .catch((error) => {
                console.log(error)
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Error removing chauffeur.',
                    },
                })
            })
    }
}

//Historic Ride Request
export const startWatchingDispatchHistoricRequest = (uid) => {
    const {
        FETCH_HISTORIC_REQUEST,
        SAVE_HISTORIC_REQUEST_WATCHER,

        FETCH_REQUEST_RIDER,
        SAVE_REQUEST_RIDER_WATCHER,
        REMOVE_REQUEST_RIDER_WATCHER,

        FETCH_REQUEST_RIDER_LOCATION,
        SAVE_REQUEST_RIDER_LOCATION_WATCHER,
        REMOVE_REQUEST_RIDER_LOCATION_WATCHER,

        FETCH_DRIVER,
        SAVE_DRIVER_WATCHER,
        REMOVE_DRIVER_WATCHER,

        FETCH_REQUEST_ORGANIZATION,
        SAVE_REQUEST_ORGANIZATION_WATCHER,
        REMOVE_REQUEST_ORGANIZATION_WATCHER,
    } = DispatchKeys

    return (dispatch, getState) => {
        const historicRequestWatcherRef = firebase.database().ref(`History/All/${uid}`)
        historicRequestWatcherRef.on('value', (snapshot) => {
            if (snapshot.val()) {
                const { riderId, driverId, organizationId } = snapshot.val()
                const {
                    historicRequest,
                    requestRiderWatcherRef,
                    requestRiderLocationWatcherRef,
                    driverWatcherRef,
                    organizationWatcherRef,
                } = getState().Dispatch

                //
                // Rider
                //
                if (historicRequest && riderId !== historicRequest.riderId) {
                    //Stop Rider Watcher
                    if (requestRiderWatcherRef) {
                        requestRiderWatcherRef.off()
                        dispatch({ type: REMOVE_REQUEST_RIDER_WATCHER })
                    }
                    //Stop Rider Location Watcher
                    if (requestRiderLocationWatcherRef) {
                        requestRiderLocationWatcherRef.off()
                        dispatch({ type: REMOVE_REQUEST_RIDER_LOCATION_WATCHER })
                    }
                }
                if (
                    riderId &&
                    (!requestRiderWatcherRef ||
                        (historicRequest && riderId !== historicRequest.riderId))
                ) {
                    //Watch Rider
                    const requestRiderWatcherRef = firebase.database().ref(`Riders/${riderId}`)
                    requestRiderWatcherRef.on('value', (snapshot) => {
                        dispatch({
                            type: FETCH_REQUEST_RIDER,
                            payload: snapshot.val(),
                        })
                    })
                    dispatch({
                        type: SAVE_REQUEST_RIDER_WATCHER,
                        payload: requestRiderWatcherRef,
                    })

                    //Watch Rider Location
                    const requestRiderLocationWatcherRef = firebase
                        .database()
                        .ref(`Locations/Riders/${riderId}`)
                    requestRiderLocationWatcherRef.on('value', (snapshot) => {
                        dispatch({
                            type: FETCH_REQUEST_RIDER_LOCATION,
                            payload: snapshot.val(),
                        })
                    })
                    dispatch({
                        type: SAVE_REQUEST_RIDER_LOCATION_WATCHER,
                        payload: requestRiderLocationWatcherRef,
                    })
                }

                //
                // Driver
                //
                if (historicRequest && driverId !== historicRequest.driverId) {
                    //Stop Driver Watcher
                    if (driverWatcherRef) {
                        driverWatcherRef.off()
                        dispatch({ type: REMOVE_DRIVER_WATCHER })
                    }
                }
                if (
                    driverId &&
                    (!driverWatcherRef ||
                        (historicRequest && driverId !== historicRequest.driverId))
                ) {
                    //Watch Driver
                    const driverWatcherRef = firebase.database().ref(`Drivers/${driverId}`)
                    driverWatcherRef.on('value', (snapshot) => {
                        dispatch({
                            type: FETCH_DRIVER,
                            payload: snapshot.val(),
                        })
                    })
                    dispatch({
                        type: SAVE_DRIVER_WATCHER,
                        payload: driverWatcherRef,
                    })
                }

                //
                // Organization
                //
                if (historicRequest && historicRequest !== historicRequest.organizationId) {
                    //Stop Driver Watcher
                    if (organizationWatcherRef) {
                        organizationWatcherRef.off()
                        dispatch({ type: REMOVE_REQUEST_ORGANIZATION_WATCHER })
                    }
                }
                if (
                    organizationId &&
                    (!organizationWatcherRef ||
                        (historicRequest && organizationId !== historicRequest.organizationId))
                ) {
                    //Watch Driver
                    const organizationWatcherRef = firebase
                        .database()
                        .ref(`Organizations/OrgInfo/${organizationId}`)
                    organizationWatcherRef.on('value', (snapshot) => {
                        dispatch({
                            type: FETCH_REQUEST_ORGANIZATION,
                            payload: snapshot.val(),
                        })
                    })
                    dispatch({
                        type: SAVE_REQUEST_ORGANIZATION_WATCHER,
                        payload: organizationWatcherRef,
                    })
                }
            }

            dispatch({
                type: FETCH_HISTORIC_REQUEST,
                payload: snapshot.val(),
            })
        })
        dispatch({
            type: SAVE_HISTORIC_REQUEST_WATCHER,
            payload: historicRequestWatcherRef,
        })
    }
}
export const stopWatchingDispatchHistoricRequest = () => {
    const {
        REMOVE_HISTORIC_REQUEST_WATCHER,
        REMOVE_REQUEST_RIDER_WATCHER,
        REMOVE_DRIVER_WATCHER,
        REMOVE_REQUEST_ORGANIZATION_WATCHER,
    } = DispatchKeys

    return (dispatch, getState) => {
        const {
            historicRequestWatcherRef,
            requestRiderWatcherRef,
            driverWatcherRef,
            organizationWatcherRef,
        } = getState().Dispatch

        if (historicRequestWatcherRef) {
            historicRequestWatcherRef.off()
            dispatch({ type: REMOVE_HISTORIC_REQUEST_WATCHER })
        }
        if (requestRiderWatcherRef) {
            requestRiderWatcherRef.off()
            dispatch({ type: REMOVE_REQUEST_RIDER_WATCHER })
        }
        if (driverWatcherRef) {
            driverWatcherRef.off()
            dispatch({ type: REMOVE_DRIVER_WATCHER })
        }
        if (organizationWatcherRef) {
            organizationWatcherRef.off()
            dispatch({ type: REMOVE_REQUEST_ORGANIZATION_WATCHER })
        }
    }
}

export const showDispatchAddDriverPopup = () => ({ type: DispatchKeys.SHOW_ADD_DRIVER_POPUP })
export const hideDispatchAddDriverPopup = () => ({ type: DispatchKeys.HIDE_ADD_DRIVER_POPUP })

export const dispatchAddDriver = (data) => {
    const { CREATE_DRIVER_SUCCESS, CREATE_DRIVER_FAIL } = DispatchKeys
    const {
        riderModel,
        createDriverFirstNameData,
        createDriverLastNameData,
        createDriverPhoneData,
        createDriverEmailData,
    } = data

    //Validate Data
    const validationArray = [
        createDriverFirstNameData,
        createDriverLastNameData,
        createDriverPhoneData,
        createDriverEmailData,
    ]
    const { validation, validationData } = validateInputDataArray(validationArray)
    if (!validation) {
        return (dispatch) => {
            dispatch({
                type: SHOW_ALERT,
                payload: validateInputData(validationData),
            })
        }
    }

    return (dispatch) => {
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Creating Chauffeur...',
        })

        const { dev, DEVELOPMENT, PRODUCTION } = settings
        const {
            FIREBASE_APIKEY,
            FIREBASE_AUTHDOMAIN,
            FIREBASE_URL,
            FIREBASE_PRODUCT_ID,
            FIREBASE_STORAGEBUCKET,
            FIREBASE_MESSAGING_ID,
        } = dev ? DEVELOPMENT : PRODUCTION

        const secondaryApp = firebase.initializeApp(
            {
                apiKey: FIREBASE_APIKEY,
                authDomain: FIREBASE_AUTHDOMAIN,
                databaseURL: FIREBASE_URL,
                projectId: FIREBASE_PRODUCT_ID,
                storageBucket: FIREBASE_STORAGEBUCKET,
                messagingSenderId: FIREBASE_MESSAGING_ID,
            },
            'secondary'
        )

        const randomPassword = generateRandomCode(2)

        secondaryApp
            .auth()
            .createUserWithEmailAndPassword(createDriverEmailData.value, randomPassword)
            .then(() => {
                const { uid } = secondaryApp.auth().currentUser
                return firebase
                    .database()
                    .ref(`UserPermissions/${uid}`)
                    .update({ driver: true })
            })
            .then(() => {
                const { uid } = secondaryApp.auth().currentUser
                return firebase
                    .database()
                    .ref(`Drivers/${uid}`)
                    .set({
                        profile: {
                            nameF: createDriverFirstNameData.value,
                            nameL: createDriverLastNameData.value,
                            phone: createDriverPhoneData.value,
                        },
                        email: createDriverEmailData.value,
                        organization: riderModel.dispatchUID,
                        statuses: {
                            status: -1,
                            clockedIn: false,
                        },
                    })
            })
            .then(() => {
                return secondaryApp.auth().signOut()
            })
            .then(() => {
                const data = {
                    firstName: createDriverFirstNameData.value,
                    lastName: createDriverLastNameData.value,
                    email: createDriverEmailData.value,
                    tempPass: randomPassword,
                }
                return apiRequest('POST', 'addDriver', data)
            })
            .then((response) => {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: CREATE_DRIVER_SUCCESS })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Success',
                        alertMessage:
                            'Chauffeur has been created. An email has been sent to the chauffeur with instructions and a temporary password.',
                    },
                })
            })
            .catch((error) => {
                console.log(error)
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: CREATE_DRIVER_FAIL })
                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Error',
                        alertMessage: 'Error creating chauffeur.',
                    },
                })
            })
    }
}
