//
//TE Version 0.1.0
//

import React, { Component } from 'react'
import { Marker } from "react-google-maps"
//import PropTypes from 'prop-types'

//import settings from './../../../config/settings'
//import images from './../../../config/images'

//import styles from './styles'

class TEGoogleMarker extends Component {
	render() {
		const {
			children,
			...rest
		} = this.props
		return (
			<Marker {...rest}>
				{children}
			</Marker>
		)
	}
}

TEGoogleMarker.propTypes = {}

TEGoogleMarker.defaultProps = {}

export default TEGoogleMarker
