//import { colors } from './../../../config/styles'

export default {
	mainPanel : {
		position        : 'relative',
		width           : '100%',
		minHeight       : '100%',
	},
	frontContentWrapper : {
        position    : 'relative',
        minHeight   : 'calc(100vh - 160px)',
	}
}
