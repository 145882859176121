//import { colors } from './../../../config/styles';

export default {
    linkWrapper : {
        cursor      : 'pointer'
    },
    link : {
        color           : 'inherit',
        textDecoration  : 'inherit',
        fontSize        : 'inherit',
        display         : 'inherit',
    }
}
