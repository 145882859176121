import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import ReactTable from 'react-table'

import {
	startWatchingRiderLiveRequests,
	startWatchingRiderLiveOrgRequests,
	stopWatchingRiderLiveRequests,
	stopWatchingRiderLiveOrgRequests,
} from './../../../actions'
import { getRiderLiveRequestsArray } from './../../../selectors'
import { RiderKeys } from './../../../actions/types'

import TEHelmet from './../../../component/Header/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import TESearchSelectRow from './../../../component/Form/TESearchSelectRow'

import { tableColumns } from './tableConfigs'
import { customTableFilter } from './../../../helpers'
import styles from './styles'

class Live extends Component {
	state = { activeView : 'Routes' }

	componentDidMount(){
		const {
			startWatchingRiderLiveRequests,
			startWatchingRiderLiveOrgRequests,
			riderModel
		} = this.props

		startWatchingRiderLiveRequests()
		if (riderModel && riderModel.organizations){
			startWatchingRiderLiveOrgRequests(riderModel.organizations)
		}
	}
	componentWillUnmount(){
		const {
			stopWatchingRiderLiveRequests,
			stopWatchingRiderLiveOrgRequests,
		} = this.props

		stopWatchingRiderLiveRequests()
		stopWatchingRiderLiveOrgRequests()
	}

	render(){
		const meta = {
			title: 'FirstClass | Live',
			description: '',
		}

		const {
			riderModel,
			requestArray,
			organizationArray,
			liveOrganizationSelectedData,
		} = this.props

		return (
			<Fragment>
				<TEHelmet {...meta}/>
				<PanelWrapper style={styles.panelWrapper}>
					<Panel
						title='Live Ride Requests'
						rightComponent={
							<div style={styles.orgSelectorWrapper}>
								{riderModel && riderModel.organizations &&
									<TESearchSelectRow
										labelForKey='activeRequetss'
										options={organizationArray}
										rowData={liveOrganizationSelectedData}
										optionLabelPath='name'
										onChangeKey={RiderKeys.LIVE_ORGANIZATION_SELECTED_CHANGED}
										validationRules={['required']}
										labelStyles={{ display: 'none' }}
									/>
								}
							</div>
						}
					>
						<ReactTable
							data={requestArray}
							columns={tableColumns}
							defaultPageSize={10}
							filterable
							defaultFilterMethod={customTableFilter}
							defaultSorted={[{
								id: "requestTime",
								desc: false
							}]}
						/>
					</Panel>
				</PanelWrapper>
			</Fragment>
		)
	}
}

Live.propTypes = {}

Live.defaultProps = {}

const mapStateToProps = (state) => {
	const {
		riderModel,
		organizations,
		liveOrganizationRequests,
		liveOrganizationSelectedData,
	} = state.Rider

	let requestArray
	if (liveOrganizationSelectedData.value.uid === 'Myself'){
		requestArray = getRiderLiveRequestsArray(state)
	}else{
		requestArray = []
		for (const key in liveOrganizationRequests[liveOrganizationSelectedData.value.uid]){
			const dataToMap = liveOrganizationRequests[liveOrganizationSelectedData.value.uid][key]
			dataToMap.uid = key
			requestArray.push(dataToMap)
		}
	}

	const organizationArray = []
	console.log(organizations)
	for (const key in organizations){
		const dataToMap = organizations[key]
		dataToMap.uid = key
		if (dataToMap.type !== 'Livery'){
			organizationArray.push(dataToMap)
		}
	}
	organizationArray.sort((a, b) => {
		if (a.name < b.name) { return -1 }
		if (a.name > b.name) { return 1 }
		return 0
	})
	organizationArray.unshift({ name: 'Myself', uid: 'Myself'})

	return {
		riderModel,
		requestArray,
		organizationArray,
		liveOrganizationRequests,
		liveOrganizationSelectedData,
	}
}

export default connect(mapStateToProps, {
	startWatchingRiderLiveRequests,
	startWatchingRiderLiveOrgRequests,
	stopWatchingRiderLiveRequests,
	stopWatchingRiderLiveOrgRequests,
})(Radium(Live))
