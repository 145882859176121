import { AdminKeys } from './../actions/types'

const INITIAL_STATE = {
	riders 				: undefined,
	ridersLoaded 		: false,
	ridersWatcherRef 	: undefined,

	//Organizations
	organizations 				: undefined,
	organizationsLoaded 		: false,
	organizationsWatcherRef 	: undefined,

	createOrganizationPopupVisible 	: false,

	createOrganizationNameData: {
		title		: 'Organization Name',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},
	createOrganizationTypeData: {
		title		: 'Organization Type',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},

	organizationModel 				: undefined,
	organizationModelLoaded 		: false,
	organizationWatcherRef 			: undefined,

	organizationNameData: {
		title		: 'Organization Name',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},
	organizationTypeData: {
		title		: 'Organization Type',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},

	organizationUsers 				: undefined,
	organizationUsersLoaded 		: false,
	organizationUsersWatcherRefs 	: undefined,

	addUserPopupVisible 	: false,

	addUserData: {
		title		: 'Select A Rider',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},
	addPermissionData: {
		title		: 'Permissions',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},

	organizationRiderModel  		: undefined,
	organizationRiderModelLoaded 	: false,
	organizationRiderWatcherRef 	: undefined,

	savePermissionData: {
		title		: 'Permissions',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},

	//Dispatch
	dispatchCompanies 				: undefined,
	dispatchCompaniesLoaded 		: false,
	dispatchCompaniesWatcherRef 	: undefined,

	createDispatchCompanyPopupVisible 	: false,

	createDispatchCompanyNameData: {
		title		: 'Dispatch Company Name',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},

	dispatchCompanyModel 				: undefined,
	dispatchCompanyModelLoaded 			: false,
	dispatchCompanyWatcherRef 			: undefined,

	dispatchCompanyNameData: {
		title		: 'Dispatch Company Name',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},

	dispatchers 				: undefined,
	dispatchersLoaded 			: false,
	dispatchersWatcherRef	 	: undefined,

	addDispatcherPopupVisible 	: false,

	addDispatcherData: {
		title		: 'Select A User',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},
}

export default (state = INITIAL_STATE, action) => {
	const {
		FETCH_RIDERS,
		SAVE_RIDERS_WATCHER,
		REMOVE_RIDERS_WATCHER,

		//Organizations
		FETCH_ORGANIZATIONS,
		SAVE_ORGANIZATIONS_WATCHER,
		REMOVE_ORGANIZATIONS_WATCHER,

		SHOW_CREATE_ORGANIZATION_POPUP,
		HIDE_CREATE_ORGANIZATION_POPUP,

		CREATE_ORGANIZATION_NAME_CHANGED,
		CREATE_ORGANIZATION_TYPE_CHANGED,

		CREATE_ORGANIZATION_SUCCESS,
		CREATE_ORGANIZATION_FAIL,

		FETCH_ORGANIZATION,
		SAVE_ORGANIZATION_WATCHER,
		REMOVE_ORGANIZATION_WATCHER,

		ORGANIZATION_NAME_CHANGED,
		ORGANIZATION_TYPE_CHANGED,

		SAVE_ORGANIZATION_SUCCESS,
		SAVE_ORGANIZATION_FAIL,

		FETCH_ORGANIZATION_USERS,
		SAVE_ORGANIZATION_USERS_WATCHERS,
		REMOVE_ORGANIZATION_USER,
		REMOVE_ORGANIZATION_USERS_WATCHERS,

		SHOW_ADD_RIDER_POPUP,
		HIDE_ADD_RIDER_POPUP,

		ADD_USER_CHANGED,
		ADD_PERMISSION_CHANGED,

		ADD_USER_TO_ORG_SUCCESS,
		ADD_USER_TO_ORG_FAIL,

		FETCH_ORG_RIDER,
		SAVE_ORG_RIDER_WATCHER,
		REMOVE_ORG_RIDER_WATCHER,

		SAVE_PERMISSION_CHANGED,

		//Dispatch
		FETCH_DISPATCH_COMPANIES,
		SAVE_DISPATCH_COMPANIES_WATCHER,
		REMOVE_DISPATCH_COMPANIES_WATCHER,

		SHOW_CREATE_DISPATCH_COMPANY_POPUP,
		HIDE_CREATE_DISPATCH_COMPANY_POPUP,

		CREATE_DISPATCH_COMPANY_NAME_CHANGED,

		CREATE_DISPATCH_COMPANY_SUCCESS,
		CREATE_DISPATCH_COMPANY_FAIL,

		FETCH_DISPATCH_COMPANY,
		SAVE_DISPATCH_COMPANY_WATCHER,
		REMOVE_DISPATCH_COMPANY_WATCHER,

		FETCH_DISPATCH_COMPANY_USERS,
		SAVE_DISPATCH_COMPANY_USERS_WATCHERS,
		REMOVE_DISPATCH_COMPANY_USERS_WATCHERS,

		DISPATCH_COMPANY_NAME_CHANGED,

		SAVE_DISPATCH_COMPANY_SUCCESS,
		SAVE_DISPATCH_COMPANY_FAIL,

		SHOW_ADD_DISPATCHER_POPUP,
		HIDE_ADD_DISPATCHER_POPUP,

		ADD_DISPATCHER_CHANGED,

		ADD_USER_TO_DISPATCH_COMPANY_SUCCESS,
		ADD_USER_TO_DISPATCH_COMPANY_FAIL,
	} = AdminKeys

	switch (action.type) {
		case FETCH_RIDERS:
			return { ...state, riders: action.payload, ridersLoaded: true }
		case SAVE_RIDERS_WATCHER:
			return { ...state, ridersWatcherRef: action.payload }
		case REMOVE_RIDERS_WATCHER:
			return { ...state,
				riders 				: INITIAL_STATE.riders,
				ridersLoaded 		: INITIAL_STATE.ridersLoaded,
				ridersWatcherRef 	: INITIAL_STATE.ridersWatcherRef,
			}

		case FETCH_ORGANIZATIONS:
			return { ...state, organizations: action.payload, organizationsLoaded: true }
		case SAVE_ORGANIZATIONS_WATCHER:
			return { ...state, organizationsWatcherRef: action.payload }
		case REMOVE_ORGANIZATIONS_WATCHER:
			return { ...state,
				organizations 				: INITIAL_STATE.organizations,
				organizationsLoaded 		: INITIAL_STATE.organizationsLoaded,
				organizationsWatcherRef 	: INITIAL_STATE.organizationsWatcherRef,
			}

		case SHOW_CREATE_ORGANIZATION_POPUP:
			return { ...state, createOrganizationPopupVisible: true }
		case HIDE_CREATE_ORGANIZATION_POPUP:
			return { ...state, createOrganizationPopupVisible: false }

		case CREATE_ORGANIZATION_NAME_CHANGED:
			return { ...state, createOrganizationNameData: { ...state.createOrganizationNameData, ...action.payload } }
		case CREATE_ORGANIZATION_TYPE_CHANGED:
			return { ...state, createOrganizationTypeData: { ...state.createOrganizationTypeData, ...action.payload } }

		case CREATE_ORGANIZATION_SUCCESS:
			return { ...state,
				createOrganizationPopupVisible 		: INITIAL_STATE.createOrganizationPopupVisible,
				createOrganizationNameData 			: INITIAL_STATE.createOrganizationNameData,
				createOrganizationTypeData 			: INITIAL_STATE.createOrganizationTypeData,
			}
		case CREATE_ORGANIZATION_FAIL:
			return state

		case FETCH_ORGANIZATION:
			if (!action.payload){
				return { ...state, organizationModel: action.payload, organizationModelLoaded: true }
			}

			const {
				name,
				type,
			} = action.payload

			const organizationNameData = { ...state.organizationNameData, value: name || INITIAL_STATE.organizationTypeData.value, valid: !!name }
			const organizationTypeData = { ...state.organizationTypeData, value: type || INITIAL_STATE.organizationTypeData.value, valid: !!type }

				return { ...state,
					organizationModel 			: action.payload,
					organizationModelLoaded 	: true,
					organizationNameData,
					organizationTypeData,
				}

		case SAVE_ORGANIZATION_WATCHER:
			return { ...state, organizationWatcherRef: action.payload }
		case REMOVE_ORGANIZATION_WATCHER:
			return { ...state,
				organizationModel 			: INITIAL_STATE.organizationModel,
				organizationModelLoaded 	: INITIAL_STATE.organizationModelLoaded,
				organizationWatcherRef 		: INITIAL_STATE.organizationWatcherRef,
			}

		case ORGANIZATION_NAME_CHANGED:
			return { ...state, organizationNameData: { ...state.organizationNameData, ...action.payload } }
		case ORGANIZATION_TYPE_CHANGED:
			return { ...state, organizationTypeData: { ...state.organizationTypeData, ...action.payload } }


		case SAVE_ORGANIZATION_SUCCESS:
		case SAVE_ORGANIZATION_FAIL:
			return state

		case FETCH_ORGANIZATION_USERS:
			return { ...state, organizationUsers: { ...state.organizationUsers, [action.payload.uid]: action.payload.riderModel }, organizationUsersLoaded: true }
		case SAVE_ORGANIZATION_USERS_WATCHERS:
			return { ...state, organizationUsersWatcherRefs: { ...state.organizationUsersWatcherRefs, [action.payload.uid]: action.payload.ref } }
		case REMOVE_ORGANIZATION_USERS_WATCHERS:
			return { ...state,
				organizationUsers 					: INITIAL_STATE.organizationUsers,
				organizationUsersLoaded 			: INITIAL_STATE.organizationUsersLoaded,
				organizationUsersWatcherRefs 		: INITIAL_STATE.organizationUsersWatcherRefs,
			}
		case REMOVE_ORGANIZATION_USER:
			const { [action.payload]: user, ...restOfUser } = state.organizationUsers
			const { [action.payload]: ref, ...restOfRefs } = state.organizationUsersWatcherRefs

			return { ...state, organizationUsers: restOfUser, organizationUsersWatcherRefs: restOfRefs }

		case SHOW_ADD_RIDER_POPUP:
			return { ...state, addUserPopupVisible: true }
		case HIDE_ADD_RIDER_POPUP:
			return { ...state, addUserPopupVisible: false }

		case ADD_USER_CHANGED:
			return { ...state, addUserData: { ...state.addUserData, ...action.payload } }
		case ADD_PERMISSION_CHANGED:
			return { ...state, addPermissionData: { ...state.addPermissionData, ...action.payload } }

		case ADD_USER_TO_ORG_SUCCESS:
			return { ...state,
				addUserPopupVisible 	: INITIAL_STATE.addUserPopupVisible,
				addUserData 			: INITIAL_STATE.addUserData,
				addPermissionData 		: INITIAL_STATE.addPermissionData,
			}
		case ADD_USER_TO_ORG_FAIL:
			return state

		case FETCH_ORG_RIDER:
			return { ...state, organizationRiderModel: action.payload, organizationRiderModelLoaded: true }
		case SAVE_ORG_RIDER_WATCHER:
			return { ...state, organizationRiderWatcherRef: action.payload }
		case REMOVE_ORG_RIDER_WATCHER:
			return { ...state,
				organizationRiderModel 			: INITIAL_STATE.organizationRiderModel,
				organizationRiderModelLoaded 	: INITIAL_STATE.organizationRiderModelLoaded,
				organizationRiderWatcherRef 	: INITIAL_STATE.organizationRiderWatcherRef,
			}

		case SAVE_PERMISSION_CHANGED:
			return { ...state, savePermissionData: { ...state.savePermissionData, ...action.payload } }

		//
		// Dispatch Companies
		//
		case FETCH_DISPATCH_COMPANIES:
			return { ...state, dispatchCompanies: action.payload, dispatchCompaniesLoaded: true }
		case SAVE_DISPATCH_COMPANIES_WATCHER:
			return { ...state, dispatchCompaniesWatcherRef: action.payload }
		case REMOVE_DISPATCH_COMPANIES_WATCHER:
			return { ...state,
				dispatchCompanies 				: INITIAL_STATE.dispatchCompanies,
				dispatchCompaniesLoaded 		: INITIAL_STATE.dispatchCompaniesLoaded,
				dispatchCompaniesWatcherRef 	: INITIAL_STATE.dispatchCompaniesWatcherRef,
			}

		case SHOW_CREATE_DISPATCH_COMPANY_POPUP:
			return { ...state, createDispatchCompanyPopupVisible: true }
		case HIDE_CREATE_DISPATCH_COMPANY_POPUP:
			return { ...state, createDispatchCompanyPopupVisible: false }

		case CREATE_DISPATCH_COMPANY_NAME_CHANGED:
			return { ...state, createDispatchCompanyNameData: { ...state.createDispatchCompanyNameData, ...action.payload } }

		case CREATE_DISPATCH_COMPANY_SUCCESS:
			return { ...state,
				createDispatchCompanyPopupVisible 	: INITIAL_STATE.createDispatchCompanyPopupVisible,
				createDispatchCompanyNameData 		: INITIAL_STATE.createDispatchCompanyNameData,
			}
		case CREATE_DISPATCH_COMPANY_FAIL:
			return state

		case FETCH_DISPATCH_COMPANY:{
			if (!action.payload){
				return { ...state, dispatchCompanyModel: action.payload, dispatchCompanyModelLoaded: true }
			}
			const { name } = action.payload
			const dispatchCompanyNameData = { ...state.dispatchCompanyNameData, value: name || INITIAL_STATE.dispatchCompanyNameData.value }

			return { ...state, dispatchCompanyModel: action.payload, dispatchCompanyModelLoaded: true, dispatchCompanyNameData }
		}
		case SAVE_DISPATCH_COMPANY_WATCHER:
			return { ...state, dispatchCompanyModelWatcherRef: action.payload }
		case REMOVE_DISPATCH_COMPANY_WATCHER:
			return { ...state,
				dispatchCompanyModel 				: INITIAL_STATE.dispatchers,
				dispatchCompanyModelLoaded 			: INITIAL_STATE.dispatchersLoaded,
				dispatchCompanyModelWatcherRef 		: INITIAL_STATE.dispatchersWatcherRef,
			}

		case FETCH_DISPATCH_COMPANY_USERS:
			return { ...state, dispatchers: action.payload, dispatchersLoaded: true }
		case SAVE_DISPATCH_COMPANY_USERS_WATCHERS:
			return { ...state, dispatchersWatcherRef: action.payload }
		case REMOVE_DISPATCH_COMPANY_USERS_WATCHERS:
			return { ...state,
				dispatchers 				: INITIAL_STATE.dispatchers,
				dispatchersLoaded 			: INITIAL_STATE.dispatchersLoaded,
				dispatchersWatcherRef 		: INITIAL_STATE.dispatchersWatcherRef,
			}

		case DISPATCH_COMPANY_NAME_CHANGED:
			return { ...state, dispatchCompanyNameData: { ...state.dispatchCompanyNameData, ...action.payload } }

		case SAVE_DISPATCH_COMPANY_SUCCESS:
		case SAVE_DISPATCH_COMPANY_FAIL:
			return state

		case SHOW_ADD_DISPATCHER_POPUP:
			return { ...state, addDispatcherPopupVisible: true }
		case HIDE_ADD_DISPATCHER_POPUP:
			return { ...state, addDispatcherPopupVisible: false }

		case ADD_DISPATCHER_CHANGED:
			return { ...state, addDispatcherData: { ...state.addDispatcherData, ...action.payload } }

		case ADD_USER_TO_DISPATCH_COMPANY_SUCCESS:
			return { ...state,
				addDispatcherPopupVisible 		: INITIAL_STATE.addDispatcherPopupVisible,
				addDispatcherData 	 			: INITIAL_STATE.addDispatcherData,
			}
		case ADD_USER_TO_DISPATCH_COMPANY_FAIL:
			return state

		default:
			return state
	}
}

