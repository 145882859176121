import { RiderKeys } from './../actions/types'

const INITIAL_STATE = {
	creditCards 				: undefined,
	creditCardsLoaded 			: false,
	createPickUpData 			: undefined,
	createDestinationData 		: undefined,

	createLinkToOrgData: {
		title		: 'Will this trip be linked to an organization?',
		value 		: 'No',
		valid 		: true,
		validLabel 	: '',
	},
	createOrgData: {
		title		: 'Select an organization this trip will be associated with.',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},
	createOrgPayingData: {
		title		: 'Will the selected organization be paying for this trip?',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},
	createOrgResForData: {
		title		: 'Who will this reservation be made for?',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},
	createOrgPaxAliasData: {
		title		: 'Passenger Alias',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},
	createOrgPaxPhoneData: {
		title		: 'Passenger Phone',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},
	createOrgPaxEmailData: {
		title		: 'Passenger Email Address',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},
	createVehicleTypeData : {
		title		: 'Vehicle Type',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},
	createPetsData: {
		title		: 'Traveling with pets? (Must be caged)',
		value 		: '',
		valid 		: true,
		validLabel 	: '',
	},
	createAdditionalNotesData: {
		title		: 'Additional Notes',
		value 		: '',
		valid 		: true,
		validLabel 	: '',
	},
	createPickUpTimeData: {
		title		: 'Pick Up Time',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},
	createPromoData: {
		title		: 'Promo Code',
		value 		: '',
		valid 		: true,
		validLabel 	: '',
	},
	createCreditCardData: {
		title		: 'Select a Credit Card',
		value 		: 'New Card',
		valid 		: false,
		validLabel 	: 'Required',
	},

	createVehicleOptions 		: undefined,
	createMinPickUpTime 		: undefined,
	createPricingInformation 	: undefined,

	riderModel 					: undefined,
	riderModelLoaded 			: false,
	riderWatcherRef 			: undefined,

	organizations 	 			: undefined,
	organizationWatchers 		: undefined,

	liveRequests 	 			: undefined,
	liveRequestsLoaded 			: false,
	liveRequestsWatcherRef 		: undefined,

	liveOrganizationRequests 	 		: undefined,
	liveOrganizationRequestsWatchers 	: undefined,

	liveOrganizationSelectedData: {
		title		: 'View Requests For',
		value 		: { name: 'Myself', uid: 'Myself' },
		valid 		: false,
		validLabel 	: 'Required',
	},

	historicRequests 	 		: undefined,
	historicRequestsLoaded 		: false,
	historicRequestsWatcherRef 	: undefined,

	historicOrganizationRequests 	 		: undefined,
	historicOrganizationRequestsWatchers 	: undefined,

	historicOrganizationSelectedData: {
		title		: 'View Requests For',
		value 		: { name: 'Myself', uid: 'Myself' },
		valid 		: false,
		validLabel 	: 'Required',
	},

	liveRequest 				: undefined,
	liveRequestLoaded 			: false,
	liveRequestWatcherRef 		: undefined,

	historicRequest 				: undefined,
	historicRequestLoaded 			: false,
	historicRequestWatcherRef 		: undefined,

	driverModel 				: undefined,
	driverModelLoaded 			: false,
	driverWatcherRef 			: undefined,

	driverLocation 				: undefined,
	driverLocationLoaded 		: false,
	driverLocationWatcherRef	: undefined,

	organization 				: undefined,
	organizationLoaded 			: false,
	organizationWatcherRef		: undefined,
}

export default (state = INITIAL_STATE, action) => {
	const {
		FETCH_RIDER_CREDIT_CARDS,
		CREATE_LINK_TO_ORG_CHANGED,
		CREATE_ORG_CHANGED,
		CREATE_ORG_PAYING_CHANGED,
		CREATE_ORG_RES_FOR_CHANGED,
		CREATE_ORG_PAX_ALIAS_CHANGED,
		CREATE_ORG_PAX_PHONE_CHANGED,
		CREATE_ORG_PAX_EMAIL_CHANGED,

		CREATE_PICK_UP_CHANGED,
		CREATE_DESTINATION_CHANGED,
		CREATE_VEHICLE_TYPE_CHANGED,
		CREATE_PETS_CHANGED,
		CREATE_NOTES_CHANGED,
		CREATE_PICK_UP_TIME_CHANGED,
		CREATE_CREDIT_CARD_CHANGED,
		CREATE_PROMO_CHANGED,

		FETCH_CREATE_VEHICLES,
		FETCH_CREATE_MIN_PICK_UP_TIME,
		FETCH_CREATE_PRICING_INFORMATION,

		CREATE_REQUEST_SUCCESS,
		CREATE_REQUEST_FAIL,

		FETCH_RIDER,
		SAVE_RIDER_WATCHER,
		REMOVE_RIDER_WATCHER,

		FETCH_RIDER_ORGANIZATION,
		SAVE_RIDER_ORGANIZATION_WATCHER,
		REMOVE_RIDER_ORANIZATION_WATCHERS,

		FETCH_LIVE_REQUESTS,
		SAVE_LIVE_REQUESTS_WATCHER,
		REMOVE_LIVE_REQUESTS_WATCHER,

		FETCH_LIVE_ORGANIZATION_REQUESTS,
		SAVE_LIVE_ORGANIZATION_REQUESTS_WATCHER,
		REMOVE_LIVE_ORGANIZATION_REQUESTS_WATCHER,

		LIVE_ORGANIZATION_SELECTED_CHANGED,

		FETCH_HISTORIC_REQUESTS,
		SAVE_HISTORIC_REQUESTS_WATCHER,
		REMOVE_HISTORIC_REQUESTS_WATCHER,

		FETCH_LIVE_REQUEST,
		SAVE_LIVE_REQUEST_WATCHER,
		REMOVE_LIVE_REQUEST_WATCHER,

		FETCH_HISTORIC_REQUEST,
		SAVE_HISTORIC_REQUEST_WATCHER,
		REMOVE_HISTORIC_REQUEST_WATCHER,

		FETCH_HISTORIC_ORGANIZATION_REQUESTS,
		SAVE_HISTORIC_ORGANIZATION_REQUESTS_WATCHER,
		REMOVE_HISTORIC_ORGANIZATION_REQUESTS_WATCHER,

		HISTORIC_ORGANIZATION_SELECTED_CHANGED,

		FETCH_REQUEST_RIDER,
		SAVE_REQUEST_RIDER_WATCHER,
		REMOVE_REQUEST_RIDER_WATCHER,

		FETCH_REQUEST_RIDER_LOCATION,
		SAVE_REQUEST_RIDER_LOCATION_WATCHER,
		REMOVE_REQUEST_RIDER_LOCATION_WATCHER,

		FETCH_DRIVER,
		SAVE_DRIVER_WATCHER,
		REMOVE_DRIVER_WATCHER,

		FETCH_DRIVER_LOCATION,
		SAVE_DRIVER_LOCATION_WATCHER,
		REMOVE_DRIVER_LOCATION_WATCHER,

		FETCH_REQUEST_ORGANIZATION,
		SAVE_REQUEST_ORGANIZATION_WATCHER,
		REMOVE_REQUEST_ORGANIZATION_WATCHER,

	} = RiderKeys

	switch (action.type) {
		case FETCH_RIDER_CREDIT_CARDS:
			if (!action.payload || (action.payload && !action.payload.sources)){
				return { ...state, creditCards: undefined, creditCardsLoaded: true }
			}
			const { sources, default_source } = action.payload
			const { data } = sources

			const createCreditCardData = { ...state.createCreditCardData, value: default_source || INITIAL_STATE.createCreditCardData.value, valid: default_source ? true : false  }

			return { ...state, creditCards: data, createCreditCardData, creditCardsLoaded: true }

		case CREATE_LINK_TO_ORG_CHANGED:
			return { ...state, createLinkToOrgData: { ...state.createLinkToOrgData, ...action.payload } }
		case CREATE_ORG_CHANGED:
			return { ...state, createOrgData: { ...state.createOrgData, ...action.payload } }
		case CREATE_ORG_PAYING_CHANGED:
			return { ...state, createOrgPayingData: { ...state.createOrgPayingData, ...action.payload } }
		case CREATE_ORG_RES_FOR_CHANGED:
			return { ...state, createOrgResForData: { ...state.createOrgResForData, ...action.payload } }
		case CREATE_ORG_PAX_ALIAS_CHANGED:
			return { ...state, createOrgPaxAliasData: { ...state.createOrgPaxAliasData, ...action.payload } }
		case CREATE_ORG_PAX_PHONE_CHANGED:
			return { ...state, createOrgPaxPhoneData: { ...state.createOrgPaxPhoneData, ...action.payload } }
		case CREATE_ORG_PAX_EMAIL_CHANGED:
			return { ...state, createOrgPaxEmailData: { ...state.createOrgPaxEmailData, ...action.payload } }
		case CREATE_PICK_UP_CHANGED:
			return { ...state, createPickUpData: action.payload }
		case CREATE_DESTINATION_CHANGED:
			return { ...state, createDestinationData: action.payload }
		case CREATE_VEHICLE_TYPE_CHANGED:
			return { ...state, createVehicleTypeData: { ...state.createVehicleTypeData, ...action.payload } }
		case CREATE_PETS_CHANGED:
			return { ...state, createPetsData: { ...state.createPetsData, ...action.payload } }
		case CREATE_NOTES_CHANGED:
			return { ...state, createAdditionalNotesData: { ...state.createAdditionalNotesData, ...action.payload } }
		case CREATE_PICK_UP_TIME_CHANGED:
			return { ...state, createPickUpTimeData: { ...state.createPickUpTimeData, ...action.payload } }
		case CREATE_CREDIT_CARD_CHANGED:
			return { ...state, createCreditCardData: { ...state.createCreditCardData, ...action.payload } }
		case CREATE_PROMO_CHANGED:
			return { ...state, createPromoData: { ...state.createPromoData, ...action.payload } }

		case FETCH_CREATE_VEHICLES:
			return { ...state, createVehicleOptions: action.payload }
		case FETCH_CREATE_MIN_PICK_UP_TIME:
			return { ...state, createMinPickUpTime: action.payload }
		case FETCH_CREATE_PRICING_INFORMATION:
			return { ...state, createPricingInformation: action.payload }

		case CREATE_REQUEST_SUCCESS:
			return { ...state,
				creditCards 				: INITIAL_STATE.creditCards,
				creditCardsLoaded 			: INITIAL_STATE.creditCardsLoaded,
				createLinkToOrgData 		: INITIAL_STATE.createLinkToOrgData,
				createOrgData 				: INITIAL_STATE.createOrgData,
				createOrgPayingData 		: INITIAL_STATE.createOrgPayingData,
				createOrgResForData 		: INITIAL_STATE.createOrgResForData,
				createOrgPaxAliasData 		: INITIAL_STATE.createOrgPaxAliasData,
				createOrgPaxPhoneData 		: INITIAL_STATE.createOrgPaxPhoneData,
				createOrgPaxEmailData 		: INITIAL_STATE.createOrgPaxEmailData,
				createPickUpData 			: INITIAL_STATE.createPickUpData,
				createDestinationData 		: INITIAL_STATE.createDestinationData,
				createVehicleTypeData 		: INITIAL_STATE.createVehicleTypeData,
				createPetsData 				: INITIAL_STATE.createPetsData,
				createAdditionalNotesData 	: INITIAL_STATE.createAdditionalNotesData,
				createPickUpTimeData 		: INITIAL_STATE.createPickUpTimeData,
				createCreditCardData 		: INITIAL_STATE.createCreditCardData,
				createPromoData 			: INITIAL_STATE.createPromoData,
				createVehicleOptions 		: INITIAL_STATE.createVehicleOptions,
				createMinPickUpTime 		: INITIAL_STATE.createMinPickUpTime,
				createPricingInformation 	: INITIAL_STATE.createPricingInformation,
			}
		case CREATE_REQUEST_FAIL:
			return state

		case FETCH_RIDER:
			return { ...state, riderModel: action.payload, riderModelLoaded: true }
		case SAVE_RIDER_WATCHER:
			return { ...state, riderWatcherRef: action.payload }
		case REMOVE_RIDER_WATCHER:
			return { ...state,
				riderModel 			: INITIAL_STATE.riderModel,
				riderModelLoaded 	: INITIAL_STATE.riderModelLoaded,
				riderWatcherRef 	: INITIAL_STATE.riderWatcherRef,
			}

		case FETCH_RIDER_ORGANIZATION:
			return { ...state, organizations: { ...state.organizations, [action.payload.uid]: action.payload.organizationInfo } }
		case SAVE_RIDER_ORGANIZATION_WATCHER:
			return { ...state, organizationWatchers: { ...state.organizationWatchers, [action.payload.uid]: action.payload.ref } }
		case REMOVE_RIDER_ORANIZATION_WATCHERS:
			return { ...state, organizations: INITIAL_STATE.organizations, organizationWatchers: INITIAL_STATE.organizationWatchers }

		case FETCH_LIVE_REQUESTS:
			return { ...state, liveRequests: action.payload, liveRequestsLoaded: true }
		case SAVE_LIVE_REQUESTS_WATCHER:
			return { ...state, liveRequestsWatcherRef: action.payload }
		case REMOVE_LIVE_REQUESTS_WATCHER:
			return { ...state,
				liveRequests 				: INITIAL_STATE.liveRequests,
				liveRequestsLoaded 			: INITIAL_STATE.liveRequestsLoaded,
				liveRequestsWatcherRef 		: INITIAL_STATE.liveRequestsWatcherRef,
			}

		case FETCH_LIVE_ORGANIZATION_REQUESTS:
			return { ...state, liveOrganizationRequests: { ...state.liveOrganizationRequests, [action.payload.uid]: action.payload.requests } }
		case SAVE_LIVE_ORGANIZATION_REQUESTS_WATCHER:
			return { ...state, liveOrganizationRequestsWatchers: { ...state.liveOrganizationRequestsWatchers, [action.payload.uid]: action.payload.ref } }
		case REMOVE_LIVE_ORGANIZATION_REQUESTS_WATCHER:
			return { ...state, liveOrganizationRequests: INITIAL_STATE.liveOrganizationRequests, liveOrganizationRequestsWatchers: INITIAL_STATE.liveOrganizationRequestsWatchers }

		case LIVE_ORGANIZATION_SELECTED_CHANGED:
			return { ...state, liveOrganizationSelectedData: { ...state.liveOrganizationSelectedData, ...action.payload } }

		case FETCH_HISTORIC_REQUESTS:
			return { ...state, historicRequests: action.payload, historicRequestsLoaded: true }
		case SAVE_HISTORIC_REQUESTS_WATCHER:
			return { ...state, historicRequestsWatcherRef: action.payload }
		case REMOVE_HISTORIC_REQUESTS_WATCHER:
			return { ...state,
				 historicRequests 				: INITIAL_STATE.historicRequests,
				 historicRequestsLoaded 		: INITIAL_STATE.historicRequestsLoaded,
				 historicRequestsWatcherRef 	: INITIAL_STATE.historicRequestsWatcherRef,
			}

		case FETCH_HISTORIC_ORGANIZATION_REQUESTS:
			return { ...state, historicOrganizationRequests: { ...state.historicOrganizationRequests, [action.payload.uid]: action.payload.requests } }
		case SAVE_HISTORIC_ORGANIZATION_REQUESTS_WATCHER:
			return { ...state, historicOrganizationRequestsWatchers: { ...state.historicOrganizationRequestsWatchers, [action.payload.uid]: action.payload.ref } }
		case REMOVE_HISTORIC_ORGANIZATION_REQUESTS_WATCHER:
			return { ...state, historicOrganizationRequests: INITIAL_STATE.historicOrganizationRequests, historicOrganizationRequestsWatchers: INITIAL_STATE.historicOrganizationRequestsWatchers }

		case HISTORIC_ORGANIZATION_SELECTED_CHANGED:
			return { ...state, historicOrganizationSelectedData: { ...state.historicOrganizationSelectedData, ...action.payload } }

		case FETCH_LIVE_REQUEST:
			return { ...state, liveRequest: action.payload, liveRequestLoaded: true }
		case SAVE_LIVE_REQUEST_WATCHER:
			return { ...state, liveRequestWatcherRef: action.payload }
		case REMOVE_LIVE_REQUEST_WATCHER:
			return { ...state,
				liveRequest 			: INITIAL_STATE.liveRequest,
				liveRequestLoaded 		: INITIAL_STATE.liveRequestLoaded,
				liveRequestWatcherRef 	: INITIAL_STATE.liveRequestWatcherRef,
			}

		case FETCH_HISTORIC_REQUEST:
			return { ...state, historicRequest: action.payload, historicRequestLoaded: true }
		case SAVE_HISTORIC_REQUEST_WATCHER:
			return { ...state, historicRequestWatcherRef: action.payload }
		case REMOVE_HISTORIC_REQUEST_WATCHER:
			return { ...state,
				historicRequest 			: INITIAL_STATE.historicRequest,
				historicRequestLoaded 		: INITIAL_STATE.historicRequestLoaded,
				historicRequestWatcherRef 	: INITIAL_STATE.historicRequestWatcherRef,
			}

		case FETCH_REQUEST_RIDER:
			return { ...state, requestRiderModel: action.payload, requestRiderModelLoaded: true }
		case SAVE_REQUEST_RIDER_WATCHER:
			return { ...state, requestRiderWatcherRef: action.paylod }
		case REMOVE_REQUEST_RIDER_WATCHER:
			return { ...state,
				requestRiderModel 			: INITIAL_STATE.requestRiderModel,
				requestRiderModelLoaded 	: INITIAL_STATE.requestRiderModelLoaded,
				requestRiderWatcherRef 		: INITIAL_STATE.requestRiderWatcherRef,
			}

		case FETCH_REQUEST_RIDER_LOCATION:
			return { ...state, requestRiderLocation: action.payload, requestRiderLocationLoaded: true }
		case SAVE_REQUEST_RIDER_LOCATION_WATCHER:
			return { ...state, requestRiderLocationWatcherRef: action.payload }
		case REMOVE_REQUEST_RIDER_LOCATION_WATCHER:
			return { ...state,
				requestRiderLocation 				: INITIAL_STATE.requestRiderLocation,
				requestRiderLocationLoaded 			: INITIAL_STATE.requestRiderLocationLoaded,
				requestRiderLocationWatcherRef 		: INITIAL_STATE.requestRiderLocationWatcherRef,
			}

		case FETCH_DRIVER:
			return { ...state, driverModel: action.payload, driverModelLoaded: true }
		case SAVE_DRIVER_WATCHER:
			return { ...state, driverWatcherRef: action.payload }
		case REMOVE_DRIVER_WATCHER:
			return { ...state,
				driverModel 			: INITIAL_STATE.driverModel,
				driverModelLoaded 		: INITIAL_STATE.driverModelLoaded,
				driverWatcherRef 		: INITIAL_STATE.driverWatcherRef,
			}

		case FETCH_DRIVER_LOCATION:
			return { ...state, driverLocation: action.payload, driverLocationLoaded: true }
		case SAVE_DRIVER_LOCATION_WATCHER:
			return { ...state, driverLocationWatcherRef: action.payload }
		case REMOVE_DRIVER_LOCATION_WATCHER:
			return { ...state,
				driverLocation 				: INITIAL_STATE.driverLocation,
				driverLocationLoaded 		: INITIAL_STATE.driverLocationLoaded,
				driverLocationWatcherRef 	: INITIAL_STATE.driverLocationWatcherRef,
			}

		case FETCH_REQUEST_ORGANIZATION:
			return { ...state, organization: action.payload, organizationLoaded:true }
		case SAVE_REQUEST_ORGANIZATION_WATCHER:
			return { ...state, organizationWatcherRef: action.payload }
		case REMOVE_REQUEST_ORGANIZATION_WATCHER:
			return { ...state,
				organization 				: INITIAL_STATE.organization,
				organizationLoaded 			: INITIAL_STATE.organizationLoaded,
				organizationWatcherRef 		: INITIAL_STATE.organizationWatcherRef,
			}
		default:
			return state
	}
}

