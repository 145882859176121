import { colors } from './../../../config/styles';

export default {
	panelWrapper : {},
	actionButton : {
		width 			: 'auto',
	},
	orgSelectorWrapper: {
		maxWidth 		: 300,
	},
	colorCell : {
		color 			: colors.white,
		textAlign 	 	: 'center',
		paddingTop 		: 5,
		paddingBottom 	: 5,
		fontSize 		: 14,
		borderRadius 	: 3,
	},
	detailLinkWrapper : {
		border          : `1px solid ${colors.lightGray}`,
		borderRadius 	: 5,
		color           : colors.darkGray,
		backgroundColor : colors.white,
		transition 	 	: 'color 0.2s ease-in, background-color 0.2s ease-in, border 0.2s ease-in',

		':hover' : {
			color 				: colors.white,
			backgroundColor 	: colors.blue,
			border         	 	: `1px solid ${colors.blue}`,
		},
		':active' : {
			color 				: colors.white,
			backgroundColor 	: colors.blue,
			border         	 	: `1px solid ${colors.blue}`,
		},
	},
	detailLink : {
		position        : 'relative',
		width           : '100%',
		textDecoration  : 'none',
		display         : 'block',
		textAlign       : 'center',
		paddingTop 		: 5,
		paddingBottom 	: 5,
		paddingRight 	: 5,
		paddingLeft 	: 5,
		fontSize		: 14,
	},
	editPanel : {
		maxWidth 	: 700,
	},
	actionButtonContainer : {
		display 	: 'flex',
	},
}
