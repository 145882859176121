export default {
    container: (size, last) => {
        let style = {
            position: 'relative',
            display: 'inline-block',
            verticalAlign: 'top',
            width: '100%',
            marginBottom: 10,
            textAlign: 'left',
        }

        switch (size) {
            case 'full':
                style = { ...style, width: '100%' }
                break
            case 'half':
                style = {
                    ...style,
                    width: '50%',

                    '@media (max-width: 450px)': {
                        width: '100%',
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                }
                break
            case 'third':
                style = { ...style, width: '33.33%' }
                break
            case 'forth':
                style = { ...style, width: '25%' }
                break
            case 'condensed':
                style = { ...style, width: 'auto' }
                break
            default:
                break
        }

        if (size !== 'full') {
            if (last) {
                style = { ...style, paddingLeft: 5 }
            } else {
                style = { ...style, paddingRight: 5 }
            }
        }

        return style
    },
}
