import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'

import {
	hideCreateAdminDispatchCompanyPopup,
	createDispatchCompany,
} from './../../../../actions'
import { AdminKeys } from './../../../../actions/types'

import TEPopup from './../../../../component/Popup/TEPopup'
import TEMultiStepForm from './../../../../component/Form/TEMultiStepForm'
import TEInputRow from './../../../../component/Form/TEInputRow'

import styles from './styles'

class Create extends Component {
	handleClosePopup = () => {
		this.props.hideCreateAdminDispatchCompanyPopup()
	}
	handleCreate = () => {
		const {
			createDispatchCompany,
			createDispatchCompanyNameData,
		} = this.props

		const data = {
			createDispatchCompanyNameData,
		}

		createDispatchCompany(data)
	}

	render(){
		const {
			visible,

			createDispatchCompanyNameData,
		} = this.props

		return (
			<TEPopup visible={visible} contentStyles={styles.content}>
				<div style={styles.container}>
					<TEMultiStepForm
						stepData={[{
							title 			: 'Details',
							component 		: <Fragment>
									<TEInputRow
										labelForKey='name'
										rowData={createDispatchCompanyNameData}
										onChangeKey={AdminKeys.CREATE_DISPATCH_COMPANY_NAME_CHANGED}
										validationRules={['required']}
										inputExtras={{ placeholder: 'Company Name' }}
									/>
								</Fragment>,
							validateData 	: [createDispatchCompanyNameData]
						}]}
						handleCancelOnClick={this.handleClosePopup}
						handleSubmitOnClick={this.handleCreate}
						popupStyles
					/>
				</div>
			</TEPopup>
		)
	}
}

Create.propTypes = {}

Create.defaultProps = {}

const mapStateToProps = (state) => {
	const {
		createDispatchCompanyNameData,
	} = state.Admin

	return {
		createDispatchCompanyNameData,
	}
}

export default connect(mapStateToProps, {
	hideCreateAdminDispatchCompanyPopup,
	createDispatchCompany,
})(Radium(Create))
