//
//TE Version 0.1.0
//

import React, { Component } from 'react'
import Radium from 'radium'
import PropTypes from 'prop-types'

import DatePicker from 'react-datepicker'

//import styles from './styles'

class TEDatetimeInput extends Component {
	determineValue(value) {
		//Safety for loading data
		if (!value) {
			return null
		}
		if (typeof value !== 'number') {
			return null
		}
		return new Date(value)
	}

	render() {
		const { specialClass, inputData, ...rest } = this.props

		const {
			value,
			//To be used later
			//validLabel,
			//valid,
		} = inputData

		return (
			<DatePicker selected={this.determineValue(value)} className={specialClass} {...rest} />
		)
	}
}

TEDatetimeInput.propTypes = {
	style: PropTypes.object,
	inputData: PropTypes.shape({
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
		validLabel: PropTypes.string.isRequired,
		valid: PropTypes.bool.isRequired,
	}),
	onChange: PropTypes.func.isRequired,
	showTimeSelect: PropTypes.bool,
	dateFormat: PropTypes.string,
	disabled: PropTypes.bool,
}

TEDatetimeInput.defaultProps = {
	showTimeSelect: false,
	disabled: false,
}

export default Radium(TEDatetimeInput)
