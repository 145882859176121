import { createSelector } from 'reselect'

export const getRiderLiveRequests  				= state => state.Rider.liveRequests
export const getRiderHistoricRequests  			= state => state.Rider.historicRequests

export const getRiderLiveRequestsArray = createSelector(
	getRiderLiveRequests,
	(requests) => {
		const requestArray = []
		for (const key in requests){
			const dataToMap = requests[key]
			dataToMap.uid = key
			requestArray.push(dataToMap)
		}
		return requestArray
	}
)
export const getRiderHistoricRequestsArray = createSelector(
	getRiderHistoricRequests,
	(requests) => {
		const requestArray = []
		for (const key in requests){
			const dataToMap = requests[key]
			dataToMap.uid = key
			requestArray.push(dataToMap)
		}
		return requestArray
	}
)
