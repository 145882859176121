//
//TE Version 0.1.0
//

import React, { Component } from 'react'
import Radium from 'radium'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { inputHasChanged } from './../../../actions'

import TERow from './../TERow'
import TELabel from './../TELabel'
import TERadioButtonInput from './../TERadioButtonInput'

import styles from './styles'

class TESegmentedGroup extends Component {
	constructor(props) {
		super(props)

		this.state = { firstDataValidation : false }

		this.shouldRenderChangedStyle					= this.shouldRenderChangedStyle.bind(this)
		this.handleInputChange 							= this.handleInputChange.bind(this)
	}
	componentDidMount() {
		//Validate Data On Load
		const {
			inputHasChanged,
			onChangeKey,
			validationRules,
			checkedInputData,
			confirmValue,
			onChange
		} = this.props
		const { value } = checkedInputData

		if (onChange){
			const event = { target: { value } }
			onChange(event)
		}else{
			const data = {
				onChangeKey,
				validationRules,
				value,
				confirmValue
			}
			inputHasChanged(data)
		}
	}
	componentWillReceiveProps(nextProps){
		if (!this.state.firstDataValidation){
			if (nextProps && this.props){
				if (nextProps.checkedInputData && this.props.checkedInputData){
					if (!this.state.firstDataValidation){
						if (nextProps.checkedInputData.value !== this.props.checkedInputData.value){
							this.setState({ firstDataValidation: true })
							const {
								inputHasChanged,
								onChangeKey,
								validationRules,
								checkedInputData,
								confirmValue,
								onChange
							} = nextProps
							const { value } = checkedInputData

							if (onChange){
								const event = { target: { value } }
								onChange(event)
							}else{
								const data = {
									onChangeKey,
									validationRules,
									value,
									confirmValue
								}

								inputHasChanged(data)
							}
						}
					}
				}
			}
		}
	}

	manipulateRowData({rowData, labelForKey = ''}){
		//Allowing For Greater Shorthand
		if (typeof rowData === 'string'){
			const label = rowData
			const value = rowData
			const key = labelForKey + rowData
			return { label, value, key }
		}else if(typeof rowData === 'object'){
			let {
				label,
				value 	: value = label,
				key 	: key 	= labelForKey + label,
			} = rowData
			return { label, value, key }
		}
		return rowData
	}

	shouldButtonBeCheck({checkedInputData, value}){
		//Doesn't require complex data object only.
		/*
		//Not Currently Used
		if (typeof checkedInputData === 'string'){
			return checkedInputData === value
		}else if (typeof checkedInputData === 'object'){
			return checkedInputData.value === value
		}
		*/
		return checkedInputData.value === value
	}
	shouldRenderChangedStyle = ({checkedInputData, value}) => {
		if (this.shouldButtonBeCheck({checkedInputData, value})){
			const checkStyles = {
				...styles.label,
				...styles.checkedSegmented
			}
			return checkStyles
		}
		return styles.label
	}
	handleInputChange(event){
		const { value, id } = event.target

		const {
			inputHasChanged,
			onChangeKey,
			validationRules,
			confirmValue
		} = this.props

		const data = {
			onChangeKey,
			validationRules,
			value,
			confirmValue,
			id,
		}

		inputHasChanged(data)
	}
	render() {
		const {
			size,
			last,
			title,
			onChange,
			checkedInputData,
			buttonArray,
			labelForKey,
			validationRules = [],
			inline,
			disabled,
			rowStyles,
			titleStyles,
			groupContainerStyles,
			labelStyles,
			checkedLabelStyles,
			labelWrapperStyles,
		} = this.props

		return (
			<TERow style={{ ...inline && styles.inlineRow, ...rowStyles}} size={size} last={last}>
				<TELabel
					labelText={title || checkedInputData.title}
					style={{ ...inline ? styles.inlineTitle : styles.title, ...titleStyles}}
					required={validationRules.includes('required')}
					disabled={disabled}
				/>
				<div style={{ ...inline ? styles.inlineSegmentedContainer : styles.segmentedContainer, ...groupContainerStyles }}>
					{buttonArray.map((rowData, index) => {
						const { label, value, key } = this.manipulateRowData({rowData, labelForKey})

						return (
							<div key={key} style={[styles.labelWrapper, labelWrapperStyles]}>
								<TERadioButtonInput
									value={value}
									onChange={onChange || this.handleInputChange}
									style={styles.input}
									id={key}
									checked={this.shouldButtonBeCheck({checkedInputData, value})}
									disabled={disabled}
								/>
								<label
									htmlFor={key}
									key={key}
									style={[
										styles.label,
										index === 0 && styles.leftSegment,
										index+1 === buttonArray.length && styles.rightSegment,
										labelStyles,
										this.shouldButtonBeCheck({checkedInputData, value}) && styles.checkedSegmented,
										this.shouldButtonBeCheck({checkedInputData, value}) && checkedLabelStyles,
										disabled && styles.disabledLabel,
									]}
								>
									{label}
								</label>
							</div>
						)
					})}
				</div>
			</TERow>
		)
	}
}

TESegmentedGroup.propTypes = {
	title 		: PropTypes.string,
	onChange 	: PropTypes.func,
	inline		: PropTypes.bool,
}

TESegmentedGroup.defaultProps = {}

const mapStateToProps = (state) => {
	return { }
}

export default connect(mapStateToProps, { inputHasChanged })(Radium(TESegmentedGroup))
