import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import tzlookup from 'tz-lookup'

import { hideDispatchUpdateStatusesPopup, saveDispatchUpdateStatuses } from './../../../../actions'

import TEPopupForm from './../../../../component2/Popup/TEPopupForm'
import TEDatetimeRow from './../../../../component2/Form/TEDatetimeRow'

import { convertToDateTimeShort24 } from './../../../../helpers'
import styles from './styles'

class EditStatusesPopup extends Component {
	state = {
		onTheWay: undefined,
		onLocation: undefined,
		passengerInCar: undefined,
		droppedOff: undefined,
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		const { liveRequest } = nextProps

		if (liveRequest && liveRequest.statusTimes && liveRequest !== prevState.liveRequest) {
			const { startingPickup, onLocation, pickedUp, droppedOff } = liveRequest.statusTimes
			return {
				liveRequest,
				onTheWay: startingPickup * 1000,
				onLocation: onLocation * 1000,
				passengerInCar: pickedUp * 1000,
				droppedOff: droppedOff * 1000,
			}
		}

		return { liveRequest }
	}
	handleClosePopup = () => {
		this.props.hideDispatchUpdateStatusesPopup()
	}
	handleSave = () => {
		const { onTheWay, onLocation, passengerInCar, droppedOff } = this.state
		const { uid, saveDispatchUpdateStatuses, liveRequest } = this.props

		const data = {
			uid,
			liveRequest,
			onTheWay,
			onLocation,
			passengerInCar,
			droppedOff,
		}

		saveDispatchUpdateStatuses(data)
	}

	render() {
		const { onTheWay, onLocation, passengerInCar, droppedOff } = this.state
		const {
			visible,

			liveRequest,
		} = this.props

		if (!liveRequest) {
			return null
		}
		const { startLat, startLon } = liveRequest

		return (
			<TEPopupForm
				visible={visible}
				contentStyles={styles.content}
				onSubmit={this.handleSave}
				onSubmitTitle="Save Timestamps"
				onClose={this.handleClosePopup}
			>
				<h2 style={styles.title}>Edit Timestamps</h2>
				<div style={styles.container}>
					<div style={styles.dataRow}>
						<span style={styles.dataTitle}>Date Created</span>
						<span style={styles.dataValue}>
							{convertToDateTimeShort24(
								liveRequest.createdAt * 1000,
								tzlookup(startLat, startLon)
							)}
						</span>
					</div>
					<div style={styles.dataRow}>
						<span style={styles.dataTitle}>Requested Pick Up</span>
						<span style={styles.dataValue}>
							{convertToDateTimeShort24(
								liveRequest.requestTime * 1000,
								tzlookup(startLat, startLon)
							)}
						</span>
					</div>
					<TEDatetimeRow
						labelForKey="onTheWay"
						title="On The Way"
						value={onTheWay}
						onChange={(onTheWay) => this.setState({ onTheWay })}
						showTimeSelect={true}
						dateFormat="MM/dd/YY HH:mm"
						timeFormat="HH:mm"
					/>
					<TEDatetimeRow
						labelForKey="onLocation"
						title="On Location"
						value={onLocation}
						onChange={(onLocation) => this.setState({ onLocation })}
						showTimeSelect={true}
						dateFormat="MM/dd/YY HH:mm"
						timeFormat="HH:mm"
					/>
					<TEDatetimeRow
						labelForKey="pickUpTime"
						title="Passenger In Vehicle"
						value={passengerInCar}
						onChange={(passengerInCar) => this.setState({ passengerInCar })}
						showTimeSelect={true}
						dateFormat="MM/dd/YY HH:mm"
						timeFormat="HH:mm"
					/>
					<TEDatetimeRow
						labelForKey="droppedOff"
						title="Dropped Off"
						value={droppedOff}
						onChange={(droppedOff) => this.setState({ droppedOff })}
						showTimeSelect={true}
						dateFormat="MM/dd/YY HH:mm"
						timeFormat="HH:mm"
					/>
				</div>
			</TEPopupForm>
		)
	}
}

const mapStateToProps = (state) => {
	return {}
}

export default connect(
	mapStateToProps,
	{
		hideDispatchUpdateStatusesPopup,
		saveDispatchUpdateStatuses,
	}
)(Radium(EditStatusesPopup))
